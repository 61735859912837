import { Component, OnInit, ViewChild } from '@angular/core';
import { EventService } from '../../services/event.service'
import { Auth } from 'aws-amplify'
import { HttpService } from '../../services/http.service'
import { APIService } from '../../API.service';  
import * as DBTypes from '../../API.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ModalController } from '@ionic/angular';

import {DetailsComponent } from './details/details.component'

@Component({
  selector: 'app-order',
  templateUrl: './order.page.html',
  styleUrls: ['./order.page.scss'],
})
export class OrderPage implements OnInit {
  
  public authState = { signedIn: false,
                       groups: [""]};
  
  public orderList:any [];
  displayedColumns: string[] = ['select', 'date', 'start', 'dest', 'reference', 'price', 'eta', 'status', 'storno'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public dataSource: MatTableDataSource<any> ;
  selection: SelectionModel<any>
  public profile = new Profile('', '', '', '', '', '','', '', '', '', '', '', '','', '','','');
  
  public now: Date = new Date();

    
  
  constructor(
    
      private _events: EventService,
  //    public amplify:AmplifyService,
      private _api:APIService,
      private _modal: ModalController,
      private _http: HttpService,
      
      ) 
      { 
            setInterval(() => {
          this.now = new Date();
        }, 10000);
      }

  async ngOnInit() {
  
    
     this.dataSource = new MatTableDataSource<any>(this.orderList); 
     this.selection = new SelectionModel<any>(true, []);
     
     this.dataSource.paginator = this.paginator;
     this.dataSource.sort = this.sort;
     this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    
    const user = await Auth.currentUserInfo();
    const session = await Auth.currentSession();
      
     if (user==null) {
     //   this.presentModal()
      }
      else{
        
        this.profile.id = user.username;
        if(user.attributes['gender']) this.profile.gender = user.attributes['gender'];
        if(user.attributes['given_name']) this.profile.firstName= user.attributes['given_name'];
        if(user.attributes['family_name'])  this.profile.lastName= user.attributes['family_name'];
        if(user.attributes['email']) this.profile.email = user.attributes['email'];
        if(user.attributes['custom:cell']) this.profile.cell= user.attributes['custom:cell'];
        if(user.attributes['phone_number'])  this.profile.officephone=  user.attributes['phone_number'];
        if(user.attributes['locale'])  this.profile.language = user.attributes['locale'];
        this.authState.signedIn = true;
  //      this.authState.groups = session.idToken.payload['cognito:groups']
                    
        //this._events.publish({name:'data:AuthState', params:this.authState});
      }
      
      this.getOrder();
  }
  
  async getOrder()
  {
    try{   
          	var result = await this._api.OrderByCustomer(this.profile.id, null, DBTypes.ModelSortDirection.DESC, null, 100)
          	
          	this.orderList = result.items;
          	
          	this.dataSource.data = this.orderList;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
            this.dataSource.sort = this.sort;
            
            
            for (var i=0;i < this.orderList.length;i++) {
              
              var sortable = [];
              this.orderList[i].transport.route.items = this.sortObjectProperties(this.orderList[i].transport.route.items, 'sequence');
              
            }
  		} catch (e) {
  		  //console.log(e);
  		}
  }
  
  getListSize(i)
  {
    var count=0;
    
    for (var item in this.orderList[i].transport.route.items ){
      count++
    }
    
    return count ;
  }
  
   public sortObjectProperties(obj, sortValue){
      var keysSorted = Object.keys(obj).sort(function(a,b){return obj[a][sortValue] > obj[b][sortValue] ? 1 : obj[a][sortValue] < obj[b][sortValue] ? -1 : 0});
      var objSorted = {};
      
      for(var i = 0; i < keysSorted.length; i++){
          objSorted[i] = obj[keysSorted[i]];
      }
      return objSorted;
    }
  
  applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  
  sortingDataAccessor(item, property) {
    if (property.includes('.')) {
      return property.split('.')
        .reduce((object, key) => object[key], item);
    }
    return item[property];
  }
  
    /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }
  
   /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  
  async open(row)
  {
    
      const modal = await this._modal.create({
        component: DetailsComponent,
        backdropDismiss: true, 
        swipeToClose:true,
        cssClass: 'detail-modal-css',
        componentProps: {
          order: row
          }
    });
    
    return await modal.present();
  }

  async cancel(cell: number){
    
      const query:DBTypes.UpdateOrderInput = { 
        id: this.orderList[cell].id,
        reference: this.orderList[cell].reference,
        status: DBTypes.Status.cancelled,
      }
    
      try{   
          	var result = await this._api.UpdateOrder(query)
          	//console.log('Query', result)
          	
          	//this.orderList.splice(cell,1)
          	this.dataSource.data = this.orderList;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
            this.dataSource.sort = this.sort;
  		} catch (e) {
  		  //console.log(e);
  		}
  		
  		try {
  		  
  		  this._http.cancelOrder(this.orderList[cell], this.profile).subscribe({
                           next: (data) => {},
                           complete: () => {}})
                           
        this.orderList[cell].status = "cancelled"
        
  		} catch (e) {
  		  //console.log(e);
  		}
  }
}



export class Profile {
    constructor(
        public id: string,
        public username: string,
        public email: string,
        public organisation: string,
        public firstName: string,
        public cell: string,
        public lastName: string,
        public gender: String,
        public nationality: string,
        public language: string = "de",
        public drivinglicense: string,
        public passport: string,
        public aboutMe: string,
        public address:string,
        public imageUrl: string,
        public group:string,
        public officephone:string,
    ){}
}