import { UntypedFormControl, AbstractControl, AsyncValidatorFn,ValidationErrors, UntypedFormGroup } from '@angular/forms';
import { HttpService } from '../services/http.service'
import { Observable, of} from 'rxjs';

import * as xml2js from 'xml2js';
import { map } from 'rxjs/operators';

export class VatValidator {

    static isValid(control: UntypedFormControl): any {

        if(control.value.length < 3){
            return {
                "not a valid VAT number": true
                
            };
        }

        return null;
    }
    
  static vatValidator(http:HttpService): AsyncValidatorFn {
  return (control: AbstractControl):Promise<ValidationErrors  | null> | Observable<ValidationErrors | null>  => {
      const ret: ValidationErrors = { isInvalidVATNumber: true };
      const una: ValidationErrors = { isServiceUnavailable: true };
    
        var parser = new xml2js.Parser();
        
        if (control.value == null || control.value === '') {
            return of(ret);
        }
      
      
      let q = new Promise<ValidationErrors>(
        (resolve, reject)=>  { 
            var name = control.value;
            if (name == '') {
              resolve(null);
            }
            else {

                if (control.value)
                 {
                        var sub = http.checkCompany(control.value).subscribe({
                            next: async (data) => { 
                   
                            await parser.parseString(data.data.result.countryCode, function (err, result) {
                               
                              //console.log('VAT', result)
                               
                               
                                   
                                if(result["env:Envelope"]["env:Body"][0]["env:Fault"] && result["env:Envelope"]["env:Body"][0]["env:Fault"][0].faultstring[0]=="INVALID_INPUT")
                                    resolve(ret);
                                    
                                else if (result["env:Envelope"]["env:Body"][0]["env:Fault"] && result["env:Envelope"]["env:Body"][0]["env:Fault"][0].faultstring[0]=="GLOBAL_MAX_CONCURRENT_REQ")
                                    resolve(una);    
                                    
                                else if (result["env:Envelope"]["env:Body"][0]["env:Fault"] && result["env:Envelope"]["env:Body"][0]["env:Fault"][0].faultstring[0]=="MS_MAX_CONCURRENT_REQ")
                                    resolve(una);
                                
                                else if (result["env:Envelope"]["env:Body"][0]["env:Fault"] && result["env:Envelope"]["env:Body"][0]["env:Fault"][0].faultstring[0]=="SERVICE_UNAVAILABLE")
                                    resolve(una);
                                    
                                else if(result["env:Envelope"]["env:Body"][0]["ns2:checkVatResponse"][0]["ns2:valid"][0]=="false") 
                                    resolve(ret);
                                  
                                else if(result["env:Envelope"]["env:Body"][0]["ns2:checkVatResponse"][0]["ns2:valid"][0]=="true") 
                                    resolve(null)
                         
                             });
                  
                         },
    
                     error: (e) => { }
                    })
                }   
                }
            })
        


        return q;
      
   
        }
    }
    
    
  static theFormValidator(http:HttpService): AsyncValidatorFn {
  return (group: UntypedFormGroup):Promise<ValidationErrors  | null> | Observable<ValidationErrors | null>  => {
      const ret: ValidationErrors = { isInvalid: true };
    
        var parser = new xml2js.Parser();
        
        const taxNo = group.get('taxNo');
        const street = group.get('street');
        const name = group.get('organisationname');
        const zip_code = group.get('zip_code');
        const city = group.get('city');
        
      ////console.log('Check')
    
      let q = new Promise<ValidationErrors>(
        (resolve, reject)=>  { 
            if (street.value == '') {
              resolve(null);
            }
            else {
                  if (taxNo.value && street.value && name.value && zip_code.value && city.value)
                    {
                   var sub = http.checkCompanyApprox(taxNo.value, street.value, name.value, zip_code.value, city.value).subscribe({
                            next: async (data) => { 
                   
                           await parser.parseString(data.data.result.countryCode, function (err, result) {
                         
                                ////console.log('Check ALL', result) 
                                if(result['soap:Envelope']['soap:Body']['0'].checkVatApproxResponse['0'].valid[0]=="false")
                                    resolve(of(ret));
                                   
                                if(result['soap:Envelope']['soap:Body']['0'].checkVatApproxResponse['0'].valid[0]=="true") 
                                    resolve(null)
                             });
                  
                         },
    
                     error: (e) => { }
                    })
                    }
                }   
                
            })
      
        return q;
      
   
        }
    }
}

