<ion-header translucent>
  <ion-toolbar>
    <img style="position:absolute; left: 0%;" src="./assets/icon/logo-final.png" >
    <ion-title><font style="position:absolute; left: 60px;"> <b>DML24</b></font> <br> <font style="position:absolute; left: 60px;" color="#c0c0c0" size ="1">Anmeldung</font></ion-title>
    <ion-buttons slot="primary" >
      <ion-button (click)="closeDialog()"> 
      <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
      <ion-button *ngIf="state.signedIn == true" (click)="signout()"> 
      <ion-icon slot="icon-only" name="log-out-outline"></ion-icon>
      </ion-button>
  </ion-buttons>
  </ion-toolbar>
</ion-header>



<!-- <div *ngIf="state.signedIn==false" class="auth" > 
      <amplify-auth-container>
        <amplify-authenticator usernameAlias="email">
          <amplify-sign-up
            slot="sign-up"
            usernameAlias="email"
            [formFields]="formFields"
            header-text="Willkommen"
          ></amplify-sign-up>
          
          </amplify-authenticator>
        </amplify-auth-container>
</div>

<amplify-authenticator [formFields]="formFields">
-->
<amplify-authenticator >
  <ng-template amplifySlot="header">
    <div style="padding: var(--amplify-space-large); text-align: center">
  <!--    <img
        class="amplify-image"
        alt="Amplify logo"
        src="https://docs.amplify.aws/assets/logo-dark.svg"
      />-->
    </div>
  </ng-template>

  <ng-template amplifySlot="sign-in-header">
    <h3
      class="amplify-heading"
      style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
    >
      Sign in to your account
    </h3>
  </ng-template>

  <ng-template amplifySlot="sign-in-footer">
    <div style="text-align: center">
      <button
        (click)="authenticator.toResetPassword()"
        class="amplify-button amplify-field-group__control"
        data-fullwidth="false"
        data-size="small"
        data-variation="link"
        type="button"
        style="font-weight: normal"
      >
        Reset Password
      </button>
    </div>
  </ng-template>

  <ng-template amplifySlot="sign-up-footer">
    <div style="text-align: center">
      <button
        (click)="authenticator.toSignIn()"
        class="amplify-button amplify-field-group__control"
        data-fullwidth="false"
        data-size="small"
        data-variation="link"
        type="button"
        style="font-weight: normal"
      >
        Back to Sign In
      </button>
    </div>
  </ng-template>

  <ng-template amplifySlot="footer">
    <div style="padding: var(--amplify-space-large); text-align: center">
      <p class="amplify-text" style="color: var(--amplify-colors-neutral-80)">
        © All Rights Reserved
      </p>
    </div>
  </ng-template>

  <ng-template amplifySlot="confirm-sign-up-header">
    <h3
      class="amplify-heading"
      style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
    >
      Enter Information:
    </h3>
  </ng-template>

  <ng-template amplifySlot="confirm-sign-up-footer">
    <div>Footer Information</div>
  </ng-template>

  <ng-template amplifySlot="setup-totp-header">
    <h3
      class="amplify-heading"
      style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
    >
      Enter Information:
    </h3>
  </ng-template>

  <ng-template amplifySlot="setup-totp-footer">
    <div>Footer Information</div>
  </ng-template>

  <ng-template amplifySlot="confirm-sign-in-header">
    <h3
      class="amplify-heading"
      style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
    >
      Enter Information:
    </h3>
  </ng-template>

  <ng-template amplifySlot="confirm-sign-in-footer">
    <div>Footer Information</div>
  </ng-template>

  <ng-template amplifySlot="reset-password-header">
    <h3
      class="amplify-heading"
      style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
    >
      Enter Information:
    </h3>
  </ng-template>

  <ng-template amplifySlot="reset-password-footer">
    <div>Footer Information</div>
  </ng-template>

  <ng-template amplifySlot="confirm-reset-password-header">
    <h3
      class="amplify-heading"
      style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
    >
      Enter Information:
    </h3>
  </ng-template>

  <ng-template amplifySlot="confirm-reset-password-footer">
    <div>Footer Information</div>
  </ng-template>

</amplify-authenticator>

<div *ngIf="state.signedIn==true"> 
<mat-dialog-content [formGroup]="contactFormGroup"> 

<ion-header translucent>
  <ion-toolbar>
    <ion-title>
     Persönliche Daten & Abrechnungsmodalitäten
    </ion-title>
    <ion-buttons slot="secondary" >
      <ion-button type="submit" *ngIf="contactFormGroup.dirty" (click)="saveChanges()">
        <ion-icon slot="icon-only" name="save-outline" ></ion-icon>
      </ion-button>
      </ion-buttons>
  </ion-toolbar>
</ion-header>
    
     <mat-tab-group dynamicHeight backgroundColor="#C4C4C4">
      <mat-tab label="Privatdaten"> 
      <p></p>
     <mat-form-field  appearance="outline" class="example-full-width">
      <mat-label>Ansprache</mat-label>
      <mat-icon matPrefix *ngIf="gender.valid && (!gender.errors )"  [style.color]="'#39a42b'">check_circle</mat-icon>
       <mat-icon matSuffix  matTooltip="Bitte geben Sie Ihre Ansprache ein." [style.color]="'#c4c4c4'">help</mat-icon>
      <mat-select formControlName="gender" class="form-control" required>
        <mat-option value="f">Herr</mat-option>
        <mat-option value="m">Frau</mat-option>
        <mat-option value="d">Sie</mat-option> 
      </mat-select>
    </mat-form-field>
        
      <mat-form-field appearance="outline" class="example-full-width"> 
        <mat-label>Vorname</mat-label>
        <mat-icon matPrefix *ngIf="firstName.valid && (!firstName.errors )" [style.color]="'#39a42b'">check_circle</mat-icon>
        <input matInput type="text" placeholder="Your first name" formControlName="firstName">
      </mat-form-field>

      <mat-form-field appearance="outline" class="example-full-width"> 
        <mat-label>Nachname</mat-label>
        <mat-icon matPrefix *ngIf="lastName.valid && (!lastName.errors )" [style.color]="'#39a42b'">check_circle</mat-icon>
        <input matInput type="text" placeholder="Your family name" formControlName="lastName">
      </mat-form-field>
      
      <mat-form-field appearance="outline" class="example-full-width"> 
        <mat-label>eMail</mat-label>
        <mat-icon matPrefix *ngIf="email.valid && (!email.errors )" [style.color]="'#39a42b'">check_circle</mat-icon>
        <input matInput type="text" placeholder="Your email address" formControlName="email" readonly>
      </mat-form-field>
      
      <mat-form-field appearance="outline" class="example-full-width"> 
        <mat-label>Mobiltelefonnummer</mat-label>
        <mat-icon matPrefix *ngIf="cell.valid && (!cell.errors ) && cell.dirty " [style.color]="'#39a42b'">check_circle</mat-icon>
        <input matInput type="tel" placeholder="Your mobile phone number" formControlName="cell">
      </mat-form-field>

      <mat-form-field appearance="outline" class="example-full-width"> 
        <mat-label>Bürotelefonnnummer</mat-label>
        <mat-icon matPrefix *ngIf="officephone.valid && (!officephone.errors )" [style.color]="'#39a42b'">check_circle</mat-icon>
        <input matInput type="tel" placeholder="Your office phone number" formControlName="officephone">
      </mat-form-field>
  
      <mat-form-field  appearance="outline" class="example-full-width">
      <mat-label>Sprache</mat-label>
      <mat-icon matPrefix *ngIf="language.valid && (!language.errors )"  [style.color]="'#39a42b'">check_circle</mat-icon>
      <mat-select formControlName="language" class="form-control" >
          <mat-option value="en" selected="true">English</mat-option>
          <mat-option value="de">Deutsch</mat-option>
          <mat-option value="es">Espanol</mat-option>
          <mat-option value="fr">Francaise</mat-option>
          <mat-option value="it">Italiano</mat-option>
          <mat-option value="nl">Nederlands</mat-option>
          <mat-option value="pl">Polskie</mat-option>
          <mat-option value="ru">русский</mat-option>
          <mat-option value="fi">Suomalainen</mat-option>
          <mat-option value="tr">Türk</mat-option>
          <mat-option value="cn">中國</mat-option>
      </mat-select>
    </mat-form-field> 
     </mat-tab>
         
     
   
   
    <mat-tab label="Firmendaten"> 
   <p></p>
    <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Firmenname</mat-label>
    <mat-icon matPrefix *ngIf="organisationname.valid && (!organisationname.errors )"  [style.color]="'#39a42b'">check_circle</mat-icon>
    <input matInput type="text" placeholder="Your Company" formControlName="organisationname"> 
  </mat-form-field>
  
  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Kundennummer</mat-label>
    <mat-icon matPrefix *ngIf="customerNo.valid && (!customerNo.errors )"  [style.color]="'#39a42b'">check_circle</mat-icon>
    <input matInput type="text" placeholder="Your Company" formControlName="customerNo" readonly> 
  </mat-form-field>
          
  <mat-form-field appearance="outline" class="example-full-width"> 
    <mat-label>Straße</mat-label>
    <mat-icon matPrefix *ngIf="street.valid && (!street.errors )" [style.color]="'#39a42b'">check_circle</mat-icon>
    <input matInput type="text" placeholder="Your Street" formControlName="street">
  </mat-form-field>
  
   <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>PLZ</mat-label>
    <mat-icon matPrefix *ngIf="zip_code.valid && (!zip_code.errors )" [style.color]="'#39a42b'">check_circle</mat-icon>
   <input matInput type="text" placeholder="Your Postcode" formControlName="zip_code">
  </mat-form-field>
  
  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Stadt</mat-label>
    <mat-icon matPrefix *ngIf="city.valid && (!city.errors )"  [style.color]="'#39a42b'">check_circle</mat-icon>
    <input matInput type="text" placeholder="Your City" formControlName="city">
  </mat-form-field>
    
  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Land</mat-label>
    <mat-icon matPrefix *ngIf="country.valid && (!country.errors )"  [style.color]="'#39a42b'">check_circle</mat-icon>
    <input matInput type="text"
             placeholder="Your Country"
             aria-label="Land"
             matInput
             formControlName="country"
             [matAutocomplete]="countrySel" 
             required>
    <mat-autocomplete #countrySel="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option}}
        </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  
   <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Umsatzsteuer-ID-Nr. (USt-ID)</mat-label>
    <mat-icon matPrefix *ngIf="taxNo.valid && (!taxNo.errors )"  [style.color]="'#39a42b'">check_circle</mat-icon>
    <input matInput type="text" placeholder="Your VAT No." formControlName="taxNo" class="form-control" required>
   </mat-form-field>
   
   <div *ngIf="taxNo.invalid && (taxNo.dirty || taxNo.touched)" class="alert alert-danger">
      <div *ngIf="taxNo.errors.required"><ion-label color="danger">Bitte geben Sie eine gültige, europäische Steuernummer mit mind. 8 Zeichen ein.</ion-label></div>
      <div *ngIf="taxNo.errors.minlength || taxNo.errors.maxlength"><ion-label color="danger">Bitte geben Sie eine gültige, europäische Steuernummer mit mind. 8 Zeichen ein.</ion-label></div>
      <div *ngIf="taxNo.errors.isInvalidVATNumber"> <ion-label color="danger"> Ungültige Steuernummer, bitte überprüfen Sie Ihre Eingabe.</ion-label> </div>
  </div>
  
  

  <ion-item>
   <ion-label position="stacked">Abweichende Rechnungsadresse</ion-label>
   <ion-toggle [(ngModel)]="billingaddress" [ngModelOptions]="{standalone: true}"></ion-toggle>
  </ion-item>
 
  </mat-tab>
  
  <mat-tab *ngIf="billingaddress" label="Abweichende Rechnungsadresse"> 
<p></p>
  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Straße</mat-label>
    <mat-icon matPrefix *ngIf="organisation.billing_street.length > 4"  [style.color]="'#39a42b'">check_circle</mat-icon>
    <input matInput type="text" placeholder="Your Billing street"  [(ngModel)]="organisation.billing_street" [ngModelOptions]="{standalone: true}">
  </mat-form-field>
  
  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Stadt</mat-label>
    <mat-icon matPrefix *ngIf="organisation.billing_city.length > 3"  [style.color]="'#39a42b'">check_circle</mat-icon>
    <input matInput type="text" placeholder="Your Billing City"  [(ngModel)]="organisation.billing_city" [ngModelOptions]="{standalone: true}">
  </mat-form-field>
  
  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>PLZ</mat-label>
    <mat-icon matPrefix *ngIf="organisation.billing_zip_code.length > 4"  [style.color]="'#39a42b'">check_circle</mat-icon>
    <input matInput type="text" placeholder="Your Billing Postcode"  [(ngModel)]="organisation.billing_zip_code" [ngModelOptions]="{standalone: true}">
  </mat-form-field>
  
  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Land</mat-label>
    <mat-icon matPrefix *ngIf="organisation.billing_zip_code.length > 4"  [style.color]="'#39a42b'">check_circle</mat-icon>
    <input matInput type="text" placeholder="Your Billing Country"  [(ngModel)]="organisation.billing_country" [ngModelOptions]="{standalone: true}">
  </mat-form-field> 
   
     </mat-tab>
     
  </mat-tab-group>
  

</mat-dialog-content>
</div>