import { Inject, Component, OnInit,OnDestroy,Input,Output, EventEmitter,ChangeDetectorRef } from '@angular/core';
import { Auth, Hub } from 'aws-amplify';
import { EventService } from '../../services/event.service'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AlertController, ToastController, ModalController, NavController } from '@ionic/angular';

import { AddressValidator } from '../../validators/address-validator'
import { VatValidator } from '../../validators/vat-validator'

import { HttpService } from '../../services/http.service'

import { Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

//import { FormFieldTypes, PhoneFormFieldType } from '@aws-amplify/ui-components';
import { AuthenticatorService,   } from '@aws-amplify/ui-angular';
import {CognitoUser } from '@aws-amplify/auth';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface AuthState {
  isLoggedIn: boolean;
  username: string | null;
  id: string | null;
  email: string | null;
}

@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss'], 
})
export class AuthenticateComponent implements OnInit, OnDestroy {
  public organisation = 
  {
    id: "",
    name: "",
    street: "",
    city: "",
    zip_code: "",
    country: "",
    taxNo: "",
    sforce_id: "",
    customer_number: "n/a",
    billing_street: "",
    billing_city: "",
    billing_zip_code: "",
    billing_country: "",
    str_id: ""
  };
  
  public profile:Profile = new Profile();
  @Output() submitClicked = new EventEmitter<any>();
  
  signedIn: boolean;
  state: any = { signedIn: false,
    				     groups: [""]};
    				 
  billingaddress: boolean; 
   
  contactFormGroup: FormGroup;
  filteredOptions: Observable<string[]>;
 
  user: any;
  greeting: string;
  taxSet:boolean = false;
  //authState: AuthState;
  
  usernameAttributes = "email";
  
 // formFields: FormFieldTypes;
  
 
  constructor(
//    private amplifyService: AmplifyService,
    public events: EventService,
    private _formBuilder: FormBuilder,
    private navCtrl: NavController, 
    private modalCtrl: ModalController,
    private _toast: ToastController,
    private _alert: AlertController,
    private http: HttpService,
    private _ref: ChangeDetectorRef,
    public dialogRef: MatDialogRef<AuthenticateComponent>,
    private _snackBar: MatSnackBar,
    public authenticator: AuthenticatorService,
  
    @Inject(MAT_DIALOG_DATA) public data: any
    )
    { 
      this.state = { 
               sender: "authenticator",
               signedIn: false,
    					 groups: [""]};
      
      
      Auth.currentAuthenticatedUser().then(
      (user: any) => {
      
        //this.authState = {isLoggedIn:true, username: user, id: "", email:""  }
        this.state.signedIn = true;
       //console.log("User", user)
        this.state.groups = user.signInUserSession.idToken.payload["cognito:groups"]//this.user.signInUserSession.accessToken.payload['cognito:groups']
        this.user = user as CognitoUser ;
        
        this.profile.id = this.user.username;
        if(this.user.attributes['gender']) this.profile.gender = this.user.attributes['gender'];
        if(this.user.attributes['given_name']) this.profile.firstName= this.user.attributes['given_name'];
        if(this.user.attributes['family_name'])  this.profile.lastName=this.user.attributes['family_name'];
        if(this.user.attributes['email']) this.profile.email = this.user.attributes['email'];
        if(this.user.attributes['custom:cell']) this.profile.cell= this.user.attributes['custom:cell'];
        if(this.user.attributes['phone_number'])  this.profile.officephone=  this.user.attributes['phone_number'];
        if(this.user.attributes['locale'])  this.profile.language = this.user.attributes['locale'];
        if(this.user.attributes['custom:customerNo'])  this.organisation.customer_number = this.user.attributes['custom:customerNo'];
        if(this.user.attributes['name'])   this.organisation.name = this.user.attributes['name'];
        if(this.user.attributes['custom:taxNo']) this.organisation.taxNo =  this.user.attributes['custom:taxNo'];
        if(this.user.attributes['custom:street']) this.organisation.street = this.user.attributes['custom:street'];
        if(this.user.attributes['custom:zip_code']) this.organisation.zip_code = this.user.attributes['custom:zip_code'];
        if(this.user.attributes['custom:city']) this.organisation.city = this.user.attributes['custom:city'];
        if(this.user.attributes['custom:country']) this.organisation.country = this.user.attributes['custom:country'];
        
            if (this.user.attributes['custom:billing_city'])
        {
          this.billingaddress = true;
          this.organisation.billing_city = this.user.attributes['custom:billing_city']
          this.organisation.billing_country = this.user.attributes['custom:billing_country']
          this.organisation.billing_zip_code = this.user.attributes['custom:billing_zip_code']
          this.organisation.billing_street = this.user.attributes['custom:billing_street']
        }
        
        this.events.publish({name:'data:AuthState', params:this.state}); 
                
      }
    );
      
  /*    const formFields = [
      {
        type: 'name',
        label: "Firmierung",
        placeholder: "DML Mittelstandslogstik GmbH",
        inputProps: { required: true },
      },
      {
        type: "email", 
        label: "Ihre Email Adresse",
        placeholder: "adam@xyz.de",
        inputProps: { required: true, autocomplete: "username" },
      },
      {
        type: "password",
        label: "Sicheres Password",
        placeholder: "****************",
        inputProps: { required: true, autocomplete: "new-password" },
      },
      {
        type: "phone_number",
        label: "Bürotelefonnummer",
        placeholder: "23246816650",
        dialCode: "+49",
        inputProps: {required: true}
      } 
   
    ];
  */
  }
    
  ngOnInit()
  {
  	
  	this.profile = this.data.profile
  	this.organisation = this.data.organisation
  	
  	 Hub.listen('auth', (data) => {
  	   
  	  //console.log(data)
  	   switch (data?.payload?.event) {
  	    case 'configured':
      
        break;
      case 'signIn':
          this.state.signedIn = true;
          this.state.groups = data.payload.data.signInUserSession.idToken.payload["cognito:groups"]//this.user.signInUserSession.accessToken.payload['cognito:groups']
          this.user = data.payload.data as CognitoUser ;
                    
                    this.events.publish({name:'data:AuthState', params:this.state}); 
                    //console.log ("USER", this.user)
                    this.profile.id = this.user.username
                    if(this.user.attributes['gender']) this.profile.gender = this.user.attributes['gender'];
                    if(this.user.attributes['given_name']) this.profile.firstName= this.user.attributes['given_name'];
                    if(this.user.attributes['family_name'])  this.profile.lastName=this.user.attributes['family_name'];
                    if(this.user.attributes['email']) this.profile.email = this.user.attributes['email'];
                    if(this.user.attributes['custom:cell']) this.profile.cell= this.user.attributes['custom:cell'];
                    if(this.user.attributes['phone_number'])  this.profile.officephone=  this.user.attributes['phone_number'];
                    if(this.user.attributes['locale'])  this.profile.language = this.user.attributes['locale'];
                    if(this.user.attributes['custom:customerNo'])  this.organisation.customer_number = this.user.attributes['custom:customerNo'];
                    if(this.user.attributes['name'])   this.organisation.name = this.user.attributes['name'];
                    if(this.user.attributes['custom:taxNo']) this.organisation.taxNo =  this.user.attributes['custom:taxNo'];
                    if(this.user.attributes['custom:street']) this.organisation.street = this.user.attributes['custom:street'];
                    if(this.user.attributes['custom:zip_code']) this.organisation.zip_code = this.user.attributes['custom:zip_code'];
                    if(this.user.attributes['custom:city']) this.organisation.city = this.user.attributes['custom:city'];
                    if(this.user.attributes['custom:country']) this.organisation.country = this.user.attributes['custom:country'];
                    
                    if (this.organisation.taxNo!='') this.contactFormGroup.get('taxNo').disable();
                 
                    if (this.user.attributes['custom:billing_city'])
                    {
                      this.billingaddress = true;
                      this.organisation.billing_city = this.user.attributes['custom:billing_city']
                      this.organisation.billing_country = this.user.attributes['custom:billing_country']
                      this.organisation.billing_zip_code = this.user.attributes['custom:billing_zip_code']
                      this.organisation.billing_street = this.user.attributes['custom:billing_street']
                    }
                      
                    this.events.publish({name:'data:user',params:{profile: this.profile, organisation: this.organisation}}); 
        break;
      case 'signIn_failure':
        
        break;
      case 'signUp':
        break;
      case 'signUp_failure':
        break;
      case 'confirmSignUp':
        this.presentToast('Wir schalten Sie frei! Bitte haben Sie einen Augenblick Geduld', 1000);
        break;
      case 'completeNewPassword_failure':
        break;
      case 'autoSignIn':
        break;
      case 'autoSignIn_failure':
        break;
      case 'forgotPassword':
        break;
      case 'forgotPassword_failure':
        break;
      case 'forgotPasswordSubmit':
        break;
      case 'forgotPasswordSubmit_failure':
        break;
      case 'verify':
        break;
      case 'tokenRefresh':
        break;
      case 'tokenRefresh_failure':
        break;
      case 'cognitoHostedUI':
        break;
      case 'cognitoHostedUI_failure':
        break;
      case 'customOAuthState':
        break;
      case 'customState_failure':
        break;
      case 'parsingCallbackUrl':
        break;
      case 'userDeleted':
        break;
      case 'updateUserAttributes':
        break;
      case 'updateUserAttributes_failure':
        break;
      case 'signOut':
        this.state.signedIn = false;
        break;
      default:
        break;
  	   }
      	//this.authState = authate;
      	//this.user = authData as CognitoUserInterface;
      
        this.contactFormGroup.setValue({
        gender: this.profile.gender ,
        firstName: this.profile.firstName,
        lastName: this.profile.lastName,
        email:this.profile.email ,
        cell: this.profile.cell,
        officephone:this.profile.officephone,
        language: this.profile.language,
        customerNo: this.organisation.customer_number,
        organisationname: this.organisation.name,
        taxNo: this.organisation.taxNo,
        street: this.organisation.street,
        zip_code: this.organisation.zip_code,
        city: this.organisation.city,
        country: this.organisation.country,
      })
      
                      
      this.filteredOptions = this.contactFormGroup.get('country').valueChanges.pipe(
	    startWith(''),
	      	map(value => this._filter(value)) //value
	    	);
	    	
    	this.events.publish({name:'data:AuthState', params:this.state}); 
    	
    	this._ref.detectChanges();
    	})
    
    
	this.contactFormGroup = this._formBuilder.group({
        gender: new FormControl(this.profile.gender, [Validators.required]),
        firstName: new FormControl(this.profile.firstName, [Validators.required, Validators.pattern("[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+[-'!` ]?[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+[-'!` ]?[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+")]),
        lastName: new FormControl(this.profile.lastName, [Validators.required, Validators.pattern("[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+[-'!` ]?[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+")]),
        email: new FormControl(this.profile.email, [Validators.required, Validators.email] ),  
        cell: new FormControl(this.profile.cell, [Validators.pattern('(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})')]),
        officephone: new FormControl(this.profile.officephone, [Validators.required, Validators.pattern('(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})')]),
        language: [this.profile.language, Validators.required],
        organisationname: [this.organisation.name, [Validators.required,Validators.pattern("[0-9A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+[-'!` ]?[0-9A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+[-'!` ]?([0-9A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF'&,-,.])+[-'!` ]?([0-9A-Za-zÀ-ž'&,-,.])+[-'!` ]?([0-9A-Za-zÀ-ž'&,-,.])+[-'!` ]?([0-9A-Za-zÀ-ž'&,-,.])+[-'!` ]?([0-9A-Za-zÀ-ž'&,-,.])+")]],
        customerNo:  [this.organisation.customer_number, null],
        street: [this.organisation.street, Validators.required, ],
        zip_code: [this.organisation.zip_code, Validators.required],
        city: [this.organisation.city, Validators.required],
        country: [this.organisation.country, [Validators.required, AddressValidator.checkCategoryInput(this.countryList)]],
        taxNo: new FormControl(this.organisation.taxNo,{ validators: [Validators.required, Validators.minLength(8), Validators.maxLength(14)], asyncValidators: VatValidator.vatValidator(this.http), updateOn: "blur" }),
    }, 
        //{asyncValidators: VatValidator.theFormValidator(this.http)}
    );
    
    this.contactFormGroup.valueChanges.pipe(
    map (x => { 
      //console.log(x, this.contactFormGroup)
      this.contactFormGroup.markAsDirty();
    } )).subscribe();


    if (this.organisation.taxNo!='') this.contactFormGroup.get('taxNo').disable();
    
}
  
    saveMessage() {
    const data = 'Your data';
    this.submitClicked.emit(data);
    this.dialogRef.close();
  }

  closeDialog() {
    this.close();
    this.submitClicked.emit({profile: this.profile, organisation: this.organisation}); 
    this.dialogRef.close();
  }
  
  async saveChanges()
  { 
  	this.contactFormGroup.updateValueAndValidity();
    if(this.contactFormGroup.invalid) {
    	
    	this.presentToast( 'Fehler beim Speichern! Bitte überprüfen Sie Ihre Eingaben.', 4000);
    	return null
    	
    }
    //console.log ("USER", this.profile, this.organisation)
    
    this.profile.gender = this.contactFormGroup.get('gender').value;
    this.profile.cell = this.contactFormGroup.get('cell').value;
    this.profile.language = this.contactFormGroup.get('language').value;
    this.profile.officephone = this.contactFormGroup.get('officephone').value;
    this.profile.firstName = this.contactFormGroup.get('firstName').value;
    this.profile.lastName = this.contactFormGroup.get('lastName').value;
    this.profile.email = this.contactFormGroup.get('email').value;
    this.organisation.customer_number = this.contactFormGroup.get('customerNo').value;
    this.organisation.street = this.contactFormGroup.get('street').value;
    this.organisation.zip_code = this.contactFormGroup.get('zip_code').value;
    this.organisation.city = this.contactFormGroup.get('city').value;
    this.organisation.country = this.contactFormGroup.get('country').value;
    this.organisation.taxNo = this.contactFormGroup.get('taxNo').value;
    this.organisation.name = this.contactFormGroup.get('organisationname').value;

   
   try{
      
    	var result = await Auth.updateUserAttributes(
          this.user, 
          {
          'given_name': this.profile.firstName,
          'family_name': this.profile.lastName,
          'locale': this.profile.language,
          'address': this.organisation.street,
          'phone_number': this.profile.officephone,
          'gender': this.profile.gender,
          'name' : this.organisation.name,
          'custom:taxNo': this.organisation.taxNo,
          'custom:country': this.organisation.country,
          'custom:zip_code': this.organisation.zip_code,
          'custom:street': this.organisation.street,
          'custom:city': this.organisation.city,
          'custom:cell': this.profile.cell,
          'custom:customerNo': this.organisation.customer_number,
          'custom:billing_street': this.organisation.billing_street,
          'custom:billing_city': this.organisation.billing_city,
          'custom:billing_zip_code': this.organisation.billing_zip_code,
          'custom:billing_country': this.organisation.billing_country
          }
    	)
    	
    	if(result)	{
    	  	if (this.organisation.taxNo!='') this.contactFormGroup.get('taxNo').disable();
    	  this.presentToast( 'Ihre Daten wurden übernommen!', 2000);
    	}
   }
   
   catch (e) {}
    
    
    this.contactFormGroup.markAsPristine();
    
  }
  
  async close()
  {
  	if (this.contactFormGroup.dirty) {
  		const alert = await this._alert.create({
            	  header: 'Warnung',
            	  subHeader: "Ihre Änderungen wurden noch nicht gespeichert",
            	  message: "Speichern und Schließen?",
           	      buttons: [
                    {
                      text: 'Verwerfen',
                      role: 'cancel',
                      cssClass: 'secondary',
                      handler: (blah) => {
                        this.modalCtrl.dismiss();
                      }
                    }, {
                      text: 'Speichern',
                      handler: (data) => 
                      {
                        this.saveChanges();
                    	this.modalCtrl.dismiss();
                      }
                    }
                  ]
            });
      
            await alert.present();
  	}
  	else this.modalCtrl.dismiss();
   
  }
  
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.countryList.filter(option => option.toLowerCase().includes(filterValue));
  }
  
  public presentToast(message, duration)
  {
      this._snackBar.open(message, "OK", {
        duration: duration,
        horizontalPosition: "center",
        verticalPosition: "bottom",
      
      } );
    
		/*const toast = await this._toast.create({
    		message: message,
    		duration: duration,
    		 position: 'middle',
    		 cssClass: 'toast-top',
    		});
    
    	toast.present();*/
  }
  
  async signout()
  {
     try {
        await Auth.signOut();
        this.state.signedIn = false;
        this.state.groups = [""];
        this._ref.detectChanges()
    } catch (error) {
       //console.log('error signing out: ', error);
    }
  }
  
   ngOnDestroy() {
    
  }
  
get taxNo() { return this.contactFormGroup.get('taxNo');}
get gender() { return this.contactFormGroup.get('gender');}
get email() { return this.contactFormGroup.get('email'); }
get firstName() { return this.contactFormGroup.get('firstName');}
get lastName() {return this.contactFormGroup.get('lastName');}
get cell() {return this.contactFormGroup.get('cell');}
get officephone() {return this.contactFormGroup.get('officephone');}
get language() {return this.contactFormGroup.get('language');}
get organisationname() {return this.contactFormGroup.get('organisationname');}
get street() {return this.contactFormGroup.get('street');}
get zip_code() {return this.contactFormGroup.get('zip_code');}
get city() {return this.contactFormGroup.get('city');}
get country() {return this.contactFormGroup.get('country');}
get customerNo() {return this.contactFormGroup.get('customerNo');}

// List of all countries in a simple list / array.
public countryList = [
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas (the)",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia (Plurinational State of)",
	"Bonaire, Sint Eustatius and Saba",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory (the)",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cayman Islands (the)",
	"Central African Republic (the)",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands (the)",
	"Colombia",
	"Comoros (the)",
	"Congo (the Democratic Republic of the)",
	"Congo (the)",
	"Cook Islands (the)",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Curaçao",
	"Cyprus",
	"Czechia",
	"Côte d'Ivoire",
	"Denmark",
	"Deutschland",
	"Djibouti",
	"Dominica",
	"Dominican Republic (the)",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Falkland Islands (the) [Malvinas]",
	"Faroe Islands (the)",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories (the)",
	"Gabon",
	"Gambia (the)",
	"Georgia",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See (the)",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran (Islamic Republic of)",
	"Iraq",
	"Ireland",
	"Isle of Man",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea (the Democratic People's Republic of)",
	"Korea (the Republic of)",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic (the)",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands (the)",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia (Federated States of)",
	"Moldova (the Republic of)",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands (the)",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger (the)",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"Northern Mariana Islands (the)",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine, State of",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines (the)",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Republic of North Macedonia",
	"Romania",
	"Russian Federation (the)",
	"Rwanda",
	"Réunion",
	"Saint Barthélemy",
	"Saint Helena, Ascension and Tristan da Cunha",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Martin (French part)",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Sint Maarten (Dutch part)",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan (the)",
	"Suriname",
	"Svalbard and Jan Mayen",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan (Province of China)",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands (the)",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates (the)",
	"United Kingdom of Great Britain and Northern Ireland (the)",
	"United States Minor Outlying Islands (the)",
	"United States of America (the)",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela (Bolivarian Republic of)",
	"Viet Nam",
	"Virgin Islands (British)",
	"Virgin Islands (U.S.)",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
	"Åland Islands"
];
}

export class Profile {
        public id: string = ""
        public username: string  = ""
        public email: string = ""
        public organisation: string = ""
        public firstName: string = ""
        public cell: string = ""
        public lastName: string = ""
        public gender: String = ""
        public nationality: string = ""
        public language: string = "de"
        public drivinglicense: string = ""
        public passport: string = ""
        public aboutMe: string = ""
        public address:string = ""
        public imageUrl: string = ""
        public group:string = ""
        public officephone:string = ""
        
    constructor(
       
    ){}
}