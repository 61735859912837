import { UntypedFormControl, AbstractControl, AsyncValidatorFn, ValidatorFn, ValidationErrors, UntypedFormGroup } from '@angular/forms';
import { MapService } from '../services/map.service'
import { Observable, of} from 'rxjs';
import { map, startWith, filter, tap } from 'rxjs/operators';


export class AddressValidator {

    static isValid(control: UntypedFormControl): any {
        
       ////console.log("iSvalid?")

        if(control && control.value && control.value.length < 3){
            return {
                "not a valid address": true
            };
        }

        return null;
    }
    
    static checkCategoryInput(categoryList: any[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const val = control.value;
        
        if (categoryList.includes(val)) return null;
        
        return {"not a valid country": true };
    }
   }
    
  static addressValidator(map:MapService): AsyncValidatorFn {
  return (control: UntypedFormControl):Promise<ValidationErrors  | null> | Observable<ValidationErrors | null>  => {
      const ret: ValidationErrors = { isNoValidAddress: true };
    
        if (control.value == null || control.value === '') {
            return of(ret);
        }
    
      let q = new Promise<ValidationErrors>(
        (resolve, reject)=>  { 
            var name = control.value;
            if (name == '') {
              resolve(null);
            }
            else {

                if (control.value != '')
                 {   
                     
                     /*map.forwardGeocode(control.value)
                     .pipe(
                         tap(x =>//console.log (x)))
                     .subscribe()*/
                     
                     if(typeof control.value === "string" && control.value.startsWith('here:')) resolve(null)
                     else resolve(ret);
                     
                     
                     
                 }
            }
                        
        });
        
       return q;
    }
  }
  
  static theFormValidator(map:MapService): AsyncValidatorFn {
  return (group: UntypedFormGroup):Promise<ValidationErrors  | null> | Observable<ValidationErrors | null>  => {
      const ret: ValidationErrors = { isInvalid: true };
      
      const start = group.get('start');
      const dest = group.get('dest');
      const finaldest = group.get('finaldest');
      const pickupDate = group.get('pickupDate');
      const deliverDate = group.get('deliverDate');
        
    
   let q = new Promise<ValidationErrors>(
        (resolve, reject)=>  { 
            if (start.value == '' || dest.value == ''|| pickupDate.value == ''|| deliverDate.value == '') {
              resolve(null);
            }
            else {
                  
                    {
                         var sub = map.forwardGeocode(start.value).subscribe({
                            next: async (data) => { 
                   
                                    
                               ////console.log('Check ALL', data) 
                                resolve(ret);
                                   
                                
                                //    resolve(null)
                             
                  
                         },
    
                     error: (e) => { }
                    })
                    }
                }   
                
            })
      
        return q;
  }}

}

