import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  
  async canActivate(
                next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot)
  {
    
   //console.log(next)
    const expectedRole = next.data.expectedRole;
    var memberOfExpectedGroup = false; 
    
    var session = await Auth.currentSession();
    var groups = session.getIdToken().decodePayload()["cognito:groups"];
    
    if(groups.some(i => expectedRole.includes(i)))
    {
      memberOfExpectedGroup = true;
    }
    
    return memberOfExpectedGroup;    
  }
  
}
