import { Component, OnInit, Input } from '@angular/core';
import {Order} from '../../../classes/order';
import {Transport} from '../../../classes/transport';
import {Cargo} from '../../../classes/cargo';

import { APIService } from '../../../API.service';  
import * as DBTypes from '../../../API.service';

import { ToastController, ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-order-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {

  @Input() order: any;
  
  cargo: Cargo[];
  totalweight = 0;
  totalvolume = 0;
  
  public transport = {
          id: "",
          waypoints: [  ],
          price: 0,
          distance: 0,
          travelTime: 0,
          days: 0,
          attributes: [],
          spannno: 0,
          trailer: [],
          eta: null,
          notes:"",
          reference:"",
          services: {
              eventtracktrace: false,
              livetracktrace: false,
              packagingexchange: false,
              insurance: false,
              documentsbypost: false
          },
          paymentType: "",
          termOfPayment: 0
  }
  
  constructor(
      private _api:APIService,
      private navCtrl: NavController, 
      private modalCtrl: ModalController,
      private _toast: ToastController,
    
      ) { }

  ngOnInit()
  {
      this.cargo= [];
      this.transport = Object.assign(this.transport, this.order.transport);
    //  this.transport = this.order.transport;
      
      //this.transport = this.order;
    
     /* this._api.GetOrder(this.order.reference).then( response => {
        
        this.transport.id = response.transport.id;
        this.transport.distance = response.transport.distance
        this.transport.eta = response.transport.eta;
        this.transport.attributes  = response.transport.attributes
        this.transport.notes  = response.transport.notes
        this.transport.price = response.transport.price
        this.transport.reference  = response.transport.reference
        //this.transport.services  = response.transport.services
        this.transport.spannno  = response.transport.straps
        this.transport.trailer  = response.transport.trailer
        this.transport.travelTime  = response.transport.travelTime
        */
  
        this._api.GetTransport(this.order.transport.id).then( response => {
        
          response.route.items.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1)
          
          for (var i=0; i< response.route.items.length; i++)
          {
            this.transport.waypoints.push({
                          id: response.route.items[i].id,
                          lat:  response.route.items[i].location.lat,  
                          lng: response.route.items[i].location.lon,
                          name: response.route.items[i].name,
                          address: response.route.items[i].address, 
                          housenumber:response.route.items[i].housenumber,
                          street: response.route.items[i].street, 
                          zip: response.route.items[i].zip, 
                          city: response.route.items[i].city,
                          country: response.route.items[i].country, 
                          reference: response.route.items[i].reference,
                          contact: response.route.items[i].contact,
                          contactphone: response.route.items[i].contactphone,
                          notes: response.route.items[i].notes, 
                          openingdate: response.route.items[i].opening, 
                          closingdate: response.route.items[i].closing, 
                          eta: response.route.items[i].eta,
                          })
          }
          
         for (var j=0; j< response.cargo.items.length; j++)
          {
              var c = new Cargo();
              c.id = response.cargo.items[j].id
              c.category = response.cargo.items[j].category
              c.height = response.cargo.items[j].height
              c.length = response.cargo.items[j].length
              c.packaging = response.cargo.items[j].packaging
              c.quantity = response.cargo.items[j].quantity
              c.reference = response.cargo.items[j].reference
              c.weight = response.cargo.items[j].weight
              c.width = response.cargo.items[j].width
              
              this.cargo.push (c);
          }
          
          this.calc();
      })
  // })
  }
   calc(){
    this.totalweight = 0;
    this.totalvolume = 0;
    
    for(var i = 0; i < this.cargo.length; i++)
    {
      this.totalweight = this.totalweight + (this.cargo[i].quantity*this.cargo[i].weight);
      this.totalvolume = this.totalvolume + (this.cargo[i].quantity*(this.cargo[i].height*this.cargo[i].length*this.cargo[i].width)/1000/1000)
    }
    
    this.totalweight = this.totalweight/1000;
  }
  
  close()
  {
    this.modalCtrl.dismiss();
  }
}
