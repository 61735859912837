<ion-header translucent>
  <ion-toolbar> 
    <div class="icon-container">
    <ion-title>
     <font style="position:absolute; left: 60px;"> <b>DML24</b></font> <br> <font style="position:absolute; left: 60px;" color="#c0c0c0" size ="1">Jetzt Komplettladung online buchen</font>
    </ion-title>
    <img style="position:absolute; left: 0%;" src="./assets/icon/logo-final.png" > 
    </div>
    <ion-buttons slot="primary" >
        <ion-button *ngIf="!authState.signedIn" (click)="presentModal()"> JETZT ANMELDEN
        <ion-icon slot="end" name="log-in-outline"></ion-icon>
      </ion-button>
      <ion-button *ngIf="authState.signedIn" (click)="presentModal()"> MEIN ACCOUNT
        <ion-icon slot="end" name="person-circle" ></ion-icon>
      </ion-button>
  </ion-buttons>
  <ion-buttons *ngIf="authState.signedIn" slot="secondary" >
        <ion-menu-button ></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="small">DML24 Online-Buchung</ion-title>
    </ion-toolbar>
  </ion-header>
  
 <mat-stepper [linear]="true" #stepper class="stepper">  
    <mat-step [editable]="!submitted"  [stepControl]="routeFormGroup">   
   <form [formGroup]="routeFormGroup" (ngSubmit)="completeTrack()" #cargoForm="ngForm">
     <ng-template matStepperIcon="edit">
            <mat-icon *ngIf="!submitted">edit</mat-icon>
            <mat-icon *ngIf="submitted">done</mat-icon>
        </ng-template>
       <ng-template matStepLabel>Strecke</ng-template>
       
<ion-card>
 
  <ion-item-group>
    <ion-item-divider>
          <label>Strecke</label>
    </ion-item-divider>

  <ion-grid>
     <ion-item-divider>
          <label>Beladung</label>
          <ion-button *ngIf="!checkRouteSub?.closed && !checked"  [disabled]="routeFormGroup.controls.starts.length >2 " slot="end" (click)="adjstart(routeFormGroup.controls.starts,transport.waypoints.loading,1)"><ion-icon name="add-circle-outline" slot="icon-only"></ion-icon></ion-button>
          <ion-button *ngIf="!checkRouteSub?.closed && !checked"  [disabled]="routeFormGroup.controls.starts.length <2 " slot="end" (click)="adjstart(routeFormGroup.controls.starts,transport.waypoints.loading,-1)"><ion-icon name="remove-circle-outline" slot="icon-only"></ion-icon></ion-button>
    </ion-item-divider>
    <ion-row *ngFor="let comp of aliasesStartArrayControl; let i = index">
 
      <ion-col>
        <dml-loading-point [loading]="aliasesStartArrayControl[i]" [stop]="i" [(field)]="aliasesStartArrayControl[i]" [(trans)]="transport.waypoints.loading" label="{{i+1}}. Beladeadresse"></dml-loading-point>
      </ion-col>
      <ion-col>
        <div *ngIf="(!comp.controls.start.errors  && comp.controls.start.touched )  && checkRouteSub && checkRouteSub?.closed && show[i]">
   <!--    <ion-item>
            <mat-label>kein Abholtermin</mat-label>
            <mat-checkbox slot="end" [(ngModel)]="isChecked" [ngModelOptions]="{standalone: true}" (change)="test()"></mat-checkbox>
            <mat-icon matSuffix  matTooltip="Ist die Ware jederzeit abholbereit, dann brauchen Sie keinen fixen Abholtermin angeben." [style.color]="'#c4c4c4'">help</mat-icon>
        </ion-item>-->
        <dml-loading-date-selector *ngIf="!isChecked" [loading]="aliasesStartArrayControl[i]" [stop]="i" [field]="aliasesStartArrayControl[i].controls.date" [dateField]="'date'" [(trans)]="transport.waypoints.loading[i]" label="Beladedatum" [min]="comp.controls.min.value" [max]="comp.controls.max.value" (change)="onDateChangeLoading($event, aliasesStartArrayControl[i], i)"></dml-loading-date-selector>
        </div>
      </ion-col>
      <ion-col>
         <div *ngIf="(!comp.controls.start.errors ) && comp.controls.date.touched && checkRouteSub?.closed ">
            <nouislider [connect]="true" [min]="0" [max]="24" [step]="1" [config]="noUIconfig" [(ngModel)]="transport.waypoints.loading[i].timeRange" [ngModelOptions]="{standalone: true}" (change)="edited=true"></nouislider>
            <ion-label>Zwischen {{ transport.waypoints.loading[i].timeRange[0] }}:00 und {{ transport.waypoints.loading[i].timeRange[1] }}:00 Uhr</ion-label>
          </div>
      </ion-col>
    </ion-row>
    
   <ion-spinner *ngIf="checkRouteSub && !checkRouteSub?.closed" name="bubbles" class="example-full-width"></ion-spinner>
    
    <ion-item-divider>
          <label>Entladung</label>
          <ion-button *ngIf="!checkRouteSub?.closed && !checked" [disabled]="routeFormGroup.controls.dests.length >2 " slot="end" (click)="adjstop(routeFormGroup.controls.dests, transport.waypoints.unloading, 1)"><ion-icon name="add-circle-outline" slot="icon-only"></ion-icon></ion-button>
          <ion-button *ngIf="!checkRouteSub?.closed && !checked" [disabled]="routeFormGroup.controls.dests.length <2 " slot="end" (click)="adjstop(routeFormGroup.controls.dests, transport.waypoints.unloading,-1)"><ion-icon name="remove-circle-outline" slot="icon-only"></ion-icon></ion-button>
   </ion-item-divider>
   
    <ion-row *ngFor="let comp of aliasesDestArrayControl; let j = index">
      <ion-col>
        <dml-loading-point [loading]="aliasesDestArrayControl[j]" [stop]="j" [(field)]="aliasesDestArrayControl[j]" [(trans)]="transport.waypoints.unloading" label="{{j+1}}. Entladeadresse" (change)="onLoadingPointChange($event)"></dml-loading-point>
      </ion-col>
      <ion-col>
          <div *ngIf=" (!comp.errors || comp.touched) && (! routeFormGroup.controls.starts.errors) && deliverMax && checkRouteSub && checkRouteSub?.closed && show[3+j]">
            <dml-loading-date-selector [loading]="aliasesDestArrayControl[j]" [stop]="j" [field]="aliasesDestArrayControl[j].controls.date" [dateField]="'date'" [(trans)]="transport.waypoints.unloading[j]" label="Entladedatum" [min]="comp.controls.min.value" [max]="comp.controls.max.value" (change)="onDateChangeUnloading($event, aliasesDestArrayControl[j], j)"></dml-loading-date-selector>
          </div>
      </ion-col>
      <ion-col>
         <div *ngIf="(!comp.errors ) && comp.controls.date.touched && (!routeFormGroup.controls.starts.errors ||  !routeFormGroup.controls.dests.touched) && checkRouteSub?.closed ">
            <nouislider [connect]="true" [min]="0" [max]="24" [step]="i" [config]="noUIconfig" [(ngModel)]="transport.waypoints.unloading[j].timeRange" [ngModelOptions]="{standalone: true}" (change)="edited=true"></nouislider>
            <ion-label>Zwischen {{ transport.waypoints.unloading[j].timeRange[0] }}:00 und {{ transport.waypoints.unloading[j].timeRange[1] }}:00 Uhr</ion-label>
          </div>
      </ion-col>
    </ion-row>
        
   <div *ngIf="routeError && checkRouteSub?.closed">
      <ion-row>
        <p> <ion-label color="danger">Die Route enthält Fehler! Ein Preis kann nicht berechnet werden. Bitte verändern Sie die Eingaben und versuchen erneut!</ion-label><br>
        </p>
      </ion-row>
    </div>
    </ion-grid>    
    
    <div style="display:flex; justify-content:center; width:100%; padding:5;">
     <ion-row><button type="reset" mat-raised-button (click)="new()">Zurücksetzen</button></ion-row>
    </div>
    <div style="display:flex; justify-content:flex-end; width:100%; padding:5;">
      
      <ion-button *ngIf="edited"  (click)="this.checked=false;checkRoute(true)">
        <ion-icon slot="icon-only" name="refresh-circle-outline"></ion-icon>
      </ion-button>
      
         <div style="display:flex; justify-content:flex-end; width:100%; padding:0;" 
              *ngIf="!edited && !routeError && transport.travelTime>0 ">
                <ion-button [disabled]="(aliasesStartArrayControl[0].controls.date.errors || aliasesDestArrayControl[0].controls.date.errors)" type="submit" slot="end" >Weiter</ion-button>
          </div>
      </div>
    </ion-item-group>

     </ion-card>
    
    <ion-card>
      <ion-item-group>
        <ion-item-divider>
           <label>Ihr Transport</label>
      </ion-item-divider>
    <ion-grid>
    <ion-row>
      <ion-col style="width: 60%; display:flex; justify-content:center;">
        <ion-img style="width:80%; height:auto;  margin: auto 0;" [src]="getImageString()"></ion-img>
 <!--       <ion-img *ngIf="transport.waypoints.loading[0].address !='' && transport.waypoints.unloading[0].address ==''" style="min-width: 300px; width:100%; display: block; margin: auto 0;" src="https://image.maps.ls.hereapi.com/mia/1.6/mapview?apiKey=AruIGcE2vscMYpqqEMk7cCjkIm9LTRwgVhXIev-wOfI&c={{transport.waypoints.loading[0].lat}},{{transport.waypoints.loading[0].lng}}&poitxs=40&poitxc=black&poifc=39a42b&z=12&h=690&w=1200&u=3k&f=4&ml=ger&style=flame&ra=22"></ion-img>
        <ion-img *ngIf="transport.waypoints.loading[0].address =='' && transport.waypoints.unloading[0].address !='' " style="min-width: 300px; width:100%; display: block; margin: auto 0;" src="https://image.maps.ls.hereapi.com/mia/1.6/mapview?apiKey=AruIGcE2vscMYpqqEMk7cCjkIm9LTRwgVhXIev-wOfI&c={{transport.waypoints.unloading[0].lat}},{{transport.waypoints.unloading[0].lng}}&poitxs=40&poitxc=black&poifc=39a42b&z=12&h=690&w=1200&u=3k&f=4&ml=ger&style=flame&ra=22"></ion-img>
        <ion-img *ngIf="transport.waypoints.loading[0].address !='' && transport.waypoints.unloading[0].address !='' " style="min-width: 300px; width:100%; display: block; margin: auto 0;" src="https://image.maps.ls.hereapi.com/mia/1.6/mapview?apiKey=AruIGcE2vscMYpqqEMk7cCjkIm9LTRwgVhXIev-wOfI&poi={{transport.waypoints.loading[0].lat}},{{transport.waypoints.loading[0].lng}},{{transport.waypoints.unloading[0].lat}},{{transport.waypoints.unloading[0].lng}}&poitxs=40&poitxc=black&poifc=39a42b&h=690&w=1200&u=3k&f=4&ml=ger&style=flame&ra=22"></ion-img>
        <ion-img *ngIf="transport.waypoints[2] && transport.waypoints[2].address" style="min-width: 300px; width:80%; display: block; margin: auto 0;" src="https://image.maps.ls.hereapi.com/mia/1.6/mapview?apiKey=AruIGcE2vscMYpqqEMk7cCjkIm9LTRwgVhXIev-wOfI&poi={{transport.waypoints.loading[0].lat}},{{transport.waypoints.loading[0].lng}},{{transport.waypoints.unloading[0].lat}},{{transport.waypoints.unloading[0].lng}},{{transport.waypoints[2].lat}},{{transport.waypoints[2].lng}}&poitxs=40&poitxc=black&poifc=39a42b&h=690&w=1200&u=3k&f=4&ml=ger&style=flame&ra=22"></ion-img>
  -->    </ion-col>
      <ion-col style="width: 20%">
         <div class="pricing-style">
           <ion-item *ngIf="startprice>0" lines="none">
              <ion-label position="stacked">Preis ab</ion-label>
              <ion-input readonly><h3>{{startprice | currency:'EUR' }}</h3></ion-input>
              <ion-label *ngIf="originalprice>0" position="stacked">Aktualisierter Preis</ion-label>
              <ion-input *ngIf="originalprice>0" readonly><ins>{{originalprice | currency:'EUR' }}</ins></ion-input>
            </ion-item>
         </div>
      </ion-col>
      <ion-col style="width: 20%"  >
          <ion-card>
            <div *ngIf="route">
            
            <ion-item  *ngIf="transport.waypoints.loading[0].eta">
              <ion-label position="stacked"> Abfahrt </ion-label>
              <ion-input readonly>{{transport.departure | date:'short':'CET':'de-DE'}}</ion-input>
            </ion-item>
            
            <ion-item  *ngIf="transport.distance">
              <ion-label position="stacked"> Entfernung </ion-label>
              <ion-input readonly>{{transport.distance | number:'1.0-0'}} km</ion-input>
            </ion-item>
                      
            <ion-item *ngIf="TMin>0||TDay>0||THours>0">
              <ion-label position="stacked">Dauer: </ion-label>
              <ion-input readonly>{{TDay}} T, {{THours}} h und {{TMin}} Min</ion-input>
            </ion-item>
            
            <ion-item *ngIf="RMin>0||RDay>0||RHours>0">
              <ion-label position="stacked">Netto-Fahrzeit: </ion-label>
              <ion-input readonly>{{RDay}} T, {{RHours}} h und {{RMin}} Min</ion-input>
            </ion-item>
            
            <ion-item *ngIf="FMin>0||FDay>0||FHours>0">
              <ion-label position="stacked">Pausenzeit: </ion-label>
              <ion-input readonly>{{FDay}} T, {{FHours}} h und {{FMin}} Min</ion-input>
            </ion-item>
            
            <ion-item *ngIf="transport.eta">
              <ion-label position="stacked">Früheste Entladezeit:  </ion-label>
              <ion-input readonly>{{transport.eta | date:'short':'CET':'de-DE' }}</ion-input>
            </ion-item>
            
            <ion-item *ngIf="standingTime.length>0">
              <ion-label position="stacked">Voraussichtliche Standzeit: </ion-label>
              <ion-input *ngFor="let item of standingTime; index as i " readonly>Wegpunkt {{i+1}}: {{item[0]/60/60 | number:'1.0-0'}}h</ion-input>
            </ion-item>
            </div>
          <!--  <p *ngFor="let warn of warnings"> {{warn.message}} </p>-->
         </ion-card>
      </ion-col>
    </ion-row>
    </ion-grid>
    <div style="display:flex; justify-content:flex-end; width:100%; padding:0;">
     
    </div>
    </ion-item-group>
    </ion-card>
 
         </form>
    </mat-step>
    
    <mat-step [editable]="!submitted"  [stepControl]="cargoFormGroup">
       <form [formGroup]="cargoFormGroup" (ngSubmit)="completeCargo()" #cargoForm="ngForm">
         <ng-template matStepperIcon="edit">
            <mat-icon *ngIf="!submitted">edit</mat-icon>
            <mat-icon *ngIf="submitted">done</mat-icon>
        </ng-template>
    
         <ng-template matStepLabel>Frachtdetails</ng-template>
  
  <ion-card>
  <ion-item-group>
     <ion-item-divider>
      <label>Anzahl an Ladungen (LKW)</label>
      <p></p>
    </ion-item-divider>
    
  <ion-item>
    <mat-form-field  appearance="outline" class="example-full-width">
      <mat-label>Anzahl an Aufträge (LKW)</mat-label>
      <mat-icon matPrefix *ngIf="trucks.valid && (!trucks.errors )"  [style.color]="'#39a42b'">check_circle</mat-icon>
      <mat-select required formControlName="trucks" class="form-control" multiple="false" [(value)]="transport.trucks" >
        <mat-option value="1"><ion-col>1</ion-col><ion-col></ion-col></mat-option>
        <mat-option value="2"><ion-col>2</ion-col><ion-col></ion-col></mat-option>
        <mat-option value="3"><ion-col>3</ion-col><ion-col></ion-col></mat-option>
        <mat-option value="4"><ion-col>4</ion-col><ion-col></ion-col></mat-option>
        <mat-option value="5"><ion-col>5</ion-col><ion-col></ion-col></mat-option>
        <mat-option value="6"><ion-col>6</ion-col><ion-col></ion-col></mat-option>
      </mat-select>
    </mat-form-field>
  </ion-item>
  
    <ion-item-divider>
      <label>Trailer</label>
      <p></p>
    </ion-item-divider>
    
  <ion-item>
    <mat-form-field  appearance="outline" class="example-full-width">
      <mat-label>Trailertyp</mat-label>
      <mat-icon matPrefix *ngIf="trailer.valid && (!trailer.errors )"  [style.color]="'#39a42b'">check_circle</mat-icon>
      <mat-select required formControlName="trailer" class="form-control" multiple="false" [(value)]="transport.trailer" >
        <mat-option value="standard"><ion-col>Standard</ion-col><ion-col></ion-col></mat-option>
        <mat-option value="coil"><ion-col>Coilmulde</ion-col><ion-col>+80 EUR</ion-col></mat-option>
        <mat-option value="frigo"><ion-col>Frigo</ion-col><ion-col></ion-col></mat-option>
        <mat-option value="joloda"><ion-col>Joloda</ion-col><ion-col>+80 EUR</ion-col></mat-option>
        <mat-option value="mega"><ion-col>Mega</ion-col><ion-col>+80 EUR</ion-col></mat-option>
        <mat-option value="walking"><ion-col>Schubboden</ion-col><ion-col></ion-col></mat-option>
      </mat-select>
    </mat-form-field>
  </ion-item>

  <ion-item *ngIf="trailer.value !='frigo'">
    <mat-form-field  appearance="outline" class="example-full-width">
    <mat-label>Ausstattung</mat-label>
    <mat-icon matPrefix *ngIf="attributes.valid && (!attributes.errors ) && (attributes.dirty || attributes.touched)"  [style.color]="'#39a42b'">check_circle</mat-icon>
    <mat-select formControlName="attributes" class="form-control" multiple="true">
      <mat-option value="anti">Antirutschmatten</mat-option>
      <mat-option value="schild">A-Schild</mat-option>
      <mat-option value="edscha">Edschaverdeck</mat-option>
      <mat-option value="mlock">Multilock</mat-option>
      <mat-option value="schoner">Plastik-Kantenschoner </mat-option>
      <mat-option value="spann">Spanngurte</mat-option>
      <mat-option value="xl">XL-Code</mat-option>
      <mat-option value="xlg">XL-Code mit Getränkezertifikat</mat-option>
    </mat-select>
    </mat-form-field>
  </ion-item>
  
   <ion-item *ngIf="attributes.value.includes('spann')">
    <mat-form-field  appearance="outline" class="example-full-width">
      <mat-label>Anzahl Spanngurte</mat-label>
      <mat-icon matPrefix *ngIf="spannno.valid && (!spannno.errors )"  [style.color]="'#39a42b'">check_circle</mat-icon>
      <mat-select formControlName="spannno" class="form-control" >
        <mat-option value="10">10</mat-option>
        <mat-option value="15">15</mat-option>
        <mat-option value="20">20</mat-option> 
        <mat-option value="99">Alternative Anzahl</mat-option>
      </mat-select>
    </mat-form-field>
    </ion-item>
    <ion-item *ngIf="spannno.value==99">
      <mat-form-field  appearance="outline" class="example-full-width">
        <mat-label>Anzahl Spanngurte</mat-label>
        <input matInput formControlName="spannnoman"  class="form-control" type="number">
      </mat-form-field>
    </ion-item>
  </ion-item-group>
  </ion-card>
  
  <ion-card>
  <ion-item-group>
    <ion-item-divider>
      <label>Frachtgut</label>
    </ion-item-divider>
    <ion-item-divider>
          <ion-button *ngIf="!showCargo" slot="start" (click)="showCargo=true"><ion-icon name="caret-down-circle-outline" slot="end"></ion-icon>Frachtdetails eingeben</ion-button>
          <ion-button *ngIf="showCargo" slot="start" (click)="showCargo=false"><ion-icon name="caret-forward-circle-outline" slot="end"></ion-icon>Nur Gesamtfracht eingeben</ion-button>
          <ion-button *ngIf="showCargo && cargo.length<5" slot="end" (click)="add()">Zusätzliches Frachtgut<ion-icon name="add-circle-outline" slot="end"></ion-icon></ion-button>
    </ion-item-divider>
  
  <div *ngIf="showCargo">
  <ion-card  *ngFor="let item of cargo; index as i">
  <ion-item-divider>
      <label>Ladung</label>
  </ion-item-divider>
    
  <ion-grid width="100">
      <ion-row >
          <ion-col>
    <ion-item>
      
      <ion-label position="stacked">Verpackungsart</ion-label>
      <ion-icon slot="end" *ngIf="item.text!=''" name="checkmark-circle-outline" [style.color]="'#39a42b'"></ion-icon>

       <ion-select required [(ngModel)]="item.text" class="form-control" [ngModelOptions]="{standalone: true}" okText="OK" cancelText="Abbruch">
          <ion-select-option value="bundles">Bündel</ion-select-option>
          <ion-select-option value="chep">Chep-Palette</ion-select-option>
          <ion-select-option value="coil">Coil</ion-select-option>
          <ion-select-option value="onewaypallet">Einwegpalette</ion-select-option>
          <ion-select-option value="europallet">Europalette</ion-select-option>
          <ion-select-option value="stillage">Gitterbox</ion-select-option>
          <ion-select-option value="cartons">Karton(s)</ion-select-option>
          <ion-select-option value="boxes">Kisten</ion-select-option>
          <ion-select-option value="longitems">Langgut</ion-select-option>
          <ion-select-option value="other">Sonstiges</ion-select-option>
        </ion-select>
    </ion-item>
  
    <ion-item>
      <ion-label position="stacked">Kategorie</ion-label>
       <ion-icon slot="end" *ngIf="item.cat != ''" name="checkmark-circle-outline" [style.color]="'#39a42b'"></ion-icon>

       <ion-select required [(ngModel)]="item.cat" class="form-control" [ngModelOptions]="{standalone: true}" okText="OK" cancelText="Abbruch">
          <ion-select-option value="Papier">Papier & Drucke</ion-select-option>
          <ion-select-option value="Stahl">Stahl</ion-select-option>
          <ion-select-option value="Getränke">Getränke</ion-select-option>
          <ion-select-option value="Lebensmittel">Lebensmittel</ion-select-option>
          <ion-select-option value="Pflanzen">Pflanzen</ion-select-option>
          <ion-select-option value="Chemikalien">Chemikalien</ion-select-option>
          <ion-select-option value="Gesundheitsprodukte">Gesundheitsprodukte</ion-select-option>
          <ion-select-option value="Möbel">Möbel</ion-select-option>
          <ion-select-option value="Industrieprodukte">Industrieprodukte</ion-select-option>
          <ion-select-option value="Kleidung">Kleidung</ion-select-option>
          <ion-select-option value="Baumaterial">Baumaterialien</ion-select-option>
          <ion-select-option value="Automobil">Autobilindustrie</ion-select-option>
          <ion-select-option value="Elektro">Elektrogeräte</ion-select-option>
          <ion-select-option value="Freizeitartikel">Freizeitartikel</ion-select-option>
          
        </ion-select>
    </ion-item>
    </ion-col>
  
  <ion-col>
    <ion-item>
      <ion-label position="stacked">Menge</ion-label>
      <ion-icon slot="end" *ngIf="item.items >0" name="checkmark-circle-outline" [style.color]="'#39a42b'"></ion-icon>
      <ion-input required type="number" min = "0" [(ngModel)]="item.items" clearOnEdit="true" [ngModelOptions]="{standalone: true}" (ionChange)="calc()"></ion-input>
    </ion-item>
    
    <ion-item>
      <ion-label position="stacked">Ladenummer (optional)</ion-label>
      <ion-input type="text" [(ngModel)]="item.no" [ngModelOptions]="{standalone: true}"></ion-input>
    </ion-item>
     
    </ion-col>
    
    <ion-col>
    <ion-item>
      <ion-label position="stacked">Gewicht (je Stück) in kg</ion-label>
      <ion-icon slot="end" *ngIf="item.weight >0" name="checkmark-circle-outline" [style.color]="'#39a42b'"></ion-icon>
      <ion-input required type="number" min = "0" steps="0.1" clearInput="true" clearOnEdit="true" [(ngModel)]="item.weight" [ngModelOptions]="{standalone: true}" (ionChange)="calc()"></ion-input>
      <ion-button *ngIf="cargo.length>1" slot="end" (click)="cargo.splice(i,1);calc()"><ion-icon name="close-circle-outline"></ion-icon></ion-button>
    </ion-item>
    </ion-col>
    
    </ion-row>
    
    <ion-item-group>
          <ion-item-divider>
            <label> Maße</label>
          </ion-item-divider>
        <ion-row>
          <ion-col>
        <ion-item>
          <ion-label position="stacked">Höhe (in cm)</ion-label>
          <ion-icon slot="end" *ngIf="item.height >0 && item.height <= 265" name="checkmark-circle-outline" [style.color]="'#39a42b'"></ion-icon>
          <ion-icon slot="end" *ngIf="item.height > maxheight" name="close-circle-outline" [style.color]="'#ff0011'"></ion-icon>
          <ion-input required type="number" min = "0" max="maxheight" clearInput="true" clearOnEdit="true" [(ngModel)]="item.height" [ngModelOptions]="{standalone: true}" class="form-control" id="height" #height="ngModel" (ionChange)="calc()"></ion-input>
        </ion-item>
        </ion-col>
        <ion-col>
        <ion-item>
          <ion-label position="stacked">Länge (in cm)</ion-label>
          <ion-icon slot="end" *ngIf="item.length >0 && item.length <=1360" name="checkmark-circle-outline" [style.color]="'#39a42b'"></ion-icon>
          <ion-icon slot="end" *ngIf="item.length > 1360" name="close-circle-outline" [style.color]="'#ff0011'"></ion-icon>
          <ion-input required  type="number" min = "0" max="1360" clearInput="true" clearOnEdit="true" [(ngModel)]="item.length" [ngModelOptions]="{standalone: true}" class="form-control" id="length" #length="ngModel" (ionChange)="calc()"></ion-input>
        </ion-item>
        </ion-col>
        <ion-col>
        <ion-item>
          <ion-label position="stacked">Breite (in cm)</ion-label>
          <ion-icon slot="end" *ngIf="item.width >0 && item.width <= 245" name="checkmark-circle-outline" [style.color]="'#39a42b'"></ion-icon>
          <ion-icon slot="end" *ngIf="item.width > 245" name="close-circle-outline" [style.color]="'#ff0011'"></ion-icon>
          <ion-input required type="number" min="0" max="245" clearInput="true" clearOnEdit="true" [(ngModel)]="item.width" [ngModelOptions]="{standalone: true}" class="form-control" id="width" #width="ngModel" (ionChange)="calc()"></ion-input>
        </ion-item>
        </ion-col>
      </ion-row>
           </ion-item-group>
           
    </ion-grid>
  </ion-card>
  </div>
  
  <ion-card>
    <ion-item-divider>
      <label>Gesamtladung</label>
    </ion-item-divider>
  <ion-grid>
  <ion-row>
    <ion-col>
      <div *ngIf="!showCargo">
        <ion-item>
          <ion-label position="stacked">Stellplätze</ion-label>
            <ion-icon slot="end" *ngIf="cargo.items > 33" name="close-circle-outline" [style.color]="'#ff0011'"></ion-icon>
            <ion-input type="number" min="0" max="34" steps="1" [(ngModel)]="cargo.items" [ngModelOptions]="{standalone: true}" ></ion-input>
        </ion-item>
      </div>
    </ion-col>
    <ion-col>
      <div *ngIf="showCargo">
        <ion-item>
          <ion-label position="stacked">Gesamtvolumen (cbm)</ion-label>
          <ion-icon slot="end" *ngIf="totalvolume > maxvolume" name="close-circle-outline" [style.color]="'#ff0011'"></ion-icon>
          <ion-input type="number" min="0" [max]="maxvolume" steps="0.1" [(ngModel)]="totalvolume" [ngModelOptions]="{standalone: true}" readonly></ion-input>
        </ion-item>
      </div>
      <div *ngIf="!showCargo">
        <ion-item>
          <ion-label position="stacked">Gesamtvolumen (cbm)</ion-label>
            <ion-icon slot="end" *ngIf="totalvolume > maxvolume" name="close-circle-outline" [style.color]="'#ff0011'"></ion-icon>
            <ion-input type="number" min="0" [max]="maxvolume" steps="0.1" [(ngModel)]="totalvolume" [ngModelOptions]="{standalone: true}" (ionInput)="adjustMax($event, 85)"></ion-input>
        </ion-item>
      </div>
    </ion-col>
          
    <ion-col>
      <div *ngIf="showCargo">
        <ion-item>
          <ion-label position="stacked">Gesamtgewicht (t)</ion-label>
          <ion-icon slot="end" *ngIf="totalweight > 24.5" name="close-circle-outline" [style.color]="'#ff0011'"></ion-icon>
          <ion-input type="number" min="0" max="24.5" steps="0.1"  [(ngModel)]="totalweight" [ngModelOptions]="{standalone: true}" readonly></ion-input>
        </ion-item>
      </div>     
      <div *ngIf="!showCargo">
      <ion-item>
        <ion-label position="stacked">Gesamtgewicht (t)</ion-label>
        <ion-icon slot="end" *ngIf="totalweight > 24.5" name="close-circle-outline" [style.color]="'#ff0011'"></ion-icon>
        <ion-input type="number" min="0" max="24.5" steps="0.1"  [(ngModel)]="totalweight" [ngModelOptions]="{standalone: true}" (ionInput)="adjustMax($event, 24.5)"></ion-input>
      </ion-item>
      </div>  
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
        <button mat-button matStepperPrevious>Zurück</button>
    </ion-col>
    <ion-col>
     <div style="display:flex; justify-content:flex-end; width:100%; padding:0;">
       <ion-button type="submit" expand="block" [disabled]=" !cargoForm.form.valid" *ngIf="checkRouteSub?.closed && transport.travelTime>0 && ((totalweight>=0 && totalweight<24.5) ||  (totalvolume>=0 && totalvolume<=maxvolume))" slot="end" >Weiter</ion-button>
      </div>
    </ion-col>
  </ion-row>
  </ion-grid>
  </ion-card>
  
 </ion-item-group>
   </ion-card>
        </form>
    </mat-step>
   
    <mat-step [editable]="!submitted"  [stepControl]="">
       <ng-template matStepperIcon="edit">
            <mat-icon *ngIf="!submitted">edit</mat-icon>
            <mat-icon *ngIf="submitted">done</mat-icon>
        </ng-template>

        <ng-template matStepLabel>Preis</ng-template>
        
         <div *ngIf="route">
             <ion-card>
              <ion-grid width="100">
              <ion-item-group>
                <ion-row >
                  <ion-col>
                    <ion-item>
                      <ion-label position="stacked"> Dauer </ion-label>
                      <ion-input readonly>{{TDay}} T, {{THours}} h und {{TMin}} Min</ion-input>
                    </ion-item>
                  </ion-col>
                  
                  <ion-col *ngIf="standingTime">
                    <ion-item>
                      <ion-label position="stacked"> Standzeit </ion-label>
                      <ion-input readonly>{{StDay}} T, {{StHours}} h und {{StMin}} Min</ion-input>
                    </ion-item>
                  </ion-col>
      
                  <ion-col>
                    <ion-item>
                      <ion-label position="stacked">Strecke</ion-label>
                      <ion-input readonly>{{transport.distance | number:'1.0-0'}} km</ion-input>
                    </ion-item>
                  </ion-col>
               </ion-row>      
                
                
                <ion-row >
                  <ion-col>
                    
                  </ion-col>
                  <ion-col>
                    <div class="pricing-style">
                    <ion-item lines="none">
                      <ion-label position="stacked">Original Preis</ion-label>
                      <ion-input readonly ><del>{{originalprice | currency:'EUR' }}</del></ion-input>
                    </ion-item>
                   
                    </div>
                  </ion-col>
                    <ion-col>
                    <div class="pricing-style">
                    <ion-item lines="none" *ngIf="priceSub && priceSub?.closed">
                      <ion-label position="stacked">Online Preis</ion-label>
                      <ion-input readonly><ins>{{transport.price*transport.trucks | currency:'EUR' }}</ins></ion-input>
                      <ion-label> für {{transport.trucks }} LKW
                                <p *ngIf="transport.services.insurance"><font style="top: -50px; left: 10%;" size ="2">inkl. Versicherung {{this.transport.costofgoods*0.001 | currency:'EUR'}} <br></font> </p>
                                <p *ngIf="tollCost>0"><font style="top: -50px; left: 10%;" size ="2">inkl. Maut  {{tollCost | currency:'EUR'}} </font></p>
                      </ion-label>
                    </ion-item>
                      <ion-spinner *ngIf="priceSub && !priceSub?.closed" name="bubbles" class="example-full-width"></ion-spinner>
                    </div>
                  </ion-col>
                  </ion-row>
                  </ion-item-group>
                  
                  <ion-row >
                  <ion-col>
                    <ion-item-divider>
                      <label> Abholung</label>
                    </ion-item-divider>              
    
                      <ion-item *ngFor="let item of transport.waypoints.loading;index as i">
                      <ion-label> {{i+1}}. Ladestelle: {{transport.waypoints.loading[i].address}}</ion-label>
                      </ion-item>
                    
                  </ion-col>
                
                  <ion-col>
                    <ion-item-divider>
                      <label>Entladung</label>
                    </ion-item-divider>              
    
                    <ion-item *ngFor="let item of transport.waypoints.unloading;index as i">
                      <ion-label> {{i+1}}. Entladestelle:  {{transport.waypoints.unloading[i].address}}</ion-label>
                      </ion-item>
                  </ion-col>
                
                </ion-row>    
                  
                  <ion-row>
                    <ion-col>
                   
                   <!--   <ion-img *ngIf="transport.waypoints.loading[0].address !='' && transport.waypoints.unloading[0].address !='' " style="width:50%; display: block; margin: 0 auto;" src="https://image.maps.ls.hereapi.com/mia/1.6/mapview?apiKey=AruIGcE2vscMYpqqEMk7cCjkIm9LTRwgVhXIev-wOfI&poi={{transport.waypoints.loading[0].lat}},{{transport.waypoints.loading[0].lng}},{{transport.waypoints.unloading[0].lat}},{{transport.waypoints.unloading[0].lng}}&poitxs=40&poitxc=black&poifc=39a42b&=690&w=1200&u=3k&f=4&ml=ger&style=flame&ra=22"></ion-img>
                      <ion-img *ngIf="transport.waypoints.loading[0].address !='' && transport.waypoints.unloading[0].address !='' && transport.waypoints.unloading[1]" style="width:50%; display: block; margin: 0 auto;" src="https://image.maps.ls.hereapi.com/mia/1.6/mapview?apiKey=AruIGcE2vscMYpqqEMk7cCjkIm9LTRwgVhXIev-wOfI&poi={{transport.waypoints.loading[0].lat}},{{transport.waypoints.loading[0].lng}},{{transport.waypoints.unloading[0].lat}},{{transport.waypoints.unloading[0].lng}},{{transport.waypoints.unloading[1].lat}},{{transport.waypoints.unloading[1].lng}}&poitxs=40&poitxc=black&poifc=39a42b&=690&w=1200&u=3k&f=4&ml=ger&style=flame&ra=22"></ion-img>
                  -->  </ion-col>
                  <ion-col>
                      <ion-img *ngIf="transport.waypoints.loading[0].address !=''" style="width:80%; height:auto; display:flex; justify-content:center; margin: auto 0;" [src]="getImageString()"></ion-img> 
                  </ion-col>
                  <ion-col>
                    
                  </ion-col>
                </ion-row>
                
                  <ion-item-group>
                        <ion-item-divider>
                          <label>Zusatzleistungen</label>
                        </ion-item-divider>
                        
                  <ion-row>
                    <ion-row>
                        <ion-item-divider>
                          <label>Beladung</label>
                        </ion-item-divider>
                    <ion-col *ngFor="let item of transport.waypoints.loading; index as i">
                      <ion-item>
                          <ion-label >Telefonische Avisierung (Abholung) - {{transport.waypoints.loading[i].address}}</ion-label>
                           <ion-checkbox slot="start"  [(ngModel)]="transport.waypoints.loading[i].services.notificationbyphone" (ionChange)="tune($event, 15)"></ion-checkbox>
                        </ion-item>
                      
                    </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-item-divider>
                          <label>Entladung</label>
                        </ion-item-divider>
                        <ion-col *ngFor="let item of transport.waypoints.unloading; index as i">
                        <ion-item>
                          <ion-label>Telefonische Avisierung (Entladung) - {{transport.waypoints.unloading[i].address}}</ion-label>
                           <ion-checkbox slot="start" [(ngModel)]="transport.waypoints.unloading[i].services.notificationbyphone" (ionChange)="tune($event, 15)"></ion-checkbox>
                        </ion-item>
                        </ion-col>
                    </ion-row>
                    </ion-row>
                    <ion-row>
                        <ion-item-divider>
                          <label>Sonstiges</label>
                        </ion-item-divider>
                      <ion-col>
                          <ion-item>
                        <ion-label >Zustellung Frachtpapiere per Post</ion-label>
                           <ion-checkbox slot="start" [(ngModel)]="transport.services.documentsbypost" (ionChange)="tune($event, 15)"></ion-checkbox>
                        </ion-item>
                        
                      <!-- <ion-item>
                         <ion-label >Packmitteltausch</ion-label>
                           <ion-checkbox slot="start" [(ngModel)]="transport.services.packagingexchange" (ionChange)="tune($event, 30)"></ion-checkbox>
                        </ion-item>-->
                       
                        </ion-col>
                      <ion-col>
                       <ion-item>
                          <ion-label>Versicherung</ion-label>
                           <ion-checkbox slot="start" [(ngModel)]="transport.services.insurance" (ionChange)="tune($event, 10)"></ion-checkbox>
                        </ion-item>
                        
                        <ion-item *ngIf="transport.services.insurance">
                          <ion-label>Warenwert in EUR:</ion-label>
                          <ion-input type="number" min="1" max="250000" step="100" [(ngModel)]="transport.costofgoods" (ionInput)="adjustMax($event, 250000);getPrice()"></ion-input>     
                        </ion-item>
                    </ion-col>
                        
                    </ion-row>
                  
                
                   </ion-item-group>
                   
            <form [formGroup]="paymentFormGroup">
                   <ion-item-group>
                       <ion-item-divider>
                          <label>Zahlungsdetails</label>
                        </ion-item-divider>
                     <ion-item>
         <ion-label>elektronische Rechnung mit digitaler Ablieferbelege (PODs) +25 EUR</ion-label>
         <ion-toggle *ngIf="bill2==false && bill3==false" [(ngModel)]="bill" [ngModelOptions]="{standalone: true}" (ionChange)="tune($event, 25)"></ion-toggle>
      </ion-item>
  
      <ion-item>
         <ion-label>elektronische Rechnung OHNE digitaler Ablieferbelege (PODs)</ion-label>
         <ion-toggle *ngIf="bill2==false && bill==false" [(ngModel)]="bill3" [ngModelOptions]="{standalone: true}"></ion-toggle>
      </ion-item>
      
      <ion-item>
         <ion-label>Gutschrift +10 EUR </ion-label>
         <ion-toggle *ngIf="bill==false && bill3==false" [(ngModel)]="bill2" [ngModelOptions]="{standalone: true}" (ionChange)="tune($event, 10)"></ion-toggle>
      </ion-item>
      
       <ion-item>
        <ion-label>Zahlungsziel</ion-label>
        <ion-select formControlName="paymentTarget" class="form-control" required >
          <ion-select-option value="-3">10 Tage ./. 3% Rabatt</ion-select-option>
          <ion-select-option value="-2" selected="true">15 Tage ./. 2% Rabatt</ion-select-option>
          <ion-select-option value="0">30 Tage</ion-select-option>
          <ion-select-option value="2">45 Tage ./. 2 % Aufschlag</ion-select-option>
          <ion-select-option value="3">60 ./. 3% Aufschlag</ion-select-option>
        </ion-select>
      </ion-item>
                   </ion-item-group>
                   </form>       
                   <ion-row>
                     <ion-col>
                                  <div>
            <button mat-button matStepperPrevious>Zurück</button>
          </div>
                    </ion-col>
                    <ion-col>
                         <div style="display:flex; justify-content:flex-end; width:100%; padding:0;">
                          <ion-button [disabled]="bill==false && bill3==false && bill2==false" type="submit" slot="end" (click)="nextStep(false)">Weiter</ion-button>
                          </div>
                    </ion-col>
          </ion-row>
              </ion-grid>
          </ion-card>
        </div>
        
   </mat-step>
   
   <mat-step *ngIf="!authState.signedIn && showContactForm " [editable]="!submitted"  [stepControl]="contactFormGroup">
       <form [formGroup]="contactFormGroup" (ngSubmit)="validate()">
         <ng-template matStepperIcon="edit">
            <mat-icon *ngIf="!submitted">edit</mat-icon>
            <mat-icon *ngIf="submitted">done</mat-icon>
        </ng-template>

        <ng-template matStepLabel>Kontakt</ng-template>
    
    <ion-card>
    <ion-item-group>
    <ion-item-divider>
      <label> Anmeldename (e-Mail Adresse):</label>
    </ion-item-divider>  
    
     <mat-form-field appearance="outline" class="example-full-width"> 
        <mat-label>e-Mail:</mat-label>
        <mat-icon matPrefix *ngIf="email.valid && (!email.errors )" [style.color]="'#39a42b'">check_circle</mat-icon>
         <mat-icon matSuffix  matTooltip="Bitte geben Sie Ihre eMail-Adresse ein. Diese wird bei uns als ihr Accountname registriert." [style.color]="'#c4c4c4'">help</mat-icon>
        <input matInput type="text" placeholder="Please enter your email address" formControlName="email" autocomplete="email" required>
      </mat-form-field>
      
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger">
        <div *ngIf="email.errors.required"><ion-label color="danger">Bitte geben Sie eine gültige Email-Adresse ein.</ion-label></div>
        <div *ngIf="email.errors.userExists"> <ion-label color="danger"> Diese Emailadresse existiert bereits. Bitte melden sie sich an!</ion-label> </div>
     </div>
    </ion-item-group>
    
    <ion-item-group>
      <div *ngIf="(email.valid && (!email.errors ))">
        <ion-item-divider>
          <label> Persönliche Daten:</label>
        </ion-item-divider>  
    
    <mat-form-field appearance="outline" class="example-full-width"> 
        <mat-label>Passwort</mat-label>
        <mat-icon matPrefix *ngIf="password.valid && (!password.errors )" [style.color]="'#39a42b'">check_circle</mat-icon>
        <mat-icon matSuffix  matTooltip="Bitte geben Sie ein mindestens 8 Zeichen langes Passwort ein." [style.color]="'#c4c4c4'">help</mat-icon>
        <input matInput type="password" placeholder="Please choose a password" formControlName="password" autocomplete="new-password" required>
      </mat-form-field>
      
    <div *ngIf="password.valid && (!password.errors )">
    <mat-form-field appearance="outline" class="example-full-width"> 
        <mat-label>Passwortbestätigung</mat-label>
        <mat-icon matPrefix *ngIf="confirmPassword.valid && (!confirmPassword.errors )" [style.color]="'#39a42b'">check_circle</mat-icon>
         <mat-icon matSuffix  matTooltip="Bitte geben Sie Ihr gewähltes Passwort erneut ein, um es zu bestätigen." [style.color]="'#c4c4c4'">help</mat-icon>
        <input matInput type="password" placeholder="Confirm your chosen password" formControlName="confirmPassword" autocomplete="confirm-password" required>
      </mat-form-field>
       <div class="example-full-width" *ngIf="confirmPassword.touched && contactFormGroup.errors && contactFormGroup.errors.invalidPWCheck"> <ion-label color="danger">Die Passwörter stimmen nicht überein, bitte überprüfen!</ion-label> </div>
    </div>  
    
    <mat-form-field  appearance="outline" class="example-full-width">
      <mat-label>Ansprache</mat-label>
      <mat-icon matPrefix *ngIf="gender.valid && (!gender.errors )"  [style.color]="'#39a42b'">check_circle</mat-icon>
       <mat-icon matSuffix  matTooltip="Bitte geben Sie Ihre Ansprache ein." [style.color]="'#c4c4c4'">help</mat-icon>
      <mat-select formControlName="gender" class="form-control" required>
        <mat-option value="f">Herr</mat-option>
        <mat-option value="m">Frau</mat-option>
        <mat-option value="d">Sie</mat-option> 
      </mat-select>
    </mat-form-field>
    
      <mat-form-field appearance="outline" class="example-full-width"> 
        <mat-label>Vorname</mat-label>
        <mat-icon matPrefix *ngIf="firstName.valid && (!firstName.errors )" [style.color]="'#39a42b'">check_circle</mat-icon>
         <mat-icon matSuffix  matTooltip="Bitte geben Sie Ihren Vornamen ein." [style.color]="'#c4c4c4'">help</mat-icon>
        <input matInput type="text" placeholder="Your first name" formControlName="firstName" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="example-full-width"> 
        <mat-label>Nachname</mat-label>
        <mat-icon matPrefix *ngIf="lastName.valid && (!lastName.errors )" [style.color]="'#39a42b'">check_circle</mat-icon>
        <mat-icon matSuffix  matTooltip="Bitte geben Sie Ihren Nachnamen ein." [style.color]="'#c4c4c4'">help</mat-icon>
        <input matInput type="text" placeholder="Your family name" formControlName="lastName" required>
      </mat-form-field>
      
      <mat-form-field appearance="outline" class="example-full-width"> 
        <mat-label>Mobiltelefonnummer</mat-label>
        <mat-icon matPrefix *ngIf="cell.valid && (!cell.errors ) && cell.dirty " [style.color]="'#39a42b'">check_circle</mat-icon>
        <mat-icon matSuffix  matTooltip="Bitte geben Sie Ihre Mobilfunknummer mit internationaler Vorwahl und ohne führende Null ein. Z.B. : +49170123456789" [style.color]="'#c4c4c4'">help</mat-icon>
        <input matInput type="tel" placeholder="Your mobile phone number" formControlName="cell">
      </mat-form-field>

      <mat-form-field appearance="outline" class="example-full-width"> 
        <mat-label>Bürotelefonnnummer</mat-label>
        <mat-icon matPrefix *ngIf="officephone.valid && (!officephone.errors )" [style.color]="'#39a42b'">check_circle</mat-icon>
        <mat-icon matSuffix  matTooltip="Bitte geben Sie Ihre Bürotelefonnummer mit internationaler Vorwahl und ohne führende Null ein. Z.B. : +492222123456789" [style.color]="'#c4c4c4'">help</mat-icon>
        <input matInput type="tel" placeholder="Your office phone number" formControlName="officephone" required>
      </mat-form-field>
  
      <mat-form-field  appearance="outline" class="example-full-width">
      <mat-label>Sprache</mat-label>
      <mat-icon matPrefix *ngIf="language.valid && (!language.errors )"  [style.color]="'#39a42b'">check_circle</mat-icon>
      <mat-icon matSuffix  matTooltip="Bitte geben Sie Ihren bevorzugte Muttersprache ein." [style.color]="'#c4c4c4'">help</mat-icon>
      <mat-select formControlName="language" class="form-control" >
          <mat-option value="en" selected="true">English</mat-option>
          <mat-option value="de">Deutsch</mat-option>
          <mat-option value="es">Espanol</mat-option>
          <mat-option value="fr">Francaise</mat-option>
          <mat-option value="it">Italiano</mat-option>
          <mat-option value="nl">Nederlands</mat-option>
          <mat-option value="pl">Polskie</mat-option>
          <mat-option value="ru">русский</mat-option>
          <mat-option value="fi">Suomalainen</mat-option>
          <mat-option value="tr">Türk</mat-option>
          <mat-option value="cn">中國</mat-option>
      </mat-select>
    </mat-form-field>
    
          </div>
      </ion-item-group>
      </ion-card>
      <ion-card width="60%">
  <ion-item-group>
    <div *ngIf="email.valid && (!email.errors )" >
    <ion-item-divider>
      <label> Firmendaten:</label>
    </ion-item-divider>  

   <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Firmenname</mat-label>
    <mat-icon matPrefix *ngIf="organisationname.valid && (!organisationname.errors )"  [style.color]="'#39a42b'">check_circle</mat-icon>
      <mat-icon matSuffix  matTooltip="Bitte geben Sie den vollständigen Namen Ihrer Organisation ein. Z.B. DML Mittelstandslogistik GmbH" [style.color]="'#c4c4c4'">help</mat-icon>
    <input matInput type="text" placeholder="Your Company" formControlName="organisationname" required>
  </mat-form-field>
      
  <mat-form-field appearance="outline" class="example-full-width"> 
    <mat-label>Straße</mat-label>
    <mat-icon matPrefix *ngIf="street.valid && (!street.errors )" [style.color]="'#39a42b'">check_circle</mat-icon>
      <mat-icon matSuffix  matTooltip="Bitte geben Sie den Straßennamen des Standorts Ihrer Organisation ein." [style.color]="'#c4c4c4'">help</mat-icon>
    <input matInput type="text" placeholder="Your Street" formControlName="street" required>
  </mat-form-field>
  
   <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>PLZ</mat-label>
    <mat-icon matPrefix *ngIf="zip_code.valid && (!zip_code.errors )" [style.color]="'#39a42b'">check_circle</mat-icon>
    <mat-icon matSuffix  matTooltip="Bitte geben Sie die Postleitzahl des Standorts Ihrer Organisation ein." [style.color]="'#c4c4c4'">help</mat-icon>
   <input matInput type="text" placeholder="Your Postcode" formControlName="zip_code" required>
  </mat-form-field>
  
  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Stadt</mat-label>
    <mat-icon matPrefix *ngIf="city.valid && (!city.errors )"  [style.color]="'#39a42b'">check_circle</mat-icon>
    <mat-icon matSuffix  matTooltip="Bitte geben Sie den Stadtnamen des Standorts Ihrer Organisation ein." [style.color]="'#c4c4c4'">help</mat-icon>
    <input matInput type="text" placeholder="Your City" formControlName="city" required>
  </mat-form-field>
    
  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Land</mat-label>
    <mat-icon matPrefix *ngIf="country.valid && (!country.errors )"  [style.color]="'#39a42b'">check_circle</mat-icon>
    <mat-icon matSuffix  matTooltip="Bitte geben Sie das Land des Standorts Ihrer Organisation ein." [style.color]="'#c4c4c4'">help</mat-icon>
    <input matInput type="text"
             placeholder="Your Country"
             aria-label="Land"
             matInput
             formControlName="country"
             [matAutocomplete]="countrySel" 
             required>
    <mat-autocomplete #countrySel="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option}}
        </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  
   <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Umsatzsteuer-ID-Nr. (USt-ID)</mat-label>
    <mat-icon matPrefix *ngIf="taxNo.valid && (!taxNo.errors )"  [style.color]="'#39a42b'">check_circle</mat-icon>
    <mat-icon matSuffix  matTooltip="Bitte geben Sie die vollständige Umsatzsteuernummer Ihrer Organisation ohne Leerzeichen ein. Z.B. : DE999999999" [style.color]="'#c4c4c4'">help</mat-icon>
    <input matInput type="text" placeholder="Your VAT No." formControlName="taxNo" class="form-control" required>
   </mat-form-field>
   
   <div *ngIf="taxNo.invalid && (taxNo.dirty || taxNo.touched)" class="alert alert-danger">
      <div *ngIf="taxNo.errors.required"><ion-label color="danger">Bitte geben Sie eine gültige, europäische Steuernummer mit mind. 8 Zeichen ein.</ion-label></div>
      <div *ngIf="taxNo.errors.minlength || taxNo.errors.maxlength"><ion-label color="danger">Bitte geben Sie eine gültige, europäische Steuernummer mit mind. 8 Zeichen ein.</ion-label></div>
      <div *ngIf="taxNo.errors.isInvalidVATNumber"> <ion-label color="danger"> Ungültige Steuernummer, bitte überprüfen Sie Ihre Eingabe.</ion-label> </div>
      <div *ngIf="taxNo.errors.isServiceUnavailable"> <ion-label color="danger"> Leider ist der von uns verwendete Webdienst vorübergehend nicht erreichbar. Bitte versuchen Sie es noch einmal.</ion-label> </div>
  </div>

  <ion-item>
   <ion-label position="stacked">Abweichende Rechnungsadresse</ion-label>
   <ion-toggle [(ngModel)]="billingaddress" [ngModelOptions]="{standalone: true}"></ion-toggle>
  </ion-item>
  </div>
  </ion-item-group>
 
  
  <div *ngIf="billingaddress">
  <ion-item-group>
    <ion-item-divider>
      <label> Rechnungsdaten:</label>
    </ion-item-divider>  
    
  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Straße</mat-label>
    <mat-icon matPrefix *ngIf="organisation.billing_street.length > 4"  [style.color]="'#39a42b'">check_circle</mat-icon>
    <input matInput type="text" placeholder="Your Billing street"  [(ngModel)]="organisation.billing_street" [ngModelOptions]="{standalone: true}">
  </mat-form-field>
  
  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Stadt</mat-label>
    <mat-icon matPrefix *ngIf="organisation.city.length > 3"  [style.color]="'#39a42b'">check_circle</mat-icon>
    <input matInput type="text" placeholder="Your Billing City"  [(ngModel)]="organisation.billing_city" [ngModelOptions]="{standalone: true}">
  </mat-form-field>
  
  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>PLZ</mat-label>
    <mat-icon matPrefix *ngIf="organisation.billing_zip_code.length > 4"  [style.color]="'#39a42b'">check_circle</mat-icon>
    <input matInput type="text" placeholder="Your Billing Postcode"  [(ngModel)]="organisation.billing_zip_code" [ngModelOptions]="{standalone: true}">
  </mat-form-field>
  
  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Land</mat-label>
    <mat-icon matPrefix *ngIf="organisation.billing_zip_code.length > 4"  [style.color]="'#39a42b'">check_circle</mat-icon>
    <input matInput type="text" placeholder="Your Billing Country"  [(ngModel)]="organisation.billing_country" [ngModelOptions]="{standalone: true}">
  </mat-form-field>
  
    </ion-item-group>
    </div>      
 
  
   <ion-row>
    <ion-col>
    <div>
        <button mat-button matStepperPrevious>Zurück</button>
      </div>
       </ion-col>
    <ion-col>
      <div style="display:flex; justify-content:flex-end; width:100%; padding:0;">
        <ion-button type="submit" slot="end" >Weiter</ion-button>
    </div>
     </ion-col>
     </ion-row>
         
       </ion-card>
      </form>
   </mat-step>

    <mat-step [editable]="!submitted"  [stepControl]="orderFormGroup" >
       <form [formGroup]="orderFormGroup" (ngSubmit)="orderNow()">
         <ng-template matStepperIcon="edit">
            <mat-icon *ngIf="!submitted">edit</mat-icon>
            <mat-icon *ngIf="submitted">done</mat-icon>
        </ng-template>

         <ng-template matStepLabel >Buchung </ng-template>
         
           <div *ngIf="route && !order  && !sub?.closed">
             <ion-card>
              <ion-grid>
                <ion-row >
                  <ion-col>
                    <ion-item>
                      <ion-label position="stacked"> Dauer </ion-label>
                      <ion-input readonly>{{TDay}} T, {{THours}} h und {{TMin}} Min</ion-input>
                    </ion-item>
                  </ion-col>
      
                  <ion-col>
                    <ion-item>
                      <ion-label position="stacked">Strecke</ion-label>
                      <ion-input readonly>{{transport.distance | number:'1.0-0'}} km</ion-input>
                    </ion-item>
                  </ion-col>
        
                  <ion-col>
                    <div class="pricing-style">
                       <ion-item lines="none">
                      <ion-label position="stacked">Online Preis</ion-label>
                      <ion-input readonly><ins>{{transport.price*transport.trucks | currency:'EUR' }}</ins></ion-input>
                      <ion-label> für {{transport.trucks }} LKW
                                   <p *ngIf="transport.services.insurance"><font size ="1">inkl. Versicherung und Maut</font></p>
                                   <p *ngIf="!transport.services.insurance"><font size ="1">inkl. Maut </font></p>
                      </ion-label>
                    </ion-item>
                    </div>
                  </ion-col>
                  </ion-row>
               
              </ion-grid>
             </ion-card>
             <ion-card>

              <ion-grid>
                 <ion-row >
                   <ion-col>
            
                    <mat-form-field appearance="outline" class="links-full-width">
                      <mat-label>Sendungsreferenz</mat-label>
                      <mat-icon matPrefix *ngIf="transport.reference.length > 4"  [style.color]="'#39a42b'">check_circle</mat-icon>
                      <input matInput type="text" maxlength="36" placeholder="Your Shipping RefNo."  [(ngModel)]="transport.reference" [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                    </ion-col>
                    <ion-col>
                    </ion-col>
                 </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-item-divider>
                      <label>Sendungsdetails für Abholung</label>
                    </ion-item-divider>
                    <div *ngFor="let item of transport.waypoints.loading;index as i"> 
                     <ion-item-group>
                       
                    <ion-item>
                      <ion-label> {{i+1}}. Ladestelle </ion-label>
                          <ion-button (click)="transport.waypoints.loading[i].show=!transport.waypoints.loading[i].show" slot="start">
                              <ion-icon slot="icon-only" *ngIf="transport.waypoints.loading[i].show==true" name="remove-circle-outline"></ion-icon>
                              <ion-icon slot="icon-only" *ngIf="transport.waypoints.loading[i].show==false" name="add-circle-outline"></ion-icon>
                          </ion-button>
                    </ion-item>
                    
                    <div *ngIf="transport.waypoints.loading[i].show==true">
                       <mat-form-field appearance="outline" class="links-full-width">
                        <mat-label>Name Abholstelle / Kunde:</mat-label>
                        <input matInput type="text" maxlength="50" placeholder="Point of Loading"  [(ngModel)]="transport.waypoints.loading[i].name" [ngModelOptions]="{standalone: true}">
                        
                      </mat-form-field>
                      <ion-item>
                        <ion-label position="stacked"> Abholort </ion-label>
                        <ion-input readonly>{{transport.waypoints.loading[i].address}}</ion-input>
                      </ion-item>
                        <ion-item>
                        <ion-label position="stacked"> Ladezeit </ion-label>
                        <ion-input readonly>Am {{transport.waypoints.loading[i].date}} zwischen {{transport.waypoints.loading[i].timeRange[0]}}:00 und {{transport.waypoints.loading[i].timeRange[1]}}:00 Uhr</ion-input>
                      </ion-item>
                      <mat-form-field appearance="outline" class="links-full-width">
                        <mat-label>Ladereferenz</mat-label>
                        <mat-icon matPrefix *ngIf="transport.waypoints.loading[i].reference.length > 4"  [style.color]="'#39a42b'">check_circle</mat-icon>
                        <input matInput type="text" maxlength="25" placeholder="Your Loading RefNo."  [(ngModel)]="transport.waypoints.loading[i].reference" [ngModelOptions]="{standalone: true}">
                      </mat-form-field>
                        <ion-col>
                          <mat-form-field appearance="outline" class="links-full-width">
                          <mat-label>Ansprechpartner</mat-label>
                          <mat-icon matPrefix *ngIf="transport.waypoints.loading[i] && transport.waypoints.loading[i].contact.length > 4"  [style.color]="'#39a42b'">check_circle</mat-icon>
                          <input matInput type="text" maxlength="25" placeholder="Contact Person"  [(ngModel)]="transport.waypoints.loading[i].contact" [ngModelOptions]="{standalone: true}">
                        </mat-form-field>  
                        </ion-col>
                          <ion-col>
                          <mat-form-field appearance="outline" class="links-full-width">
                          <mat-label>Telefonnummer Ansprechpartner</mat-label>
                          <mat-icon matPrefix *ngIf="transport.waypoints.loading[i] && transport.waypoints.loading[i].contactphone.length > 4"  [style.color]="'#39a42b'">check_circle</mat-icon>
                          <input matInput type="tel" maxlength="25" placeholder="Phone numer of contact person"  [(ngModel)]="transport.waypoints.loading[i].contactphone" [ngModelOptions]="{standalone: true}">
                        </mat-form-field>  
                        </ion-col>
                      <mat-form-field appearance="outline" class="links-full-width">
                        <mat-label>Bemerkung (Beladung):</mat-label>
                        <input matInput type="text" maxlength="50" placeholder="Your Loading notes"  [(ngModel)]="transport.waypoints.loading[i].notes" [ngModelOptions]="{standalone: true}">
                        <mat-hint align="end">{{transport.waypoints.loading[i].notes.length || 0}}/50</mat-hint>
                      </mat-form-field>
                       </div>
                      </ion-item-group>
                  </div>
                  </ion-col>
                  <ion-col>
                     <ion-item-divider>
                        <label>Sendungsdetails für Anlieferung</label>
                      </ion-item-divider>
                    <div *ngFor="let item of transport.waypoints.unloading;index as i"> 
                    <ion-item-group>
                      
                            
                    <ion-item>
                      <ion-label> {{i+1}}. Entladestelle </ion-label>
                          <ion-button (click)="transport.waypoints.unloading[i].show=!transport.waypoints.unloading[i].show" slot="start">
                              <ion-icon slot="icon-only" *ngIf="transport.waypoints.unloading[i].show==true" name="remove-circle-outline"></ion-icon>
                              <ion-icon slot="icon-only" *ngIf="transport.waypoints.unloading[i].show==false" name="add-circle-outline"></ion-icon>
                          </ion-button>
                    </ion-item>
                    
                    <div *ngIf="transport.waypoints.unloading[i].show==true">
                    
                    <mat-form-field appearance="outline" class="links-full-width">
                      <mat-label>Name Lieferstelle / Kunde:</mat-label>
                      <input matInput type="text" maxlength="50" placeholder="Point of Unloading"  [(ngModel)]="transport.waypoints.unloading[i].name" [ngModelOptions]="{standalone: true}">
                     
                    </mat-form-field>
                    <ion-item>
                      <ion-label position="stacked"> Entladeort </ion-label>
                      <ion-input readonly>{{transport.waypoints.unloading[i].address}}</ion-input>
                    </ion-item>
                    <ion-item>
                      <ion-label position="stacked"> Entladezeit </ion-label>
                      <ion-input readonly>Am {{transport.waypoints.unloading[i].date}} zwischen {{transport.waypoints.unloading[i].timeRange[0]}}:00 und {{transport.waypoints.unloading[i].timeRange[1]}}:00 Uhr</ion-input>
                    </ion-item>
                    <mat-form-field appearance="outline" class="links-full-width">
                      <mat-label>Entladereferenz</mat-label>
                      <mat-icon matPrefix *ngIf="transport.waypoints.unloading[i].reference.length > 4"  [style.color]="'#39a42b'">check_circle</mat-icon>
                      <input matInput type="text" maxlength="25" placeholder="Your Unloading RefNo."  [(ngModel)]="transport.waypoints.unloading[i].reference" [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                      <ion-col>
                        <mat-form-field appearance="outline" class="links-full-width">
                        <mat-label>Ansprechpartner</mat-label>
                        <mat-icon matPrefix *ngIf="transport.waypoints.unloading[i] && transport.waypoints.unloading[0].contact.length > 4"  [style.color]="'#39a42b'">check_circle</mat-icon>
                        <input matInput type="text" maxlength="25" placeholder="Contact Person"  [(ngModel)]="transport.waypoints.unloading[i].contact" [ngModelOptions]="{standalone: true}">
                      </mat-form-field>  
                      </ion-col>
                        <ion-col>
                        <mat-form-field appearance="outline" class="links-full-width">
                        <mat-label>Telefonnummer Ansprechpartner</mat-label>
                        <mat-icon matPrefix *ngIf="transport.waypoints.unloading[i] && transport.waypoints.unloading[i].contactphone.length > 4"  [style.color]="'#39a42b'">check_circle</mat-icon>
                        <input matInput type="tel" maxlength="25" placeholder="Phone numer of contact person"  [(ngModel)]="transport.waypoints.unloading[i].contactphone" [ngModelOptions]="{standalone: true}">
                      </mat-form-field>  
                      </ion-col>
                    <mat-form-field appearance="outline" class="links-full-width">
                      <mat-label>Bemerkung (Entladung):</mat-label>
                      <input matInput type="text" maxlength="50" placeholder="Your Unloading notes"  [(ngModel)]="transport.waypoints.unloading[i].notes" [ngModelOptions]="{standalone: true}">
                      <mat-hint align="end">{{transport.waypoints.unloading[i].notes.length || 0}}/50</mat-hint>
                    </mat-form-field>
                 </div>   
                    </ion-item-group>
                 </div>
                    </ion-col>
                    
                </ion-row>
                  </ion-grid>
              </ion-card>
          

  <ion-card>
    <ion-item-group>
    <ion-item-divider>
      <label>Trailer</label>
      <p></p>
    </ion-item-divider>
    
        <ion-item>
          <ion-label>{{transport.trailer}}</ion-label>
        </ion-item>
    
   <ion-item>
          <ion-label>{{transport.attributes}}</ion-label>
    </ion-item>
    
  </ion-item-group>
  
  <ion-item-group>
    <ion-item-divider>
      <label> Cargo</label>
    </ion-item-divider>
    
  <div *ngIf="showCargo">
  <ion-card  *ngFor="let item of cargo; index as i">
  <ion-grid>
      <ion-row>
          <ion-col>
    <ion-item>
      
      <ion-label position="stacked">Verpackungsart</ion-label>
      <ion-input [(ngModel)]="item.text" [ngModelOptions]="{standalone: true}" readonly ></ion-input>
    </ion-item>
    
    <ion-item>
      <ion-label position="stacked">Kategorie</ion-label>
       <ion-input  [(ngModel)]="item.cat" [ngModelOptions]="{standalone: true}" readonly></ion-input>
    </ion-item>
    </ion-col>
  
  <ion-col>
    <ion-item>
      <ion-label position="stacked">Menge</ion-label>
      <ion-input type="number" min = "0" [(ngModel)]="item.items" [ngModelOptions]="{standalone: true}" readonly></ion-input>
    </ion-item>
    
    <ion-item>
      <ion-label position="stacked">Ladenummer (optional)</ion-label>
      <ion-input type="text" [(ngModel)]="item.no" [ngModelOptions]="{standalone: true}" readonly></ion-input>
    </ion-item>
    </ion-col>
    
    <ion-col>
    <ion-item>
      <ion-label position="stacked">Gewicht (je Stück) in kg</ion-label>
      <ion-input  type="number" min = "0" steps="0.1" [(ngModel)]="item.weight" [ngModelOptions]="{standalone: true}" readonly></ion-input>
    </ion-item>
    </ion-col>
    
    </ion-row>
    
    <ion-item-group>
          <ion-item-divider>
            <label>Maße</label>
          </ion-item-divider>
        <ion-row>
          <ion-col>
        <ion-item>
          <ion-label position="stacked">Höhe (in cm)</ion-label>
          <ion-input type="number" min = "0" max="265" [(ngModel)]="item.height" [ngModelOptions]="{standalone: true}" readonly></ion-input>
        </ion-item>
        </ion-col>
        <ion-col>
        <ion-item>
          <ion-label position="stacked">Länge (in cm)</ion-label>
          <ion-input type="number" min = "0" max = "1360" [(ngModel)]="item.length" [ngModelOptions]="{standalone: true}" readonly></ion-input>
        </ion-item>
        </ion-col>
        <ion-col>
        <ion-item>
          <ion-label position="stacked">Breite (in cm)</ion-label>
          <ion-input type="number" min = "0" max = "245" [(ngModel)]="item.width" [ngModelOptions]="{standalone: true}" readonly></ion-input>
        </ion-item>
        </ion-col>
      </ion-row>
  </ion-item-group>
           
    </ion-grid>
  </ion-card>
  </div>
  

       <ion-row>
      
       <ion-col>
    </ion-col>
   
    <ion-col>
    <ion-item>
      <ion-label position="stacked">Gesamtvolumen (cbm)</ion-label>
    <ion-input type="number" min="0" max="85" steps="0.1" [(ngModel)]="totalvolume" [ngModelOptions]="{standalone: true}" readonly></ion-input>
    </ion-item>
    
    </ion-col>
          
      <ion-col>
    <ion-item>
      <ion-label position="stacked">Gesamtgewicht (t)</ion-label>
      <ion-input type="number" min="0" max="24.5" steps="0.1"  [(ngModel)]="totalweight" [ngModelOptions]="{standalone: true}" readonly></ion-input>
    </ion-item>
    </ion-col>
    </ion-row>
  
  
 </ion-item-group>
   </ion-card>
  
   <ion-card>
        
            <ion-item-group>
                        <ion-item-divider>
                          <label>Zusatzleistungen</label>
                        </ion-item-divider>
                        
                  <ion-row>
                    <ion-row>
                        <ion-item-divider>
                          <label>Beladung</label>
                        </ion-item-divider>
                    <ion-col *ngFor="let item of transport.waypoints.loading; index as i">
                      <ion-item>
                          <ion-label >Telefonische Avisierung (Abholung) - {{transport.waypoints.loading[i].address}}</ion-label>
                           <ion-checkbox slot="start"  [(ngModel)]="transport.waypoints.loading[i].services.notificationbyphone" [ngModelOptions]="{standalone: true}" disabled="true"></ion-checkbox>
                        </ion-item>
                      
                    </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-item-divider>
                          <label>Entladung</label>
                        </ion-item-divider>
                        <ion-col *ngFor="let item of transport.waypoints.unloading; index as i">
                        <ion-item>
                          <ion-label>Telefonische Avisierung (Entladung) - {{transport.waypoints.unloading[i].address}}</ion-label>
                           <ion-checkbox slot="start" [(ngModel)]="transport.waypoints.unloading[i].services.notificationbyphone" [ngModelOptions]="{standalone: true}" disabled="true"></ion-checkbox>
                        </ion-item>
                        </ion-col>
                    </ion-row>
                    </ion-row>
                    <ion-row>
                        <ion-item-divider>
                          <label>Sonstiges</label>
                        </ion-item-divider>
                      <ion-col>
                          <ion-item>
                        <ion-label >Zustellung Frachtpapiere per Post</ion-label>
                           <ion-checkbox slot="start" [(ngModel)]="transport.services.documentsbypost" [ngModelOptions]="{standalone: true}" disabled="true"></ion-checkbox>
                        </ion-item>
                        
                       <ion-item>
                         <ion-label >Packmitteltausch</ion-label>
                           <ion-checkbox slot="start" [(ngModel)]="transport.services.packagingexchange" [ngModelOptions]="{standalone: true}" disabled="true"></ion-checkbox>
                        </ion-item>
                       
                        </ion-col>
                      <ion-col>
                       <ion-item>
                          <ion-label>Versicherung</ion-label>
                           <ion-checkbox slot="start" [(ngModel)]="transport.services.insurance" [ngModelOptions]="{standalone: true}" disabled="true"></ion-checkbox>
                        </ion-item>
                        
                        <ion-item *ngIf="transport.services.insurance">
                          <ion-label>Warenwert in EUR:</ion-label>
                         <ion-input type="number" min="1" max="250000" [(ngModel)]="transport.costofgoods" [ngModelOptions]="{standalone: true}" readonly></ion-input>        
                        </ion-item>
                    </ion-col>
                        
                    </ion-row>
                  
                
      </ion-item-group>
   </ion-card> 
     
  
   <ion-card>
    <ion-item-group>
    <ion-item-divider>
      <label>Bemerkung: ({{transport.notes.length}}/255 Zeichen)</label>
      <p></p>
    </ion-item-divider>
    
    <ion-item>
       <ion-textarea type="text" maxlength="255" rows="10"  [(ngModel)]="transport.notes" [ngModelOptions]="{standalone: true}" placeholder="Zusätzliche Information an uns..."></ion-textarea>
    </ion-item>     
    </ion-item-group>
    </ion-card>
    
    <ion-card>
      <ion-item>
        <mat-checkbox required="true" color="primary" formControlName="vertrag">Ich nehme das Frachtangebot der DML24 an und bin berechtigt im Namen meines Unternehmens Verträge abzuschließen.</mat-checkbox>
        <mat-icon matSuffix *ngIf="vertrag.valid"  [style.color]="'#39a42b'">check_circle</mat-icon>
      </ion-item>

      <ion-item>
        <mat-checkbox required="true" color="primary" formControlName="agb">Ich stimme hiermit den DML Geschäftsbedingungen basierend auf der <a href="https://www.dslv.org/dslv/web.nsf/gfx/E58DF8579DEF6A88C12583F3003EC79A/$file/DSLV-ADSp-2017.pdf"  target="_blank"> ADSp (2017) </a> zu.</mat-checkbox>
      </ion-item>
      
      <ion-item>
         <mat-checkbox required="true" color="primary" formControlName="dsgvo">Ich stimme den <a href="https://www.dml24.de/Datenschutz/" target="_blank"> Datenschutzbestimmungen </a>  zu.</mat-checkbox>
      </ion-item>
      
      <ion-item>
         <mat-checkbox required="true" color="primary" formControlName="adr">Ich bestätige hiermit, keine Gefahrgüter (ADR) in Auftrag zu geben.</mat-checkbox>
      </ion-item>
      
       <ion-item *ngIf="!authState.signedIn">
         <mat-checkbox color="primary" formControlName="account">Ich möchte einen Account für zukünftige Nutzung und Rabattaktionen bei der DML24 weiter nutzen.</mat-checkbox>
      </ion-item>
      
    <div *ngIf="orderFormGroup.invalid" class="alert alert-danger">
      <ion-item>
        <ion-label color="danger"><font size ="1">*Bitte geben Sie Ihre Zustimmung durch Setzen der Haken.</font></ion-label>
      </ion-item>
      </div>  
      <div>
      <button mat-button matStepperPrevious>Zurück</button>
    </div>
     <div style="display:flex; justify-content:center; width:100%; padding:0;">
      <ion-button type="submit" shape="round" fill="outline" (click)="myStepper.next()">
         <ion-icon slot="start" name="star"></ion-icon>
        Jetzt verbindlich abschließen
          <ion-icon slot="end" name="star"></ion-icon>
        </ion-button>
    </div>
    </ion-card>
       </div>
      </form>

<!--   </mat-step>
   
     <mat-step [editable]="!submitted"  [stepControl]="" >
       
        <ng-template matStepperIcon="edit">
            <mat-icon *ngIf="!submitted">edit</mat-icon>
            <mat-icon *ngIf="submitted">done</mat-icon>
        </ng-template>

         <ng-template matStepLabel>
         <p (click)="orderNow()">Bestätigung</p>
         </ng-template>
  -->      
        <div *ngIf="!order && sub && !sub?.closed" >
          <p style="display:flex; justify-content:center; width:80%; padding:0;">
          Wir bearbeiten gerade Ihre Anfrage. 
          <br>
         <ion-progress-bar style="width=90%;" type="indeterminate"></ion-progress-bar>
          <br>
          Bitte haben Sie einen Augenblick Geduld...
          </p>
        </div>
         
        <div *ngIf="order">
             <p>Vielen Dank für Ihren Auftrag:</p>
             <ion-card>
              <ion-grid width="100">
               <ion-row>
                  <ion-col>
                    <ion-item-divider>
                      <label>Sendungsdetails für Abholung</label>
                    </ion-item-divider>
                    <div *ngFor="let item of transport.waypoints.loading;index as i"> 
                     <ion-item-group>
                       
                    <ion-item>
                      <ion-label> {{i+1}}. Ladestelle </ion-label>
                          <ion-button (click)="transport.waypoints.loading[i].show=!transport.waypoints.loading[i].show" slot="start">
                              <ion-icon slot="icon-only" *ngIf="transport.waypoints.loading[i].show==true" name="remove-circle-outline"></ion-icon>
                              <ion-icon slot="icon-only" *ngIf="transport.waypoints.loading[i].show==false" name="add-circle-outline"></ion-icon>
                          </ion-button>
                    </ion-item>
                    
                    <div *ngIf="transport.waypoints.loading[i].show==true">
                       <mat-form-field appearance="outline" class="links-full-width">
                        <mat-label>Name Abholstelle / Kunde:</mat-label>
                        <input readonly matInput type="text" maxlength="50" placeholder="Point of Loading"  [(ngModel)]="transport.waypoints.loading[i].name" [ngModelOptions]="{standalone: true}">
                        
                      </mat-form-field>
                      <ion-item>
                        <ion-label position="stacked"> Abholort </ion-label>
                        <ion-input readonly>{{transport.waypoints.loading[i].address}}</ion-input>
                      </ion-item>
                        <ion-item>
                        <ion-label position="stacked"> Ladezeit </ion-label>
                        <ion-input readonly>Am {{transport.waypoints.loading[i].date}} zwischen {{transport.waypoints.loading[i].timeRange[0]}}:00 und {{transport.waypoints.loading[i].timeRange[1]}}:00 Uhr</ion-input>
                      </ion-item>
                      <mat-form-field appearance="outline" class="links-full-width">
                        <mat-label>Ladereferenz</mat-label>
                        <mat-icon matPrefix *ngIf="transport.waypoints.loading[i].reference.length > 4"  [style.color]="'#39a42b'">check_circle</mat-icon>
                        <input readonly matInput type="text" maxlength="25" placeholder="Your Loading RefNo."  [(ngModel)]="transport.waypoints.loading[i].reference" [ngModelOptions]="{standalone: true}">
                      </mat-form-field>
                        <ion-col>
                          <mat-form-field appearance="outline" class="links-full-width">
                          <mat-label>Ansprechpartner</mat-label>
                          <mat-icon matPrefix *ngIf="transport.waypoints.loading[i] && transport.waypoints.loading[i].contact.length > 4"  [style.color]="'#39a42b'">check_circle</mat-icon>
                          <input readonly matInput type="text" maxlength="25" placeholder="Contact Person"  [(ngModel)]="transport.waypoints.loading[i].contact" [ngModelOptions]="{standalone: true}">
                        </mat-form-field>  
                        </ion-col>
                          <ion-col>
                          <mat-form-field appearance="outline" class="links-full-width">
                          <mat-label>Telefonnummer Ansprechpartner</mat-label>
                          <mat-icon matPrefix *ngIf="transport.waypoints.loading[i] && transport.waypoints.loading[i].contactphone.length > 4"  [style.color]="'#39a42b'">check_circle</mat-icon>
                          <input readonly matInput type="tel" maxlength="25" placeholder="Phone numer of contact person"  [(ngModel)]="transport.waypoints.loading[i].contactphone" [ngModelOptions]="{standalone: true}">
                        </mat-form-field>  
                        </ion-col>
                      <mat-form-field appearance="outline" class="links-full-width">
                        <mat-label>Bemerkung (Beladung):</mat-label>
                        <input readonly matInput type="text" maxlength="50" placeholder="Your Loading notes"  [(ngModel)]="transport.waypoints.loading[i].notes" [ngModelOptions]="{standalone: true}">
                        <mat-hint align="end">{{transport.waypoints.loading[i].notes.length || 0}}/50</mat-hint>
                      </mat-form-field>
                       </div>
                      </ion-item-group>
                  </div>
                  </ion-col>
                  <ion-col>
                     <ion-item-divider>
                        <label>Sendungsdetails für Anlieferung</label>
                      </ion-item-divider>
                    <div *ngFor="let item of transport.waypoints.unloading;index as i"> 
                    <ion-item-group>
                      
                            
                    <ion-item>
                      <ion-label> {{i+1}}. Entladestelle </ion-label>
                          <ion-button (click)="transport.waypoints.unloading[i].show=!transport.waypoints.unloading[i].show" slot="start">
                              <ion-icon slot="icon-only" *ngIf="transport.waypoints.unloading[i].show==true" name="remove-circle-outline"></ion-icon>
                              <ion-icon slot="icon-only" *ngIf="transport.waypoints.unloading[i].show==false" name="add-circle-outline"></ion-icon>
                          </ion-button>
                    </ion-item>
                    
                    <div *ngIf="transport.waypoints.unloading[i].show==true">
                    
                    <mat-form-field appearance="outline" class="links-full-width">
                      <mat-label>Name Lieferstelle / Kunde:</mat-label>
                      <input readonly matInput type="text" maxlength="50" placeholder="Point of Unloading"  [(ngModel)]="transport.waypoints.unloading[i].name" [ngModelOptions]="{standalone: true}">
                     
                    </mat-form-field>
                    <ion-item>
                      <ion-label position="stacked"> Entladeort </ion-label>
                      <ion-input readonly>{{transport.waypoints.unloading[i].address}}</ion-input>
                    </ion-item>
                    <ion-item>
                      <ion-label position="stacked"> Entladezeit </ion-label>
                      <ion-input readonly>Am {{transport.waypoints.unloading[i].date}} zwischen {{transport.waypoints.unloading[i].timeRange[0]}}:00 und {{transport.waypoints.unloading[i].timeRange[1]}}:00 Uhr</ion-input>
                    </ion-item>
                    <mat-form-field appearance="outline" class="links-full-width">
                      <mat-label>Entladereferenz</mat-label>
                      <mat-icon matPrefix *ngIf="transport.waypoints.unloading[i].reference.length > 4"  [style.color]="'#39a42b'">check_circle</mat-icon>
                      <input readonly matInput type="text" maxlength="25" placeholder="Your Unloading RefNo."  [(ngModel)]="transport.waypoints.unloading[i].reference" [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                      <ion-col>
                        <mat-form-field appearance="outline" class="links-full-width">
                        <mat-label>Ansprechpartner</mat-label>
                        <mat-icon matPrefix *ngIf="transport.waypoints.unloading[i] && transport.waypoints.unloading[0].contact.length > 4"  [style.color]="'#39a42b'">check_circle</mat-icon>
                        <input readonly matInput type="text" maxlength="25" placeholder="Contact Person"  [(ngModel)]="transport.waypoints.unloading[i].contact" [ngModelOptions]="{standalone: true}">
                      </mat-form-field>  
                      </ion-col>
                        <ion-col>
                        <mat-form-field appearance="outline" class="links-full-width">
                        <mat-label>Telefonnummer Ansprechpartner</mat-label>
                        <mat-icon matPrefix *ngIf="transport.waypoints.unloading[i] && transport.waypoints.unloading[i].contactphone.length > 4"  [style.color]="'#39a42b'">check_circle</mat-icon>
                        <input readonly matInput type="tel" maxlength="25" placeholder="Phone numer of contact person"  [(ngModel)]="transport.waypoints.unloading[i].contactphone" [ngModelOptions]="{standalone: true}">
                      </mat-form-field>  
                      </ion-col>
                    <mat-form-field appearance="outline" class="links-full-width">
                      <mat-label>Bemerkung (Entladung):</mat-label>
                      <input readonly matInput type="text" maxlength="50" placeholder="Your Unloading notes"  [(ngModel)]="transport.waypoints.unloading[i].notes" [ngModelOptions]="{standalone: true}">
                      <mat-hint align="end">{{transport.waypoints.unloading[i].notes.length || 0}}/50</mat-hint>
                    </mat-form-field>
                 </div>   
                    </ion-item-group>
                 </div>
                    </ion-col>
                    
                </ion-row>
      
                <ion-row >
                  <ion-col>
                    <ion-item>
                      <ion-label position="stacked"> Fahrzeit </ion-label>
                      <ion-input readonly>{{TDay}} Tage, {{THours}} Stunden und {{TMin}} Minuten</ion-input>
                    </ion-item>
                  </ion-col>
      
                  <ion-col>
                    <ion-item>
                      <ion-label position="stacked">Strecke</ion-label>
                      <ion-input readonly>{{transport.distance | number:'1.0-0'}} km</ion-input>
                    </ion-item>
                  </ion-col>
        
                  <ion-col>
                   <div class="pricing-style">
                       <ion-item lines="none">
                      <ion-label position="stacked">Online Preis</ion-label>
                      <ion-input readonly><ins>{{transport.price*transport.trucks | currency:'EUR' }}</ins></ion-input>
                      <ion-label> für {{transport.trucks }} LKW</ion-label>
                    </ion-item>
                    <ion-item  lines="none">
                      <ion-label>  <p *ngIf="transport.services.insurance"><font size ="1">inkl. Versicherung und Maut</font></p>
                                   <p *ngIf="!transport.services.insurance"><font size ="1">inkl. Maut </font></p>
                      </ion-label>
                    </ion-item>
                    </div>
                  </ion-col>
                  </ion-row>
              </ion-grid>
          </ion-card>
      
  
  <ion-card>
  <ion-item-group>
    <ion-item-divider>
      <label> Cargo</label>
    </ion-item-divider>
  
  <ion-card  *ngFor="let item of cargo; index as i">
  <ion-grid>
      <ion-row>
          <ion-col>
    <ion-item>
      
    <ion-label position="stacked">Verpackungsart</ion-label>
      <ion-icon slot="end" *ngIf="item.text!=''" name="checkmark-circle-outline" [style.color]="'#39a42b'"></ion-icon>
      <ion-input required [(ngModel)]="item.text" [ngModelOptions]="{standalone: true}" readonly ></ion-input>
    </ion-item>
    
    <ion-item>
      <ion-label position="stacked">Kategorie</ion-label>
       <ion-icon slot="end" *ngIf="item.cat != ''" name="checkmark-circle-outline" [style.color]="'#39a42b'"></ion-icon>
       <ion-input required [(ngModel)]="item.cat" [ngModelOptions]="{standalone: true}" readonly></ion-input>
    </ion-item>
    </ion-col>
  
  <ion-col>
    <ion-item>
      <ion-label position="stacked">Menge</ion-label>
      <ion-icon slot="end" *ngIf="item.items >0" name="checkmark-circle-outline" [style.color]="'#39a42b'"></ion-icon>
      <ion-input required type="number" min = "0" [(ngModel)]="item.items" [ngModelOptions]="{standalone: true}" readonly></ion-input>
    </ion-item>
    
    <ion-item *ngIf="transport.no!=''">
      <ion-label position="stacked">Ladenummer (optional)</ion-label>
      <ion-input type="text" [(ngModel)]="item.no" [ngModelOptions]="{standalone: true}" readonly></ion-input>
    </ion-item>
     
    </ion-col>
    
    <ion-col>
    <ion-item>
      <ion-label position="stacked">Gewicht (je Stück) in kg</ion-label>
      <ion-icon slot="end" *ngIf="item.length >0" name="checkmark-circle-outline" [style.color]="'#39a42b'"></ion-icon>
      <ion-input required type="number" min = "0" steps="0.1" [(ngModel)]="item.weight" [ngModelOptions]="{standalone: true}" readonly></ion-input>
      <ion-button *ngIf="cargo.length>1" slot="end" (click)="cargo.splice(i,1);calc()"><ion-icon name="close-circle-outline"></ion-icon></ion-button>
    </ion-item>
    </ion-col>
    
    </ion-row>
    
    <ion-item-group>
          <ion-item-divider>
            <label> Maße</label>
          </ion-item-divider>
        <ion-row>
          <ion-col>
        <ion-item>
          <ion-label position="stacked">Höhe (in cm)</ion-label>
          <ion-icon slot="end" *ngIf="item.height >0" name="checkmark-circle-outline" [style.color]="'#39a42b'"></ion-icon>
          <ion-input type="number" min = "0" max="265" [(ngModel)]="item.height" [ngModelOptions]="{standalone: true}" readonly></ion-input>
        </ion-item>
        </ion-col>
        <ion-col>
        <ion-item>
          <ion-label position="stacked">Länge (in cm)</ion-label>
          <ion-icon slot="end" *ngIf="item.length >0" name="checkmark-circle-outline" [style.color]="'#39a42b'"></ion-icon>
          <ion-input type="number" min = "0" max = "1360" [(ngModel)]="item.length" [ngModelOptions]="{standalone: true}" readonly></ion-input>
        </ion-item>
        </ion-col>
        <ion-col>
        <ion-item>
          <ion-label position="stacked">Breite (in cm)</ion-label>
          <ion-icon slot="end" *ngIf="item.width >0" name="checkmark-circle-outline" [style.color]="'#39a42b'"></ion-icon>
          <ion-input type="number" min = "0" max = "245" [(ngModel)]="item.width" [ngModelOptions]="{standalone: true}" readonly></ion-input>
        </ion-item>
        </ion-col>
      </ion-row>
           </ion-item-group>
           
    </ion-grid>
  </ion-card>
  <ion-grid>
       <ion-row>
      
       <ion-col>
    </ion-col>
   
    <ion-col>
    <ion-item>
      <ion-label position="stacked">Gesamtvolumen (cbm)</ion-label>
    <ion-input type="number" min="0" max="85" steps="0.1" [(ngModel)]="totalvolume" [ngModelOptions]="{standalone: true}" readonly></ion-input>
    </ion-item>
    
    </ion-col>
          
      <ion-col>
    <ion-item>
      <ion-label position="stacked">Gesamtgewicht (t)</ion-label>
      <ion-input type="number" min="0" max="24.5" steps="0.1"  [(ngModel)]="totalweight" [ngModelOptions]="{standalone: true}" readonly></ion-input>
    </ion-item>
    </ion-col>
    </ion-row>
  </ion-grid>
  
 </ion-item-group>
   </ion-card> 
   <ion-button (click)="new()">Neustart </ion-button>
          </div>
     

   </mat-step>
   

   
   </mat-stepper>
</ion-content>

<ion-footer class="animated fade-in-out"> 
  <ion-toolbar>
    <ion-row style="display:flex; justify-content:center; width:100%; padding:0;">
      <ion-col><font size="2"> &copy; DML Mittelstandslogistik GmbH {{copyrightyear}} </font></ion-col>
      <ion-col></ion-col>
      <ion-col><ion-icon name="reorder-three-outline"></ion-icon></ion-col>
      <ion-col></ion-col>
      <ion-col><font size="1"> Version 1.6, 12.08.2023 </font></ion-col>
    </ion-row>
    <ion-row>
      <ion-col style="display:flex; justify-content:center; width:100%; padding:0;">
      </ion-col>
      <ion-col style="display:flex; justify-content:center; width:100%; padding:0;">
        <ul>
        <li> <font size="2"> <a href="https://www.dml24.de/Impressum"  target="_blank">Impressum</a></font></li>
        <li> <font size="2"> <a href="https://www.dml24.de/Datenschutz"  target="_blank">Datenschutz</a></font></li>
        </ul>
      </ion-col>
      <ion-col style="display:flex; justify-content:center; width:100%; padding:0;">
        <p><ion-icon size="small" name="call-outline"></ion-icon>
        <font size="2"> <a href="tel:+4923246816650"> +49 2324 6816650</a></font><br>
        <font size="2" color="#c4c4c4">Mo - Fr: 08:00 - 17:00 Uhr</font>  </p><br>
      </ion-col>
      <ion-col style="display:flex; justify-content:center; width:100%; padding:0;">
        <p>
          <ion-icon name="mail-outline"></ion-icon>
          <font size="2"> <a href="mailto:landtransporte@dml24.de?Subject=Transportanfrage" target="_top"> Anfrage per e-Mail</a></font> <br>
          <font size="2" color="#c4c4c4">landtransporte@dml24.de</font>  
        </p>
      </ion-col>
      <ion-col style="display:flex; justify-content:center; width:100%; padding:0;">
           <ion-img style="width: 120px; height: auto;" [src]="'./assets/icon/iso9000.png'"></ion-img>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col style="display:flex; justify-content:center; width:100%; padding:0;"> <font size="2"> Wir arbeiten auf Grundlage der <a href="https://www.dslv.org/dslv/web.nsf/gfx/E58DF8579DEF6A88C12583F3003EC79A/$file/DSLV-ADSp-2017.pdf"  target="_blank">Allgemeinen Deutschen Spediteurbedingungen (ADSp 2017)</a>, soweit unsere <a href="https://www.dml24.de/agb/" target="_blank">Allgemeinen Geschäftsbedingungen </a> nichts abweichendes regeln. </font></ion-col>
    </ion-row>
  </ion-toolbar>
</ion-footer>
