<ion-header translucent>
  <ion-toolbar> 
    <ion-title>
     <font style="position:absolute; left: 10%;"> <b>DML24</b></font> <br> <font style="position:absolute; left: 10%;" color="#c0c0c0" size ="1">Buchungshistorie</font>
    </ion-title>
    <img src="./assets/icon/logo-final.png" > 
    
    <ion-buttons *ngIf="authState.signedIn" slot="secondary" >
        <ion-menu-button ></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
    <div style="padding: 20px">
  <div class="example-container mat-elevation-z8">
    
<!--  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
    -->
  <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
 
 <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()">
      </mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)"
                    [aria-label]="checkboxLabel(row)">
      </mat-checkbox>
    </mat-cell>
    <mat-footer-cell *matFooterCellDef>  </mat-footer-cell>
  </ng-container>
  
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef > Datum </mat-header-cell>
      <mat-cell *matCellDef="let orderList">  {{orderList.date| date:'short':'CET':'de-DE'}}  </mat-cell>
      <mat-footer-cell *matFooterCellDef>  </mat-footer-cell>
    </ng-container>
    
    <ng-container matColumnDef="start">
      <mat-header-cell *matHeaderCellDef> Von </mat-header-cell>
      <mat-cell *matCellDef="let orderList">  {{orderList.transport.route.items[0].address}}  </mat-cell>
      <mat-footer-cell *matFooterCellDef>  </mat-footer-cell>
    </ng-container>
    
    <ng-container matColumnDef="dest">
      <mat-header-cell *matHeaderCellDef> Nach </mat-header-cell>
      <mat-cell *matCellDef="let element; index as i;"> {{element.transport.route.items[getListSize(i+ (paginator.pageIndex * paginator.pageSize))-1].address}}</mat-cell>
      <mat-footer-cell *matFooterCellDef>  </mat-footer-cell>
    </ng-container>
    
    <ng-container matColumnDef="reference">
      <mat-header-cell *matHeaderCellDef > Bestellreferenznummer </mat-header-cell>
      <mat-cell *matCellDef="let orderList">  {{orderList.reference}} </mat-cell>
      <mat-footer-cell *matFooterCellDef>  </mat-footer-cell>
    </ng-container>
 
    <ng-container matColumnDef="price">
      <mat-header-cell *matHeaderCellDef > Preis </mat-header-cell>
      <mat-cell *matCellDef="let orderList"> {{orderList.price/100 | currency:'EUR'}} </mat-cell>
      <mat-footer-cell *matFooterCellDef>  </mat-footer-cell>
    </ng-container>
    
    <ng-container matColumnDef="eta">
      <mat-header-cell *matHeaderCellDef> ETA </mat-header-cell>
      <mat-cell *matCellDef="let orderList"> {{orderList.transport.eta | date:'short':'CET':'de-DE'}} </mat-cell>
      <mat-footer-cell *matFooterCellDef>  </mat-footer-cell>
    </ng-container>
    
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef > Status </mat-header-cell>
      <mat-cell *matCellDef="let orderList"> {{orderList.status}} </mat-cell>
      <mat-footer-cell *matFooterCellDef>  </mat-footer-cell>
    </ng-container>
   
    <ng-container matColumnDef="storno">
      <mat-header-cell *matHeaderCellDef> Storno </mat-header-cell>
      <mat-cell *matCellDef="let orderList, index as i"> 
          <ion-button *ngIf="orderList.status=='new' &&  (now-orderList.createdAt < 60000)" (click)="cancel(i); $event.stopPropagation()"><ion-icon  slot="icon-only" name="close-circle-outline"></ion-icon></ion-button>
           </mat-cell>
       <mat-footer-cell *matFooterCellDef> </mat-footer-cell>      
    </ng-container>
    
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    
    <mat-row *matRowDef="let row; columns: displayedColumns"  
      [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}"
      (click) ="open(row)"
      (mouseover)="row.hovered = true" 
      (mouseout)="row.hovered = false"></mat-row>
    <mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></mat-footer-row>
 
</mat-table>
 
<mat-paginator [pageSizeOptions]="[5, 10, 50]" showFirstLastButtons></mat-paginator>
</div>
</div>
</ion-content>
