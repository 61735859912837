import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
   constructor(
 //   private _amplify:AmplifyService
    ) 
    { }
    
  public register(user, organisation,pw):Subject<any>
  {
    
     let values:Subject<any> = new Subject<any>(); 
    
   var Password = {
 
    _pattern : /[a-zA-Z0-9]/,
    
    
    _getRandomByte : function()
    {
      // http://caniuse.com/#feat=getrandomvalues
      if(window.crypto && window.crypto.getRandomValues) 
      {
        var result = new Uint8Array(1);
        window.crypto.getRandomValues(result);
        return result[0];
      }
      else if(window.crypto && window.crypto.getRandomValues) 
      {
        var result = new Uint8Array(1);
        window.crypto.getRandomValues(result);
        return result[0];
      }
      else
      {
        return Math.floor(Math.random() * 256);
      }
    },
  
    generate : function(length)
    {
      return Array.apply(null, {'length': length})
        .map(function()
        {
          var result;
          while(true) 
          {
            result = String.fromCharCode(this._getRandomByte());
            if(this._pattern.test(result))
            {
              return result;
            }
          }        
        }, this)
        .join('');  
    }
  }

    
    var tempPass = Password.generate(12);
    
   ////console.log(user, organisation)
   
   var billing;
   
  
   
   var attributes = {
          'given_name': user.firstName,
          'family_name': user.lastName,
          'locale': user.language,
          'address': organisation.street,
          'phone_number': user.officephone,
          'gender': user.gender,
          'name' : organisation.name,
          'custom:taxNo': organisation.taxNo,
          'custom:country': organisation.country,
          'custom:zip_code': organisation.zip_code,
          'custom:street': organisation.street,
          'custom:city': organisation.city,
          'custom:cell': user.cell,
          'custom:customerNo': 'n/a',
   }
   
   if (organisation.billing_street != "")
   {
      billing = {
          'custom:billing_street': organisation.billing_street,
          'custom:billing_city': organisation.billing_city,
          'custom:billing_zip_code': organisation.billing_zip_code,
          'custom:billing_country': organisation.billing_country
      }
      attributes = {...attributes, ...billing}
   }
   
      Auth.signUp({
      username: user.email,
      password: pw,
      attributes: attributes
         
      ,
      validationData: [] 
      })
      .then(data => {
        
        values.next(data);
        values.complete();
      })
      .catch(error => {
        //The user has already registered so go to the SignIn method
        if(error['code'] === "UsernameExistsException")  { values.error(error) }
        if(error['code'] === "InvalidParameterException"){ values.error(error) }
      });
      
       return values;
   }
   
   confirm (user, code):any
   {
    
      Auth.confirmSignUp(user.user.username, code, {
          // Optional. Force user confirmation irrespective of existing alias. By default set to True.
          forceAliasCreation: true    
      }).then(data => { return data;})
        .catch(err => {return null;});
   }
   

    
}
