import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DatePipe } from '@angular/common';

import { LoadingPointComponent } from '../../components/loading-point/loading-point.component'
import { LoadingDateSelectorComponent } from '../../components/loading-date-selector/loading-date-selector.component'
import { MaterialModule } from '../../modules/material/material.module'

@NgModule({
  imports: [
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        DatePipe
    ],
})
export class DmlCommonModule {}

