import { Component, NgZone, ViewChild, OnInit, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { MapService } from '../services/map.service'
import { MatStepper } from '@angular/material/stepper';
import { HttpService } from '../services/http.service'
import { EventService } from '../services/event.service'
import { AuthService } from '../services/auth.service'
import { DatabaseService } from '../services/database.service'
import { Auth } from 'aws-amplify'
import { Analytics } from 'aws-amplify';
import { Router, ActivatedRoute } from '@angular/router';

import { CookieService } from 'ngx-cookie-service'

import { DatePipe } from '@angular/common';
import { Observable} from 'rxjs';
import {tap,  map, startWith, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import {  FormBuilder, FormGroup, Validators, AbstractControl, NgForm, FormArray, FormControl } from '@angular/forms';

import { ModalController, ToastController, AlertController } from '@ionic/angular';
import { AuthenticateComponent } from '../components/authenticate/authenticate.component';
//import { LoadingPointComponent } from '../components/loading-point/loading-point.component'
//import { LoadingDateSelectorComponent } from '../components/loading-date-selector/loading-date-selector.component'

import { AddressValidator } from '../validators/address-validator'
import { VatValidator } from '../validators/vat-validator'
import { UserValidator } from '../validators/user-validator'

import { MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
  providers: [DatePipe]
})
export class HomePage implements OnInit, OnDestroy {
  
  @ViewChild('cargoForm') myForm: NgForm;
  
  public autocompleteItems: Observable<any>;
  public autocompleteItems2: Array<any>;
  public autocompleteItems3: Array<any>;
  public autocompleteAddress: Array<any>;
  
  filteredOptions: Observable<string[]>;
  copyrightyear: String;
  originalprice:number;
  startprice:number=0;
   
  public sub:Subscription;
  checkRouteSub:Subscription;
  checkCargoSub:Subscription;
  priceSub:Subscription;
  
  isChecked:boolean = false
  
   show = [true, false, false, false, false, false]
  
  wpunloadstop = 1;
  wploadstop = 1;
  
  public tollCost = 0;
 
  public multistop = false;
 
  public transport = {
          id: "",
          trucks: 1,
          waypoints: {
            loading: [  { id: "",
                          lat:0,lng:0,
                          name:"",
                          address: "", housenumber:"", street:"", zip:"", city:"", county:"", country:"", 
                          reference:"",
                          contact: "",
                          contactphone: "",
                          notes:"", 
                          date:"", 
                          timeRange:[6,9],    
                          eta: null,
                          services: {
                            notificationbyphone: false,
                            notificationbySMS:false,
                            notificationbyEmail:false,
                          },
                          show: false,
                          localHolidays: [{date: {day:0,
                                                  dayOfWeek:0,
                                                  month:0,
                                                  year:0
                                                  },
                                          holidayType: "",
                                          name: [{lang:"",
                                                  text:""
                                          }]}],
                        }],
            
            unloading: 
              [  { id: "",
                          lat:0,lng:0,
                          name:"",
                          address: "", housenumber:"", street:"", zip:"", city:"", county:"", country:"", 
                          reference:"",
                          contact: "",
                          contactphone: "",
                          notes:"", 
                          date:"", 
                          timeRange:[6,9],    
                          eta: null,
                          services: {
                            notificationbyphone: false,
                            notificationbySMS:false,
                            notificationbyEmail:false,
                          },
                          show: false,
                          localHolidays: [{date: {day:0,
                                                  dayOfWeek:0,
                                                  month:0,
                                                  year:0
                                                  },
                                          holidayType: "",
                                          name: [{lang:"",
                                                  text:""
                                          }]}],
                          }],
                          
                          
            },
          price: 0,
          costofgoods:0,
          distance: 0,
          travelTime: 0,
          days: 0,
          attributes: [],
          spannno: 0,
          trailer: ["standard"],
          eta: null,
          departure: null,
          notes:"",
          reference:"",
          services: {
              eventtracktrace: false,
              livetracktrace: false,
              packagingexchange: false,
              insurance: false,
              documentsbypost: false
          },
          paymentType: "",
          termOfPayment: 0
  }
  
  routeFormGroup: FormGroup;
//  simpleContactForm: FormGroup;
  cargoFormGroup: FormGroup;
  contactFormGroup: FormGroup; 
  paymentFormGroup: FormGroup;
  orderFormGroup: FormGroup;
  
  
  public authState = { signedIn: false,
                       groups: [""]};
                       
  public bill = false;
  public bill2 = false;
  public bill3 = true;
  //paymentTarget = "";
  
  vertrag= false;
  agb = false;
  dsgvo = false;
  adr = false;
  account = false;
  post = false;
  avis = false;
  avisent = false;
  avis2ent = false;
  vers = false;
  pack = false;
  maxheight = 265;
  maxvolume = 85;
  public edited= false;
  showCargo = false;
  showContactForm = false;
  
  submitted = false;
  checked = false;
  user:any;
  status:any;
  allNecInfoAvailable:boolean = false;
  
  public route:any;
  private _priceroute:any;
  public order:any;
  public warnings: any = [];
  error:any;
  public standingTime: any = [];
  public restTime: any = [];
  
 // pickupCountryHolidays:any
  //deliveryCountryHolidays:any
  //finalDeliveryCountryHolidays:any

  @ViewChild('stepper') public myStepper: MatStepper
 
  
  public billingaddress= false;
  public minDate:Date;
  public maxDate:Date;
  public deliverMin:Date;
  public deliverMax:Date;
  public finalDeliverMin:Date;
  public finalDeliverMax:Date;
  
  startdisabled: boolean = false;
  
  public totalweight = 0;
  public totalvolume = 0;
  
  public cargo:Array<
            { no: "",
              text:"",
              cat:"", 
              items: 0, 
              weight: 0,
              height:0,
              length:0,
              width:0,
           
              }>;
            
  public profile = new Profile('', '', '', '', '', '','', '', '', '', '', '', '','', '','','');
            
  public organisation = 
  {
    id: "",
    name: "",
    street: "",
    city: "",
    zip_code: "",
    country: "",
    taxNo: "",
    sforce_id: "",
    customer_number: "n/a",
    billing_street: "",
    billing_city: "",
    billing_zip_code: "",
    billing_country: "",
    str_id: ""
  }
    routeError: boolean = false;
    inp: Date;
    TDay: number;
    THours: number;
    TMin: number;
    
    FDay: number;
    FHours: number;
    FMin: number;
    
    RDay: number;
    RHours: number;
    RMin: number;
    
    StMin: number;
    StDay: number;
    StHours: number;
   

    noUIconfig: any = {
      behaviour: 'drag',
      connect: true,
      margin: 3,
      limit: 24, // NOTE: overwritten by [limit]="10"
      range: {
        min: 0,
        max: 24
      }
};
    
  constructor(
    private _formBuilder: FormBuilder,
    private mapService: MapService,
    //private dateAdapter: DatePipe, 
    private http: HttpService,
    private _alert:AlertController,
    private _modal: ModalController,
    private _toast: ToastController,
    private _auth:AuthService, 
    public events: EventService,
//    public auth:Auth,
    private _database: DatabaseService,
    private _activatedRoute: ActivatedRoute,
    private _router:Router,
    private _cookie: CookieService,
    public _dateAdapter: DatePipe, 
    public dialog: MatDialog,
    public zone:NgZone
    ) 
    {
      
        this.copyrightyear = (this._dateAdapter.transform( new Date(),'yyyy')).toString();
      
        var temp = new Date();
        this.minDate = temp;
       //console.log(temp)
        
        this.finalDeliverMin = this.finalDeliverMax = this.deliverMin = temp;
        ////console.log("TempTime", temp);
        if (temp.getHours() > 12) this.minDate = this.setNextWorkingDay(false, temp, (1*3600*24));
        this.maxDate = this.setNextWorkingDay(false, temp, (180*3600*24));
        
        this.cargo = [];
        this.cargo.push({
            no: "",
            text:"", 
            cat:"",
            items:0, 
            weight:0,
            height:0,
            length:0,
            width:0, 
          
        });
        

    }
    
    public async ngOnInit()
    {
      Analytics.record({ name: 'newVisitor' });
      
      this.routeFormGroup = this._formBuilder.group({
        
        starts:  
              this._formBuilder.array([
                
                 this._formBuilder.group({
                        start: new FormControl('',
                                      { validators: 
                                         [ Validators.required, 
                                           AddressValidator.isValid, 
                                           ],
                                         asyncValidators:
                                          [ AddressValidator.addressValidator(this.mapService)],
                                      }),
                        date: new FormControl('', null),
                        min: new FormControl(this.minDate, null),
                        max: new FormControl(this.maxDate, null),
                        name: 'start'
                  }),
             ]),
        dests:  
              this._formBuilder.array([
                 this._formBuilder.group({
                    dest: new FormControl('',
                { validators: 
                 [ Validators.required, 
                   AddressValidator.isValid, 
                   ],
                  asyncValidators:
                  [ AddressValidator.addressValidator(this.mapService)],
                  }),
                    date: new FormControl('', null),
                    name: 'dest',
                    min: new FormControl('', null),
                    max: new FormControl('', null),
                 }),
                    
                   ])
    
      },
      );
      
      this.cargoFormGroup = this._formBuilder.group({
        trucks: new FormControl(this.transport.trucks, [Validators.required]),
        trailer: new FormControl(this.transport.trailer, [Validators.required]),
        attributes: new FormControl(this.transport.attributes,null),
        spannno: new FormControl(this.transport.spannno, null),
        spannnoman: new FormControl(this.transport.spannno, null),
      },
      
      );
      
      this.cargoFormGroup.get('trucks').setValue('1');
      this.cargoFormGroup.get('trailer').setValue('standard');
      
      
 /*     this.simpleContactForm = this._formBuilder.group({
      
        simple_firstName: new FormControl(this.profile.firstName, [Validators.required, Validators.pattern("[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+[-'!` ]?[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+[-'!` ]?[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+")]),
        simple_lastName: new FormControl(this.profile.lastName, [Validators.required, Validators.pattern("[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+[-'!` ]?[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+")]),
        simple_email: new FormControl(this.profile.email,{ validators: [Validators.required, Validators.email], asyncValidators: UserValidator.userValidator(this.amplify), updateOn: "blur" }),  
        
    }, 
        //{validators: this.passwordConfirming },
        //{asyncValidators: VatValidator.theFormValidator(this.http)}
    );*/
      
      this.contactFormGroup = this._formBuilder.group({
        gender: new FormControl(this.profile.gender, [Validators.required]),
        firstName: new FormControl(this.profile.firstName, [Validators.required, Validators.pattern("[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+[-'!` ]?[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+[-'!` ]?[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+")]),
        lastName: new FormControl(this.profile.lastName, [Validators.required, Validators.pattern("[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+[-'!` ]?[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+")]),
        password: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(32)]),
        confirmPassword: new FormControl(null, [Validators.required, /*this.passwordConfirming*/]),
        email: new FormControl(this.profile.email,{ validators: [Validators.required, Validators.email], asyncValidators: UserValidator.userValidator(), updateOn: "blur" }),  
        cell: new FormControl(this.profile.cell, [Validators.pattern('(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})')]),
        officephone: new FormControl(this.profile.officephone, [Validators.required, Validators.pattern('(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})')]),
        language: [this.profile.language, Validators.required],
        organisationname: [this.organisation.name, [Validators.required,Validators.pattern("[0-9A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+[-'!` ]?[0-9A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+[-'!` ]?([0-9A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF'&,-,.])+[-'!` ]?([0-9A-Za-zÀ-ž'&,-,.])+[-'!` ]?([0-9A-Za-zÀ-ž'&,-,.])+[-'!` ]?([0-9A-Za-zÀ-ž'&,-,.])+[-'!` ]?([0-9A-Za-zÀ-ž'&,-,.])+")]],
        street: [this.organisation.street, Validators.required],
        zip_code: [this.organisation.zip_code, Validators.required],
        city: [this.organisation.city, Validators.required],
        country: [this.organisation.country, [Validators.required, AddressValidator.checkCategoryInput(this.countryList)]],
        taxNo: new FormControl(this.organisation.taxNo,{ validators: [Validators.required, Validators.minLength(8), Validators.maxLength(12)], asyncValidators: VatValidator.vatValidator(this.http), updateOn: "blur" }),
    }, 
        {validators: this.passwordConfirming },
        //{asyncValidators: VatValidator.theFormValidator(this.http)}
    );
    
      this.paymentFormGroup = this._formBuilder.group({
        paymentTarget: ['', Validators.required],
       
      });
      
      this.paymentFormGroup.get('paymentTarget').setValue('0');
      
      this.orderFormGroup = this._formBuilder.group({
        account: ['', null],
        dsgvo: ['', Validators.requiredTrue],
        agb : ['', Validators.requiredTrue],
        vertrag: ['', Validators.requiredTrue],
        adr: ['', Validators.requiredTrue]
      });
      
      this.routeFormGroup.statusChanges.pipe(
      // Pause for 350ms
      debounceTime(350)).subscribe(
      term => {
        
      //console.log('StatusChange', term, this.routeFormGroup, this.checked)
          if ( this.routeFormGroup.valid && !this.checked  ) {
           //console.log('checked due to StatusChange')
            this.checked = true;
            this.checkRoute(false);
          }
        
      })
      
      this.routeFormGroup.valueChanges.pipe(
       // Pause for 350ms
      debounceTime(350),
        // Only if the value has changed
      distinctUntilChanged()
      ).subscribe(
        term  => {
           //console.log('ValueChange', term, this.routeFormGroup, this.checked, this.transport )
       if ( this.routeFormGroup.valid && !this.checked ) {
        //console.log('checked due to ValueChange')
         this.checked = true;
         this.checkRoute(false);
       }
        
      })
      
      this.paymentFormGroup.valueChanges.pipe(
      ).subscribe( x => this.getPrice());
      
      this.cargoFormGroup.get('trailer').valueChanges.subscribe(x => {
        switch (x){
         case "mega": { 
            this.maxheight = 300 
            this.maxvolume = 100;
          break; 
         }
         default: {
             this.maxheight = 265;
             this.maxvolume = 85;
         }
        }
      })
      
      this.filteredOptions = this.contactFormGroup.get('country').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter("")) //value))
      );
    
    var user:any;
    var session:any
    try {
    user = await Auth.currentUserInfo();
    session = await Auth.currentSession();
    } catch (e) {}
      
    this.events.currentEvent.subscribe(value =>
          {
            
            //console.log(value)
            
            if(value.name=='data:AuthState'){
              //get value.name 
              this.authState.signedIn = value.params.signedIn;
            }
            
            else if(value.name=='data:user'){
              this.profile = value.params.profile;
              this.organisation = value.params.organisation;
              
              this.contactFormGroup.setValue({
                        gender: this.profile.gender ,
                        firstName: this.profile.firstName,
                        lastName: this.profile.lastName,
                        password: '',
                        confirmPassword: '',
                        email:this.profile.email ,
                        cell: this.profile.cell,
                        officephone:this.profile.officephone,
                        language: this.profile.language,
                        //customerNo: this.organisation.customer_number,
                        organisationname: this.organisation.name,
                        taxNo: this.organisation.taxNo,
                        street: this.organisation.street,
                        zip_code: this.organisation.zip_code,
                        city: this.organisation.city,
                        country: this.organisation.country,
                      })
              
             ////console.log(this.profile, this.organisation)
              if (  this.organisation.taxNo   == "" || 
                    this.organisation.name    == "" || 
                    this.organisation.street  == "" ||  
                    this.organisation.country == "" || 
                    this.organisation.zip_code== "")
                    {
                      this.allNecInfoAvailable = false;
                    }
              else    this.allNecInfoAvailable = true;
              
            }
        });
      
      if (user==null) {
        
        this.profile.email = this._cookie.get("DML24Email")
        this.profile.firstName = this._cookie.get("DML24FirstName")
        this.profile.lastName = this._cookie.get("DML24LastName")
        this.organisation.name= this._cookie.get("DML24Organisation");
        
      }
      else{
        this.profile.id = user.username;
        if(user.attributes['gender']) this.profile.gender = user.attributes['gender'];
        if(user.attributes['given_name']) this.profile.firstName= user.attributes['given_name'];
        if(user.attributes['family_name'])  this.profile.lastName= user.attributes['family_name'];
        if(user.attributes['email']) this.profile.email = user.attributes['email'];
        if(user.attributes['custom:cell']) this.profile.cell= user.attributes['custom:cell'];
        if(user.attributes['phone_number'])  this.profile.officephone=  user.attributes['phone_number'];
        if(user.attributes['locale'])  this.profile.language = user.attributes['locale'];
        if(user.attributes['custom:customerNo'])  this.organisation.customer_number = user.attributes['custom:customerNo'];
        if(user.attributes['name'])   this.organisation.name = user.attributes['name'];
        if(user.attributes['custom:taxNo']) this.organisation.taxNo =  user.attributes['custom:taxNo'];
        if(user.attributes['custom:street']) this.organisation.street = user.attributes['custom:street'];
        if(user.attributes['custom:zip_code']) this.organisation.zip_code = user.attributes['custom:zip_code'];
        if(user.attributes['custom:city']) this.organisation.city = user.attributes['custom:city'];
        if(user.attributes['custom:country']) this.organisation.country = user.attributes['custom:country'];
        this.authState.signedIn = true;
        this.authState.groups = session.idToken.payload['cognito:groups']
                    
        this.events.publish({name:'data:AuthState', params:this.authState});
      }


      // Document in Pinpont that the request came from the website widget and strat search!...
      if(this._activatedRoute.snapshot.queryParams)
      {
      //  Analytics.record("entryFromWebsite")
        
        var start = this._activatedRoute.snapshot.queryParams['start']
        var end = this._activatedRoute.snapshot.queryParams['dest']
        
        if (typeof start === "string" && start != "" )
          this.http.getPlace(start.trim()).pipe(
          map(data => data))
            .subscribe(val => {
                if(val.Response&&val.Response.View.length >0) this.autocompleteItems = val.Response.View[0].Result;
            })
        
        if (typeof end === "string" && end != "" )
          this.http.getPlace(end.trim()).pipe(
          map(data => data))
            .subscribe(val => {
                if(val.Response&&val.Response.View.length >0) this.autocompleteItems2 = val.Response.View[0].Result;
                
          })
      }      
    } 
    
  
    displayFn(options: any): (id: number) => string | null {
      return (id: number) => { 
        if(typeof options != 'undefined')
        {
        const correspondingOption = Array.isArray(options) ? options.find(option => option.Location.LocationId === id) : null;
        return correspondingOption ? correspondingOption.Location.Address.Label : '';
        }
    }
  }
  
  test()
  {
    this.show = [true, false, false, true, false, false]
    
     var a= this.routeFormGroup.get('starts') as FormArray
     a.at(0).get('date').clearValidators();
     a.at(0).get('date').updateValueAndValidity();
     a.updateValueAndValidity();
     this.routeFormGroup.updateValueAndValidity()
     //console.log("a", a, this.routeFormGroup)
     
  }
  
  onLoadingPointChange(e)
  {
  //console.log('Waypoints', this.transport.waypoints)
  
    this.startprice = this.originalprice = this.transport.price = 0;
    
    var attr = {
              // Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment.
              city: this.transport.waypoints.loading[0].city,
              regionFrom: this.transport.waypoints.loading[0].country + "-" + this.transport.waypoints.loading[0].zip.substr(0,2),
              regionTo: this.transport.waypoints.unloading[this.transport.waypoints.unloading.length-1].country + "-" + this.transport.waypoints.unloading[this.transport.waypoints.unloading.length-1].zip.substr(0,2),
              start: this.transport.waypoints.loading[0].address,
              end: this.transport.waypoints.unloading[this.transport.waypoints.unloading.length-1].address,
    };
    
    
    Analytics.record({ name: 'search',
            attributes: 
              attr
    });
    this.checked=false;
   // this.checkRoute(false);
  }
  
  onDateChangeLoading(e, comp:FormGroup, i)
  {
     //console.log('DateChange Loading',comp, e)
      this.checked=false;
      comp.setValidators(Validators.required);
      comp.get('date').patchValue(e.date);
      this.transport.waypoints.loading[i].date=e.date
      
      this.resetDates(this.routeFormGroup.get('starts')as FormArray, this.transport.waypoints.loading, i+1);
      this.resetDates(this.routeFormGroup.get('dests')as FormArray, this.transport.waypoints.unloading, 0);
     
    //  comp.updateValueAndValidity();
      
      
  }
  
   onDateChangeUnloading(e, comp:FormGroup, i)
  {
     //console.log('DateChange UnLoading',comp, e)
      this.checked=false;
      comp.setValidators(Validators.required);
      comp.get('date').patchValue(e.date);
      this.transport.waypoints.unloading[i].date=e.date
      
      
      this.resetDates(this.routeFormGroup.get('dests')as FormArray, this.transport.waypoints.unloading, i+1);
      
     // comp.updateValueAndValidity();
      
  }
  
  resetDates(f: FormArray, t: any, i){
    
    for (i; i < f.length; i++)
    {
      t[i].date = "";
      t[i].eta = null;
      f.at(i).disable()
      f.at(i).get('date').patchValue('');
      f.at(i).get('date').clearValidators();
      f.at(i).get('min').patchValue('');
      f.at(i).get('max').patchValue('');
      f.at(i).enable()
    }
    
  }
  
 /* onClickAutocomplete($event){
    const element = document.getElementById($event.target.id);
    element.dispatchEvent( new Event( "input" ) );
  }*/
  
   get aliasesStartArrayControl() {
    return (this.routeFormGroup.get('starts') as FormArray).controls;
  }
  
   get aliasesDestArrayControl() {
    return (this.routeFormGroup.get('dests') as FormArray).controls;
  }
 
  setTime()
  {
     if (!this.route || this.transport.waypoints.loading[0].eta==null ) return;
     
     var i = 0;
     var arrayControlD = this.routeFormGroup.get('dests') as FormArray;
     var arrayControlS = this.routeFormGroup.get('starts') as FormArray;
      
     for ( i = 0; i< this.transport.waypoints.loading.length; i++ )
     {
        if ( i <  this.transport.waypoints.loading.length-1) this.deliverMin =   this.transport.waypoints.loading[i+1].eta//this.setNextWorkingDay( true, this.transport.departure, this.route.response.route[0].leg[i].travelTime);
        if ( i == this.transport.waypoints.loading.length-1) this.deliverMin =   this.transport.waypoints.unloading[0].eta// this.setNextWorkingDay( true, this.transport.waypoints.loading[i-1].eta, this.route.response.route[0].leg[i].travelTime);
        
       //console.log(i, this.deliverMin)
        this.deliverMax =   this.setNextWorkingDay( true, new Date(this.deliverMin), (3*3600*24))
        var deliverRange =  new Date(this.deliverMin).getHours();
        
        //console.log(i, this.deliverMin, deliverRange)
        this.show[i] = true;
        var st = 0;
        for (var k= 0; k<this.standingTime.length; k++)
        {
            st = st + parseInt(this.standingTime[k]); 
        }
        
        // Write first unloaidng date / eta
        if( i == this.transport.waypoints.loading.length-1 )
        {
          var temp:Date;
          
          temp = this.setNextWorkingDay( true, this.transport.waypoints.loading[this.transport.waypoints.loading.length-1].eta, this.route.response.route[0].leg[i].travelTime-st);
          arrayControlD.at(0).get('date').patchValue(this._dateAdapter.transform(this.deliverMin, 'yyyy-MM-dd'));
          arrayControlD.at(0).get('min').patchValue(this._dateAdapter.transform(temp, 'yyyy-MM-dd'));
          arrayControlD.at(0).get('max').patchValue(this._dateAdapter.transform(this.deliverMax, 'yyyy-MM-dd'))
          arrayControlD.at(0).get('date').setValidators([Validators.required])
          arrayControlD.at(0).get('date').markAsTouched()
          arrayControlD.at(0).updateValueAndValidity();
          
          this.transport.waypoints.unloading[0].date = this._dateAdapter.transform(this.deliverMin, 'yyyy-MM-dd').toString().substr(0,10);
          
          if (deliverRange <= 21)
          {
            this.transport.waypoints.unloading[0].timeRange[0] = deliverRange;
            this.transport.waypoints.unloading[0].timeRange[1] = deliverRange + 3;
           }
          
         if (deliverRange > 21)
          {
            this.transport.waypoints.unloading[0].timeRange[0] = deliverRange;
            this.transport.waypoints.unloading[0].timeRange[1] = 24;
          }
        }
        else 
        {
          var temp:Date;
          
          temp = this.setNextWorkingDay( true, this.transport.waypoints.loading[i].eta, this.route.response.route[0].leg[i].travelTime-st);
          
          arrayControlS.at(i+1).get('date').patchValue(this._dateAdapter.transform(this.deliverMin.toISOString().substr(0,10), 'yyyy-MM-dd'));
          arrayControlS.at(i+1).get('min').patchValue(this._dateAdapter.transform(temp.toISOString().substr(0,10), 'yyyy-MM-dd'));
          arrayControlS.at(i+1).get('max').patchValue(this._dateAdapter.transform(this.deliverMax.toISOString().substr(0,10), 'yyyy-MM-dd'))
          arrayControlS.at(i+1).get('date').setValidators([Validators.required])
          arrayControlS.at(i+1).get('date').markAsTouched();
          arrayControlS.at(i+1).updateValueAndValidity();
          
          this.transport.waypoints.loading[i+1].date = this._dateAdapter.transform(this.deliverMin.toISOString().substr(0,10), 'yyyy-MM-dd');
         
        }
      if(this.transport.waypoints.loading[i+1]) 
      {
         if (deliverRange <= 21)
          {
            this.transport.waypoints.loading[i+1].timeRange[0] = deliverRange;
            this.transport.waypoints.loading[i+1].timeRange[1] = deliverRange + 3;
           }
          
         if (deliverRange > 21)
          {
            this.transport.waypoints.loading[i+1].timeRange[0] = deliverRange;
            this.transport.waypoints.loading[i+1].timeRange[1] = 24;
          }
      }
     }
     
     for (var j = 0; j < this.transport.waypoints.unloading.length; j++ )
     {
       //Make MatDateSelector visibile
       this.show[3+j] = true;
        
        if (this.transport.waypoints.unloading[j+1])
        {
          this.deliverMin =   this.transport.waypoints.unloading[j+1].eta;
          this.deliverMax =   this.setNextWorkingDay( true, this.deliverMin, (3*3600*24))
          var deliverRange =  this.deliverMin.getHours();
        
          arrayControlD.at(j+1).get('date').patchValue(this._dateAdapter.transform( this.transport.waypoints.unloading[j+1].eta.toISOString().substr(0,10), 'yyyy-MM-dd'));
          if (arrayControlD.at(j+1).get('min').value=="") arrayControlD.at(j+1).get('min').patchValue(this._dateAdapter.transform( this.transport.waypoints.unloading[j+1].eta.toISOString().substr(0,10), 'yyyy-MM-dd'));
          arrayControlD.at(j+1).get('max').patchValue(this._dateAdapter.transform( this.deliverMax.toISOString().substr(0,10), 'yyyy-MM-dd'))
          arrayControlD.at(j+1).get('date').setValidators([Validators.required])
          arrayControlD.at(j+1).get('date').markAsTouched();
          arrayControlD.at(j+1).updateValueAndValidity();
          
          this.transport.waypoints.unloading[j+1].date = this._dateAdapter.transform( this.transport.waypoints.unloading[j+1].eta.toISOString().substr(0,10), 'yyyy-MM-dd');
        
          if (deliverRange <= 21)
          {
            this.transport.waypoints.unloading[j+1].timeRange[0] = deliverRange;
            this.transport.waypoints.unloading[j+1].timeRange[1] = deliverRange + 3;
          }
          
         if (deliverRange > 21)
          {
            this.transport.waypoints.unloading[j+1].timeRange[0] = deliverRange;
            this.transport.waypoints.unloading[j+1].timeRange[1] = 24;
          }
        }
     }
  }
  
  checkRoute(refresh)
  {
  //console.log('CheckRoute', this.transport, this.routeFormGroup)
    Analytics.record({ name: 'checkRoute' });
    
   
    
    const start = this.routeFormGroup.get('starts') as FormArray;
    const dest = this.routeFormGroup.get('dests') as FormArray;
    const finaldest = this.routeFormGroup.get('finaldest')
    this.transport.travelTime = 0;
    
    this.route = null;
    this.transport.eta = null;
    this.transport.travelTime = this.transport.distance = this.transport.price = 0;
    this.routeError = false;
    
    while (this.standingTime.length > 0) {
      this.standingTime.pop();
    } // Fastest
    
    while (this.warnings.length > 0) {
      this.warnings.pop();
    }
    
    while (this.restTime.length > 0) {
      this.restTime.pop();
    } // Fastest
                                                        
    if (true)//this.routeFormGroup.controls.starts.status=="VALID" && this.routeFormGroup.controls.dests.status=="VALID")
    {
       start.at(0).get('date').setValidators([Validators.required])
      
        this.checkRouteSub = this.http.getRoute(this.cargo, this.transport).pipe(
        map((data)=> 
          {
           //console.log("", data);
            return data
          }))
        .subscribe(
          {
             next: async (data) => { 
               
               try{
                 
                   ////console.log(data);
                    
                    if(typeof data!= undefined)
                    {
                      this.status = data.data.response.statusCode;
                      
                      switch (this.status)
                      {
                        case 400:
                        {
                         ////console.log("False");
                          this.routeError = true;
                          throw (data.data.response)
                        }
                        
                        case 200:
                        {
                              while (this.standingTime.length > 0) {
                                this.standingTime.pop();
                              } // Fastest
                              
                              while (this.restTime.length > 0) {
                                this.restTime.pop();
                              } // Fastest
                                
                              while (this.warnings.length > 0) {
                                this.warnings.pop();
                              }

                              this.route = JSON.parse(data.data.response.body);
                              
                             //console.log('Response Route check for Standin & Resttimes', this.route)
                              
                              this.tollCost = this.route.response.route[0].cost.details.tollCost;
                              
                              if (this.route.response.warnings) 
                              { this.warnings = this.route.response.warnings;
                                
                               
                                for( var i = 0; i < this.warnings.length; i++)
                                {
                                  if (this.warnings[i].code == '16') this.standingTime.push(this.warnings[i].message.match(/\d+/g));  
                                  if (this.warnings[i].code == '14') this.restTime.push(this.warnings[i].message.match(/\d+/g));  
                                  
                                }
                  
                              }
                              this.routeError = false;
                              this.edited = false;
                              
                              break;
                        }

                        default:
                           ////console.log("Deafult - shouldnt get here")
                      }
                    }
                }
                catch (e) {
                            const err =  JSON.parse(e.body);
                            this.routeError = true;
                            this.error = err[0].message;
                            
                            var msgtemp;
                            if(err[0].message.includes("closest link to destination reached")) msgtemp = err[0].message.match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g)
                            
                          //console.log('Err', msgtemp, err)
                            if (msgtemp)
                            {
                              
                              this.mapService.reverseGeocode({lat: msgtemp[msgtemp.length-2], lng: msgtemp[msgtemp.length-1]})
                              .pipe(
                            //  tap(x =>console.log(x)),
                                map(x => this.presentToast("Wir können das " + msgtemp[0] + ". Ziel mit den eingegeben Parametern nicht erreichen. <br> Wir erreichen nur: " + x.Response.View[0].Result[0].Location.Address.Label))
                               ).subscribe()                              
                            }
                            else if (err[0].message.includes("closing time is negative"))
                              this.presentToast("Es konnte keine Route bestimmt werden... <br> Ein Entladefenster liegt zeitlich vor dem Beladefenster" );
                            else
                              this.presentToast("Es konnte keine Route bestimmt werden... <br>" + err[0].message);
                            	
                            }
                            
               },
             complete: () => {
                            
                            if (this.route && this.route.response && this.route.response.route[0]) 
                            {
                              
                              this.transport.distance = this.route.response.route[0].summary.distance/1000;
                              
                               var _temprestTime = 0;
                               
                              //console.log ('CheckRoute', this.route.response.route[0])
                              //console.log('RestTime', this.restTime)
                              //console.log('Standzeit', this.standingTime)
                               
                               
                               for (var k = 0; k < this.restTime.length; k++)
                               {
                                  _temprestTime = _temprestTime + parseInt(this.restTime[k][1]);   
                               }
                                
                               this.transport.travelTime = this.route.response.route[0].summary.travelTime //- _temprestTime;
                               
                               var _standingTime = 0;
                               for (var i= 0; i<this.standingTime.length; i++)
                               {
                                    _standingTime = _standingTime + parseInt(this.standingTime[i]); 
                               }
                            
                              if (this.route.response.route[0].summary.arrival) 
                              {
                                this.transport.eta = new Date(this.route.response.route[0].summary.arrival.slice(0,10));
                                this.transport.eta.setHours(this.route.response.route[0].summary.arrival.slice(11,13));
                                this.transport.eta.setMinutes(this.route.response.route[0].summary.arrival.slice(14,16));
                              //  this.transport.eta = this._dateAdapter.transform( new Date(this.transport.eta),'yyyy-MM-dd HH:mm');
                                this.transport.departure = new Date(this.route.response.route[0].summary.departure.slice(0,10));
                                this.transport.departure.setHours(this.route.response.route[0].summary.departure.slice(11,13));
                                this.transport.departure.setMinutes(this.route.response.route[0].summary.departure.slice(14,16));
                                                   
                                
                                this.transport.waypoints.loading[0].eta = new Date(this.transport.waypoints.loading[0].date); 
                                this.transport.waypoints.loading[0].eta.setHours(this.transport.waypoints.loading[0].timeRange[0]);
                                
                                this.transport.waypoints.unloading[this.transport.waypoints.unloading.length-1].eta = this.transport.eta;
                                
                                var i = 0;
                                var j = 0;
                                for (i = 0 ; i < this.transport.waypoints.loading.length; i++ )
                                {
                                  if( i==0 && this.transport.waypoints.loading[i+1] ) 
                                  {
                                    this.transport.waypoints.loading[i+1].eta= this.setNextWorkingDay(true, this.transport.departure, this.route.response.route[0].leg[i].travelTime)
                                  }
                                  else if (this.transport.waypoints.loading[i+1]) 
                                  { 
                                    this.transport.waypoints.loading[i+1].eta=this.setNextWorkingDay(true, this.transport.waypoints.loading[i].eta, this.route.response.route[0].leg[i].travelTime)
                                  }
                                }
                                
                                for (j = 0 ; j < this.transport.waypoints.unloading.length; j++ )
                                {
                                  // Last Unloading
                                  if(j == this.transport.waypoints.unloading.length-1 ) 
                                  {
                                    this.transport.waypoints.unloading[j].eta = this.transport.eta
                                  }
                                  else if(this.transport.waypoints.loading.length==1 && j==0)
                                  {
                                     this.transport.waypoints.unloading[j].eta=this.setNextWorkingDay(true, this.transport.departure, this.route.response.route[0].leg[i+j-1].travelTime)
                                  }
                                  // First Unloading & more to come
                                  else if(i>0 && this.transport.waypoints.unloading.length>1 && j==0)
                                  {
                                    this.transport.waypoints.unloading[j].eta=this.setNextWorkingDay(true, this.transport.waypoints.loading[this.transport.waypoints.loading.length-1].eta, this.route.response.route[0].leg[i+j-1].travelTime)
                                  }
                                  // other waypoints
                                  else 
                                  {
                                    this.transport.waypoints.unloading[j].eta=this.setNextWorkingDay(true, this.transport.waypoints.unloading[j-1].eta, this.route.response.route[0].leg[i+j-1].travelTime)
                                  }
                                }
                                
                                if(_standingTime>32400) this.presentToast("Die Route enthält eine Standzeit von über 9h, welches Zusatzkosten verursacht. Bitte prüfen Sie, ob eine frühere Anlieferung möglich ist. Dies können Sie mit den Schiebereglern beim Zeitfenster optimieren.");
                               
                                
                              }
                              else 
                              {
                                var inp = new Date(0, 0, 0, 0, 0, _temprestTime); // assumes seconds as an input
                                this.FMin = inp.getMinutes();
                                this.FHours = inp.getHours();
                                this.FDay = inp.getDay();
                              }
                              
                              var inp = new Date(0, 0, 0, 0, 0, this.transport.travelTime); // assumes seconds as an input
                                  this.TMin = inp.getMinutes();
                                  this.THours = inp.getHours();
                                  this.TDay = inp.getDay();
                              
                              
                              var inp = new Date(0, 0, 0, 0, 0, this.transport.travelTime-_temprestTime-_standingTime); // assumes seconds as an input
                              this.RMin = inp.getMinutes();
                              this.RHours = inp.getHours();
                              this.RDay = inp.getDay();
                                  
                              if (!refresh ) this.setTime();
                              
                            //console.log("Transport before getPrice", this.transport)
                              this.getPrice()
                              
                              if(this.transport.id != "") this.http.updateRequest(this.organisation, this.profile, this.transport, this.cargo);
                      
                            //console.log("Transport", this.transport)
                            }
              },
             error: async (e) => { 
                                   this.presentToast("Es konnte für Ihre Eingabe kein passendes Angebot ermittelt werden. <br> Bitte kontaktieren Sie uns und stellen die <a href='mailto:landtransporte@dml24.de?Subject=Transportanfrage' target='_top'> Anfrage per e-Mail</a> oder rufen Sie uns an! ");
                                    Analytics.record({ name: 'Network Error' });
                                }
                              })
    }
  }
 
  completeRoute()
  {
  }
  
  calc(){
    this.totalweight = 0;
    this.totalvolume = 0;
    
    for(var i = 0; i < this.cargo.length; i++)
    {
      this.totalweight = this.totalweight + (this.cargo[i].items*this.cargo[i].weight);
      this.totalvolume = this.totalvolume + (this.cargo[i].items*(this.cargo[i].height*this.cargo[i].length*this.cargo[i].width)/1000/1000)
    }
    
    this.totalweight = this.totalweight/1000;
  }
  
  add(){
    
     this.cargo.push({no:"",text:"", cat:"", items:0, weight:0,
            height:0,
            length:0,
            width:0,  });
    
  }
  
   completeTrack(){
    
    Analytics.record({ name: 'CompleteRoute' });
    this.transport = Object.assign(this.transport, this.cargoFormGroup.value);
    
  //console.log("CompleteRoute", this.transport)
    
    var parsed; 
    this.checkRouteSub = this.http.createRequest(this.organisation, this.profile, this.transport, this.cargo).subscribe({
                                     next:  (data) => { 
                                        
                                       parsed = JSON.parse(data.data.response.body);
                                     //console.log(parsed);
                                       
                                     },
                                  complete: () => { 
                                     this.transport.id = parsed.id
                                       const wpids = parsed.wpids;
                                       
                                     //console.table(wpids);
                                       
                                       for( var i= 0 ; i < wpids.loading.length; i++)
                                       {
                                          this.transport.waypoints.loading[wpids.loading[i].sequence].id = wpids.loading[i].id
                                       }
                                       
                                       for( var i= 0 ; i < wpids.unloading.length; i++)
                                       {
                                          this.transport.waypoints.unloading[wpids.unloading[i].sequence-wpids.loading.length].id = wpids.unloading[i].id
                                       }
                                       
                                        Analytics.record({ name: 'Track data entered' });
                                       
                                        if( this.authState.signedIn == true) 
                                        {
                                        //  const wps = this.transport.waypoints.map( wp => wp.city);
                                          Analytics.updateEndpoint(
                                            {
                                              address: this.profile.email||"", // The unique identifier for the recipient. For example, an address could be a device token, email address, or mobile phone number.
                                              attributes: {
                                                  // Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment.
                                          //        city: wps,
                                                  regionFrom: [this.transport.waypoints.loading[0].country + "-" + this.transport.waypoints.loading[0].zip.substr(0,2)],
                                                  regionTo: [this.transport.waypoints.unloading[0].country + "-" + this.transport.waypoints.unloading[0].zip.substr(0,2)],
                                                  startDay: [new Date(this.transport.waypoints.loading[0].date).getDay().toLocaleString()],
                                                  deliveryDay: [new Date(this.transport.waypoints.unloading[0].date).getDay().toLocaleString()],
                                                  start: [this.transport.waypoints.loading[0].address],
                                                  end: [this.transport.waypoints.unloading[0].address],
                                                  
                                              },
                                              channelType: 'EMAIL', // The channel type. Valid values: APNS, GCM
                                              demographic: {
                                                  
                                                 },
                                              metrics: {
                                                  // Custom metrics that your app reports to Amazon Pinpoint.
                                              },
                                              /** Indicates whether a user has opted out of receiving messages with one of the following values:
                                                  * ALL - User has opted out of all messages.
                                                  * NONE - Users has not opted out and receives all messages.
                                                  */
                                              optOut: 'NONE',
                                              
                                              // Customized userId
                                              userId: this.profile.username,
                                              // User attributes
                                              userAttributes: {
                                                  organisation: [this.organisation.name, this.organisation.taxNo, ]
                                                  // ...
                                              }
                                          });
                                        }
                                        else
                                        {
                                            //const wps = this.transport.waypoints.map( wp => {if(wp.city="") wp.city});    
                                            var attr = {
                                                  // Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment.
                                              //    city: wps,
                                                  regionFrom: this.transport.waypoints.loading[0].country + "-" + this.transport.waypoints.loading[0].zip.substr(0,2),
                                                  regionTo: this.transport.waypoints.unloading[0].country + "-" + this.transport.waypoints.unloading[0].zip.substr(0,2),
                                                  startDay: new Date(this.transport.waypoints.loading[0].date).getDay().toLocaleString(),
                                                  deliveryDay: new Date(this.transport.waypoints.unloading[0].date).getDay().toLocaleString(),
                                                  start: this.transport.waypoints.loading[0].address,
                                                  end: this.transport.waypoints.unloading[0].address,
                                              };
                                              
                                              Analytics.record({ name: 'search',
                                                attributes: 
                                                  attr
                                              });
                                        
                                        }
  
                    
                                  }
    })
    
    this.myStepper.next();
  }
  
  completeCargo(){
    
    Analytics.record({ name: 'CompleteCargo' });
    this.getPrice()
    this.transport = Object.assign(this.transport, this.cargoFormGroup.value);
    
     var parsed; 
    this.checkCargoSub = this.http.updateRequest(this.organisation, this.profile, this.transport, this.cargo).subscribe({
                                     next:  (data) => { 
                                      
                                      //console.log("Update Reuqest raw", data)
                                        parsed = JSON.parse(data.data.response.body);
                                      //console.log("Update Reuqest", parsed)
                                     },
                                  complete: () => { 
                                     /*this.transport.id = parsed.id
                                    //   const wpids = parsed.wpids;
                                       
                                    //   for( var i= 0 ; i < wpids.length; i++)
                                       {
                                          this.transport.waypoints[wpids[i].sequence].id = wpids[i].id
                                       
                                       }*/
                                        Analytics.record({ name: 'Cargo data entered' });
                                       
                                       
                                        if( this.authState.signedIn == true) 
                                        {
                                        //  const wps = this.transport.waypoints.map( wp => wp.city);
                                          Analytics.updateEndpoint(
                                            {
                                              address: this.profile.email||"", // The unique identifier for the recipient. For example, an address could be a device token, email address, or mobile phone number.
                                              attributes: {
                                                  // Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment.
                                          //        city: wps,
                                                  regionFrom: [this.transport.waypoints.loading[0].country + "-" + this.transport.waypoints.loading[0].zip.substr(0,2)],
                                                  regionTo: [this.transport.waypoints.unloading[0].country + "-" + this.transport.waypoints.unloading[0].zip.substr(0,2)],
                                                  startDay: [new Date(this.transport.waypoints.loading[0].date).getDay().toLocaleString()],
                                                  deliveryDay: [new Date(this.transport.waypoints.unloading[0].date).getDay().toLocaleString()],
                                                  start: [this.transport.waypoints.loading[0].address],
                                                  end: [this.transport.waypoints.unloading[0].address],
                                                  trailer: [this.transport.trailer],
                                                  packaging: [this.cargo[0].text],
                                                  category: [this.cargo[0].cat],
                                                  
                                              },
                                              channelType: 'EMAIL', // The channel type. Valid values: APNS, GCM
                                              demographic: {
                                                  
                                                 },
                                              metrics: {
                                                  // Custom metrics that your app reports to Amazon Pinpoint.
                                              },
                                              /** Indicates whether a user has opted out of receiving messages with one of the following values:
                                                  * ALL - User has opted out of all messages.
                                                  * NONE - Users has not opted out and receives all messages.
                                                  */
                                              optOut: 'NONE',
                                              
                                              // Customized userId
                                              userId: this.profile.username,
                                              // User attributes
                                              userAttributes: {
                                                  organisation: [this.organisation.name, this.organisation.taxNo, ]
                                                  // ...
                                              }
                                          });
                                        }
                                        else
                                        {
                                            //const wps = this.transport.waypoints.map( wp => {if(wp.city="") wp.city});    
                                            var attr = {
                                                  // Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment.
                                              //    city: wps,
                                                  regionFrom: this.transport.waypoints.loading[0].country + "-" + this.transport.waypoints.loading[0].zip.substr(0,2),
                                                  regionTo: this.transport.waypoints.unloading[0].country + "-" + this.transport.waypoints.unloading[0].zip.substr(0,2),
                                                  startDay: new Date(this.transport.waypoints.loading[0].date).getDay().toLocaleString(),
                                                  deliveryDay: new Date(this.transport.waypoints.unloading[0].date).getDay().toLocaleString(),
                                                  start: this.transport.waypoints.loading[0].address,
                                                  end: this.transport.waypoints.unloading[0].address,
                                                  trailer: [this.transport.trailer],
                                                  packaging: [this.cargo[0].text],
                                                  category: [this.cargo[0].cat],
                                              };
                                              
            //                                  Analytics.record({ name: 'search',
            //                                    attributes: 
            //                                      attr
            //                                  });
                                        
                                        }
  
                                  }
    })
    
   
                                       
    this.myStepper.next();
  }
  
  validate()
  {
    Analytics.record({ name: 'validateCustomer' });
    this.contactFormGroup.updateValueAndValidity();
    if(this.contactFormGroup.invalid) {return null;}
  
   
    if (this.authState.signedIn==false)
    {
      ////console.log('not signed in')
      this.profile.gender = this.contactFormGroup.get('gender').value;
      this.profile.cell = this.contactFormGroup.get('cell').value;
      this.profile.language = this.contactFormGroup.get('language').value;
      this.profile.officephone = this.contactFormGroup.get('officephone').value;
      this.profile.firstName = this.contactFormGroup.get('firstName').value;
      this.profile.lastName = this.contactFormGroup.get('lastName').value;
      this.profile.email = this.contactFormGroup.get('email').value;
      this.organisation.street = this.contactFormGroup.get('street').value;
      this.organisation.zip_code = this.contactFormGroup.get('zip_code').value;
      this.organisation.city = this.contactFormGroup.get('city').value;
      this.organisation.country = this.contactFormGroup.get('country').value;
      this.organisation.taxNo = this.contactFormGroup.get('taxNo').value;
      this.organisation.name = this.contactFormGroup.get('organisationname').value;
      
      this.sub = this._auth.register(this.profile, this.organisation, this.contactFormGroup.get('password').value).subscribe(
        {next: (data => 
                  {
                    this.user = data;
                  } ),
                  
         complete: (async () => {
              
             const alert = await this._alert.create({
            	  header: 'Validierung',
            	  subHeader: "Überprüfung Ihrer Kontaktdaten",
            	  message: "Mit der Eingabe des Verifikationscodes bestätigen Sie Ihre Eingaben.",
            	   inputs: [
                  {
                    name: 'code',
                    type: 'text',
                    placeholder: 'Ihr Verifikationscode'
                  }],
            	      buttons: [
                    {
                      text: 'Abbruch',
                      role: 'cancel',
                      cssClass: 'secondary',
                      handler: (blah) => {
                        
                      }
                    }, {
                      text: 'Bestätigen',
                      handler: (data) => 
                      {
                        const resp = this._auth.confirm(this.user, data.code); 
                        Auth.signIn(this.user.user.username, this.contactFormGroup.get('password').value)
                        this.myStepper.next();     
                     
                      }
                    }
                  ]
            });
      
            await alert.present();
         }),
         
         error: async (e) => {
          ////console.log(this.user)
           switch (e['code']) 
           {
             
              case "InvalidParameterException":
              {
                
                this.presentToast("Bitte überprüfen Sie Ihre Eingabe: <br>" + e.message);
                break;
              }
           
           case "UsernameExistsException": 
             {
             
              const alert = await this._alert.create({
            	  header: 'Validierung',
            	  subHeader: "Überprüfung Ihrer Kontaktdaten",
            	  message: "Mit der Eingabe des Verifikationscodes bestätigen Sie Ihre Eingaben.",
            	   inputs: [
                  {
                    name: 'code',
                    type: 'text',
                    placeholder: 'Ihr Verifikationscode'
                  }],
            	      buttons: [
                    {
                      text: 'Abbruch',
                      role: 'cancel',
                      cssClass: 'secondary',
                      handler: (blah) => {
                        
                      }
                    }, {
                      text: 'Bestätigen',
                      handler: (data) => 
                      {
                        const resp = this._auth.confirm(this.user, data.code); 
                        this.myStepper.next();     
                     
                      }
                    }
                  ]
             
           });
           
            await alert.present();
            break;
         }
         default:
         {
            this.presentToast("Bitte überprüfen Sie Ihre Eingabe: <br>" + e.message);
            break;
          }
        }
    }});
  }
  }
  
  getPrice()
  {
    Analytics.record({ name: 'getPrice' });
    
    this.transport.price = this.originalprice = 0;
    
    var discount = parseInt(this.paymentFormGroup.get('paymentTarget').value);
    //console.log("Discount:" , discount)
     
   // if (this.cargoFormGroup.invalid || this.totalweight > 24.5 || this.totalvolume >= this.maxvolume || this.paymentFormGroup.invalid ) {return null}
    //if (this.bill == false && this.bill2 == false && this.bill3 == false) {return null}
    this.transport = Object.assign(this.transport, this.cargoFormGroup.value);
    
  //console.log(this.routeFormGroup)
    if(this.routeFormGroup.valid)
        this._priceroute = null;
          this.priceSub =  this.http.getPrice(this.cargo, this.transport).subscribe({
                           next: (data) => {
                             
                           //console.log("GetPriceData roh", data)
                              
                              try{
                                  this._priceroute = JSON.parse(data.data.response.body);
                                  
                                 //console.log('Price JSON pARSED', this._priceroute)
                                    
                                  this.transport.distance = parseInt(this._priceroute.distance)
                                  this.transport.travelTime = this._priceroute.travelTime
                                  this.transport.days = parseInt(this._priceroute.days)/100
                                  this.originalprice = parseInt(this._priceroute.price)
                                  if (this.startprice==0) this.startprice = parseInt(this._priceroute.start)
                                  
                                 //console.log("price", this._priceroute)
                                  var uplift = 0;;
                             
                                  var temp = 0;
                                 //console.log("Standin Time", this.standingTime)
                                  for (var i= 0; i<this.standingTime.length; i++)
                                  {
                                    temp = temp + parseInt(this.standingTime[i]); 
                                  }
                                  
                                  // check if weekend, if so reduce standing time
                                 //console.log("Transport Check", this.transport)
                                  if(this.transport.waypoints.loading[0].date!="")
                                  {
                                   //console.log("Check Weekend")
                                    var weekendDays = this.isWeekend(this.transport.waypoints.loading[0].date, this.transport.waypoints.unloading[this.transport.waypoints.unloading.length-1].date);
                                    if (weekendDays>0) temp = (temp - (weekendDays*86400));
                                    
                                   //console.log("REsttime", this.restTime)
                                    for (var i= 0 ; i< this.restTime.length; i++)
                                    {
                                      temp = temp - this.restTime[i][1]
                                    }
                                    
                                    // if bank holiday in area don't add to pricing   
                                    for (var i = 0; i< this.transport.waypoints.unloading.length ; i++)
                                    {
                                       var _bankHolCheck = new Date(this.transport.waypoints.unloading[i].eta)
                                      _bankHolCheck.setTime(_bankHolCheck.getTime()+(60000*60*24))
                                      if (new Date(this.transport.waypoints.unloading[i].date).getDay() == 6 ) uplift = uplift + 150
                                       
                                      while (_bankHolCheck.getTime() < this.transport.waypoints.unloading[i].eta.getTime())
                                      {
                                        for (var j = 0; j< this.transport.waypoints.unloading[i].localHolidays.length ; j++)
                                        {
                                          if (_bankHolCheck.getDay() != 0 &&
                                              _bankHolCheck.getDate() == this.transport.waypoints.unloading[i].localHolidays[j].date.day &&
                                              _bankHolCheck.getMonth() == this.transport.waypoints.unloading[i].localHolidays[j].date.month 
                                               
                                            ) temp = temp - 24*60*60
                                        }
                                      }
                                      _bankHolCheck.setMilliseconds(_bankHolCheck.getMilliseconds()+(60000*60*24))
                                    }
                                    
                                     for (var i = 0; i< this.transport.waypoints.loading.length ; i++)
                                    {
                                       var _bankHolCheck = new Date(this.transport.waypoints.loading[i].eta)
                                      _bankHolCheck.setTime(_bankHolCheck.getTime()+(60000*60*24))
                                      
                                      if (new Date(this.transport.waypoints.loading[i].date).getDay() == 6 ) uplift = uplift + 150;
                                      
                                      while (_bankHolCheck.getTime() < this.transport.waypoints.loading[i].eta.getTime())
                                      {
                                        for (var j = 0; j< this.transport.waypoints.loading[i].localHolidays.length ; j++)
                                        {
                                          if (_bankHolCheck.getDay() != 0 &&
                                              _bankHolCheck.getDate() == this.transport.waypoints.loading[i].localHolidays[j].date.day &&
                                              _bankHolCheck.getMonth() == this.transport.waypoints.loading[i].localHolidays[j].date.month 
                                               
                                            ) temp = temp - 24*60*60
                                        }
                                      }
                                      _bankHolCheck.setMilliseconds(_bankHolCheck.getMilliseconds()+(60000*60*24))
                                    }
                                   //console.log(" Checkweekend Ende")
                                  } 
                                  
                                  //console.log("After Checkweekend")
                                    if (temp > 0)
                                    {
                                        var secs = (350+50)/24/60/60;
                                        uplift = temp*secs;
                                    }
                                    else temp = 0;
                                      
                                    var inp2 = new Date(0, 0, 0, 0, 0, temp); // assumes seconds as an input
                                    this.StMin = inp2.getMinutes();
                                    this.StHours = inp2.getHours();
                                    this.StDay = inp2.getDay();
                                    
                                    if (this.transport.trailer.includes('coil') || this.transport.trailer.includes('joloda') || this.transport.trailer.includes('mega')) uplift = uplift + 80
                                    if (this.transport.waypoints.loading.length + this.transport.waypoints.unloading.length > 2 ) uplift = uplift + ((this.transport.waypoints.loading.length + this.transport.waypoints.unloading.length-2)*75)
                                    
                                     
                                    this.originalprice += uplift;
                                    
                                    const t:number = (this.originalprice)*(1+((discount)/100))
                                    
                                   //console.log ("T", t)
                                    this.transport.price = parseInt(t.toFixed(2));
                                   //console.log("UnDscointed Price", this.transport.price)
                                    
                                    this.transport.price *= 0.98;
                                    
                                   //console.log("Dscointed Price", this.transport.price)
                                    
                                    if(this.transport.services.insurance) this.transport.price+=this.transport.costofgoods*0.001;
                                    
                                    if (this.originalprice) Analytics.record({ name: 'price' , metrics: { basePrice:  this.originalprice }});
                                  
                                  
                              }
                              catch (e) {
                                
                              //console.log('getPriceError', e)
                                
                              }
                           }
    });
  }
  
  getImageString()
  {
    
    var herestring = "https://image.maps.ls.hereapi.com/mia/1.6/mapview?apiKey=h3oSo8EI6WGods8OHU6nN98WeXbcJ0M9UE40qCoqIVc&style=fleet&poithm=0&sb=km&z=15&ra=0&poitxs=30&poitxc=black&poifc=39a42b&h=1200&w=1200&u=3k&f=1&ml=ger&poi="
    for(var  i = 0; i < this.transport.waypoints.loading.length;i++)
    {
      if (this.transport.waypoints.loading[i].lat!=0) herestring += this.transport.waypoints.loading[i].lat.toString() +','+ this.transport.waypoints.loading[i].lng.toString() + ',';    
    }
    
    for(var  i = 0; i < this.transport.waypoints.unloading.length;i++)
    {
       if (i == this.transport.waypoints.unloading.length-1 && this.transport.waypoints.unloading[i].lat!=0) herestring += this.transport.waypoints.unloading[i].lat.toString() +','+ this.transport.waypoints.unloading[i].lng.toString();
       else if (this.transport.waypoints.unloading[i].lat!=0) herestring += this.transport.waypoints.unloading[i].lat.toString() +','+ this.transport.waypoints.unloading[i].lng.toString() + ',';
    }
    
    return herestring
  }
  
  tune(evt, adj)
  {
    if(!this.transport.services.insurance) 
    {
      this.transport.price-=this.transport.costofgoods*0.001;
      this.transport.costofgoods=0
    }
     if (evt.detail.checked) { 
       this.transport.price = this.transport.price + adj
     }
     else 
     {
       this.transport.price = this.transport.price - adj
     }
  }
  
  nextStep(show:boolean)
  {
    if (this.authState.signedIn == false && this.profile.email != "") 
    {
      if(this._cookie.get("DML24Email")!=this.profile.email) this._cookie.set("DML24Email", this.profile.email, 365,'/' ,null,true,"Strict")
      if(this._cookie.get("DML24FirstName")!=this.profile.firstName) this._cookie.set("DML24FirstName", this.profile.firstName, 365,'/' ,null,true,"Strict")
      if(this._cookie.get("DML24LastName")!=this.profile.lastName) this._cookie.set("DML24LastName", this.profile.lastName, 365,'/' ,null,true,"Strict")
      if(this._cookie.get("DML24Organisation")!=this.organisation.name) this._cookie.set("DML24Organisation", this.organisation.name, 365,'/' ,null,true,"Strict");
    }
    
//    const wps = this.transport.waypoints.map( wp => wp.city);
    
    Analytics.updateEndpoint(
        {
          address: this.profile.email || "", // The unique identifier for the recipient. For example, an address could be a device token, email address, or mobile phone number.
          attributes: {
              // Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment.
     //         city: wps,
              regionFrom: [this.transport.waypoints.loading[0].country + "-" + this.transport.waypoints.loading[0].zip.substr(0,2)],
              regionTo: [this.transport.waypoints.unloading[this.transport.waypoints.unloading.length-1].country + "-" + this.transport.waypoints.unloading[this.transport.waypoints.unloading.length-1].zip.substr(0,2)],
              startDay: [new Date(this.transport.waypoints.loading[0].date).getDay().toLocaleString()],
              deliveryDay: [new Date(this.transport.waypoints.unloading[0].date).getDay().toLocaleString()],
              start: [this.transport.waypoints.loading[0].address],
              end: [this.transport.waypoints.unloading[this.transport.waypoints.unloading.length-1].address],
              
          },
          channelType: 'EMAIL', // The channel type. Valid values: APNS, GCM
          demographic: {
              
             },
          metrics: {
              // Custom metrics that your app reports to Amazon Pinpoint.
          },
          /** Indicates whether a user has opted out of receiving messages with one of the following values:
              * ALL - User has opted out of all messages.
              * NONE - Users has not opted out and receives all messages.
              */
          optOut: 'NONE',
          
          // Customized userId
          userId: this.profile.username,
          // User attributes
          userAttributes: {
              organisation: [this.organisation.name ]
              // ...
          }
      });
      
    if(this.authState.signedIn==false) this.showContactForm = true;
    
    this.http.updateRequest(this.organisation, this.profile, this.transport, this.cargo)
    this.myStepper.next();
  }
  
  async orderNow()
  {
    Analytics.record({ name: 'orderNow'});
    this.orderFormGroup.updateValueAndValidity();
    if(this.orderFormGroup.invalid) {return null}
     
    if (this.bill) this.transport.paymentType = "einvoice"
    else if (this.bill2) this.transport.paymentType = "invoice"
    else if (this.bill3) this.transport.paymentType = "creditnote"
    
    switch (this.paymentFormGroup.get('paymentTarget').value)
    {
      case "-3": this.transport.termOfPayment = 10; break;
      case "-2": this.transport.termOfPayment = 15; break;
      case "0": this.transport.termOfPayment = 30; break;
      case "2": this.transport.termOfPayment = 45; break;
      case "3": this.transport.termOfPayment = 60; break;
      
    }
    if (this.organisation.taxNo   == "" || 
        this.organisation.name    == "" || 
        this.organisation.street  == "" ||  
        this.organisation.country == "" || 
        this.organisation.zip_code== "")
      {
        
        const alert = await this._alert.create({
                                                    	  header: 'Unvollständige Daten',
                                                    	  message: "Bitte vervollständigen Sie ihr Profil",
                                                    	      buttons: [
                                                            {
                                                              text: 'OK',
                                                              role: 'cancel',
                                                              cssClass: 'secondary',
                                                              handler: () => {
                                                                  this.presentModal();
                                                              }
                                                            }
                                                          ]
                                                        });
                                                  
                                                        await alert.present();
                                                        
        return null; 
      }
     
     
     this.checkRouteSub = this.http.updateRequest(this.organisation, this.profile, this.transport, this.cargo).subscribe({
                                     next:  () => { 
                                        
                                      ////console.log('nextData', data)
                                       
                                      // var parsed = JSON.parse(data.data.response.body);
                                      ////console.log(parsed)
                                      
                                     },
                                     complete: async () => {
                                       
                                        const alert = await this._alert.create({
                                      	  header: 'Jetzt bestellen',
                                      	  subHeader: "Kostenpflichtig bestellen",
                                      	  message: "Hiermit bestätigen Sie den Transport verbindlich.",
                                      	      buttons: [
                                              {
                                                text: 'Abbruch',
                                                role: 'cancel',
                                                cssClass: 'secondary',
                                                handler: (blah) => {
                                                    this.myStepper.previous();
                                                }
                                              }, {
                                                text: 'Bestellen',
                                                handler: (data) => 
                                                {
                                                  
                                                  this.sub = this.http.placeOrder(this.cargo, this.organisation, this.profile, this.transport ).subscribe({
                                                           next: async (data) => { 
                                                                  ////////console.log('Response:',data)
                                                                  
                                                                this.presentToast("<b> Vielen Dank für Ihr Vertrauen! <br> Ihre Bestellung wurde erfolgreich entgegengenommen. <br> Wir freuen uns auf gute Zusammenarbeit!");
                                                                      
                                                                            
                                                                 this.order = data;
                                                                //console.log("the Order", this.order);
                                                                        
                                                                  this.submitted=true;
                                                                  
                                                                  for(var oid = 0 ; oid <this.order.data.orderid.length;  oid++)
                                                                  {
                                                                  
                                                                     Analytics.record({name:'_monetization.purchase',
                                                                     attributes:
                                                                     {
                                                                        _currency: 'EUR',
                                                                        _product_id: this.transport.id,
                                                                        _transaction_id:  this.order.data.orderid[oid],
                                                                      },
                                                                      metrics:
                                                                      {
                                                                        _item_price: this.transport.price,
                                                                        _quantity: 1.0,
                                                                      }
                                                                      })
                                                                  }
                                                                  
                                                             }
                                                  });
                                                }
                                              }
                                            ]
                                          });
                                    
                                          await alert.present();
                                     }
     })
     
 
      
  }
  
  async presentModal() {
      /*const modal = await this._modal.create({ 
        component: AuthenticateComponent, 
        cssClass: 'auth-modal-css',
        componentProps: {
          profile: this.profile,
          organisation: this.organisation
      }
    });
    
    return await modal.present();*/
    
    this.zone.run(() => {
    let dialogRef = this.dialog.open(AuthenticateComponent, {
      hasBackdrop:false,
      width:"90%",
      height:"95%"   ,
      autoFocus:false,
      panelClass: 'custom-modalbox',
      data: {   profile: this.profile,
                  organisation: this.organisation}}
    );

    dialogRef.afterClosed().subscribe(result => {
      
      //console.log(result)
    });

   const dialogSubmitSubscription = 
      dialogRef.componentInstance.submitClicked.subscribe(result => {
     dialogSubmitSubscription.unsubscribe();
    });
    });
  }
  

  setNextWorkingDay(considerweekend:boolean, start:Date, seconds:number ): Date 
  {
    var temp:Date =  new Date(start)
    
    
    var d:Date = new Date();
    while(seconds>0)
    {
        d.setTime(temp.getTime()+(60*1000)) 
        if((considerweekend && temp.getDay() != 0 /*|| temp.getDay() != 6))*/)) seconds = seconds-60;
        if(considerweekend==false  /*|| temp.getDay() != 6))*/) seconds = seconds-60;
        if((considerweekend && temp.getDay()==0 || temp.getDay()==6) && this.standingTime.length>0 && this.standingTime[0]>0)  this.standingTime[0] -= 60*24
        if(considerweekend && this.standingTime.length>0 && this.standingTime[0] < 0) this.standingTime[0] = 0;
        temp = d;
        
    }
   
   return temp
  }
  
   private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.countryList.filter(option => option.toLowerCase().includes(filterValue));
  }
  
   isWeekend(date1, date2) {
    var d1 = new Date(date1),
        d2 = new Date(date2), 
        isWeekendDays = 0;
   ////console.log(d1, d2)
    while (d1 < d2) {
        var day = d1.getDay();
        if( (day == 6) || (day == 0)) isWeekendDays++; 
        
        d1.setDate(d1.getDate() + 1);
    }
    
   ////console.log(isWeekendDays);
    return isWeekendDays;
}
  
  clear()
  {
    Analytics.record({ name: 'clearForm' });
    
    var arrayControlD = this.routeFormGroup.get('dests') as FormArray;
    var arrayControlS = this.routeFormGroup.get('starts') as FormArray;
  
  //console.log(arrayControlS, arrayControlD)
    for(var i=0; i<=arrayControlS.length;i++ )
      this.adjstart(arrayControlS, this.transport.waypoints.loading, -1)
      
    for(var i=0; i<=arrayControlD.length;i++ ) 
    this.adjstop(arrayControlD, this.transport.waypoints.unloading, -1)

  this.adjstart(arrayControlS, this.transport.waypoints.loading, 1)
  this.adjstop(arrayControlD, this.transport.waypoints.unloading, 1)
  this.startprice = 0;
//console.log(this.myStepper.selectedIndex);
  
  while(this.myStepper.selectedIndex>0) this.myStepper.previous()
  
  //this.myForm.resetForm();
      
    if (this.submitted==true)
    {
      this.submitted = false;
  
    //console.log(this.myStepper.selectedIndex);
        
      this.route = null;
      this.order = null;
      this.sub = null;
        
      this.myStepper.reset();
            
    }
    else
    {
      this.route = null;
      this.order = null;
      this.sub = null;
    }
  }
  
  adjustMax(e, max){
    
   ////console.log(e.target.value, max)
     if (e.target.value > max) {
            e.target.value = max; 
        }
  }
  
  adjstop(r: FormArray, t:any, inc:number){
    this.checked=false;
    if (inc < 0) { 
      t.pop()
      r.removeAt(r.length-1)
       if (this.routeFormGroup.valid && this.checked==false) {
           //console.log('checked due to StatusChange')
            this.checked = true;
            this.checkRoute(false);
          //console.log('inner valid')
          }
    }
    else {
      t.push
    ({ id: "",
                          lat:0,lng:0,
                          name:"",
                          address: "", housenumber:"", street:"", zip:"", city:"", county:"", country:"", 
                          reference:"",
                          contact: "",
                          contactphone: "",
                          notes:"", 
                          date:"", 
                          timeRange:[6,9],    
                          eta: null,
                          services: {
                            notificationbyphone: false,
                            notificationbySMS:false,
                            notificationbyEmail:false,
                          },
                          show: false
                        })
    r.push(
     this._formBuilder.group({
                    dest: new FormControl('',
                { validators: 
                 [ Validators.required, 
                   AddressValidator.isValid, 
                   ],
                  asyncValidators:
                  [ AddressValidator.addressValidator(this.mapService)],
                  }),
                    name: 'dest',
                    min: new FormControl('', null),
                    max: new FormControl('', null),
                    date: new FormControl('', null)
            })
            )
            
    }
    
      for(var i = 0 ; i< t.length; i++)
      {
        t.date = ""
        t.eta = null;
      }
    
   
    
  }
  
  adjstart(r: FormArray, t:any , inc: number){
    
    this.show = [true, false, false, false, false, false]
    this.checked=false;
    var arrayControlD = this.routeFormGroup.get('dests') as FormArray;
    for (var i=0; i < arrayControlD.length; i++)
    {
      arrayControlD.at(i).get('date').clearValidators();
      arrayControlD.at(i).get('date').patchValue('');
      arrayControlD.at(i).get('min').patchValue('');
      arrayControlD.at(i).get('max').patchValue('')
      arrayControlD.at(i).updateValueAndValidity();
     
    }
  
    for(var i = 0; i< this.transport.waypoints.unloading.length; i++)
    {
      this.transport.waypoints.unloading[i].eta = null,
      this.transport.waypoints.unloading[i].date = "";
    }
    
    
    if (inc < 0) { 
      t.pop()
      r.removeAt(r.length-1)
       if (this.routeFormGroup.valid && this.checked==false) {
           //console.log('checked due to StatusChange')
            this.checked = true;
            this.checkRoute(false);
          //console.log('inner valid')
          }
    }
 
    else {
      
      t.push
    ({ id: "",
                          lat:0,lng:0,
                          name:"",
                          address: "", housenumber:"", street:"", zip:"", city:"", county:"", country:"", 
                          reference:"",
                          contact: "",
                          contactphone: "",
                          notes:"", 
                          date:"", 
                          timeRange:[6,9],    
                          eta: null,
                          services: {
                            notificationbyphone: false,
                            notificationbySMS:false,
                            notificationbyEmail:false,
                          },
                          show: false
                        })
    r.push(
     this._formBuilder.group({
                    start: new FormControl('',
                { validators: 
                 [ Validators.required, 
                   AddressValidator.isValid, 
                   ],
                  asyncValidators:
                  [ AddressValidator.addressValidator(this.mapService)],
                  }),
                  name: 'start',
                  min: new FormControl('', null),
                  max: new FormControl('', null),
                  date: new FormControl('', null)
                  })
            )
    
    }
    
    for(var i = 0 ; i< t.length; i++)
      {
        t.date = ""
        t.eta = null;
      }
      
    
  }
  
  new(){
      //this._router.navigate(['order','home'])
      window.location.reload();
  }
     
     
get start() { return this.routeFormGroup.get('start')}
get dest() { return this.routeFormGroup.get('dest')}
get finaldest() { return this.routeFormGroup.get('finaldest')}
get pickupDate() { return this.routeFormGroup.get('pickupDate')}
get deliverDate() { return this.routeFormGroup.get('deliverDate')}
get finalDeliverDate() { return this.routeFormGroup.get('finalDeliverDate')}

//get simple_firstName() {return this.simpleContactForm.get('simple_firstName')}
//get simple_lastName() {return this.simpleContactForm.get('simple_lastName')}
//get simple_email() {return this.simpleContactForm.get('simple_email')}

get taxNo() { return this.contactFormGroup.get('taxNo');}
get gender() { return this.contactFormGroup.get('gender');}
get password() { return this.contactFormGroup.get('password');}
get confirmPassword() { return this.contactFormGroup.get('confirmPassword');}
get email() { return this.contactFormGroup.get('email'); }
get firstName() { return this.contactFormGroup.get('firstName');}
get lastName() {return this.contactFormGroup.get('lastName');}
get cell() {return this.contactFormGroup.get('cell');}
get officephone() {return this.contactFormGroup.get('officephone');}
get language() {return this.contactFormGroup.get('language');}
get organisationname() {return this.contactFormGroup.get('organisationname');}
get street() {return this.contactFormGroup.get('street');}
get zip_code() {return this.contactFormGroup.get('zip_code');}
get city() {return this.contactFormGroup.get('city');}
get country() {return this.contactFormGroup.get('country');}

get trucks() {return this.cargoFormGroup.get('trucks');}
get trailer() {return this.cargoFormGroup.get('trailer');}
get attributes() {return this.cargoFormGroup.get('attributes');}
get spannno() {return this.cargoFormGroup.get('spannno')}
get spannnoman() {return this.cargoFormGroup.get('spannnoman')}

get paymentTarget() {return this.paymentFormGroup.get('paymentTarget')}

  ngOnDestroy()
  {
    if (this.checkRouteSub) this.checkRouteSub.unsubscribe();
    if (this.sub) this.sub.unsubscribe();
    if( this.priceSub) this.priceSub.unsubscribe();
    if (this.checkCargoSub) this.checkCargoSub.unsubscribe();
  }
  
  public async presentToast(message)
  {
    	const toast = await this._toast.create({
    		message: message,
    		duration: 9000,
    		 position: 'middle',
    		});
    
    	toast.present();
  }
  
   passwordConfirming(c: AbstractControl): { invalidPWCheck: boolean } {
   ////console.log(c);
    if (c.get('confirmPassword').value != c.get('password').value) {
        return {invalidPWCheck: true};
    }
}
  

// List of all countries in a simple list / array.
public countryList = [
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas (the)",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia (Plurinational State of)",
	"Bonaire, Sint Eustatius and Saba",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory (the)",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cayman Islands (the)",
	"Central African Republic (the)",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands (the)",
	"Colombia",
	"Comoros (the)",
	"Congo (the Democratic Republic of the)",
	"Congo (the)",
	"Cook Islands (the)",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Curaçao",
	"Cyprus",
	"Czechia",
	"Côte d'Ivoire",
	"Denmark",
	"Deutschland",
	"Djibouti",
	"Dominica",
	"Dominican Republic (the)",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Falkland Islands (the) [Malvinas]",
	"Faroe Islands (the)",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories (the)",
	"Gabon",
	"Gambia (the)",
	"Georgia",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See (the)",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran (Islamic Republic of)",
	"Iraq",
	"Ireland",
	"Isle of Man",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea (the Democratic People's Republic of)",
	"Korea (the Republic of)",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic (the)",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands (the)",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia (Federated States of)",
	"Moldova (the Republic of)",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands (the)",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger (the)",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"Northern Mariana Islands (the)",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine, State of",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines (the)",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Republic of North Macedonia",
	"Romania",
	"Russian Federation (the)",
	"Rwanda",
	"Réunion",
	"Saint Barthélemy",
	"Saint Helena, Ascension and Tristan da Cunha",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Martin (French part)",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Sint Maarten (Dutch part)",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan (the)",
	"Suriname",
	"Svalbard and Jan Mayen",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan (Province of China)",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands (the)",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates (the)",
	"United Kingdom of Great Britain and Northern Ireland (the)",
	"United States Minor Outlying Islands (the)",
	"United States of America (the)",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela (Bolivarian Republic of)",
	"Viet Nam",
	"Virgin Islands (British)",
	"Virgin Islands (U.S.)",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
	"Åland Islands"
];
  
}







export class Profile {
    constructor(
        public id: string,
        public username: string,
        public email: string,
        public organisation: string,
        public firstName: string,
        public cell: string,
        public lastName: string,
        public gender: String,
        public nationality: string,
        public language: string = "de",
        public drivinglicense: string,
        public passport: string,
        public aboutMe: string,
        public address:string,
        public imageUrl: string,
        public group:string,
        public officephone:string,
    ){}
}