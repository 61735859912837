/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateTransport: OnCreateTransportSubscription;
  onUpdateTransport: OnUpdateTransportSubscription;
  onDeleteTransport: OnDeleteTransportSubscription;
  onCreateOrder: OnCreateOrderSubscription;
  onUpdateOrder: OnUpdateOrderSubscription;
  onDeleteOrder: OnDeleteOrderSubscription;
  onCreateEvent: OnCreateEventSubscription;
  onUpdateEvent: OnUpdateEventSubscription;
  onDeleteEvent: OnDeleteEventSubscription;
  onCreateTruck: OnCreateTruckSubscription;
  onUpdateTruck: OnUpdateTruckSubscription;
  onDeleteTruck: OnDeleteTruckSubscription;
};

export type CreateTransportInput = {
  id?: string | null;
  customer?: string | null;
  price?: number | null;
  costofgoods?: number | null;
  distance?: number | null;
  trailer?: Array<Trailer | null> | null;
  attributes?: Array<Feature | null> | null;
  straps?: number | null;
  eta?: string | null;
  notes?: string | null;
  reference?: string | null;
  travelTime?: number | null;
  services?: Array<Service | null> | null;
  adr?: boolean | null;
  _version?: number | null;
  transportOrderId?: string | null;
};

export enum Trailer {
  coil = "coil",
  frigo = "frigo",
  joloda = "joloda",
  mega = "mega",
  standard = "standard",
  walking = "walking"
}

export enum Feature {
  anti = "anti",
  schild = "schild",
  edscha = "edscha",
  mlock = "mlock",
  schoner = "schoner",
  spann = "spann",
  gdp = "gdp",
  gmp = "gmp",
  xl = "xl",
  xlg = "xlg"
}

export enum Service {
  eventtracktrace = "eventtracktrace",
  livetracktrace = "livetracktrace",
  packagingexchange = "packagingexchange",
  insurance = "insurance",
  notificationbyphone = "notificationbyphone",
  notificationbyemail = "notificationbyemail",
  notificationbySMS = "notificationbySMS",
  documentsbypost = "documentsbypost"
}

export type ModelTransportConditionInput = {
  customer?: ModelStringInput | null;
  price?: ModelIntInput | null;
  costofgoods?: ModelIntInput | null;
  distance?: ModelIntInput | null;
  trailer?: ModelTrailerListInput | null;
  attributes?: ModelFeatureListInput | null;
  straps?: ModelIntInput | null;
  eta?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  reference?: ModelStringInput | null;
  travelTime?: ModelIntInput | null;
  services?: ModelServiceListInput | null;
  adr?: ModelBooleanInput | null;
  and?: Array<ModelTransportConditionInput | null> | null;
  or?: Array<ModelTransportConditionInput | null> | null;
  not?: ModelTransportConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelTrailerListInput = {
  eq?: Array<Trailer | null> | null;
  ne?: Array<Trailer | null> | null;
  contains?: Trailer | null;
  notContains?: Trailer | null;
};

export type ModelFeatureListInput = {
  eq?: Array<Feature | null> | null;
  ne?: Array<Feature | null> | null;
  contains?: Feature | null;
  notContains?: Feature | null;
};

export type ModelServiceListInput = {
  eq?: Array<Service | null> | null;
  ne?: Array<Service | null> | null;
  contains?: Service | null;
  notContains?: Service | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type Transport = {
  __typename: "Transport";
  id: string;
  customer?: string | null;
  price?: number | null;
  costofgoods?: number | null;
  distance?: number | null;
  trailer?: Array<Trailer | null> | null;
  attributes?: Array<Feature | null> | null;
  straps?: number | null;
  eta?: string | null;
  notes?: string | null;
  reference?: string | null;
  travelTime?: number | null;
  services?: Array<Service | null> | null;
  adr?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  order?: Order | null;
  route?: ModelWaypointConnection | null;
  cargo?: ModelCargoConnection | null;
};

export type Order = {
  __typename: "Order";
  id: string;
  customer: string;
  reference: string;
  comment?: string | null;
  price?: number | null;
  date?: string | null;
  status?: Status | null;
  payment?: PaymentType | null;
  termOfPayment?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  transport?: ModelTransportConnection | null;
  event?: ModelEventConnection | null;
  truck?: Truck | null;
};

export enum Status {
  new = "new",
  requested = "requested",
  priced = "priced",
  offeredonplatform = "offeredonplatform",
  soldonplatform = "soldonplatform",
  ordered = "ordered",
  planned = "planned",
  onTime = "onTime",
  delayed = "delayed",
  done = "done",
  charged = "charged",
  paid = "paid",
  cancelled = "cancelled"
}

export enum PaymentType {
  invoice = "invoice",
  einvoice = "einvoice",
  creditnote = "creditnote",
  directdebit = "directdebit",
  creditcard = "creditcard"
}

export type ModelTransportConnection = {
  __typename: "ModelTransportConnection";
  items: Array<Transport | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelEventConnection = {
  __typename: "ModelEventConnection";
  items: Array<Event | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type Event = {
  __typename: "Event";
  id: string;
  type?: EventType | null;
  time?: string | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  order?: Order | null;
};

export enum EventType {
  arrivalLoading = "arrivalLoading",
  loaded = "loaded",
  departed = "departed",
  arrivalDestination = "arrivalDestination",
  unloaded = "unloaded",
  jammed = "jammed",
  accident = "accident",
  ill = "ill",
  roadcheck = "roadcheck",
  trucktrouble = "trucktrouble"
}

export type Truck = {
  __typename: "Truck";
  id: string;
  name?: string | null;
  plate?: string | null;
  trailerPlate?: string | null;
  location?: Location | null;
  ignitionState?: boolean | null;
  deviceID?: string | null;
  lastSignalTime?: string | null;
  heading?: number | null;
  speed?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
};

export type Location = {
  __typename: "Location";
  lat?: number | null;
  lon?: number | null;
};

export type ModelWaypointConnection = {
  __typename: "ModelWaypointConnection";
  items: Array<Waypoint | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type Waypoint = {
  __typename: "Waypoint";
  id: string;
  sequence: number;
  location: Location;
  name?: string | null;
  address?: string | null;
  housenumber?: string | null;
  street?: string | null;
  zip?: string | null;
  city?: string | null;
  country?: string | null;
  reference?: string | null;
  contact?: string | null;
  contactphone?: string | null;
  notes?: string | null;
  eta?: string | null;
  opening?: string | null;
  closing?: string | null;
  service?: Array<Service | null> | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  transport?: Transport | null;
};

export type ModelCargoConnection = {
  __typename: "ModelCargoConnection";
  items: Array<Cargo | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type Cargo = {
  __typename: "Cargo";
  id: string;
  reference?: string | null;
  category?: string | null;
  packaging?: Packaging | null;
  quantity?: number | null;
  weight?: number | null;
  height?: number | null;
  length?: number | null;
  width?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  transport?: Transport | null;
};

export enum Packaging {
  bundles = "bundles",
  chep = "chep",
  europallet = "europallet",
  coil = "coil",
  onewaypallet = "onewaypallet",
  stillage = "stillage",
  cartons = "cartons",
  boxes = "boxes",
  longitems = "longitems",
  other = "other"
}

export type UpdateTransportInput = {
  id: string;
  customer?: string | null;
  price?: number | null;
  costofgoods?: number | null;
  distance?: number | null;
  trailer?: Array<Trailer | null> | null;
  attributes?: Array<Feature | null> | null;
  straps?: number | null;
  eta?: string | null;
  notes?: string | null;
  reference?: string | null;
  travelTime?: number | null;
  services?: Array<Service | null> | null;
  adr?: boolean | null;
  _version?: number | null;
  transportOrderId?: string | null;
};

export type DeleteTransportInput = {
  id: string;
  _version?: number | null;
};

export type CreateOrderInput = {
  id?: string | null;
  customer: string;
  reference: string;
  comment?: string | null;
  price?: number | null;
  date?: string | null;
  status?: Status | null;
  payment?: PaymentType | null;
  termOfPayment?: number | null;
  _version?: number | null;
  orderTruckId?: string | null;
};

export type ModelOrderConditionInput = {
  customer?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  price?: ModelIntInput | null;
  date?: ModelStringInput | null;
  status?: ModelStatusInput | null;
  payment?: ModelPaymentTypeInput | null;
  termOfPayment?: ModelIntInput | null;
  and?: Array<ModelOrderConditionInput | null> | null;
  or?: Array<ModelOrderConditionInput | null> | null;
  not?: ModelOrderConditionInput | null;
};

export type ModelStatusInput = {
  eq?: Status | null;
  ne?: Status | null;
};

export type ModelPaymentTypeInput = {
  eq?: PaymentType | null;
  ne?: PaymentType | null;
};

export type UpdateOrderInput = {
  id?: string | null;
  customer?: string | null;
  reference: string;
  comment?: string | null;
  price?: number | null;
  date?: string | null;
  status?: Status | null;
  payment?: PaymentType | null;
  termOfPayment?: number | null;
  _version?: number | null;
  orderTruckId?: string | null;
};

export type DeleteOrderInput = {
  reference: string;
  _version?: number | null;
};

export type CreateEventInput = {
  id?: string | null;
  type?: EventType | null;
  time?: string | null;
  _version?: number | null;
  eventOrderId?: string | null;
};

export type ModelEventConditionInput = {
  type?: ModelEventTypeInput | null;
  time?: ModelStringInput | null;
  and?: Array<ModelEventConditionInput | null> | null;
  or?: Array<ModelEventConditionInput | null> | null;
  not?: ModelEventConditionInput | null;
};

export type ModelEventTypeInput = {
  eq?: EventType | null;
  ne?: EventType | null;
};

export type UpdateEventInput = {
  id: string;
  type?: EventType | null;
  time?: string | null;
  _version?: number | null;
  eventOrderId?: string | null;
};

export type DeleteEventInput = {
  id: string;
  _version?: number | null;
};

export type CreateTruckInput = {
  id?: string | null;
  name?: string | null;
  plate?: string | null;
  trailerPlate?: string | null;
  location?: LocationInput | null;
  ignitionState?: boolean | null;
  deviceID?: string | null;
  lastSignalTime?: string | null;
  heading?: number | null;
  speed?: number | null;
  _version?: number | null;
};

export type LocationInput = {
  lat?: number | null;
  lon?: number | null;
};

export type ModelTruckConditionInput = {
  name?: ModelStringInput | null;
  plate?: ModelStringInput | null;
  trailerPlate?: ModelStringInput | null;
  ignitionState?: ModelBooleanInput | null;
  deviceID?: ModelStringInput | null;
  lastSignalTime?: ModelStringInput | null;
  heading?: ModelIntInput | null;
  speed?: ModelIntInput | null;
  and?: Array<ModelTruckConditionInput | null> | null;
  or?: Array<ModelTruckConditionInput | null> | null;
  not?: ModelTruckConditionInput | null;
};

export type UpdateTruckInput = {
  id: string;
  name?: string | null;
  plate?: string | null;
  trailerPlate?: string | null;
  location?: LocationInput | null;
  ignitionState?: boolean | null;
  deviceID?: string | null;
  lastSignalTime?: string | null;
  heading?: number | null;
  speed?: number | null;
  _version?: number | null;
};

export type DeleteTruckInput = {
  id: string;
  _version?: number | null;
};

export type CreateWaypointInput = {
  id?: string | null;
  sequence: number;
  location: LocationInput;
  name?: string | null;
  address?: string | null;
  housenumber?: string | null;
  street?: string | null;
  zip?: string | null;
  city?: string | null;
  country?: string | null;
  reference?: string | null;
  contact?: string | null;
  contactphone?: string | null;
  notes?: string | null;
  eta?: string | null;
  opening?: string | null;
  closing?: string | null;
  service?: Array<Service | null> | null;
  _version?: number | null;
  waypointTransportId?: string | null;
};

export type ModelWaypointConditionInput = {
  sequence?: ModelIntInput | null;
  name?: ModelStringInput | null;
  address?: ModelStringInput | null;
  housenumber?: ModelStringInput | null;
  street?: ModelStringInput | null;
  zip?: ModelStringInput | null;
  city?: ModelStringInput | null;
  country?: ModelStringInput | null;
  reference?: ModelStringInput | null;
  contact?: ModelStringInput | null;
  contactphone?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  eta?: ModelStringInput | null;
  opening?: ModelStringInput | null;
  closing?: ModelStringInput | null;
  service?: ModelServiceListInput | null;
  and?: Array<ModelWaypointConditionInput | null> | null;
  or?: Array<ModelWaypointConditionInput | null> | null;
  not?: ModelWaypointConditionInput | null;
};

export type UpdateWaypointInput = {
  id: string;
  sequence?: number | null;
  location?: LocationInput | null;
  name?: string | null;
  address?: string | null;
  housenumber?: string | null;
  street?: string | null;
  zip?: string | null;
  city?: string | null;
  country?: string | null;
  reference?: string | null;
  contact?: string | null;
  contactphone?: string | null;
  notes?: string | null;
  eta?: string | null;
  opening?: string | null;
  closing?: string | null;
  service?: Array<Service | null> | null;
  _version?: number | null;
  waypointTransportId?: string | null;
};

export type DeleteWaypointInput = {
  id: string;
  _version?: number | null;
};

export type CreateCargoInput = {
  id?: string | null;
  reference?: string | null;
  category?: string | null;
  packaging?: Packaging | null;
  quantity?: number | null;
  weight?: number | null;
  height?: number | null;
  length?: number | null;
  width?: number | null;
  _version?: number | null;
  cargoTransportId?: string | null;
};

export type ModelCargoConditionInput = {
  reference?: ModelStringInput | null;
  category?: ModelStringInput | null;
  packaging?: ModelPackagingInput | null;
  quantity?: ModelIntInput | null;
  weight?: ModelIntInput | null;
  height?: ModelIntInput | null;
  length?: ModelIntInput | null;
  width?: ModelIntInput | null;
  and?: Array<ModelCargoConditionInput | null> | null;
  or?: Array<ModelCargoConditionInput | null> | null;
  not?: ModelCargoConditionInput | null;
};

export type ModelPackagingInput = {
  eq?: Packaging | null;
  ne?: Packaging | null;
};

export type UpdateCargoInput = {
  id: string;
  reference?: string | null;
  category?: string | null;
  packaging?: Packaging | null;
  quantity?: number | null;
  weight?: number | null;
  height?: number | null;
  length?: number | null;
  width?: number | null;
  _version?: number | null;
  cargoTransportId?: string | null;
};

export type DeleteCargoInput = {
  id: string;
  _version?: number | null;
};

export type ModelTransportFilterInput = {
  id?: ModelIDInput | null;
  customer?: ModelStringInput | null;
  price?: ModelIntInput | null;
  costofgoods?: ModelIntInput | null;
  distance?: ModelIntInput | null;
  trailer?: ModelTrailerListInput | null;
  attributes?: ModelFeatureListInput | null;
  straps?: ModelIntInput | null;
  eta?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  reference?: ModelStringInput | null;
  travelTime?: ModelIntInput | null;
  services?: ModelServiceListInput | null;
  adr?: ModelBooleanInput | null;
  and?: Array<ModelTransportFilterInput | null> | null;
  or?: Array<ModelTransportFilterInput | null> | null;
  not?: ModelTransportFilterInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelOrderFilterInput = {
  id?: ModelIDInput | null;
  customer?: ModelStringInput | null;
  reference?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  price?: ModelIntInput | null;
  date?: ModelStringInput | null;
  status?: ModelStatusInput | null;
  payment?: ModelPaymentTypeInput | null;
  termOfPayment?: ModelIntInput | null;
  and?: Array<ModelOrderFilterInput | null> | null;
  or?: Array<ModelOrderFilterInput | null> | null;
  not?: ModelOrderFilterInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelOrderConnection = {
  __typename: "ModelOrderConnection";
  items: Array<Order | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelEventFilterInput = {
  type?: ModelEventTypeInput | null;
  time?: ModelStringInput | null;
  and?: Array<ModelEventFilterInput | null> | null;
  or?: Array<ModelEventFilterInput | null> | null;
  not?: ModelEventFilterInput | null;
};

export type ModelTruckFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  plate?: ModelStringInput | null;
  trailerPlate?: ModelStringInput | null;
  ignitionState?: ModelBooleanInput | null;
  deviceID?: ModelStringInput | null;
  lastSignalTime?: ModelStringInput | null;
  heading?: ModelIntInput | null;
  speed?: ModelIntInput | null;
  and?: Array<ModelTruckFilterInput | null> | null;
  or?: Array<ModelTruckFilterInput | null> | null;
  not?: ModelTruckFilterInput | null;
};

export type ModelTruckConnection = {
  __typename: "ModelTruckConnection";
  items: Array<Truck | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelWaypointFilterInput = {
  id?: ModelIDInput | null;
  sequence?: ModelIntInput | null;
  name?: ModelStringInput | null;
  address?: ModelStringInput | null;
  housenumber?: ModelStringInput | null;
  street?: ModelStringInput | null;
  zip?: ModelStringInput | null;
  city?: ModelStringInput | null;
  country?: ModelStringInput | null;
  reference?: ModelStringInput | null;
  contact?: ModelStringInput | null;
  contactphone?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  eta?: ModelStringInput | null;
  opening?: ModelStringInput | null;
  closing?: ModelStringInput | null;
  service?: ModelServiceListInput | null;
  and?: Array<ModelWaypointFilterInput | null> | null;
  or?: Array<ModelWaypointFilterInput | null> | null;
  not?: ModelWaypointFilterInput | null;
};

export type ModelCargoFilterInput = {
  id?: ModelIDInput | null;
  reference?: ModelStringInput | null;
  category?: ModelStringInput | null;
  packaging?: ModelPackagingInput | null;
  quantity?: ModelIntInput | null;
  weight?: ModelIntInput | null;
  height?: ModelIntInput | null;
  length?: ModelIntInput | null;
  width?: ModelIntInput | null;
  and?: Array<ModelCargoFilterInput | null> | null;
  or?: Array<ModelCargoFilterInput | null> | null;
  not?: ModelCargoFilterInput | null;
};

export type CreateTransportMutation = {
  __typename: "Transport";
  id: string;
  customer?: string | null;
  price?: number | null;
  costofgoods?: number | null;
  distance?: number | null;
  trailer?: Array<Trailer | null> | null;
  attributes?: Array<Feature | null> | null;
  straps?: number | null;
  eta?: string | null;
  notes?: string | null;
  reference?: string | null;
  travelTime?: number | null;
  services?: Array<Service | null> | null;
  adr?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  order?: {
    __typename: "Order";
    id: string;
    customer: string;
    reference: string;
    comment?: string | null;
    price?: number | null;
    date?: string | null;
    status?: Status | null;
    payment?: PaymentType | null;
    termOfPayment?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "ModelTransportConnection";
      items: Array<{
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    event?: {
      __typename: "ModelEventConnection";
      items: Array<{
        __typename: "Event";
        id: string;
        type?: EventType | null;
        time?: string | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    truck?: {
      __typename: "Truck";
      id: string;
      name?: string | null;
      plate?: string | null;
      trailerPlate?: string | null;
      location?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      ignitionState?: boolean | null;
      deviceID?: string | null;
      lastSignalTime?: string | null;
      heading?: number | null;
      speed?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
  route?: {
    __typename: "ModelWaypointConnection";
    items: Array<{
      __typename: "Waypoint";
      id: string;
      sequence: number;
      location: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      };
      name?: string | null;
      address?: string | null;
      housenumber?: string | null;
      street?: string | null;
      zip?: string | null;
      city?: string | null;
      country?: string | null;
      reference?: string | null;
      contact?: string | null;
      contactphone?: string | null;
      notes?: string | null;
      eta?: string | null;
      opening?: string | null;
      closing?: string | null;
      service?: Array<Service | null> | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  cargo?: {
    __typename: "ModelCargoConnection";
    items: Array<{
      __typename: "Cargo";
      id: string;
      reference?: string | null;
      category?: string | null;
      packaging?: Packaging | null;
      quantity?: number | null;
      weight?: number | null;
      height?: number | null;
      length?: number | null;
      width?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
};

export type UpdateTransportMutation = {
  __typename: "Transport";
  id: string;
  customer?: string | null;
  price?: number | null;
  costofgoods?: number | null;
  distance?: number | null;
  trailer?: Array<Trailer | null> | null;
  attributes?: Array<Feature | null> | null;
  straps?: number | null;
  eta?: string | null;
  notes?: string | null;
  reference?: string | null;
  travelTime?: number | null;
  services?: Array<Service | null> | null;
  adr?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  order?: {
    __typename: "Order";
    id: string;
    customer: string;
    reference: string;
    comment?: string | null;
    price?: number | null;
    date?: string | null;
    status?: Status | null;
    payment?: PaymentType | null;
    termOfPayment?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "ModelTransportConnection";
      items: Array<{
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    event?: {
      __typename: "ModelEventConnection";
      items: Array<{
        __typename: "Event";
        id: string;
        type?: EventType | null;
        time?: string | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    truck?: {
      __typename: "Truck";
      id: string;
      name?: string | null;
      plate?: string | null;
      trailerPlate?: string | null;
      location?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      ignitionState?: boolean | null;
      deviceID?: string | null;
      lastSignalTime?: string | null;
      heading?: number | null;
      speed?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
  route?: {
    __typename: "ModelWaypointConnection";
    items: Array<{
      __typename: "Waypoint";
      id: string;
      sequence: number;
      location: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      };
      name?: string | null;
      address?: string | null;
      housenumber?: string | null;
      street?: string | null;
      zip?: string | null;
      city?: string | null;
      country?: string | null;
      reference?: string | null;
      contact?: string | null;
      contactphone?: string | null;
      notes?: string | null;
      eta?: string | null;
      opening?: string | null;
      closing?: string | null;
      service?: Array<Service | null> | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  cargo?: {
    __typename: "ModelCargoConnection";
    items: Array<{
      __typename: "Cargo";
      id: string;
      reference?: string | null;
      category?: string | null;
      packaging?: Packaging | null;
      quantity?: number | null;
      weight?: number | null;
      height?: number | null;
      length?: number | null;
      width?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
};

export type DeleteTransportMutation = {
  __typename: "Transport";
  id: string;
  customer?: string | null;
  price?: number | null;
  costofgoods?: number | null;
  distance?: number | null;
  trailer?: Array<Trailer | null> | null;
  attributes?: Array<Feature | null> | null;
  straps?: number | null;
  eta?: string | null;
  notes?: string | null;
  reference?: string | null;
  travelTime?: number | null;
  services?: Array<Service | null> | null;
  adr?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  order?: {
    __typename: "Order";
    id: string;
    customer: string;
    reference: string;
    comment?: string | null;
    price?: number | null;
    date?: string | null;
    status?: Status | null;
    payment?: PaymentType | null;
    termOfPayment?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "ModelTransportConnection";
      items: Array<{
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    event?: {
      __typename: "ModelEventConnection";
      items: Array<{
        __typename: "Event";
        id: string;
        type?: EventType | null;
        time?: string | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    truck?: {
      __typename: "Truck";
      id: string;
      name?: string | null;
      plate?: string | null;
      trailerPlate?: string | null;
      location?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      ignitionState?: boolean | null;
      deviceID?: string | null;
      lastSignalTime?: string | null;
      heading?: number | null;
      speed?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
  route?: {
    __typename: "ModelWaypointConnection";
    items: Array<{
      __typename: "Waypoint";
      id: string;
      sequence: number;
      location: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      };
      name?: string | null;
      address?: string | null;
      housenumber?: string | null;
      street?: string | null;
      zip?: string | null;
      city?: string | null;
      country?: string | null;
      reference?: string | null;
      contact?: string | null;
      contactphone?: string | null;
      notes?: string | null;
      eta?: string | null;
      opening?: string | null;
      closing?: string | null;
      service?: Array<Service | null> | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  cargo?: {
    __typename: "ModelCargoConnection";
    items: Array<{
      __typename: "Cargo";
      id: string;
      reference?: string | null;
      category?: string | null;
      packaging?: Packaging | null;
      quantity?: number | null;
      weight?: number | null;
      height?: number | null;
      length?: number | null;
      width?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
};

export type CreateOrderMutation = {
  __typename: "Order";
  id: string;
  customer: string;
  reference: string;
  comment?: string | null;
  price?: number | null;
  date?: string | null;
  status?: Status | null;
  payment?: PaymentType | null;
  termOfPayment?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  transport?: {
    __typename: "ModelTransportConnection";
    items: Array<{
      __typename: "Transport";
      id: string;
      customer?: string | null;
      price?: number | null;
      costofgoods?: number | null;
      distance?: number | null;
      trailer?: Array<Trailer | null> | null;
      attributes?: Array<Feature | null> | null;
      straps?: number | null;
      eta?: string | null;
      notes?: string | null;
      reference?: string | null;
      travelTime?: number | null;
      services?: Array<Service | null> | null;
      adr?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      order?: {
        __typename: "Order";
        id: string;
        customer: string;
        reference: string;
        comment?: string | null;
        price?: number | null;
        date?: string | null;
        status?: Status | null;
        payment?: PaymentType | null;
        termOfPayment?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "ModelTransportConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        event?: {
          __typename: "ModelEventConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        truck?: {
          __typename: "Truck";
          id: string;
          name?: string | null;
          plate?: string | null;
          trailerPlate?: string | null;
          ignitionState?: boolean | null;
          deviceID?: string | null;
          lastSignalTime?: string | null;
          heading?: number | null;
          speed?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null;
      route?: {
        __typename: "ModelWaypointConnection";
        items: Array<{
          __typename: "Waypoint";
          id: string;
          sequence: number;
          name?: string | null;
          address?: string | null;
          housenumber?: string | null;
          street?: string | null;
          zip?: string | null;
          city?: string | null;
          country?: string | null;
          reference?: string | null;
          contact?: string | null;
          contactphone?: string | null;
          notes?: string | null;
          eta?: string | null;
          opening?: string | null;
          closing?: string | null;
          service?: Array<Service | null> | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      cargo?: {
        __typename: "ModelCargoConnection";
        items: Array<{
          __typename: "Cargo";
          id: string;
          reference?: string | null;
          category?: string | null;
          packaging?: Packaging | null;
          quantity?: number | null;
          weight?: number | null;
          height?: number | null;
          length?: number | null;
          width?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  event?: {
    __typename: "ModelEventConnection";
    items: Array<{
      __typename: "Event";
      id: string;
      type?: EventType | null;
      time?: string | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      order?: {
        __typename: "Order";
        id: string;
        customer: string;
        reference: string;
        comment?: string | null;
        price?: number | null;
        date?: string | null;
        status?: Status | null;
        payment?: PaymentType | null;
        termOfPayment?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "ModelTransportConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        event?: {
          __typename: "ModelEventConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        truck?: {
          __typename: "Truck";
          id: string;
          name?: string | null;
          plate?: string | null;
          trailerPlate?: string | null;
          ignitionState?: boolean | null;
          deviceID?: string | null;
          lastSignalTime?: string | null;
          heading?: number | null;
          speed?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  truck?: {
    __typename: "Truck";
    id: string;
    name?: string | null;
    plate?: string | null;
    trailerPlate?: string | null;
    location?: {
      __typename: "Location";
      lat?: number | null;
      lon?: number | null;
    } | null;
    ignitionState?: boolean | null;
    deviceID?: string | null;
    lastSignalTime?: string | null;
    heading?: number | null;
    speed?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateOrderMutation = {
  __typename: "Order";
  id: string;
  customer: string;
  reference: string;
  comment?: string | null;
  price?: number | null;
  date?: string | null;
  status?: Status | null;
  payment?: PaymentType | null;
  termOfPayment?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  transport?: {
    __typename: "ModelTransportConnection";
    items: Array<{
      __typename: "Transport";
      id: string;
      customer?: string | null;
      price?: number | null;
      costofgoods?: number | null;
      distance?: number | null;
      trailer?: Array<Trailer | null> | null;
      attributes?: Array<Feature | null> | null;
      straps?: number | null;
      eta?: string | null;
      notes?: string | null;
      reference?: string | null;
      travelTime?: number | null;
      services?: Array<Service | null> | null;
      adr?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      order?: {
        __typename: "Order";
        id: string;
        customer: string;
        reference: string;
        comment?: string | null;
        price?: number | null;
        date?: string | null;
        status?: Status | null;
        payment?: PaymentType | null;
        termOfPayment?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "ModelTransportConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        event?: {
          __typename: "ModelEventConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        truck?: {
          __typename: "Truck";
          id: string;
          name?: string | null;
          plate?: string | null;
          trailerPlate?: string | null;
          ignitionState?: boolean | null;
          deviceID?: string | null;
          lastSignalTime?: string | null;
          heading?: number | null;
          speed?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null;
      route?: {
        __typename: "ModelWaypointConnection";
        items: Array<{
          __typename: "Waypoint";
          id: string;
          sequence: number;
          name?: string | null;
          address?: string | null;
          housenumber?: string | null;
          street?: string | null;
          zip?: string | null;
          city?: string | null;
          country?: string | null;
          reference?: string | null;
          contact?: string | null;
          contactphone?: string | null;
          notes?: string | null;
          eta?: string | null;
          opening?: string | null;
          closing?: string | null;
          service?: Array<Service | null> | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      cargo?: {
        __typename: "ModelCargoConnection";
        items: Array<{
          __typename: "Cargo";
          id: string;
          reference?: string | null;
          category?: string | null;
          packaging?: Packaging | null;
          quantity?: number | null;
          weight?: number | null;
          height?: number | null;
          length?: number | null;
          width?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  event?: {
    __typename: "ModelEventConnection";
    items: Array<{
      __typename: "Event";
      id: string;
      type?: EventType | null;
      time?: string | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      order?: {
        __typename: "Order";
        id: string;
        customer: string;
        reference: string;
        comment?: string | null;
        price?: number | null;
        date?: string | null;
        status?: Status | null;
        payment?: PaymentType | null;
        termOfPayment?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "ModelTransportConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        event?: {
          __typename: "ModelEventConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        truck?: {
          __typename: "Truck";
          id: string;
          name?: string | null;
          plate?: string | null;
          trailerPlate?: string | null;
          ignitionState?: boolean | null;
          deviceID?: string | null;
          lastSignalTime?: string | null;
          heading?: number | null;
          speed?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  truck?: {
    __typename: "Truck";
    id: string;
    name?: string | null;
    plate?: string | null;
    trailerPlate?: string | null;
    location?: {
      __typename: "Location";
      lat?: number | null;
      lon?: number | null;
    } | null;
    ignitionState?: boolean | null;
    deviceID?: string | null;
    lastSignalTime?: string | null;
    heading?: number | null;
    speed?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteOrderMutation = {
  __typename: "Order";
  id: string;
  customer: string;
  reference: string;
  comment?: string | null;
  price?: number | null;
  date?: string | null;
  status?: Status | null;
  payment?: PaymentType | null;
  termOfPayment?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  transport?: {
    __typename: "ModelTransportConnection";
    items: Array<{
      __typename: "Transport";
      id: string;
      customer?: string | null;
      price?: number | null;
      costofgoods?: number | null;
      distance?: number | null;
      trailer?: Array<Trailer | null> | null;
      attributes?: Array<Feature | null> | null;
      straps?: number | null;
      eta?: string | null;
      notes?: string | null;
      reference?: string | null;
      travelTime?: number | null;
      services?: Array<Service | null> | null;
      adr?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      order?: {
        __typename: "Order";
        id: string;
        customer: string;
        reference: string;
        comment?: string | null;
        price?: number | null;
        date?: string | null;
        status?: Status | null;
        payment?: PaymentType | null;
        termOfPayment?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "ModelTransportConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        event?: {
          __typename: "ModelEventConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        truck?: {
          __typename: "Truck";
          id: string;
          name?: string | null;
          plate?: string | null;
          trailerPlate?: string | null;
          ignitionState?: boolean | null;
          deviceID?: string | null;
          lastSignalTime?: string | null;
          heading?: number | null;
          speed?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null;
      route?: {
        __typename: "ModelWaypointConnection";
        items: Array<{
          __typename: "Waypoint";
          id: string;
          sequence: number;
          name?: string | null;
          address?: string | null;
          housenumber?: string | null;
          street?: string | null;
          zip?: string | null;
          city?: string | null;
          country?: string | null;
          reference?: string | null;
          contact?: string | null;
          contactphone?: string | null;
          notes?: string | null;
          eta?: string | null;
          opening?: string | null;
          closing?: string | null;
          service?: Array<Service | null> | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      cargo?: {
        __typename: "ModelCargoConnection";
        items: Array<{
          __typename: "Cargo";
          id: string;
          reference?: string | null;
          category?: string | null;
          packaging?: Packaging | null;
          quantity?: number | null;
          weight?: number | null;
          height?: number | null;
          length?: number | null;
          width?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  event?: {
    __typename: "ModelEventConnection";
    items: Array<{
      __typename: "Event";
      id: string;
      type?: EventType | null;
      time?: string | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      order?: {
        __typename: "Order";
        id: string;
        customer: string;
        reference: string;
        comment?: string | null;
        price?: number | null;
        date?: string | null;
        status?: Status | null;
        payment?: PaymentType | null;
        termOfPayment?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "ModelTransportConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        event?: {
          __typename: "ModelEventConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        truck?: {
          __typename: "Truck";
          id: string;
          name?: string | null;
          plate?: string | null;
          trailerPlate?: string | null;
          ignitionState?: boolean | null;
          deviceID?: string | null;
          lastSignalTime?: string | null;
          heading?: number | null;
          speed?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  truck?: {
    __typename: "Truck";
    id: string;
    name?: string | null;
    plate?: string | null;
    trailerPlate?: string | null;
    location?: {
      __typename: "Location";
      lat?: number | null;
      lon?: number | null;
    } | null;
    ignitionState?: boolean | null;
    deviceID?: string | null;
    lastSignalTime?: string | null;
    heading?: number | null;
    speed?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateEventMutation = {
  __typename: "Event";
  id: string;
  type?: EventType | null;
  time?: string | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  order?: {
    __typename: "Order";
    id: string;
    customer: string;
    reference: string;
    comment?: string | null;
    price?: number | null;
    date?: string | null;
    status?: Status | null;
    payment?: PaymentType | null;
    termOfPayment?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "ModelTransportConnection";
      items: Array<{
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    event?: {
      __typename: "ModelEventConnection";
      items: Array<{
        __typename: "Event";
        id: string;
        type?: EventType | null;
        time?: string | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    truck?: {
      __typename: "Truck";
      id: string;
      name?: string | null;
      plate?: string | null;
      trailerPlate?: string | null;
      location?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      ignitionState?: boolean | null;
      deviceID?: string | null;
      lastSignalTime?: string | null;
      heading?: number | null;
      speed?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
};

export type UpdateEventMutation = {
  __typename: "Event";
  id: string;
  type?: EventType | null;
  time?: string | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  order?: {
    __typename: "Order";
    id: string;
    customer: string;
    reference: string;
    comment?: string | null;
    price?: number | null;
    date?: string | null;
    status?: Status | null;
    payment?: PaymentType | null;
    termOfPayment?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "ModelTransportConnection";
      items: Array<{
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    event?: {
      __typename: "ModelEventConnection";
      items: Array<{
        __typename: "Event";
        id: string;
        type?: EventType | null;
        time?: string | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    truck?: {
      __typename: "Truck";
      id: string;
      name?: string | null;
      plate?: string | null;
      trailerPlate?: string | null;
      location?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      ignitionState?: boolean | null;
      deviceID?: string | null;
      lastSignalTime?: string | null;
      heading?: number | null;
      speed?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
};

export type DeleteEventMutation = {
  __typename: "Event";
  id: string;
  type?: EventType | null;
  time?: string | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  order?: {
    __typename: "Order";
    id: string;
    customer: string;
    reference: string;
    comment?: string | null;
    price?: number | null;
    date?: string | null;
    status?: Status | null;
    payment?: PaymentType | null;
    termOfPayment?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "ModelTransportConnection";
      items: Array<{
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    event?: {
      __typename: "ModelEventConnection";
      items: Array<{
        __typename: "Event";
        id: string;
        type?: EventType | null;
        time?: string | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    truck?: {
      __typename: "Truck";
      id: string;
      name?: string | null;
      plate?: string | null;
      trailerPlate?: string | null;
      location?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      ignitionState?: boolean | null;
      deviceID?: string | null;
      lastSignalTime?: string | null;
      heading?: number | null;
      speed?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
};

export type CreateTruckMutation = {
  __typename: "Truck";
  id: string;
  name?: string | null;
  plate?: string | null;
  trailerPlate?: string | null;
  location?: {
    __typename: "Location";
    lat?: number | null;
    lon?: number | null;
  } | null;
  ignitionState?: boolean | null;
  deviceID?: string | null;
  lastSignalTime?: string | null;
  heading?: number | null;
  speed?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTruckMutation = {
  __typename: "Truck";
  id: string;
  name?: string | null;
  plate?: string | null;
  trailerPlate?: string | null;
  location?: {
    __typename: "Location";
    lat?: number | null;
    lon?: number | null;
  } | null;
  ignitionState?: boolean | null;
  deviceID?: string | null;
  lastSignalTime?: string | null;
  heading?: number | null;
  speed?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
};

export type DeleteTruckMutation = {
  __typename: "Truck";
  id: string;
  name?: string | null;
  plate?: string | null;
  trailerPlate?: string | null;
  location?: {
    __typename: "Location";
    lat?: number | null;
    lon?: number | null;
  } | null;
  ignitionState?: boolean | null;
  deviceID?: string | null;
  lastSignalTime?: string | null;
  heading?: number | null;
  speed?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
};

export type CreateWaypointMutation = {
  __typename: "Waypoint";
  id: string;
  sequence: number;
  location: {
    __typename: "Location";
    lat?: number | null;
    lon?: number | null;
  };
  name?: string | null;
  address?: string | null;
  housenumber?: string | null;
  street?: string | null;
  zip?: string | null;
  city?: string | null;
  country?: string | null;
  reference?: string | null;
  contact?: string | null;
  contactphone?: string | null;
  notes?: string | null;
  eta?: string | null;
  opening?: string | null;
  closing?: string | null;
  service?: Array<Service | null> | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  transport?: {
    __typename: "Transport";
    id: string;
    customer?: string | null;
    price?: number | null;
    costofgoods?: number | null;
    distance?: number | null;
    trailer?: Array<Trailer | null> | null;
    attributes?: Array<Feature | null> | null;
    straps?: number | null;
    eta?: string | null;
    notes?: string | null;
    reference?: string | null;
    travelTime?: number | null;
    services?: Array<Service | null> | null;
    adr?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    order?: {
      __typename: "Order";
      id: string;
      customer: string;
      reference: string;
      comment?: string | null;
      price?: number | null;
      date?: string | null;
      status?: Status | null;
      payment?: PaymentType | null;
      termOfPayment?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "ModelTransportConnection";
        items: Array<{
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      event?: {
        __typename: "ModelEventConnection";
        items: Array<{
          __typename: "Event";
          id: string;
          type?: EventType | null;
          time?: string | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      truck?: {
        __typename: "Truck";
        id: string;
        name?: string | null;
        plate?: string | null;
        trailerPlate?: string | null;
        location?: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        } | null;
        ignitionState?: boolean | null;
        deviceID?: string | null;
        lastSignalTime?: string | null;
        heading?: number | null;
        speed?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null;
    route?: {
      __typename: "ModelWaypointConnection";
      items: Array<{
        __typename: "Waypoint";
        id: string;
        sequence: number;
        location: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        };
        name?: string | null;
        address?: string | null;
        housenumber?: string | null;
        street?: string | null;
        zip?: string | null;
        city?: string | null;
        country?: string | null;
        reference?: string | null;
        contact?: string | null;
        contactphone?: string | null;
        notes?: string | null;
        eta?: string | null;
        opening?: string | null;
        closing?: string | null;
        service?: Array<Service | null> | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    cargo?: {
      __typename: "ModelCargoConnection";
      items: Array<{
        __typename: "Cargo";
        id: string;
        reference?: string | null;
        category?: string | null;
        packaging?: Packaging | null;
        quantity?: number | null;
        weight?: number | null;
        height?: number | null;
        length?: number | null;
        width?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
  } | null;
};

export type UpdateWaypointMutation = {
  __typename: "Waypoint";
  id: string;
  sequence: number;
  location: {
    __typename: "Location";
    lat?: number | null;
    lon?: number | null;
  };
  name?: string | null;
  address?: string | null;
  housenumber?: string | null;
  street?: string | null;
  zip?: string | null;
  city?: string | null;
  country?: string | null;
  reference?: string | null;
  contact?: string | null;
  contactphone?: string | null;
  notes?: string | null;
  eta?: string | null;
  opening?: string | null;
  closing?: string | null;
  service?: Array<Service | null> | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  transport?: {
    __typename: "Transport";
    id: string;
    customer?: string | null;
    price?: number | null;
    costofgoods?: number | null;
    distance?: number | null;
    trailer?: Array<Trailer | null> | null;
    attributes?: Array<Feature | null> | null;
    straps?: number | null;
    eta?: string | null;
    notes?: string | null;
    reference?: string | null;
    travelTime?: number | null;
    services?: Array<Service | null> | null;
    adr?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    order?: {
      __typename: "Order";
      id: string;
      customer: string;
      reference: string;
      comment?: string | null;
      price?: number | null;
      date?: string | null;
      status?: Status | null;
      payment?: PaymentType | null;
      termOfPayment?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "ModelTransportConnection";
        items: Array<{
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      event?: {
        __typename: "ModelEventConnection";
        items: Array<{
          __typename: "Event";
          id: string;
          type?: EventType | null;
          time?: string | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      truck?: {
        __typename: "Truck";
        id: string;
        name?: string | null;
        plate?: string | null;
        trailerPlate?: string | null;
        location?: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        } | null;
        ignitionState?: boolean | null;
        deviceID?: string | null;
        lastSignalTime?: string | null;
        heading?: number | null;
        speed?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null;
    route?: {
      __typename: "ModelWaypointConnection";
      items: Array<{
        __typename: "Waypoint";
        id: string;
        sequence: number;
        location: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        };
        name?: string | null;
        address?: string | null;
        housenumber?: string | null;
        street?: string | null;
        zip?: string | null;
        city?: string | null;
        country?: string | null;
        reference?: string | null;
        contact?: string | null;
        contactphone?: string | null;
        notes?: string | null;
        eta?: string | null;
        opening?: string | null;
        closing?: string | null;
        service?: Array<Service | null> | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    cargo?: {
      __typename: "ModelCargoConnection";
      items: Array<{
        __typename: "Cargo";
        id: string;
        reference?: string | null;
        category?: string | null;
        packaging?: Packaging | null;
        quantity?: number | null;
        weight?: number | null;
        height?: number | null;
        length?: number | null;
        width?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
  } | null;
};

export type DeleteWaypointMutation = {
  __typename: "Waypoint";
  id: string;
  sequence: number;
  location: {
    __typename: "Location";
    lat?: number | null;
    lon?: number | null;
  };
  name?: string | null;
  address?: string | null;
  housenumber?: string | null;
  street?: string | null;
  zip?: string | null;
  city?: string | null;
  country?: string | null;
  reference?: string | null;
  contact?: string | null;
  contactphone?: string | null;
  notes?: string | null;
  eta?: string | null;
  opening?: string | null;
  closing?: string | null;
  service?: Array<Service | null> | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  transport?: {
    __typename: "Transport";
    id: string;
    customer?: string | null;
    price?: number | null;
    costofgoods?: number | null;
    distance?: number | null;
    trailer?: Array<Trailer | null> | null;
    attributes?: Array<Feature | null> | null;
    straps?: number | null;
    eta?: string | null;
    notes?: string | null;
    reference?: string | null;
    travelTime?: number | null;
    services?: Array<Service | null> | null;
    adr?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    order?: {
      __typename: "Order";
      id: string;
      customer: string;
      reference: string;
      comment?: string | null;
      price?: number | null;
      date?: string | null;
      status?: Status | null;
      payment?: PaymentType | null;
      termOfPayment?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "ModelTransportConnection";
        items: Array<{
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      event?: {
        __typename: "ModelEventConnection";
        items: Array<{
          __typename: "Event";
          id: string;
          type?: EventType | null;
          time?: string | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      truck?: {
        __typename: "Truck";
        id: string;
        name?: string | null;
        plate?: string | null;
        trailerPlate?: string | null;
        location?: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        } | null;
        ignitionState?: boolean | null;
        deviceID?: string | null;
        lastSignalTime?: string | null;
        heading?: number | null;
        speed?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null;
    route?: {
      __typename: "ModelWaypointConnection";
      items: Array<{
        __typename: "Waypoint";
        id: string;
        sequence: number;
        location: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        };
        name?: string | null;
        address?: string | null;
        housenumber?: string | null;
        street?: string | null;
        zip?: string | null;
        city?: string | null;
        country?: string | null;
        reference?: string | null;
        contact?: string | null;
        contactphone?: string | null;
        notes?: string | null;
        eta?: string | null;
        opening?: string | null;
        closing?: string | null;
        service?: Array<Service | null> | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    cargo?: {
      __typename: "ModelCargoConnection";
      items: Array<{
        __typename: "Cargo";
        id: string;
        reference?: string | null;
        category?: string | null;
        packaging?: Packaging | null;
        quantity?: number | null;
        weight?: number | null;
        height?: number | null;
        length?: number | null;
        width?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
  } | null;
};

export type CreateCargoMutation = {
  __typename: "Cargo";
  id: string;
  reference?: string | null;
  category?: string | null;
  packaging?: Packaging | null;
  quantity?: number | null;
  weight?: number | null;
  height?: number | null;
  length?: number | null;
  width?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  transport?: {
    __typename: "Transport";
    id: string;
    customer?: string | null;
    price?: number | null;
    costofgoods?: number | null;
    distance?: number | null;
    trailer?: Array<Trailer | null> | null;
    attributes?: Array<Feature | null> | null;
    straps?: number | null;
    eta?: string | null;
    notes?: string | null;
    reference?: string | null;
    travelTime?: number | null;
    services?: Array<Service | null> | null;
    adr?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    order?: {
      __typename: "Order";
      id: string;
      customer: string;
      reference: string;
      comment?: string | null;
      price?: number | null;
      date?: string | null;
      status?: Status | null;
      payment?: PaymentType | null;
      termOfPayment?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "ModelTransportConnection";
        items: Array<{
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      event?: {
        __typename: "ModelEventConnection";
        items: Array<{
          __typename: "Event";
          id: string;
          type?: EventType | null;
          time?: string | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      truck?: {
        __typename: "Truck";
        id: string;
        name?: string | null;
        plate?: string | null;
        trailerPlate?: string | null;
        location?: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        } | null;
        ignitionState?: boolean | null;
        deviceID?: string | null;
        lastSignalTime?: string | null;
        heading?: number | null;
        speed?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null;
    route?: {
      __typename: "ModelWaypointConnection";
      items: Array<{
        __typename: "Waypoint";
        id: string;
        sequence: number;
        location: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        };
        name?: string | null;
        address?: string | null;
        housenumber?: string | null;
        street?: string | null;
        zip?: string | null;
        city?: string | null;
        country?: string | null;
        reference?: string | null;
        contact?: string | null;
        contactphone?: string | null;
        notes?: string | null;
        eta?: string | null;
        opening?: string | null;
        closing?: string | null;
        service?: Array<Service | null> | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    cargo?: {
      __typename: "ModelCargoConnection";
      items: Array<{
        __typename: "Cargo";
        id: string;
        reference?: string | null;
        category?: string | null;
        packaging?: Packaging | null;
        quantity?: number | null;
        weight?: number | null;
        height?: number | null;
        length?: number | null;
        width?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
  } | null;
};

export type UpdateCargoMutation = {
  __typename: "Cargo";
  id: string;
  reference?: string | null;
  category?: string | null;
  packaging?: Packaging | null;
  quantity?: number | null;
  weight?: number | null;
  height?: number | null;
  length?: number | null;
  width?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  transport?: {
    __typename: "Transport";
    id: string;
    customer?: string | null;
    price?: number | null;
    costofgoods?: number | null;
    distance?: number | null;
    trailer?: Array<Trailer | null> | null;
    attributes?: Array<Feature | null> | null;
    straps?: number | null;
    eta?: string | null;
    notes?: string | null;
    reference?: string | null;
    travelTime?: number | null;
    services?: Array<Service | null> | null;
    adr?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    order?: {
      __typename: "Order";
      id: string;
      customer: string;
      reference: string;
      comment?: string | null;
      price?: number | null;
      date?: string | null;
      status?: Status | null;
      payment?: PaymentType | null;
      termOfPayment?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "ModelTransportConnection";
        items: Array<{
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      event?: {
        __typename: "ModelEventConnection";
        items: Array<{
          __typename: "Event";
          id: string;
          type?: EventType | null;
          time?: string | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      truck?: {
        __typename: "Truck";
        id: string;
        name?: string | null;
        plate?: string | null;
        trailerPlate?: string | null;
        location?: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        } | null;
        ignitionState?: boolean | null;
        deviceID?: string | null;
        lastSignalTime?: string | null;
        heading?: number | null;
        speed?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null;
    route?: {
      __typename: "ModelWaypointConnection";
      items: Array<{
        __typename: "Waypoint";
        id: string;
        sequence: number;
        location: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        };
        name?: string | null;
        address?: string | null;
        housenumber?: string | null;
        street?: string | null;
        zip?: string | null;
        city?: string | null;
        country?: string | null;
        reference?: string | null;
        contact?: string | null;
        contactphone?: string | null;
        notes?: string | null;
        eta?: string | null;
        opening?: string | null;
        closing?: string | null;
        service?: Array<Service | null> | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    cargo?: {
      __typename: "ModelCargoConnection";
      items: Array<{
        __typename: "Cargo";
        id: string;
        reference?: string | null;
        category?: string | null;
        packaging?: Packaging | null;
        quantity?: number | null;
        weight?: number | null;
        height?: number | null;
        length?: number | null;
        width?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
  } | null;
};

export type DeleteCargoMutation = {
  __typename: "Cargo";
  id: string;
  reference?: string | null;
  category?: string | null;
  packaging?: Packaging | null;
  quantity?: number | null;
  weight?: number | null;
  height?: number | null;
  length?: number | null;
  width?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  transport?: {
    __typename: "Transport";
    id: string;
    customer?: string | null;
    price?: number | null;
    costofgoods?: number | null;
    distance?: number | null;
    trailer?: Array<Trailer | null> | null;
    attributes?: Array<Feature | null> | null;
    straps?: number | null;
    eta?: string | null;
    notes?: string | null;
    reference?: string | null;
    travelTime?: number | null;
    services?: Array<Service | null> | null;
    adr?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    order?: {
      __typename: "Order";
      id: string;
      customer: string;
      reference: string;
      comment?: string | null;
      price?: number | null;
      date?: string | null;
      status?: Status | null;
      payment?: PaymentType | null;
      termOfPayment?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "ModelTransportConnection";
        items: Array<{
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      event?: {
        __typename: "ModelEventConnection";
        items: Array<{
          __typename: "Event";
          id: string;
          type?: EventType | null;
          time?: string | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      truck?: {
        __typename: "Truck";
        id: string;
        name?: string | null;
        plate?: string | null;
        trailerPlate?: string | null;
        location?: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        } | null;
        ignitionState?: boolean | null;
        deviceID?: string | null;
        lastSignalTime?: string | null;
        heading?: number | null;
        speed?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null;
    route?: {
      __typename: "ModelWaypointConnection";
      items: Array<{
        __typename: "Waypoint";
        id: string;
        sequence: number;
        location: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        };
        name?: string | null;
        address?: string | null;
        housenumber?: string | null;
        street?: string | null;
        zip?: string | null;
        city?: string | null;
        country?: string | null;
        reference?: string | null;
        contact?: string | null;
        contactphone?: string | null;
        notes?: string | null;
        eta?: string | null;
        opening?: string | null;
        closing?: string | null;
        service?: Array<Service | null> | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    cargo?: {
      __typename: "ModelCargoConnection";
      items: Array<{
        __typename: "Cargo";
        id: string;
        reference?: string | null;
        category?: string | null;
        packaging?: Packaging | null;
        quantity?: number | null;
        weight?: number | null;
        height?: number | null;
        length?: number | null;
        width?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
  } | null;
};

export type GetTransportQuery = {
  __typename: "Transport";
  id: string;
  customer?: string | null;
  price?: number | null;
  costofgoods?: number | null;
  distance?: number | null;
  trailer?: Array<Trailer | null> | null;
  attributes?: Array<Feature | null> | null;
  straps?: number | null;
  eta?: string | null;
  notes?: string | null;
  reference?: string | null;
  travelTime?: number | null;
  services?: Array<Service | null> | null;
  adr?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  order?: {
    __typename: "Order";
    id: string;
    customer: string;
    reference: string;
    comment?: string | null;
    price?: number | null;
    date?: string | null;
    status?: Status | null;
    payment?: PaymentType | null;
    termOfPayment?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "ModelTransportConnection";
      items: Array<{
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    event?: {
      __typename: "ModelEventConnection";
      items: Array<{
        __typename: "Event";
        id: string;
        type?: EventType | null;
        time?: string | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    truck?: {
      __typename: "Truck";
      id: string;
      name?: string | null;
      plate?: string | null;
      trailerPlate?: string | null;
      location?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      ignitionState?: boolean | null;
      deviceID?: string | null;
      lastSignalTime?: string | null;
      heading?: number | null;
      speed?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
  route?: {
    __typename: "ModelWaypointConnection";
    items: Array<{
      __typename: "Waypoint";
      id: string;
      sequence: number;
      location: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      };
      name?: string | null;
      address?: string | null;
      housenumber?: string | null;
      street?: string | null;
      zip?: string | null;
      city?: string | null;
      country?: string | null;
      reference?: string | null;
      contact?: string | null;
      contactphone?: string | null;
      notes?: string | null;
      eta?: string | null;
      opening?: string | null;
      closing?: string | null;
      service?: Array<Service | null> | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  cargo?: {
    __typename: "ModelCargoConnection";
    items: Array<{
      __typename: "Cargo";
      id: string;
      reference?: string | null;
      category?: string | null;
      packaging?: Packaging | null;
      quantity?: number | null;
      weight?: number | null;
      height?: number | null;
      length?: number | null;
      width?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
};

export type ListTransportsQuery = {
  __typename: "ModelTransportConnection";
  items: Array<{
    __typename: "Transport";
    id: string;
    customer?: string | null;
    price?: number | null;
    costofgoods?: number | null;
    distance?: number | null;
    trailer?: Array<Trailer | null> | null;
    attributes?: Array<Feature | null> | null;
    straps?: number | null;
    eta?: string | null;
    notes?: string | null;
    reference?: string | null;
    travelTime?: number | null;
    services?: Array<Service | null> | null;
    adr?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    order?: {
      __typename: "Order";
      id: string;
      customer: string;
      reference: string;
      comment?: string | null;
      price?: number | null;
      date?: string | null;
      status?: Status | null;
      payment?: PaymentType | null;
      termOfPayment?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "ModelTransportConnection";
        items: Array<{
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      event?: {
        __typename: "ModelEventConnection";
        items: Array<{
          __typename: "Event";
          id: string;
          type?: EventType | null;
          time?: string | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      truck?: {
        __typename: "Truck";
        id: string;
        name?: string | null;
        plate?: string | null;
        trailerPlate?: string | null;
        location?: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        } | null;
        ignitionState?: boolean | null;
        deviceID?: string | null;
        lastSignalTime?: string | null;
        heading?: number | null;
        speed?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null;
    route?: {
      __typename: "ModelWaypointConnection";
      items: Array<{
        __typename: "Waypoint";
        id: string;
        sequence: number;
        location: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        };
        name?: string | null;
        address?: string | null;
        housenumber?: string | null;
        street?: string | null;
        zip?: string | null;
        city?: string | null;
        country?: string | null;
        reference?: string | null;
        contact?: string | null;
        contactphone?: string | null;
        notes?: string | null;
        eta?: string | null;
        opening?: string | null;
        closing?: string | null;
        service?: Array<Service | null> | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    cargo?: {
      __typename: "ModelCargoConnection";
      items: Array<{
        __typename: "Cargo";
        id: string;
        reference?: string | null;
        category?: string | null;
        packaging?: Packaging | null;
        quantity?: number | null;
        weight?: number | null;
        height?: number | null;
        length?: number | null;
        width?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncTransportsQuery = {
  __typename: "ModelTransportConnection";
  items: Array<{
    __typename: "Transport";
    id: string;
    customer?: string | null;
    price?: number | null;
    costofgoods?: number | null;
    distance?: number | null;
    trailer?: Array<Trailer | null> | null;
    attributes?: Array<Feature | null> | null;
    straps?: number | null;
    eta?: string | null;
    notes?: string | null;
    reference?: string | null;
    travelTime?: number | null;
    services?: Array<Service | null> | null;
    adr?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    order?: {
      __typename: "Order";
      id: string;
      customer: string;
      reference: string;
      comment?: string | null;
      price?: number | null;
      date?: string | null;
      status?: Status | null;
      payment?: PaymentType | null;
      termOfPayment?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "ModelTransportConnection";
        items: Array<{
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      event?: {
        __typename: "ModelEventConnection";
        items: Array<{
          __typename: "Event";
          id: string;
          type?: EventType | null;
          time?: string | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      truck?: {
        __typename: "Truck";
        id: string;
        name?: string | null;
        plate?: string | null;
        trailerPlate?: string | null;
        location?: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        } | null;
        ignitionState?: boolean | null;
        deviceID?: string | null;
        lastSignalTime?: string | null;
        heading?: number | null;
        speed?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null;
    route?: {
      __typename: "ModelWaypointConnection";
      items: Array<{
        __typename: "Waypoint";
        id: string;
        sequence: number;
        location: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        };
        name?: string | null;
        address?: string | null;
        housenumber?: string | null;
        street?: string | null;
        zip?: string | null;
        city?: string | null;
        country?: string | null;
        reference?: string | null;
        contact?: string | null;
        contactphone?: string | null;
        notes?: string | null;
        eta?: string | null;
        opening?: string | null;
        closing?: string | null;
        service?: Array<Service | null> | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    cargo?: {
      __typename: "ModelCargoConnection";
      items: Array<{
        __typename: "Cargo";
        id: string;
        reference?: string | null;
        category?: string | null;
        packaging?: Packaging | null;
        quantity?: number | null;
        weight?: number | null;
        height?: number | null;
        length?: number | null;
        width?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetOrderQuery = {
  __typename: "Order";
  id: string;
  customer: string;
  reference: string;
  comment?: string | null;
  price?: number | null;
  date?: string | null;
  status?: Status | null;
  payment?: PaymentType | null;
  termOfPayment?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  transport?: {
    __typename: "ModelTransportConnection";
    items: Array<{
      __typename: "Transport";
      id: string;
      customer?: string | null;
      price?: number | null;
      costofgoods?: number | null;
      distance?: number | null;
      trailer?: Array<Trailer | null> | null;
      attributes?: Array<Feature | null> | null;
      straps?: number | null;
      eta?: string | null;
      notes?: string | null;
      reference?: string | null;
      travelTime?: number | null;
      services?: Array<Service | null> | null;
      adr?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      order?: {
        __typename: "Order";
        id: string;
        customer: string;
        reference: string;
        comment?: string | null;
        price?: number | null;
        date?: string | null;
        status?: Status | null;
        payment?: PaymentType | null;
        termOfPayment?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "ModelTransportConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        event?: {
          __typename: "ModelEventConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        truck?: {
          __typename: "Truck";
          id: string;
          name?: string | null;
          plate?: string | null;
          trailerPlate?: string | null;
          ignitionState?: boolean | null;
          deviceID?: string | null;
          lastSignalTime?: string | null;
          heading?: number | null;
          speed?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null;
      route?: {
        __typename: "ModelWaypointConnection";
        items: Array<{
          __typename: "Waypoint";
          id: string;
          sequence: number;
          name?: string | null;
          address?: string | null;
          housenumber?: string | null;
          street?: string | null;
          zip?: string | null;
          city?: string | null;
          country?: string | null;
          reference?: string | null;
          contact?: string | null;
          contactphone?: string | null;
          notes?: string | null;
          eta?: string | null;
          opening?: string | null;
          closing?: string | null;
          service?: Array<Service | null> | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      cargo?: {
        __typename: "ModelCargoConnection";
        items: Array<{
          __typename: "Cargo";
          id: string;
          reference?: string | null;
          category?: string | null;
          packaging?: Packaging | null;
          quantity?: number | null;
          weight?: number | null;
          height?: number | null;
          length?: number | null;
          width?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  event?: {
    __typename: "ModelEventConnection";
    items: Array<{
      __typename: "Event";
      id: string;
      type?: EventType | null;
      time?: string | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      order?: {
        __typename: "Order";
        id: string;
        customer: string;
        reference: string;
        comment?: string | null;
        price?: number | null;
        date?: string | null;
        status?: Status | null;
        payment?: PaymentType | null;
        termOfPayment?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "ModelTransportConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        event?: {
          __typename: "ModelEventConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        truck?: {
          __typename: "Truck";
          id: string;
          name?: string | null;
          plate?: string | null;
          trailerPlate?: string | null;
          ignitionState?: boolean | null;
          deviceID?: string | null;
          lastSignalTime?: string | null;
          heading?: number | null;
          speed?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  truck?: {
    __typename: "Truck";
    id: string;
    name?: string | null;
    plate?: string | null;
    trailerPlate?: string | null;
    location?: {
      __typename: "Location";
      lat?: number | null;
      lon?: number | null;
    } | null;
    ignitionState?: boolean | null;
    deviceID?: string | null;
    lastSignalTime?: string | null;
    heading?: number | null;
    speed?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListOrdersQuery = {
  __typename: "ModelOrderConnection";
  items: Array<{
    __typename: "Order";
    id: string;
    customer: string;
    reference: string;
    comment?: string | null;
    price?: number | null;
    date?: string | null;
    status?: Status | null;
    payment?: PaymentType | null;
    termOfPayment?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "ModelTransportConnection";
      items: Array<{
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    event?: {
      __typename: "ModelEventConnection";
      items: Array<{
        __typename: "Event";
        id: string;
        type?: EventType | null;
        time?: string | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    truck?: {
      __typename: "Truck";
      id: string;
      name?: string | null;
      plate?: string | null;
      trailerPlate?: string | null;
      location?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      ignitionState?: boolean | null;
      deviceID?: string | null;
      lastSignalTime?: string | null;
      heading?: number | null;
      speed?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type OrderByDateAndStatusQuery = {
  __typename: "ModelOrderConnection";
  items: Array<{
    __typename: "Order";
    id: string;
    customer: string;
    reference: string;
    comment?: string | null;
    price?: number | null;
    date?: string | null;
    status?: Status | null;
    payment?: PaymentType | null;
    termOfPayment?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "ModelTransportConnection";
      items: Array<{
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    event?: {
      __typename: "ModelEventConnection";
      items: Array<{
        __typename: "Event";
        id: string;
        type?: EventType | null;
        time?: string | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    truck?: {
      __typename: "Truck";
      id: string;
      name?: string | null;
      plate?: string | null;
      trailerPlate?: string | null;
      location?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      ignitionState?: boolean | null;
      deviceID?: string | null;
      lastSignalTime?: string | null;
      heading?: number | null;
      speed?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type OrderByCustomerQuery = {
  __typename: "ModelOrderConnection";
  items: Array<{
    __typename: "Order";
    id: string;
    customer: string;
    reference: string;
    comment?: string | null;
    price?: number | null;
    date?: string | null;
    status?: Status | null;
    payment?: PaymentType | null;
    termOfPayment?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "ModelTransportConnection";
      items: Array<{
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    event?: {
      __typename: "ModelEventConnection";
      items: Array<{
        __typename: "Event";
        id: string;
        type?: EventType | null;
        time?: string | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    truck?: {
      __typename: "Truck";
      id: string;
      name?: string | null;
      plate?: string | null;
      trailerPlate?: string | null;
      location?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      ignitionState?: boolean | null;
      deviceID?: string | null;
      lastSignalTime?: string | null;
      heading?: number | null;
      speed?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncOrdersQuery = {
  __typename: "ModelOrderConnection";
  items: Array<{
    __typename: "Order";
    id: string;
    customer: string;
    reference: string;
    comment?: string | null;
    price?: number | null;
    date?: string | null;
    status?: Status | null;
    payment?: PaymentType | null;
    termOfPayment?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "ModelTransportConnection";
      items: Array<{
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    event?: {
      __typename: "ModelEventConnection";
      items: Array<{
        __typename: "Event";
        id: string;
        type?: EventType | null;
        time?: string | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    truck?: {
      __typename: "Truck";
      id: string;
      name?: string | null;
      plate?: string | null;
      trailerPlate?: string | null;
      location?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      ignitionState?: boolean | null;
      deviceID?: string | null;
      lastSignalTime?: string | null;
      heading?: number | null;
      speed?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetEventQuery = {
  __typename: "Event";
  id: string;
  type?: EventType | null;
  time?: string | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  order?: {
    __typename: "Order";
    id: string;
    customer: string;
    reference: string;
    comment?: string | null;
    price?: number | null;
    date?: string | null;
    status?: Status | null;
    payment?: PaymentType | null;
    termOfPayment?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "ModelTransportConnection";
      items: Array<{
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    event?: {
      __typename: "ModelEventConnection";
      items: Array<{
        __typename: "Event";
        id: string;
        type?: EventType | null;
        time?: string | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    truck?: {
      __typename: "Truck";
      id: string;
      name?: string | null;
      plate?: string | null;
      trailerPlate?: string | null;
      location?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      ignitionState?: boolean | null;
      deviceID?: string | null;
      lastSignalTime?: string | null;
      heading?: number | null;
      speed?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
};

export type ListEventsQuery = {
  __typename: "ModelEventConnection";
  items: Array<{
    __typename: "Event";
    id: string;
    type?: EventType | null;
    time?: string | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    order?: {
      __typename: "Order";
      id: string;
      customer: string;
      reference: string;
      comment?: string | null;
      price?: number | null;
      date?: string | null;
      status?: Status | null;
      payment?: PaymentType | null;
      termOfPayment?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "ModelTransportConnection";
        items: Array<{
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      event?: {
        __typename: "ModelEventConnection";
        items: Array<{
          __typename: "Event";
          id: string;
          type?: EventType | null;
          time?: string | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      truck?: {
        __typename: "Truck";
        id: string;
        name?: string | null;
        plate?: string | null;
        trailerPlate?: string | null;
        location?: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        } | null;
        ignitionState?: boolean | null;
        deviceID?: string | null;
        lastSignalTime?: string | null;
        heading?: number | null;
        speed?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncEventsQuery = {
  __typename: "ModelEventConnection";
  items: Array<{
    __typename: "Event";
    id: string;
    type?: EventType | null;
    time?: string | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    order?: {
      __typename: "Order";
      id: string;
      customer: string;
      reference: string;
      comment?: string | null;
      price?: number | null;
      date?: string | null;
      status?: Status | null;
      payment?: PaymentType | null;
      termOfPayment?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "ModelTransportConnection";
        items: Array<{
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      event?: {
        __typename: "ModelEventConnection";
        items: Array<{
          __typename: "Event";
          id: string;
          type?: EventType | null;
          time?: string | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      truck?: {
        __typename: "Truck";
        id: string;
        name?: string | null;
        plate?: string | null;
        trailerPlate?: string | null;
        location?: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        } | null;
        ignitionState?: boolean | null;
        deviceID?: string | null;
        lastSignalTime?: string | null;
        heading?: number | null;
        speed?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetTruckQuery = {
  __typename: "Truck";
  id: string;
  name?: string | null;
  plate?: string | null;
  trailerPlate?: string | null;
  location?: {
    __typename: "Location";
    lat?: number | null;
    lon?: number | null;
  } | null;
  ignitionState?: boolean | null;
  deviceID?: string | null;
  lastSignalTime?: string | null;
  heading?: number | null;
  speed?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
};

export type ListTrucksQuery = {
  __typename: "ModelTruckConnection";
  items: Array<{
    __typename: "Truck";
    id: string;
    name?: string | null;
    plate?: string | null;
    trailerPlate?: string | null;
    location?: {
      __typename: "Location";
      lat?: number | null;
      lon?: number | null;
    } | null;
    ignitionState?: boolean | null;
    deviceID?: string | null;
    lastSignalTime?: string | null;
    heading?: number | null;
    speed?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type TrucksByDateQuery = {
  __typename: "ModelTruckConnection";
  items: Array<{
    __typename: "Truck";
    id: string;
    name?: string | null;
    plate?: string | null;
    trailerPlate?: string | null;
    location?: {
      __typename: "Location";
      lat?: number | null;
      lon?: number | null;
    } | null;
    ignitionState?: boolean | null;
    deviceID?: string | null;
    lastSignalTime?: string | null;
    heading?: number | null;
    speed?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type TruckByDateAndDeviceIDQuery = {
  __typename: "ModelTruckConnection";
  items: Array<{
    __typename: "Truck";
    id: string;
    name?: string | null;
    plate?: string | null;
    trailerPlate?: string | null;
    location?: {
      __typename: "Location";
      lat?: number | null;
      lon?: number | null;
    } | null;
    ignitionState?: boolean | null;
    deviceID?: string | null;
    lastSignalTime?: string | null;
    heading?: number | null;
    speed?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncTrucksQuery = {
  __typename: "ModelTruckConnection";
  items: Array<{
    __typename: "Truck";
    id: string;
    name?: string | null;
    plate?: string | null;
    trailerPlate?: string | null;
    location?: {
      __typename: "Location";
      lat?: number | null;
      lon?: number | null;
    } | null;
    ignitionState?: boolean | null;
    deviceID?: string | null;
    lastSignalTime?: string | null;
    heading?: number | null;
    speed?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetWaypointQuery = {
  __typename: "Waypoint";
  id: string;
  sequence: number;
  location: {
    __typename: "Location";
    lat?: number | null;
    lon?: number | null;
  };
  name?: string | null;
  address?: string | null;
  housenumber?: string | null;
  street?: string | null;
  zip?: string | null;
  city?: string | null;
  country?: string | null;
  reference?: string | null;
  contact?: string | null;
  contactphone?: string | null;
  notes?: string | null;
  eta?: string | null;
  opening?: string | null;
  closing?: string | null;
  service?: Array<Service | null> | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  transport?: {
    __typename: "Transport";
    id: string;
    customer?: string | null;
    price?: number | null;
    costofgoods?: number | null;
    distance?: number | null;
    trailer?: Array<Trailer | null> | null;
    attributes?: Array<Feature | null> | null;
    straps?: number | null;
    eta?: string | null;
    notes?: string | null;
    reference?: string | null;
    travelTime?: number | null;
    services?: Array<Service | null> | null;
    adr?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    order?: {
      __typename: "Order";
      id: string;
      customer: string;
      reference: string;
      comment?: string | null;
      price?: number | null;
      date?: string | null;
      status?: Status | null;
      payment?: PaymentType | null;
      termOfPayment?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "ModelTransportConnection";
        items: Array<{
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      event?: {
        __typename: "ModelEventConnection";
        items: Array<{
          __typename: "Event";
          id: string;
          type?: EventType | null;
          time?: string | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      truck?: {
        __typename: "Truck";
        id: string;
        name?: string | null;
        plate?: string | null;
        trailerPlate?: string | null;
        location?: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        } | null;
        ignitionState?: boolean | null;
        deviceID?: string | null;
        lastSignalTime?: string | null;
        heading?: number | null;
        speed?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null;
    route?: {
      __typename: "ModelWaypointConnection";
      items: Array<{
        __typename: "Waypoint";
        id: string;
        sequence: number;
        location: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        };
        name?: string | null;
        address?: string | null;
        housenumber?: string | null;
        street?: string | null;
        zip?: string | null;
        city?: string | null;
        country?: string | null;
        reference?: string | null;
        contact?: string | null;
        contactphone?: string | null;
        notes?: string | null;
        eta?: string | null;
        opening?: string | null;
        closing?: string | null;
        service?: Array<Service | null> | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    cargo?: {
      __typename: "ModelCargoConnection";
      items: Array<{
        __typename: "Cargo";
        id: string;
        reference?: string | null;
        category?: string | null;
        packaging?: Packaging | null;
        quantity?: number | null;
        weight?: number | null;
        height?: number | null;
        length?: number | null;
        width?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
  } | null;
};

export type ListWaypointsQuery = {
  __typename: "ModelWaypointConnection";
  items: Array<{
    __typename: "Waypoint";
    id: string;
    sequence: number;
    location: {
      __typename: "Location";
      lat?: number | null;
      lon?: number | null;
    };
    name?: string | null;
    address?: string | null;
    housenumber?: string | null;
    street?: string | null;
    zip?: string | null;
    city?: string | null;
    country?: string | null;
    reference?: string | null;
    contact?: string | null;
    contactphone?: string | null;
    notes?: string | null;
    eta?: string | null;
    opening?: string | null;
    closing?: string | null;
    service?: Array<Service | null> | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "Transport";
      id: string;
      customer?: string | null;
      price?: number | null;
      costofgoods?: number | null;
      distance?: number | null;
      trailer?: Array<Trailer | null> | null;
      attributes?: Array<Feature | null> | null;
      straps?: number | null;
      eta?: string | null;
      notes?: string | null;
      reference?: string | null;
      travelTime?: number | null;
      services?: Array<Service | null> | null;
      adr?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      order?: {
        __typename: "Order";
        id: string;
        customer: string;
        reference: string;
        comment?: string | null;
        price?: number | null;
        date?: string | null;
        status?: Status | null;
        payment?: PaymentType | null;
        termOfPayment?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "ModelTransportConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        event?: {
          __typename: "ModelEventConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        truck?: {
          __typename: "Truck";
          id: string;
          name?: string | null;
          plate?: string | null;
          trailerPlate?: string | null;
          ignitionState?: boolean | null;
          deviceID?: string | null;
          lastSignalTime?: string | null;
          heading?: number | null;
          speed?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null;
      route?: {
        __typename: "ModelWaypointConnection";
        items: Array<{
          __typename: "Waypoint";
          id: string;
          sequence: number;
          name?: string | null;
          address?: string | null;
          housenumber?: string | null;
          street?: string | null;
          zip?: string | null;
          city?: string | null;
          country?: string | null;
          reference?: string | null;
          contact?: string | null;
          contactphone?: string | null;
          notes?: string | null;
          eta?: string | null;
          opening?: string | null;
          closing?: string | null;
          service?: Array<Service | null> | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      cargo?: {
        __typename: "ModelCargoConnection";
        items: Array<{
          __typename: "Cargo";
          id: string;
          reference?: string | null;
          category?: string | null;
          packaging?: Packaging | null;
          quantity?: number | null;
          weight?: number | null;
          height?: number | null;
          length?: number | null;
          width?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncWaypointsQuery = {
  __typename: "ModelWaypointConnection";
  items: Array<{
    __typename: "Waypoint";
    id: string;
    sequence: number;
    location: {
      __typename: "Location";
      lat?: number | null;
      lon?: number | null;
    };
    name?: string | null;
    address?: string | null;
    housenumber?: string | null;
    street?: string | null;
    zip?: string | null;
    city?: string | null;
    country?: string | null;
    reference?: string | null;
    contact?: string | null;
    contactphone?: string | null;
    notes?: string | null;
    eta?: string | null;
    opening?: string | null;
    closing?: string | null;
    service?: Array<Service | null> | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "Transport";
      id: string;
      customer?: string | null;
      price?: number | null;
      costofgoods?: number | null;
      distance?: number | null;
      trailer?: Array<Trailer | null> | null;
      attributes?: Array<Feature | null> | null;
      straps?: number | null;
      eta?: string | null;
      notes?: string | null;
      reference?: string | null;
      travelTime?: number | null;
      services?: Array<Service | null> | null;
      adr?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      order?: {
        __typename: "Order";
        id: string;
        customer: string;
        reference: string;
        comment?: string | null;
        price?: number | null;
        date?: string | null;
        status?: Status | null;
        payment?: PaymentType | null;
        termOfPayment?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "ModelTransportConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        event?: {
          __typename: "ModelEventConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        truck?: {
          __typename: "Truck";
          id: string;
          name?: string | null;
          plate?: string | null;
          trailerPlate?: string | null;
          ignitionState?: boolean | null;
          deviceID?: string | null;
          lastSignalTime?: string | null;
          heading?: number | null;
          speed?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null;
      route?: {
        __typename: "ModelWaypointConnection";
        items: Array<{
          __typename: "Waypoint";
          id: string;
          sequence: number;
          name?: string | null;
          address?: string | null;
          housenumber?: string | null;
          street?: string | null;
          zip?: string | null;
          city?: string | null;
          country?: string | null;
          reference?: string | null;
          contact?: string | null;
          contactphone?: string | null;
          notes?: string | null;
          eta?: string | null;
          opening?: string | null;
          closing?: string | null;
          service?: Array<Service | null> | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      cargo?: {
        __typename: "ModelCargoConnection";
        items: Array<{
          __typename: "Cargo";
          id: string;
          reference?: string | null;
          category?: string | null;
          packaging?: Packaging | null;
          quantity?: number | null;
          weight?: number | null;
          height?: number | null;
          length?: number | null;
          width?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetCargoQuery = {
  __typename: "Cargo";
  id: string;
  reference?: string | null;
  category?: string | null;
  packaging?: Packaging | null;
  quantity?: number | null;
  weight?: number | null;
  height?: number | null;
  length?: number | null;
  width?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  transport?: {
    __typename: "Transport";
    id: string;
    customer?: string | null;
    price?: number | null;
    costofgoods?: number | null;
    distance?: number | null;
    trailer?: Array<Trailer | null> | null;
    attributes?: Array<Feature | null> | null;
    straps?: number | null;
    eta?: string | null;
    notes?: string | null;
    reference?: string | null;
    travelTime?: number | null;
    services?: Array<Service | null> | null;
    adr?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    order?: {
      __typename: "Order";
      id: string;
      customer: string;
      reference: string;
      comment?: string | null;
      price?: number | null;
      date?: string | null;
      status?: Status | null;
      payment?: PaymentType | null;
      termOfPayment?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "ModelTransportConnection";
        items: Array<{
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      event?: {
        __typename: "ModelEventConnection";
        items: Array<{
          __typename: "Event";
          id: string;
          type?: EventType | null;
          time?: string | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      truck?: {
        __typename: "Truck";
        id: string;
        name?: string | null;
        plate?: string | null;
        trailerPlate?: string | null;
        location?: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        } | null;
        ignitionState?: boolean | null;
        deviceID?: string | null;
        lastSignalTime?: string | null;
        heading?: number | null;
        speed?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null;
    route?: {
      __typename: "ModelWaypointConnection";
      items: Array<{
        __typename: "Waypoint";
        id: string;
        sequence: number;
        location: {
          __typename: "Location";
          lat?: number | null;
          lon?: number | null;
        };
        name?: string | null;
        address?: string | null;
        housenumber?: string | null;
        street?: string | null;
        zip?: string | null;
        city?: string | null;
        country?: string | null;
        reference?: string | null;
        contact?: string | null;
        contactphone?: string | null;
        notes?: string | null;
        eta?: string | null;
        opening?: string | null;
        closing?: string | null;
        service?: Array<Service | null> | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    cargo?: {
      __typename: "ModelCargoConnection";
      items: Array<{
        __typename: "Cargo";
        id: string;
        reference?: string | null;
        category?: string | null;
        packaging?: Packaging | null;
        quantity?: number | null;
        weight?: number | null;
        height?: number | null;
        length?: number | null;
        width?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "Transport";
          id: string;
          customer?: string | null;
          price?: number | null;
          costofgoods?: number | null;
          distance?: number | null;
          trailer?: Array<Trailer | null> | null;
          attributes?: Array<Feature | null> | null;
          straps?: number | null;
          eta?: string | null;
          notes?: string | null;
          reference?: string | null;
          travelTime?: number | null;
          services?: Array<Service | null> | null;
          adr?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
  } | null;
};

export type ListCargosQuery = {
  __typename: "ModelCargoConnection";
  items: Array<{
    __typename: "Cargo";
    id: string;
    reference?: string | null;
    category?: string | null;
    packaging?: Packaging | null;
    quantity?: number | null;
    weight?: number | null;
    height?: number | null;
    length?: number | null;
    width?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "Transport";
      id: string;
      customer?: string | null;
      price?: number | null;
      costofgoods?: number | null;
      distance?: number | null;
      trailer?: Array<Trailer | null> | null;
      attributes?: Array<Feature | null> | null;
      straps?: number | null;
      eta?: string | null;
      notes?: string | null;
      reference?: string | null;
      travelTime?: number | null;
      services?: Array<Service | null> | null;
      adr?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      order?: {
        __typename: "Order";
        id: string;
        customer: string;
        reference: string;
        comment?: string | null;
        price?: number | null;
        date?: string | null;
        status?: Status | null;
        payment?: PaymentType | null;
        termOfPayment?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "ModelTransportConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        event?: {
          __typename: "ModelEventConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        truck?: {
          __typename: "Truck";
          id: string;
          name?: string | null;
          plate?: string | null;
          trailerPlate?: string | null;
          ignitionState?: boolean | null;
          deviceID?: string | null;
          lastSignalTime?: string | null;
          heading?: number | null;
          speed?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null;
      route?: {
        __typename: "ModelWaypointConnection";
        items: Array<{
          __typename: "Waypoint";
          id: string;
          sequence: number;
          name?: string | null;
          address?: string | null;
          housenumber?: string | null;
          street?: string | null;
          zip?: string | null;
          city?: string | null;
          country?: string | null;
          reference?: string | null;
          contact?: string | null;
          contactphone?: string | null;
          notes?: string | null;
          eta?: string | null;
          opening?: string | null;
          closing?: string | null;
          service?: Array<Service | null> | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      cargo?: {
        __typename: "ModelCargoConnection";
        items: Array<{
          __typename: "Cargo";
          id: string;
          reference?: string | null;
          category?: string | null;
          packaging?: Packaging | null;
          quantity?: number | null;
          weight?: number | null;
          height?: number | null;
          length?: number | null;
          width?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncCargosQuery = {
  __typename: "ModelCargoConnection";
  items: Array<{
    __typename: "Cargo";
    id: string;
    reference?: string | null;
    category?: string | null;
    packaging?: Packaging | null;
    quantity?: number | null;
    weight?: number | null;
    height?: number | null;
    length?: number | null;
    width?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "Transport";
      id: string;
      customer?: string | null;
      price?: number | null;
      costofgoods?: number | null;
      distance?: number | null;
      trailer?: Array<Trailer | null> | null;
      attributes?: Array<Feature | null> | null;
      straps?: number | null;
      eta?: string | null;
      notes?: string | null;
      reference?: string | null;
      travelTime?: number | null;
      services?: Array<Service | null> | null;
      adr?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      order?: {
        __typename: "Order";
        id: string;
        customer: string;
        reference: string;
        comment?: string | null;
        price?: number | null;
        date?: string | null;
        status?: Status | null;
        payment?: PaymentType | null;
        termOfPayment?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "ModelTransportConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        event?: {
          __typename: "ModelEventConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        truck?: {
          __typename: "Truck";
          id: string;
          name?: string | null;
          plate?: string | null;
          trailerPlate?: string | null;
          ignitionState?: boolean | null;
          deviceID?: string | null;
          lastSignalTime?: string | null;
          heading?: number | null;
          speed?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null;
      route?: {
        __typename: "ModelWaypointConnection";
        items: Array<{
          __typename: "Waypoint";
          id: string;
          sequence: number;
          name?: string | null;
          address?: string | null;
          housenumber?: string | null;
          street?: string | null;
          zip?: string | null;
          city?: string | null;
          country?: string | null;
          reference?: string | null;
          contact?: string | null;
          contactphone?: string | null;
          notes?: string | null;
          eta?: string | null;
          opening?: string | null;
          closing?: string | null;
          service?: Array<Service | null> | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      cargo?: {
        __typename: "ModelCargoConnection";
        items: Array<{
          __typename: "Cargo";
          id: string;
          reference?: string | null;
          category?: string | null;
          packaging?: Packaging | null;
          quantity?: number | null;
          weight?: number | null;
          height?: number | null;
          length?: number | null;
          width?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type OnCreateTransportSubscription = {
  __typename: "Transport";
  id: string;
  customer?: string | null;
  price?: number | null;
  costofgoods?: number | null;
  distance?: number | null;
  trailer?: Array<Trailer | null> | null;
  attributes?: Array<Feature | null> | null;
  straps?: number | null;
  eta?: string | null;
  notes?: string | null;
  reference?: string | null;
  travelTime?: number | null;
  services?: Array<Service | null> | null;
  adr?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  order?: {
    __typename: "Order";
    id: string;
    customer: string;
    reference: string;
    comment?: string | null;
    price?: number | null;
    date?: string | null;
    status?: Status | null;
    payment?: PaymentType | null;
    termOfPayment?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "ModelTransportConnection";
      items: Array<{
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    event?: {
      __typename: "ModelEventConnection";
      items: Array<{
        __typename: "Event";
        id: string;
        type?: EventType | null;
        time?: string | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    truck?: {
      __typename: "Truck";
      id: string;
      name?: string | null;
      plate?: string | null;
      trailerPlate?: string | null;
      location?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      ignitionState?: boolean | null;
      deviceID?: string | null;
      lastSignalTime?: string | null;
      heading?: number | null;
      speed?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
  route?: {
    __typename: "ModelWaypointConnection";
    items: Array<{
      __typename: "Waypoint";
      id: string;
      sequence: number;
      location: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      };
      name?: string | null;
      address?: string | null;
      housenumber?: string | null;
      street?: string | null;
      zip?: string | null;
      city?: string | null;
      country?: string | null;
      reference?: string | null;
      contact?: string | null;
      contactphone?: string | null;
      notes?: string | null;
      eta?: string | null;
      opening?: string | null;
      closing?: string | null;
      service?: Array<Service | null> | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  cargo?: {
    __typename: "ModelCargoConnection";
    items: Array<{
      __typename: "Cargo";
      id: string;
      reference?: string | null;
      category?: string | null;
      packaging?: Packaging | null;
      quantity?: number | null;
      weight?: number | null;
      height?: number | null;
      length?: number | null;
      width?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
};

export type OnUpdateTransportSubscription = {
  __typename: "Transport";
  id: string;
  customer?: string | null;
  price?: number | null;
  costofgoods?: number | null;
  distance?: number | null;
  trailer?: Array<Trailer | null> | null;
  attributes?: Array<Feature | null> | null;
  straps?: number | null;
  eta?: string | null;
  notes?: string | null;
  reference?: string | null;
  travelTime?: number | null;
  services?: Array<Service | null> | null;
  adr?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  order?: {
    __typename: "Order";
    id: string;
    customer: string;
    reference: string;
    comment?: string | null;
    price?: number | null;
    date?: string | null;
    status?: Status | null;
    payment?: PaymentType | null;
    termOfPayment?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "ModelTransportConnection";
      items: Array<{
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    event?: {
      __typename: "ModelEventConnection";
      items: Array<{
        __typename: "Event";
        id: string;
        type?: EventType | null;
        time?: string | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    truck?: {
      __typename: "Truck";
      id: string;
      name?: string | null;
      plate?: string | null;
      trailerPlate?: string | null;
      location?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      ignitionState?: boolean | null;
      deviceID?: string | null;
      lastSignalTime?: string | null;
      heading?: number | null;
      speed?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
  route?: {
    __typename: "ModelWaypointConnection";
    items: Array<{
      __typename: "Waypoint";
      id: string;
      sequence: number;
      location: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      };
      name?: string | null;
      address?: string | null;
      housenumber?: string | null;
      street?: string | null;
      zip?: string | null;
      city?: string | null;
      country?: string | null;
      reference?: string | null;
      contact?: string | null;
      contactphone?: string | null;
      notes?: string | null;
      eta?: string | null;
      opening?: string | null;
      closing?: string | null;
      service?: Array<Service | null> | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  cargo?: {
    __typename: "ModelCargoConnection";
    items: Array<{
      __typename: "Cargo";
      id: string;
      reference?: string | null;
      category?: string | null;
      packaging?: Packaging | null;
      quantity?: number | null;
      weight?: number | null;
      height?: number | null;
      length?: number | null;
      width?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
};

export type OnDeleteTransportSubscription = {
  __typename: "Transport";
  id: string;
  customer?: string | null;
  price?: number | null;
  costofgoods?: number | null;
  distance?: number | null;
  trailer?: Array<Trailer | null> | null;
  attributes?: Array<Feature | null> | null;
  straps?: number | null;
  eta?: string | null;
  notes?: string | null;
  reference?: string | null;
  travelTime?: number | null;
  services?: Array<Service | null> | null;
  adr?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  order?: {
    __typename: "Order";
    id: string;
    customer: string;
    reference: string;
    comment?: string | null;
    price?: number | null;
    date?: string | null;
    status?: Status | null;
    payment?: PaymentType | null;
    termOfPayment?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "ModelTransportConnection";
      items: Array<{
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    event?: {
      __typename: "ModelEventConnection";
      items: Array<{
        __typename: "Event";
        id: string;
        type?: EventType | null;
        time?: string | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    truck?: {
      __typename: "Truck";
      id: string;
      name?: string | null;
      plate?: string | null;
      trailerPlate?: string | null;
      location?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      ignitionState?: boolean | null;
      deviceID?: string | null;
      lastSignalTime?: string | null;
      heading?: number | null;
      speed?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
  route?: {
    __typename: "ModelWaypointConnection";
    items: Array<{
      __typename: "Waypoint";
      id: string;
      sequence: number;
      location: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      };
      name?: string | null;
      address?: string | null;
      housenumber?: string | null;
      street?: string | null;
      zip?: string | null;
      city?: string | null;
      country?: string | null;
      reference?: string | null;
      contact?: string | null;
      contactphone?: string | null;
      notes?: string | null;
      eta?: string | null;
      opening?: string | null;
      closing?: string | null;
      service?: Array<Service | null> | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  cargo?: {
    __typename: "ModelCargoConnection";
    items: Array<{
      __typename: "Cargo";
      id: string;
      reference?: string | null;
      category?: string | null;
      packaging?: Packaging | null;
      quantity?: number | null;
      weight?: number | null;
      height?: number | null;
      length?: number | null;
      width?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      transport?: {
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
};

export type OnCreateOrderSubscription = {
  __typename: "Order";
  id: string;
  customer: string;
  reference: string;
  comment?: string | null;
  price?: number | null;
  date?: string | null;
  status?: Status | null;
  payment?: PaymentType | null;
  termOfPayment?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  transport?: {
    __typename: "ModelTransportConnection";
    items: Array<{
      __typename: "Transport";
      id: string;
      customer?: string | null;
      price?: number | null;
      costofgoods?: number | null;
      distance?: number | null;
      trailer?: Array<Trailer | null> | null;
      attributes?: Array<Feature | null> | null;
      straps?: number | null;
      eta?: string | null;
      notes?: string | null;
      reference?: string | null;
      travelTime?: number | null;
      services?: Array<Service | null> | null;
      adr?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      order?: {
        __typename: "Order";
        id: string;
        customer: string;
        reference: string;
        comment?: string | null;
        price?: number | null;
        date?: string | null;
        status?: Status | null;
        payment?: PaymentType | null;
        termOfPayment?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "ModelTransportConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        event?: {
          __typename: "ModelEventConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        truck?: {
          __typename: "Truck";
          id: string;
          name?: string | null;
          plate?: string | null;
          trailerPlate?: string | null;
          ignitionState?: boolean | null;
          deviceID?: string | null;
          lastSignalTime?: string | null;
          heading?: number | null;
          speed?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null;
      route?: {
        __typename: "ModelWaypointConnection";
        items: Array<{
          __typename: "Waypoint";
          id: string;
          sequence: number;
          name?: string | null;
          address?: string | null;
          housenumber?: string | null;
          street?: string | null;
          zip?: string | null;
          city?: string | null;
          country?: string | null;
          reference?: string | null;
          contact?: string | null;
          contactphone?: string | null;
          notes?: string | null;
          eta?: string | null;
          opening?: string | null;
          closing?: string | null;
          service?: Array<Service | null> | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      cargo?: {
        __typename: "ModelCargoConnection";
        items: Array<{
          __typename: "Cargo";
          id: string;
          reference?: string | null;
          category?: string | null;
          packaging?: Packaging | null;
          quantity?: number | null;
          weight?: number | null;
          height?: number | null;
          length?: number | null;
          width?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  event?: {
    __typename: "ModelEventConnection";
    items: Array<{
      __typename: "Event";
      id: string;
      type?: EventType | null;
      time?: string | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      order?: {
        __typename: "Order";
        id: string;
        customer: string;
        reference: string;
        comment?: string | null;
        price?: number | null;
        date?: string | null;
        status?: Status | null;
        payment?: PaymentType | null;
        termOfPayment?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "ModelTransportConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        event?: {
          __typename: "ModelEventConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        truck?: {
          __typename: "Truck";
          id: string;
          name?: string | null;
          plate?: string | null;
          trailerPlate?: string | null;
          ignitionState?: boolean | null;
          deviceID?: string | null;
          lastSignalTime?: string | null;
          heading?: number | null;
          speed?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  truck?: {
    __typename: "Truck";
    id: string;
    name?: string | null;
    plate?: string | null;
    trailerPlate?: string | null;
    location?: {
      __typename: "Location";
      lat?: number | null;
      lon?: number | null;
    } | null;
    ignitionState?: boolean | null;
    deviceID?: string | null;
    lastSignalTime?: string | null;
    heading?: number | null;
    speed?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateOrderSubscription = {
  __typename: "Order";
  id: string;
  customer: string;
  reference: string;
  comment?: string | null;
  price?: number | null;
  date?: string | null;
  status?: Status | null;
  payment?: PaymentType | null;
  termOfPayment?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  transport?: {
    __typename: "ModelTransportConnection";
    items: Array<{
      __typename: "Transport";
      id: string;
      customer?: string | null;
      price?: number | null;
      costofgoods?: number | null;
      distance?: number | null;
      trailer?: Array<Trailer | null> | null;
      attributes?: Array<Feature | null> | null;
      straps?: number | null;
      eta?: string | null;
      notes?: string | null;
      reference?: string | null;
      travelTime?: number | null;
      services?: Array<Service | null> | null;
      adr?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      order?: {
        __typename: "Order";
        id: string;
        customer: string;
        reference: string;
        comment?: string | null;
        price?: number | null;
        date?: string | null;
        status?: Status | null;
        payment?: PaymentType | null;
        termOfPayment?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "ModelTransportConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        event?: {
          __typename: "ModelEventConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        truck?: {
          __typename: "Truck";
          id: string;
          name?: string | null;
          plate?: string | null;
          trailerPlate?: string | null;
          ignitionState?: boolean | null;
          deviceID?: string | null;
          lastSignalTime?: string | null;
          heading?: number | null;
          speed?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null;
      route?: {
        __typename: "ModelWaypointConnection";
        items: Array<{
          __typename: "Waypoint";
          id: string;
          sequence: number;
          name?: string | null;
          address?: string | null;
          housenumber?: string | null;
          street?: string | null;
          zip?: string | null;
          city?: string | null;
          country?: string | null;
          reference?: string | null;
          contact?: string | null;
          contactphone?: string | null;
          notes?: string | null;
          eta?: string | null;
          opening?: string | null;
          closing?: string | null;
          service?: Array<Service | null> | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      cargo?: {
        __typename: "ModelCargoConnection";
        items: Array<{
          __typename: "Cargo";
          id: string;
          reference?: string | null;
          category?: string | null;
          packaging?: Packaging | null;
          quantity?: number | null;
          weight?: number | null;
          height?: number | null;
          length?: number | null;
          width?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  event?: {
    __typename: "ModelEventConnection";
    items: Array<{
      __typename: "Event";
      id: string;
      type?: EventType | null;
      time?: string | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      order?: {
        __typename: "Order";
        id: string;
        customer: string;
        reference: string;
        comment?: string | null;
        price?: number | null;
        date?: string | null;
        status?: Status | null;
        payment?: PaymentType | null;
        termOfPayment?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "ModelTransportConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        event?: {
          __typename: "ModelEventConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        truck?: {
          __typename: "Truck";
          id: string;
          name?: string | null;
          plate?: string | null;
          trailerPlate?: string | null;
          ignitionState?: boolean | null;
          deviceID?: string | null;
          lastSignalTime?: string | null;
          heading?: number | null;
          speed?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  truck?: {
    __typename: "Truck";
    id: string;
    name?: string | null;
    plate?: string | null;
    trailerPlate?: string | null;
    location?: {
      __typename: "Location";
      lat?: number | null;
      lon?: number | null;
    } | null;
    ignitionState?: boolean | null;
    deviceID?: string | null;
    lastSignalTime?: string | null;
    heading?: number | null;
    speed?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteOrderSubscription = {
  __typename: "Order";
  id: string;
  customer: string;
  reference: string;
  comment?: string | null;
  price?: number | null;
  date?: string | null;
  status?: Status | null;
  payment?: PaymentType | null;
  termOfPayment?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  transport?: {
    __typename: "ModelTransportConnection";
    items: Array<{
      __typename: "Transport";
      id: string;
      customer?: string | null;
      price?: number | null;
      costofgoods?: number | null;
      distance?: number | null;
      trailer?: Array<Trailer | null> | null;
      attributes?: Array<Feature | null> | null;
      straps?: number | null;
      eta?: string | null;
      notes?: string | null;
      reference?: string | null;
      travelTime?: number | null;
      services?: Array<Service | null> | null;
      adr?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      order?: {
        __typename: "Order";
        id: string;
        customer: string;
        reference: string;
        comment?: string | null;
        price?: number | null;
        date?: string | null;
        status?: Status | null;
        payment?: PaymentType | null;
        termOfPayment?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "ModelTransportConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        event?: {
          __typename: "ModelEventConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        truck?: {
          __typename: "Truck";
          id: string;
          name?: string | null;
          plate?: string | null;
          trailerPlate?: string | null;
          ignitionState?: boolean | null;
          deviceID?: string | null;
          lastSignalTime?: string | null;
          heading?: number | null;
          speed?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null;
      route?: {
        __typename: "ModelWaypointConnection";
        items: Array<{
          __typename: "Waypoint";
          id: string;
          sequence: number;
          name?: string | null;
          address?: string | null;
          housenumber?: string | null;
          street?: string | null;
          zip?: string | null;
          city?: string | null;
          country?: string | null;
          reference?: string | null;
          contact?: string | null;
          contactphone?: string | null;
          notes?: string | null;
          eta?: string | null;
          opening?: string | null;
          closing?: string | null;
          service?: Array<Service | null> | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
      cargo?: {
        __typename: "ModelCargoConnection";
        items: Array<{
          __typename: "Cargo";
          id: string;
          reference?: string | null;
          category?: string | null;
          packaging?: Packaging | null;
          quantity?: number | null;
          weight?: number | null;
          height?: number | null;
          length?: number | null;
          width?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
        startedAt?: number | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  event?: {
    __typename: "ModelEventConnection";
    items: Array<{
      __typename: "Event";
      id: string;
      type?: EventType | null;
      time?: string | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
      order?: {
        __typename: "Order";
        id: string;
        customer: string;
        reference: string;
        comment?: string | null;
        price?: number | null;
        date?: string | null;
        status?: Status | null;
        payment?: PaymentType | null;
        termOfPayment?: number | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        transport?: {
          __typename: "ModelTransportConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        event?: {
          __typename: "ModelEventConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        truck?: {
          __typename: "Truck";
          id: string;
          name?: string | null;
          plate?: string | null;
          trailerPlate?: string | null;
          ignitionState?: boolean | null;
          deviceID?: string | null;
          lastSignalTime?: string | null;
          heading?: number | null;
          speed?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  truck?: {
    __typename: "Truck";
    id: string;
    name?: string | null;
    plate?: string | null;
    trailerPlate?: string | null;
    location?: {
      __typename: "Location";
      lat?: number | null;
      lon?: number | null;
    } | null;
    ignitionState?: boolean | null;
    deviceID?: string | null;
    lastSignalTime?: string | null;
    heading?: number | null;
    speed?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateEventSubscription = {
  __typename: "Event";
  id: string;
  type?: EventType | null;
  time?: string | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  order?: {
    __typename: "Order";
    id: string;
    customer: string;
    reference: string;
    comment?: string | null;
    price?: number | null;
    date?: string | null;
    status?: Status | null;
    payment?: PaymentType | null;
    termOfPayment?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "ModelTransportConnection";
      items: Array<{
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    event?: {
      __typename: "ModelEventConnection";
      items: Array<{
        __typename: "Event";
        id: string;
        type?: EventType | null;
        time?: string | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    truck?: {
      __typename: "Truck";
      id: string;
      name?: string | null;
      plate?: string | null;
      trailerPlate?: string | null;
      location?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      ignitionState?: boolean | null;
      deviceID?: string | null;
      lastSignalTime?: string | null;
      heading?: number | null;
      speed?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
};

export type OnUpdateEventSubscription = {
  __typename: "Event";
  id: string;
  type?: EventType | null;
  time?: string | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  order?: {
    __typename: "Order";
    id: string;
    customer: string;
    reference: string;
    comment?: string | null;
    price?: number | null;
    date?: string | null;
    status?: Status | null;
    payment?: PaymentType | null;
    termOfPayment?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "ModelTransportConnection";
      items: Array<{
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    event?: {
      __typename: "ModelEventConnection";
      items: Array<{
        __typename: "Event";
        id: string;
        type?: EventType | null;
        time?: string | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    truck?: {
      __typename: "Truck";
      id: string;
      name?: string | null;
      plate?: string | null;
      trailerPlate?: string | null;
      location?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      ignitionState?: boolean | null;
      deviceID?: string | null;
      lastSignalTime?: string | null;
      heading?: number | null;
      speed?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
};

export type OnDeleteEventSubscription = {
  __typename: "Event";
  id: string;
  type?: EventType | null;
  time?: string | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
  order?: {
    __typename: "Order";
    id: string;
    customer: string;
    reference: string;
    comment?: string | null;
    price?: number | null;
    date?: string | null;
    status?: Status | null;
    payment?: PaymentType | null;
    termOfPayment?: number | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    createdAt: string;
    updatedAt: string;
    transport?: {
      __typename: "ModelTransportConnection";
      items: Array<{
        __typename: "Transport";
        id: string;
        customer?: string | null;
        price?: number | null;
        costofgoods?: number | null;
        distance?: number | null;
        trailer?: Array<Trailer | null> | null;
        attributes?: Array<Feature | null> | null;
        straps?: number | null;
        eta?: string | null;
        notes?: string | null;
        reference?: string | null;
        travelTime?: number | null;
        services?: Array<Service | null> | null;
        adr?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
        route?: {
          __typename: "ModelWaypointConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
        cargo?: {
          __typename: "ModelCargoConnection";
          nextToken?: string | null;
          startedAt?: number | null;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    event?: {
      __typename: "ModelEventConnection";
      items: Array<{
        __typename: "Event";
        id: string;
        type?: EventType | null;
        time?: string | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt: number;
        createdAt: string;
        updatedAt: string;
        order?: {
          __typename: "Order";
          id: string;
          customer: string;
          reference: string;
          comment?: string | null;
          price?: number | null;
          date?: string | null;
          status?: Status | null;
          payment?: PaymentType | null;
          termOfPayment?: number | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt: number;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    truck?: {
      __typename: "Truck";
      id: string;
      name?: string | null;
      plate?: string | null;
      trailerPlate?: string | null;
      location?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      ignitionState?: boolean | null;
      deviceID?: string | null;
      lastSignalTime?: string | null;
      heading?: number | null;
      speed?: number | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
};

export type OnCreateTruckSubscription = {
  __typename: "Truck";
  id: string;
  name?: string | null;
  plate?: string | null;
  trailerPlate?: string | null;
  location?: {
    __typename: "Location";
    lat?: number | null;
    lon?: number | null;
  } | null;
  ignitionState?: boolean | null;
  deviceID?: string | null;
  lastSignalTime?: string | null;
  heading?: number | null;
  speed?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTruckSubscription = {
  __typename: "Truck";
  id: string;
  name?: string | null;
  plate?: string | null;
  trailerPlate?: string | null;
  location?: {
    __typename: "Location";
    lat?: number | null;
    lon?: number | null;
  } | null;
  ignitionState?: boolean | null;
  deviceID?: string | null;
  lastSignalTime?: string | null;
  heading?: number | null;
  speed?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTruckSubscription = {
  __typename: "Truck";
  id: string;
  name?: string | null;
  plate?: string | null;
  trailerPlate?: string | null;
  location?: {
    __typename: "Location";
    lat?: number | null;
    lon?: number | null;
  } | null;
  ignitionState?: boolean | null;
  deviceID?: string | null;
  lastSignalTime?: string | null;
  heading?: number | null;
  speed?: number | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateTransport(
    input: CreateTransportInput,
    condition?: ModelTransportConditionInput
  ): Promise<CreateTransportMutation> {
    const statement = `mutation CreateTransport($input: CreateTransportInput!, $condition: ModelTransportConditionInput) {
        createTransport(input: $input, condition: $condition) {
          __typename
          id
          customer
          price
          costofgoods
          distance
          trailer
          attributes
          straps
          eta
          notes
          reference
          travelTime
          services
          adr
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          order {
            __typename
            id
            customer
            reference
            comment
            price
            date
            status
            payment
            termOfPayment
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              items {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
            event {
              __typename
              items {
                __typename
                id
                type
                time
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            truck {
              __typename
              id
              name
              plate
              trailerPlate
              location {
                __typename
                lat
                lon
              }
              ignitionState
              deviceID
              lastSignalTime
              heading
              speed
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          route {
            __typename
            items {
              __typename
              id
              sequence
              location {
                __typename
                lat
                lon
              }
              name
              address
              housenumber
              street
              zip
              city
              country
              reference
              contact
              contactphone
              notes
              eta
              opening
              closing
              service
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
            }
            nextToken
            startedAt
          }
          cargo {
            __typename
            items {
              __typename
              id
              reference
              category
              packaging
              quantity
              weight
              height
              length
              width
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
            }
            nextToken
            startedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTransportMutation>response.data.createTransport;
  }
  async UpdateTransport(
    input: UpdateTransportInput,
    condition?: ModelTransportConditionInput
  ): Promise<UpdateTransportMutation> {
    const statement = `mutation UpdateTransport($input: UpdateTransportInput!, $condition: ModelTransportConditionInput) {
        updateTransport(input: $input, condition: $condition) {
          __typename
          id
          customer
          price
          costofgoods
          distance
          trailer
          attributes
          straps
          eta
          notes
          reference
          travelTime
          services
          adr
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          order {
            __typename
            id
            customer
            reference
            comment
            price
            date
            status
            payment
            termOfPayment
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              items {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
            event {
              __typename
              items {
                __typename
                id
                type
                time
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            truck {
              __typename
              id
              name
              plate
              trailerPlate
              location {
                __typename
                lat
                lon
              }
              ignitionState
              deviceID
              lastSignalTime
              heading
              speed
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          route {
            __typename
            items {
              __typename
              id
              sequence
              location {
                __typename
                lat
                lon
              }
              name
              address
              housenumber
              street
              zip
              city
              country
              reference
              contact
              contactphone
              notes
              eta
              opening
              closing
              service
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
            }
            nextToken
            startedAt
          }
          cargo {
            __typename
            items {
              __typename
              id
              reference
              category
              packaging
              quantity
              weight
              height
              length
              width
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
            }
            nextToken
            startedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTransportMutation>response.data.updateTransport;
  }
  async DeleteTransport(
    input: DeleteTransportInput,
    condition?: ModelTransportConditionInput
  ): Promise<DeleteTransportMutation> {
    const statement = `mutation DeleteTransport($input: DeleteTransportInput!, $condition: ModelTransportConditionInput) {
        deleteTransport(input: $input, condition: $condition) {
          __typename
          id
          customer
          price
          costofgoods
          distance
          trailer
          attributes
          straps
          eta
          notes
          reference
          travelTime
          services
          adr
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          order {
            __typename
            id
            customer
            reference
            comment
            price
            date
            status
            payment
            termOfPayment
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              items {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
            event {
              __typename
              items {
                __typename
                id
                type
                time
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            truck {
              __typename
              id
              name
              plate
              trailerPlate
              location {
                __typename
                lat
                lon
              }
              ignitionState
              deviceID
              lastSignalTime
              heading
              speed
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          route {
            __typename
            items {
              __typename
              id
              sequence
              location {
                __typename
                lat
                lon
              }
              name
              address
              housenumber
              street
              zip
              city
              country
              reference
              contact
              contactphone
              notes
              eta
              opening
              closing
              service
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
            }
            nextToken
            startedAt
          }
          cargo {
            __typename
            items {
              __typename
              id
              reference
              category
              packaging
              quantity
              weight
              height
              length
              width
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
            }
            nextToken
            startedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTransportMutation>response.data.deleteTransport;
  }
  async CreateOrder(
    input: CreateOrderInput,
    condition?: ModelOrderConditionInput
  ): Promise<CreateOrderMutation> {
    const statement = `mutation CreateOrder($input: CreateOrderInput!, $condition: ModelOrderConditionInput) {
        createOrder(input: $input, condition: $condition) {
          __typename
          id
          customer
          reference
          comment
          price
          date
          status
          payment
          termOfPayment
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          transport {
            __typename
            items {
              __typename
              id
              customer
              price
              costofgoods
              distance
              trailer
              attributes
              straps
              eta
              notes
              reference
              travelTime
              services
              adr
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              order {
                __typename
                id
                customer
                reference
                comment
                price
                date
                status
                payment
                termOfPayment
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  nextToken
                  startedAt
                }
                event {
                  __typename
                  nextToken
                  startedAt
                }
                truck {
                  __typename
                  id
                  name
                  plate
                  trailerPlate
                  ignitionState
                  deviceID
                  lastSignalTime
                  heading
                  speed
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              route {
                __typename
                items {
                  __typename
                  id
                  sequence
                  name
                  address
                  housenumber
                  street
                  zip
                  city
                  country
                  reference
                  contact
                  contactphone
                  notes
                  eta
                  opening
                  closing
                  service
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              cargo {
                __typename
                items {
                  __typename
                  id
                  reference
                  category
                  packaging
                  quantity
                  weight
                  height
                  length
                  width
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
            }
            nextToken
            startedAt
          }
          event {
            __typename
            items {
              __typename
              id
              type
              time
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              order {
                __typename
                id
                customer
                reference
                comment
                price
                date
                status
                payment
                termOfPayment
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  nextToken
                  startedAt
                }
                event {
                  __typename
                  nextToken
                  startedAt
                }
                truck {
                  __typename
                  id
                  name
                  plate
                  trailerPlate
                  ignitionState
                  deviceID
                  lastSignalTime
                  heading
                  speed
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
            }
            nextToken
            startedAt
          }
          truck {
            __typename
            id
            name
            plate
            trailerPlate
            location {
              __typename
              lat
              lon
            }
            ignitionState
            deviceID
            lastSignalTime
            heading
            speed
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrderMutation>response.data.createOrder;
  }
  async UpdateOrder(
    input: UpdateOrderInput,
    condition?: ModelOrderConditionInput
  ): Promise<UpdateOrderMutation> {
    const statement = `mutation UpdateOrder($input: UpdateOrderInput!, $condition: ModelOrderConditionInput) {
        updateOrder(input: $input, condition: $condition) {
          __typename
          id
          customer
          reference
          comment
          price
          date
          status
          payment
          termOfPayment
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          transport {
            __typename
            items {
              __typename
              id
              customer
              price
              costofgoods
              distance
              trailer
              attributes
              straps
              eta
              notes
              reference
              travelTime
              services
              adr
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              order {
                __typename
                id
                customer
                reference
                comment
                price
                date
                status
                payment
                termOfPayment
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  nextToken
                  startedAt
                }
                event {
                  __typename
                  nextToken
                  startedAt
                }
                truck {
                  __typename
                  id
                  name
                  plate
                  trailerPlate
                  ignitionState
                  deviceID
                  lastSignalTime
                  heading
                  speed
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              route {
                __typename
                items {
                  __typename
                  id
                  sequence
                  name
                  address
                  housenumber
                  street
                  zip
                  city
                  country
                  reference
                  contact
                  contactphone
                  notes
                  eta
                  opening
                  closing
                  service
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              cargo {
                __typename
                items {
                  __typename
                  id
                  reference
                  category
                  packaging
                  quantity
                  weight
                  height
                  length
                  width
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
            }
            nextToken
            startedAt
          }
          event {
            __typename
            items {
              __typename
              id
              type
              time
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              order {
                __typename
                id
                customer
                reference
                comment
                price
                date
                status
                payment
                termOfPayment
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  nextToken
                  startedAt
                }
                event {
                  __typename
                  nextToken
                  startedAt
                }
                truck {
                  __typename
                  id
                  name
                  plate
                  trailerPlate
                  ignitionState
                  deviceID
                  lastSignalTime
                  heading
                  speed
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
            }
            nextToken
            startedAt
          }
          truck {
            __typename
            id
            name
            plate
            trailerPlate
            location {
              __typename
              lat
              lon
            }
            ignitionState
            deviceID
            lastSignalTime
            heading
            speed
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrderMutation>response.data.updateOrder;
  }
  async DeleteOrder(
    input: DeleteOrderInput,
    condition?: ModelOrderConditionInput
  ): Promise<DeleteOrderMutation> {
    const statement = `mutation DeleteOrder($input: DeleteOrderInput!, $condition: ModelOrderConditionInput) {
        deleteOrder(input: $input, condition: $condition) {
          __typename
          id
          customer
          reference
          comment
          price
          date
          status
          payment
          termOfPayment
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          transport {
            __typename
            items {
              __typename
              id
              customer
              price
              costofgoods
              distance
              trailer
              attributes
              straps
              eta
              notes
              reference
              travelTime
              services
              adr
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              order {
                __typename
                id
                customer
                reference
                comment
                price
                date
                status
                payment
                termOfPayment
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  nextToken
                  startedAt
                }
                event {
                  __typename
                  nextToken
                  startedAt
                }
                truck {
                  __typename
                  id
                  name
                  plate
                  trailerPlate
                  ignitionState
                  deviceID
                  lastSignalTime
                  heading
                  speed
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              route {
                __typename
                items {
                  __typename
                  id
                  sequence
                  name
                  address
                  housenumber
                  street
                  zip
                  city
                  country
                  reference
                  contact
                  contactphone
                  notes
                  eta
                  opening
                  closing
                  service
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              cargo {
                __typename
                items {
                  __typename
                  id
                  reference
                  category
                  packaging
                  quantity
                  weight
                  height
                  length
                  width
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
            }
            nextToken
            startedAt
          }
          event {
            __typename
            items {
              __typename
              id
              type
              time
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              order {
                __typename
                id
                customer
                reference
                comment
                price
                date
                status
                payment
                termOfPayment
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  nextToken
                  startedAt
                }
                event {
                  __typename
                  nextToken
                  startedAt
                }
                truck {
                  __typename
                  id
                  name
                  plate
                  trailerPlate
                  ignitionState
                  deviceID
                  lastSignalTime
                  heading
                  speed
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
            }
            nextToken
            startedAt
          }
          truck {
            __typename
            id
            name
            plate
            trailerPlate
            location {
              __typename
              lat
              lon
            }
            ignitionState
            deviceID
            lastSignalTime
            heading
            speed
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrderMutation>response.data.deleteOrder;
  }
  async CreateEvent(
    input: CreateEventInput,
    condition?: ModelEventConditionInput
  ): Promise<CreateEventMutation> {
    const statement = `mutation CreateEvent($input: CreateEventInput!, $condition: ModelEventConditionInput) {
        createEvent(input: $input, condition: $condition) {
          __typename
          id
          type
          time
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          order {
            __typename
            id
            customer
            reference
            comment
            price
            date
            status
            payment
            termOfPayment
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              items {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
            event {
              __typename
              items {
                __typename
                id
                type
                time
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            truck {
              __typename
              id
              name
              plate
              trailerPlate
              location {
                __typename
                lat
                lon
              }
              ignitionState
              deviceID
              lastSignalTime
              heading
              speed
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEventMutation>response.data.createEvent;
  }
  async UpdateEvent(
    input: UpdateEventInput,
    condition?: ModelEventConditionInput
  ): Promise<UpdateEventMutation> {
    const statement = `mutation UpdateEvent($input: UpdateEventInput!, $condition: ModelEventConditionInput) {
        updateEvent(input: $input, condition: $condition) {
          __typename
          id
          type
          time
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          order {
            __typename
            id
            customer
            reference
            comment
            price
            date
            status
            payment
            termOfPayment
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              items {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
            event {
              __typename
              items {
                __typename
                id
                type
                time
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            truck {
              __typename
              id
              name
              plate
              trailerPlate
              location {
                __typename
                lat
                lon
              }
              ignitionState
              deviceID
              lastSignalTime
              heading
              speed
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEventMutation>response.data.updateEvent;
  }
  async DeleteEvent(
    input: DeleteEventInput,
    condition?: ModelEventConditionInput
  ): Promise<DeleteEventMutation> {
    const statement = `mutation DeleteEvent($input: DeleteEventInput!, $condition: ModelEventConditionInput) {
        deleteEvent(input: $input, condition: $condition) {
          __typename
          id
          type
          time
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          order {
            __typename
            id
            customer
            reference
            comment
            price
            date
            status
            payment
            termOfPayment
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              items {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
            event {
              __typename
              items {
                __typename
                id
                type
                time
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            truck {
              __typename
              id
              name
              plate
              trailerPlate
              location {
                __typename
                lat
                lon
              }
              ignitionState
              deviceID
              lastSignalTime
              heading
              speed
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEventMutation>response.data.deleteEvent;
  }
  async CreateTruck(
    input: CreateTruckInput,
    condition?: ModelTruckConditionInput
  ): Promise<CreateTruckMutation> {
    const statement = `mutation CreateTruck($input: CreateTruckInput!, $condition: ModelTruckConditionInput) {
        createTruck(input: $input, condition: $condition) {
          __typename
          id
          name
          plate
          trailerPlate
          location {
            __typename
            lat
            lon
          }
          ignitionState
          deviceID
          lastSignalTime
          heading
          speed
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTruckMutation>response.data.createTruck;
  }
  async UpdateTruck(
    input: UpdateTruckInput,
    condition?: ModelTruckConditionInput
  ): Promise<UpdateTruckMutation> {
    const statement = `mutation UpdateTruck($input: UpdateTruckInput!, $condition: ModelTruckConditionInput) {
        updateTruck(input: $input, condition: $condition) {
          __typename
          id
          name
          plate
          trailerPlate
          location {
            __typename
            lat
            lon
          }
          ignitionState
          deviceID
          lastSignalTime
          heading
          speed
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTruckMutation>response.data.updateTruck;
  }
  async DeleteTruck(
    input: DeleteTruckInput,
    condition?: ModelTruckConditionInput
  ): Promise<DeleteTruckMutation> {
    const statement = `mutation DeleteTruck($input: DeleteTruckInput!, $condition: ModelTruckConditionInput) {
        deleteTruck(input: $input, condition: $condition) {
          __typename
          id
          name
          plate
          trailerPlate
          location {
            __typename
            lat
            lon
          }
          ignitionState
          deviceID
          lastSignalTime
          heading
          speed
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTruckMutation>response.data.deleteTruck;
  }
  async CreateWaypoint(
    input: CreateWaypointInput,
    condition?: ModelWaypointConditionInput
  ): Promise<CreateWaypointMutation> {
    const statement = `mutation CreateWaypoint($input: CreateWaypointInput!, $condition: ModelWaypointConditionInput) {
        createWaypoint(input: $input, condition: $condition) {
          __typename
          id
          sequence
          location {
            __typename
            lat
            lon
          }
          name
          address
          housenumber
          street
          zip
          city
          country
          reference
          contact
          contactphone
          notes
          eta
          opening
          closing
          service
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          transport {
            __typename
            id
            customer
            price
            costofgoods
            distance
            trailer
            attributes
            straps
            eta
            notes
            reference
            travelTime
            services
            adr
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            order {
              __typename
              id
              customer
              reference
              comment
              price
              date
              status
              payment
              termOfPayment
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                items {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              event {
                __typename
                items {
                  __typename
                  id
                  type
                  time
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              truck {
                __typename
                id
                name
                plate
                trailerPlate
                location {
                  __typename
                  lat
                  lon
                }
                ignitionState
                deviceID
                lastSignalTime
                heading
                speed
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
            }
            route {
              __typename
              items {
                __typename
                id
                sequence
                location {
                  __typename
                  lat
                  lon
                }
                name
                address
                housenumber
                street
                zip
                city
                country
                reference
                contact
                contactphone
                notes
                eta
                opening
                closing
                service
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            cargo {
              __typename
              items {
                __typename
                id
                reference
                category
                packaging
                quantity
                weight
                height
                length
                width
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateWaypointMutation>response.data.createWaypoint;
  }
  async UpdateWaypoint(
    input: UpdateWaypointInput,
    condition?: ModelWaypointConditionInput
  ): Promise<UpdateWaypointMutation> {
    const statement = `mutation UpdateWaypoint($input: UpdateWaypointInput!, $condition: ModelWaypointConditionInput) {
        updateWaypoint(input: $input, condition: $condition) {
          __typename
          id
          sequence
          location {
            __typename
            lat
            lon
          }
          name
          address
          housenumber
          street
          zip
          city
          country
          reference
          contact
          contactphone
          notes
          eta
          opening
          closing
          service
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          transport {
            __typename
            id
            customer
            price
            costofgoods
            distance
            trailer
            attributes
            straps
            eta
            notes
            reference
            travelTime
            services
            adr
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            order {
              __typename
              id
              customer
              reference
              comment
              price
              date
              status
              payment
              termOfPayment
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                items {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              event {
                __typename
                items {
                  __typename
                  id
                  type
                  time
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              truck {
                __typename
                id
                name
                plate
                trailerPlate
                location {
                  __typename
                  lat
                  lon
                }
                ignitionState
                deviceID
                lastSignalTime
                heading
                speed
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
            }
            route {
              __typename
              items {
                __typename
                id
                sequence
                location {
                  __typename
                  lat
                  lon
                }
                name
                address
                housenumber
                street
                zip
                city
                country
                reference
                contact
                contactphone
                notes
                eta
                opening
                closing
                service
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            cargo {
              __typename
              items {
                __typename
                id
                reference
                category
                packaging
                quantity
                weight
                height
                length
                width
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateWaypointMutation>response.data.updateWaypoint;
  }
  async DeleteWaypoint(
    input: DeleteWaypointInput,
    condition?: ModelWaypointConditionInput
  ): Promise<DeleteWaypointMutation> {
    const statement = `mutation DeleteWaypoint($input: DeleteWaypointInput!, $condition: ModelWaypointConditionInput) {
        deleteWaypoint(input: $input, condition: $condition) {
          __typename
          id
          sequence
          location {
            __typename
            lat
            lon
          }
          name
          address
          housenumber
          street
          zip
          city
          country
          reference
          contact
          contactphone
          notes
          eta
          opening
          closing
          service
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          transport {
            __typename
            id
            customer
            price
            costofgoods
            distance
            trailer
            attributes
            straps
            eta
            notes
            reference
            travelTime
            services
            adr
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            order {
              __typename
              id
              customer
              reference
              comment
              price
              date
              status
              payment
              termOfPayment
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                items {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              event {
                __typename
                items {
                  __typename
                  id
                  type
                  time
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              truck {
                __typename
                id
                name
                plate
                trailerPlate
                location {
                  __typename
                  lat
                  lon
                }
                ignitionState
                deviceID
                lastSignalTime
                heading
                speed
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
            }
            route {
              __typename
              items {
                __typename
                id
                sequence
                location {
                  __typename
                  lat
                  lon
                }
                name
                address
                housenumber
                street
                zip
                city
                country
                reference
                contact
                contactphone
                notes
                eta
                opening
                closing
                service
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            cargo {
              __typename
              items {
                __typename
                id
                reference
                category
                packaging
                quantity
                weight
                height
                length
                width
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteWaypointMutation>response.data.deleteWaypoint;
  }
  async CreateCargo(
    input: CreateCargoInput,
    condition?: ModelCargoConditionInput
  ): Promise<CreateCargoMutation> {
    const statement = `mutation CreateCargo($input: CreateCargoInput!, $condition: ModelCargoConditionInput) {
        createCargo(input: $input, condition: $condition) {
          __typename
          id
          reference
          category
          packaging
          quantity
          weight
          height
          length
          width
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          transport {
            __typename
            id
            customer
            price
            costofgoods
            distance
            trailer
            attributes
            straps
            eta
            notes
            reference
            travelTime
            services
            adr
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            order {
              __typename
              id
              customer
              reference
              comment
              price
              date
              status
              payment
              termOfPayment
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                items {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              event {
                __typename
                items {
                  __typename
                  id
                  type
                  time
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              truck {
                __typename
                id
                name
                plate
                trailerPlate
                location {
                  __typename
                  lat
                  lon
                }
                ignitionState
                deviceID
                lastSignalTime
                heading
                speed
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
            }
            route {
              __typename
              items {
                __typename
                id
                sequence
                location {
                  __typename
                  lat
                  lon
                }
                name
                address
                housenumber
                street
                zip
                city
                country
                reference
                contact
                contactphone
                notes
                eta
                opening
                closing
                service
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            cargo {
              __typename
              items {
                __typename
                id
                reference
                category
                packaging
                quantity
                weight
                height
                length
                width
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCargoMutation>response.data.createCargo;
  }
  async UpdateCargo(
    input: UpdateCargoInput,
    condition?: ModelCargoConditionInput
  ): Promise<UpdateCargoMutation> {
    const statement = `mutation UpdateCargo($input: UpdateCargoInput!, $condition: ModelCargoConditionInput) {
        updateCargo(input: $input, condition: $condition) {
          __typename
          id
          reference
          category
          packaging
          quantity
          weight
          height
          length
          width
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          transport {
            __typename
            id
            customer
            price
            costofgoods
            distance
            trailer
            attributes
            straps
            eta
            notes
            reference
            travelTime
            services
            adr
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            order {
              __typename
              id
              customer
              reference
              comment
              price
              date
              status
              payment
              termOfPayment
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                items {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              event {
                __typename
                items {
                  __typename
                  id
                  type
                  time
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              truck {
                __typename
                id
                name
                plate
                trailerPlate
                location {
                  __typename
                  lat
                  lon
                }
                ignitionState
                deviceID
                lastSignalTime
                heading
                speed
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
            }
            route {
              __typename
              items {
                __typename
                id
                sequence
                location {
                  __typename
                  lat
                  lon
                }
                name
                address
                housenumber
                street
                zip
                city
                country
                reference
                contact
                contactphone
                notes
                eta
                opening
                closing
                service
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            cargo {
              __typename
              items {
                __typename
                id
                reference
                category
                packaging
                quantity
                weight
                height
                length
                width
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCargoMutation>response.data.updateCargo;
  }
  async DeleteCargo(
    input: DeleteCargoInput,
    condition?: ModelCargoConditionInput
  ): Promise<DeleteCargoMutation> {
    const statement = `mutation DeleteCargo($input: DeleteCargoInput!, $condition: ModelCargoConditionInput) {
        deleteCargo(input: $input, condition: $condition) {
          __typename
          id
          reference
          category
          packaging
          quantity
          weight
          height
          length
          width
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          transport {
            __typename
            id
            customer
            price
            costofgoods
            distance
            trailer
            attributes
            straps
            eta
            notes
            reference
            travelTime
            services
            adr
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            order {
              __typename
              id
              customer
              reference
              comment
              price
              date
              status
              payment
              termOfPayment
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                items {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              event {
                __typename
                items {
                  __typename
                  id
                  type
                  time
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              truck {
                __typename
                id
                name
                plate
                trailerPlate
                location {
                  __typename
                  lat
                  lon
                }
                ignitionState
                deviceID
                lastSignalTime
                heading
                speed
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
            }
            route {
              __typename
              items {
                __typename
                id
                sequence
                location {
                  __typename
                  lat
                  lon
                }
                name
                address
                housenumber
                street
                zip
                city
                country
                reference
                contact
                contactphone
                notes
                eta
                opening
                closing
                service
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            cargo {
              __typename
              items {
                __typename
                id
                reference
                category
                packaging
                quantity
                weight
                height
                length
                width
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCargoMutation>response.data.deleteCargo;
  }
  async GetTransport(id: string): Promise<GetTransportQuery> {
    const statement = `query GetTransport($id: ID!) {
        getTransport(id: $id) {
          __typename
          id
          customer
          price
          costofgoods
          distance
          trailer
          attributes
          straps
          eta
          notes
          reference
          travelTime
          services
          adr
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          order {
            __typename
            id
            customer
            reference
            comment
            price
            date
            status
            payment
            termOfPayment
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              items {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
            event {
              __typename
              items {
                __typename
                id
                type
                time
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            truck {
              __typename
              id
              name
              plate
              trailerPlate
              location {
                __typename
                lat
                lon
              }
              ignitionState
              deviceID
              lastSignalTime
              heading
              speed
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          route {
            __typename
            items {
              __typename
              id
              sequence
              location {
                __typename
                lat
                lon
              }
              name
              address
              housenumber
              street
              zip
              city
              country
              reference
              contact
              contactphone
              notes
              eta
              opening
              closing
              service
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
            }
            nextToken
            startedAt
          }
          cargo {
            __typename
            items {
              __typename
              id
              reference
              category
              packaging
              quantity
              weight
              height
              length
              width
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
            }
            nextToken
            startedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTransportQuery>response.data.getTransport;
  }
  async ListTransports(
    filter?: ModelTransportFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTransportsQuery> {
    const statement = `query ListTransports($filter: ModelTransportFilterInput, $limit: Int, $nextToken: String) {
        listTransports(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            customer
            price
            costofgoods
            distance
            trailer
            attributes
            straps
            eta
            notes
            reference
            travelTime
            services
            adr
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            order {
              __typename
              id
              customer
              reference
              comment
              price
              date
              status
              payment
              termOfPayment
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                items {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              event {
                __typename
                items {
                  __typename
                  id
                  type
                  time
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              truck {
                __typename
                id
                name
                plate
                trailerPlate
                location {
                  __typename
                  lat
                  lon
                }
                ignitionState
                deviceID
                lastSignalTime
                heading
                speed
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
            }
            route {
              __typename
              items {
                __typename
                id
                sequence
                location {
                  __typename
                  lat
                  lon
                }
                name
                address
                housenumber
                street
                zip
                city
                country
                reference
                contact
                contactphone
                notes
                eta
                opening
                closing
                service
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            cargo {
              __typename
              items {
                __typename
                id
                reference
                category
                packaging
                quantity
                weight
                height
                length
                width
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTransportsQuery>response.data.listTransports;
  }
  async SyncTransports(
    filter?: ModelTransportFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncTransportsQuery> {
    const statement = `query SyncTransports($filter: ModelTransportFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncTransports(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            id
            customer
            price
            costofgoods
            distance
            trailer
            attributes
            straps
            eta
            notes
            reference
            travelTime
            services
            adr
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            order {
              __typename
              id
              customer
              reference
              comment
              price
              date
              status
              payment
              termOfPayment
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                items {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              event {
                __typename
                items {
                  __typename
                  id
                  type
                  time
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              truck {
                __typename
                id
                name
                plate
                trailerPlate
                location {
                  __typename
                  lat
                  lon
                }
                ignitionState
                deviceID
                lastSignalTime
                heading
                speed
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
            }
            route {
              __typename
              items {
                __typename
                id
                sequence
                location {
                  __typename
                  lat
                  lon
                }
                name
                address
                housenumber
                street
                zip
                city
                country
                reference
                contact
                contactphone
                notes
                eta
                opening
                closing
                service
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            cargo {
              __typename
              items {
                __typename
                id
                reference
                category
                packaging
                quantity
                weight
                height
                length
                width
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncTransportsQuery>response.data.syncTransports;
  }
  async GetOrder(reference: string): Promise<GetOrderQuery> {
    const statement = `query GetOrder($reference: String!) {
        getOrder(reference: $reference) {
          __typename
          id
          customer
          reference
          comment
          price
          date
          status
          payment
          termOfPayment
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          transport {
            __typename
            items {
              __typename
              id
              customer
              price
              costofgoods
              distance
              trailer
              attributes
              straps
              eta
              notes
              reference
              travelTime
              services
              adr
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              order {
                __typename
                id
                customer
                reference
                comment
                price
                date
                status
                payment
                termOfPayment
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  nextToken
                  startedAt
                }
                event {
                  __typename
                  nextToken
                  startedAt
                }
                truck {
                  __typename
                  id
                  name
                  plate
                  trailerPlate
                  ignitionState
                  deviceID
                  lastSignalTime
                  heading
                  speed
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              route {
                __typename
                items {
                  __typename
                  id
                  sequence
                  name
                  address
                  housenumber
                  street
                  zip
                  city
                  country
                  reference
                  contact
                  contactphone
                  notes
                  eta
                  opening
                  closing
                  service
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              cargo {
                __typename
                items {
                  __typename
                  id
                  reference
                  category
                  packaging
                  quantity
                  weight
                  height
                  length
                  width
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
            }
            nextToken
            startedAt
          }
          event {
            __typename
            items {
              __typename
              id
              type
              time
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              order {
                __typename
                id
                customer
                reference
                comment
                price
                date
                status
                payment
                termOfPayment
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  nextToken
                  startedAt
                }
                event {
                  __typename
                  nextToken
                  startedAt
                }
                truck {
                  __typename
                  id
                  name
                  plate
                  trailerPlate
                  ignitionState
                  deviceID
                  lastSignalTime
                  heading
                  speed
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
            }
            nextToken
            startedAt
          }
          truck {
            __typename
            id
            name
            plate
            trailerPlate
            location {
              __typename
              lat
              lon
            }
            ignitionState
            deviceID
            lastSignalTime
            heading
            speed
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      reference
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOrderQuery>response.data.getOrder;
  }
  async ListOrders(
    reference?: string,
    filter?: ModelOrderFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListOrdersQuery> {
    const statement = `query ListOrders($reference: String, $filter: ModelOrderFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listOrders(
          reference: $reference
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            id
            customer
            reference
            comment
            price
            date
            status
            payment
            termOfPayment
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              items {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
            event {
              __typename
              items {
                __typename
                id
                type
                time
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            truck {
              __typename
              id
              name
              plate
              trailerPlate
              location {
                __typename
                lat
                lon
              }
              ignitionState
              deviceID
              lastSignalTime
              heading
              speed
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (reference) {
      gqlAPIServiceArguments.reference = reference;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrdersQuery>response.data.listOrders;
  }
  async OrderByDateAndStatus(
    date?: string,
    status?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelOrderFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<OrderByDateAndStatusQuery> {
    const statement = `query OrderByDateAndStatus($date: AWSDateTime, $status: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelOrderFilterInput, $limit: Int, $nextToken: String) {
        OrderByDateAndStatus(
          date: $date
          status: $status
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            customer
            reference
            comment
            price
            date
            status
            payment
            termOfPayment
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              items {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
            event {
              __typename
              items {
                __typename
                id
                type
                time
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            truck {
              __typename
              id
              name
              plate
              trailerPlate
              location {
                __typename
                lat
                lon
              }
              ignitionState
              deviceID
              lastSignalTime
              heading
              speed
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (date) {
      gqlAPIServiceArguments.date = date;
    }
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OrderByDateAndStatusQuery>response.data.OrderByDateAndStatus;
  }
  async OrderByCustomer(
    customer?: string,
    date?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelOrderFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<OrderByCustomerQuery> {
    const statement = `query OrderByCustomer($customer: String, $date: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelOrderFilterInput, $limit: Int, $nextToken: String) {
        OrderByCustomer(
          customer: $customer
          date: $date
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            customer
            reference
            comment
            price
            date
            status
            payment
            termOfPayment
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              items {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
            event {
              __typename
              items {
                __typename
                id
                type
                time
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            truck {
              __typename
              id
              name
              plate
              trailerPlate
              location {
                __typename
                lat
                lon
              }
              ignitionState
              deviceID
              lastSignalTime
              heading
              speed
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customer) {
      gqlAPIServiceArguments.customer = customer;
    }
    if (date) {
      gqlAPIServiceArguments.date = date;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OrderByCustomerQuery>response.data.OrderByCustomer;
  }
  async SyncOrders(
    filter?: ModelOrderFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncOrdersQuery> {
    const statement = `query SyncOrders($filter: ModelOrderFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncOrders(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            id
            customer
            reference
            comment
            price
            date
            status
            payment
            termOfPayment
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              items {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
            event {
              __typename
              items {
                __typename
                id
                type
                time
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            truck {
              __typename
              id
              name
              plate
              trailerPlate
              location {
                __typename
                lat
                lon
              }
              ignitionState
              deviceID
              lastSignalTime
              heading
              speed
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncOrdersQuery>response.data.syncOrders;
  }
  async GetEvent(id: string): Promise<GetEventQuery> {
    const statement = `query GetEvent($id: ID!) {
        getEvent(id: $id) {
          __typename
          id
          type
          time
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          order {
            __typename
            id
            customer
            reference
            comment
            price
            date
            status
            payment
            termOfPayment
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              items {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
            event {
              __typename
              items {
                __typename
                id
                type
                time
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            truck {
              __typename
              id
              name
              plate
              trailerPlate
              location {
                __typename
                lat
                lon
              }
              ignitionState
              deviceID
              lastSignalTime
              heading
              speed
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEventQuery>response.data.getEvent;
  }
  async ListEvents(
    filter?: ModelEventFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEventsQuery> {
    const statement = `query ListEvents($filter: ModelEventFilterInput, $limit: Int, $nextToken: String) {
        listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            type
            time
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            order {
              __typename
              id
              customer
              reference
              comment
              price
              date
              status
              payment
              termOfPayment
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                items {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              event {
                __typename
                items {
                  __typename
                  id
                  type
                  time
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              truck {
                __typename
                id
                name
                plate
                trailerPlate
                location {
                  __typename
                  lat
                  lon
                }
                ignitionState
                deviceID
                lastSignalTime
                heading
                speed
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
            }
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEventsQuery>response.data.listEvents;
  }
  async SyncEvents(
    filter?: ModelEventFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncEventsQuery> {
    const statement = `query SyncEvents($filter: ModelEventFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncEvents(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            id
            type
            time
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            order {
              __typename
              id
              customer
              reference
              comment
              price
              date
              status
              payment
              termOfPayment
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                items {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              event {
                __typename
                items {
                  __typename
                  id
                  type
                  time
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              truck {
                __typename
                id
                name
                plate
                trailerPlate
                location {
                  __typename
                  lat
                  lon
                }
                ignitionState
                deviceID
                lastSignalTime
                heading
                speed
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
            }
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncEventsQuery>response.data.syncEvents;
  }
  async GetTruck(id: string): Promise<GetTruckQuery> {
    const statement = `query GetTruck($id: ID!) {
        getTruck(id: $id) {
          __typename
          id
          name
          plate
          trailerPlate
          location {
            __typename
            lat
            lon
          }
          ignitionState
          deviceID
          lastSignalTime
          heading
          speed
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTruckQuery>response.data.getTruck;
  }
  async ListTrucks(
    filter?: ModelTruckFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTrucksQuery> {
    const statement = `query ListTrucks($filter: ModelTruckFilterInput, $limit: Int, $nextToken: String) {
        listTrucks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            plate
            trailerPlate
            location {
              __typename
              lat
              lon
            }
            ignitionState
            deviceID
            lastSignalTime
            heading
            speed
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTrucksQuery>response.data.listTrucks;
  }
  async TrucksByDate(
    lastSignalTime?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTruckFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TrucksByDateQuery> {
    const statement = `query TrucksByDate($lastSignalTime: AWSDateTime, $sortDirection: ModelSortDirection, $filter: ModelTruckFilterInput, $limit: Int, $nextToken: String) {
        TrucksByDate(
          lastSignalTime: $lastSignalTime
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            plate
            trailerPlate
            location {
              __typename
              lat
              lon
            }
            ignitionState
            deviceID
            lastSignalTime
            heading
            speed
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (lastSignalTime) {
      gqlAPIServiceArguments.lastSignalTime = lastSignalTime;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TrucksByDateQuery>response.data.TrucksByDate;
  }
  async TruckByDateAndDeviceID(
    deviceID?: string,
    lastSignalTime?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelTruckFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TruckByDateAndDeviceIDQuery> {
    const statement = `query TruckByDateAndDeviceID($deviceID: String, $lastSignalTime: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTruckFilterInput, $limit: Int, $nextToken: String) {
        TruckByDateAndDeviceID(
          deviceID: $deviceID
          lastSignalTime: $lastSignalTime
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            plate
            trailerPlate
            location {
              __typename
              lat
              lon
            }
            ignitionState
            deviceID
            lastSignalTime
            heading
            speed
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (deviceID) {
      gqlAPIServiceArguments.deviceID = deviceID;
    }
    if (lastSignalTime) {
      gqlAPIServiceArguments.lastSignalTime = lastSignalTime;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TruckByDateAndDeviceIDQuery>response.data.TruckByDateAndDeviceID;
  }
  async SyncTrucks(
    filter?: ModelTruckFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncTrucksQuery> {
    const statement = `query SyncTrucks($filter: ModelTruckFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncTrucks(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            id
            name
            plate
            trailerPlate
            location {
              __typename
              lat
              lon
            }
            ignitionState
            deviceID
            lastSignalTime
            heading
            speed
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncTrucksQuery>response.data.syncTrucks;
  }
  async GetWaypoint(id: string): Promise<GetWaypointQuery> {
    const statement = `query GetWaypoint($id: ID!) {
        getWaypoint(id: $id) {
          __typename
          id
          sequence
          location {
            __typename
            lat
            lon
          }
          name
          address
          housenumber
          street
          zip
          city
          country
          reference
          contact
          contactphone
          notes
          eta
          opening
          closing
          service
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          transport {
            __typename
            id
            customer
            price
            costofgoods
            distance
            trailer
            attributes
            straps
            eta
            notes
            reference
            travelTime
            services
            adr
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            order {
              __typename
              id
              customer
              reference
              comment
              price
              date
              status
              payment
              termOfPayment
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                items {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              event {
                __typename
                items {
                  __typename
                  id
                  type
                  time
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              truck {
                __typename
                id
                name
                plate
                trailerPlate
                location {
                  __typename
                  lat
                  lon
                }
                ignitionState
                deviceID
                lastSignalTime
                heading
                speed
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
            }
            route {
              __typename
              items {
                __typename
                id
                sequence
                location {
                  __typename
                  lat
                  lon
                }
                name
                address
                housenumber
                street
                zip
                city
                country
                reference
                contact
                contactphone
                notes
                eta
                opening
                closing
                service
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            cargo {
              __typename
              items {
                __typename
                id
                reference
                category
                packaging
                quantity
                weight
                height
                length
                width
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetWaypointQuery>response.data.getWaypoint;
  }
  async ListWaypoints(
    filter?: ModelWaypointFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListWaypointsQuery> {
    const statement = `query ListWaypoints($filter: ModelWaypointFilterInput, $limit: Int, $nextToken: String) {
        listWaypoints(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            sequence
            location {
              __typename
              lat
              lon
            }
            name
            address
            housenumber
            street
            zip
            city
            country
            reference
            contact
            contactphone
            notes
            eta
            opening
            closing
            service
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              id
              customer
              price
              costofgoods
              distance
              trailer
              attributes
              straps
              eta
              notes
              reference
              travelTime
              services
              adr
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              order {
                __typename
                id
                customer
                reference
                comment
                price
                date
                status
                payment
                termOfPayment
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  nextToken
                  startedAt
                }
                event {
                  __typename
                  nextToken
                  startedAt
                }
                truck {
                  __typename
                  id
                  name
                  plate
                  trailerPlate
                  ignitionState
                  deviceID
                  lastSignalTime
                  heading
                  speed
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              route {
                __typename
                items {
                  __typename
                  id
                  sequence
                  name
                  address
                  housenumber
                  street
                  zip
                  city
                  country
                  reference
                  contact
                  contactphone
                  notes
                  eta
                  opening
                  closing
                  service
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              cargo {
                __typename
                items {
                  __typename
                  id
                  reference
                  category
                  packaging
                  quantity
                  weight
                  height
                  length
                  width
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
            }
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListWaypointsQuery>response.data.listWaypoints;
  }
  async SyncWaypoints(
    filter?: ModelWaypointFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncWaypointsQuery> {
    const statement = `query SyncWaypoints($filter: ModelWaypointFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncWaypoints(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            id
            sequence
            location {
              __typename
              lat
              lon
            }
            name
            address
            housenumber
            street
            zip
            city
            country
            reference
            contact
            contactphone
            notes
            eta
            opening
            closing
            service
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              id
              customer
              price
              costofgoods
              distance
              trailer
              attributes
              straps
              eta
              notes
              reference
              travelTime
              services
              adr
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              order {
                __typename
                id
                customer
                reference
                comment
                price
                date
                status
                payment
                termOfPayment
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  nextToken
                  startedAt
                }
                event {
                  __typename
                  nextToken
                  startedAt
                }
                truck {
                  __typename
                  id
                  name
                  plate
                  trailerPlate
                  ignitionState
                  deviceID
                  lastSignalTime
                  heading
                  speed
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              route {
                __typename
                items {
                  __typename
                  id
                  sequence
                  name
                  address
                  housenumber
                  street
                  zip
                  city
                  country
                  reference
                  contact
                  contactphone
                  notes
                  eta
                  opening
                  closing
                  service
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              cargo {
                __typename
                items {
                  __typename
                  id
                  reference
                  category
                  packaging
                  quantity
                  weight
                  height
                  length
                  width
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
            }
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncWaypointsQuery>response.data.syncWaypoints;
  }
  async GetCargo(id: string): Promise<GetCargoQuery> {
    const statement = `query GetCargo($id: ID!) {
        getCargo(id: $id) {
          __typename
          id
          reference
          category
          packaging
          quantity
          weight
          height
          length
          width
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          transport {
            __typename
            id
            customer
            price
            costofgoods
            distance
            trailer
            attributes
            straps
            eta
            notes
            reference
            travelTime
            services
            adr
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            order {
              __typename
              id
              customer
              reference
              comment
              price
              date
              status
              payment
              termOfPayment
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                items {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              event {
                __typename
                items {
                  __typename
                  id
                  type
                  time
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              truck {
                __typename
                id
                name
                plate
                trailerPlate
                location {
                  __typename
                  lat
                  lon
                }
                ignitionState
                deviceID
                lastSignalTime
                heading
                speed
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
            }
            route {
              __typename
              items {
                __typename
                id
                sequence
                location {
                  __typename
                  lat
                  lon
                }
                name
                address
                housenumber
                street
                zip
                city
                country
                reference
                contact
                contactphone
                notes
                eta
                opening
                closing
                service
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            cargo {
              __typename
              items {
                __typename
                id
                reference
                category
                packaging
                quantity
                weight
                height
                length
                width
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  id
                  customer
                  price
                  costofgoods
                  distance
                  trailer
                  attributes
                  straps
                  eta
                  notes
                  reference
                  travelTime
                  services
                  adr
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCargoQuery>response.data.getCargo;
  }
  async ListCargos(
    filter?: ModelCargoFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCargosQuery> {
    const statement = `query ListCargos($filter: ModelCargoFilterInput, $limit: Int, $nextToken: String) {
        listCargos(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            reference
            category
            packaging
            quantity
            weight
            height
            length
            width
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              id
              customer
              price
              costofgoods
              distance
              trailer
              attributes
              straps
              eta
              notes
              reference
              travelTime
              services
              adr
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              order {
                __typename
                id
                customer
                reference
                comment
                price
                date
                status
                payment
                termOfPayment
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  nextToken
                  startedAt
                }
                event {
                  __typename
                  nextToken
                  startedAt
                }
                truck {
                  __typename
                  id
                  name
                  plate
                  trailerPlate
                  ignitionState
                  deviceID
                  lastSignalTime
                  heading
                  speed
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              route {
                __typename
                items {
                  __typename
                  id
                  sequence
                  name
                  address
                  housenumber
                  street
                  zip
                  city
                  country
                  reference
                  contact
                  contactphone
                  notes
                  eta
                  opening
                  closing
                  service
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              cargo {
                __typename
                items {
                  __typename
                  id
                  reference
                  category
                  packaging
                  quantity
                  weight
                  height
                  length
                  width
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
            }
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCargosQuery>response.data.listCargos;
  }
  async SyncCargos(
    filter?: ModelCargoFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncCargosQuery> {
    const statement = `query SyncCargos($filter: ModelCargoFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncCargos(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            id
            reference
            category
            packaging
            quantity
            weight
            height
            length
            width
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              id
              customer
              price
              costofgoods
              distance
              trailer
              attributes
              straps
              eta
              notes
              reference
              travelTime
              services
              adr
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              order {
                __typename
                id
                customer
                reference
                comment
                price
                date
                status
                payment
                termOfPayment
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  nextToken
                  startedAt
                }
                event {
                  __typename
                  nextToken
                  startedAt
                }
                truck {
                  __typename
                  id
                  name
                  plate
                  trailerPlate
                  ignitionState
                  deviceID
                  lastSignalTime
                  heading
                  speed
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              route {
                __typename
                items {
                  __typename
                  id
                  sequence
                  name
                  address
                  housenumber
                  street
                  zip
                  city
                  country
                  reference
                  contact
                  contactphone
                  notes
                  eta
                  opening
                  closing
                  service
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              cargo {
                __typename
                items {
                  __typename
                  id
                  reference
                  category
                  packaging
                  quantity
                  weight
                  height
                  length
                  width
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
            }
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncCargosQuery>response.data.syncCargos;
  }
  OnCreateTransportListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTransport">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTransport {
        onCreateTransport {
          __typename
          id
          customer
          price
          costofgoods
          distance
          trailer
          attributes
          straps
          eta
          notes
          reference
          travelTime
          services
          adr
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          order {
            __typename
            id
            customer
            reference
            comment
            price
            date
            status
            payment
            termOfPayment
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              items {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
            event {
              __typename
              items {
                __typename
                id
                type
                time
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            truck {
              __typename
              id
              name
              plate
              trailerPlate
              location {
                __typename
                lat
                lon
              }
              ignitionState
              deviceID
              lastSignalTime
              heading
              speed
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          route {
            __typename
            items {
              __typename
              id
              sequence
              location {
                __typename
                lat
                lon
              }
              name
              address
              housenumber
              street
              zip
              city
              country
              reference
              contact
              contactphone
              notes
              eta
              opening
              closing
              service
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
            }
            nextToken
            startedAt
          }
          cargo {
            __typename
            items {
              __typename
              id
              reference
              category
              packaging
              quantity
              weight
              height
              length
              width
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
            }
            nextToken
            startedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTransport">>
  >;

  OnUpdateTransportListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTransport">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTransport {
        onUpdateTransport {
          __typename
          id
          customer
          price
          costofgoods
          distance
          trailer
          attributes
          straps
          eta
          notes
          reference
          travelTime
          services
          adr
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          order {
            __typename
            id
            customer
            reference
            comment
            price
            date
            status
            payment
            termOfPayment
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              items {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
            event {
              __typename
              items {
                __typename
                id
                type
                time
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            truck {
              __typename
              id
              name
              plate
              trailerPlate
              location {
                __typename
                lat
                lon
              }
              ignitionState
              deviceID
              lastSignalTime
              heading
              speed
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          route {
            __typename
            items {
              __typename
              id
              sequence
              location {
                __typename
                lat
                lon
              }
              name
              address
              housenumber
              street
              zip
              city
              country
              reference
              contact
              contactphone
              notes
              eta
              opening
              closing
              service
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
            }
            nextToken
            startedAt
          }
          cargo {
            __typename
            items {
              __typename
              id
              reference
              category
              packaging
              quantity
              weight
              height
              length
              width
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
            }
            nextToken
            startedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTransport">>
  >;

  OnDeleteTransportListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTransport">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTransport {
        onDeleteTransport {
          __typename
          id
          customer
          price
          costofgoods
          distance
          trailer
          attributes
          straps
          eta
          notes
          reference
          travelTime
          services
          adr
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          order {
            __typename
            id
            customer
            reference
            comment
            price
            date
            status
            payment
            termOfPayment
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              items {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
            event {
              __typename
              items {
                __typename
                id
                type
                time
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            truck {
              __typename
              id
              name
              plate
              trailerPlate
              location {
                __typename
                lat
                lon
              }
              ignitionState
              deviceID
              lastSignalTime
              heading
              speed
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          route {
            __typename
            items {
              __typename
              id
              sequence
              location {
                __typename
                lat
                lon
              }
              name
              address
              housenumber
              street
              zip
              city
              country
              reference
              contact
              contactphone
              notes
              eta
              opening
              closing
              service
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
            }
            nextToken
            startedAt
          }
          cargo {
            __typename
            items {
              __typename
              id
              reference
              category
              packaging
              quantity
              weight
              height
              length
              width
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              transport {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
            }
            nextToken
            startedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTransport">>
  >;

  OnCreateOrderListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateOrder">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateOrder {
        onCreateOrder {
          __typename
          id
          customer
          reference
          comment
          price
          date
          status
          payment
          termOfPayment
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          transport {
            __typename
            items {
              __typename
              id
              customer
              price
              costofgoods
              distance
              trailer
              attributes
              straps
              eta
              notes
              reference
              travelTime
              services
              adr
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              order {
                __typename
                id
                customer
                reference
                comment
                price
                date
                status
                payment
                termOfPayment
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  nextToken
                  startedAt
                }
                event {
                  __typename
                  nextToken
                  startedAt
                }
                truck {
                  __typename
                  id
                  name
                  plate
                  trailerPlate
                  ignitionState
                  deviceID
                  lastSignalTime
                  heading
                  speed
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              route {
                __typename
                items {
                  __typename
                  id
                  sequence
                  name
                  address
                  housenumber
                  street
                  zip
                  city
                  country
                  reference
                  contact
                  contactphone
                  notes
                  eta
                  opening
                  closing
                  service
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              cargo {
                __typename
                items {
                  __typename
                  id
                  reference
                  category
                  packaging
                  quantity
                  weight
                  height
                  length
                  width
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
            }
            nextToken
            startedAt
          }
          event {
            __typename
            items {
              __typename
              id
              type
              time
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              order {
                __typename
                id
                customer
                reference
                comment
                price
                date
                status
                payment
                termOfPayment
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  nextToken
                  startedAt
                }
                event {
                  __typename
                  nextToken
                  startedAt
                }
                truck {
                  __typename
                  id
                  name
                  plate
                  trailerPlate
                  ignitionState
                  deviceID
                  lastSignalTime
                  heading
                  speed
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
            }
            nextToken
            startedAt
          }
          truck {
            __typename
            id
            name
            plate
            trailerPlate
            location {
              __typename
              lat
              lon
            }
            ignitionState
            deviceID
            lastSignalTime
            heading
            speed
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateOrder">>
  >;

  OnUpdateOrderListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateOrder">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateOrder {
        onUpdateOrder {
          __typename
          id
          customer
          reference
          comment
          price
          date
          status
          payment
          termOfPayment
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          transport {
            __typename
            items {
              __typename
              id
              customer
              price
              costofgoods
              distance
              trailer
              attributes
              straps
              eta
              notes
              reference
              travelTime
              services
              adr
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              order {
                __typename
                id
                customer
                reference
                comment
                price
                date
                status
                payment
                termOfPayment
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  nextToken
                  startedAt
                }
                event {
                  __typename
                  nextToken
                  startedAt
                }
                truck {
                  __typename
                  id
                  name
                  plate
                  trailerPlate
                  ignitionState
                  deviceID
                  lastSignalTime
                  heading
                  speed
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              route {
                __typename
                items {
                  __typename
                  id
                  sequence
                  name
                  address
                  housenumber
                  street
                  zip
                  city
                  country
                  reference
                  contact
                  contactphone
                  notes
                  eta
                  opening
                  closing
                  service
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              cargo {
                __typename
                items {
                  __typename
                  id
                  reference
                  category
                  packaging
                  quantity
                  weight
                  height
                  length
                  width
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
            }
            nextToken
            startedAt
          }
          event {
            __typename
            items {
              __typename
              id
              type
              time
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              order {
                __typename
                id
                customer
                reference
                comment
                price
                date
                status
                payment
                termOfPayment
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  nextToken
                  startedAt
                }
                event {
                  __typename
                  nextToken
                  startedAt
                }
                truck {
                  __typename
                  id
                  name
                  plate
                  trailerPlate
                  ignitionState
                  deviceID
                  lastSignalTime
                  heading
                  speed
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
            }
            nextToken
            startedAt
          }
          truck {
            __typename
            id
            name
            plate
            trailerPlate
            location {
              __typename
              lat
              lon
            }
            ignitionState
            deviceID
            lastSignalTime
            heading
            speed
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateOrder">>
  >;

  OnDeleteOrderListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteOrder">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteOrder {
        onDeleteOrder {
          __typename
          id
          customer
          reference
          comment
          price
          date
          status
          payment
          termOfPayment
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          transport {
            __typename
            items {
              __typename
              id
              customer
              price
              costofgoods
              distance
              trailer
              attributes
              straps
              eta
              notes
              reference
              travelTime
              services
              adr
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              order {
                __typename
                id
                customer
                reference
                comment
                price
                date
                status
                payment
                termOfPayment
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  nextToken
                  startedAt
                }
                event {
                  __typename
                  nextToken
                  startedAt
                }
                truck {
                  __typename
                  id
                  name
                  plate
                  trailerPlate
                  ignitionState
                  deviceID
                  lastSignalTime
                  heading
                  speed
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              route {
                __typename
                items {
                  __typename
                  id
                  sequence
                  name
                  address
                  housenumber
                  street
                  zip
                  city
                  country
                  reference
                  contact
                  contactphone
                  notes
                  eta
                  opening
                  closing
                  service
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              cargo {
                __typename
                items {
                  __typename
                  id
                  reference
                  category
                  packaging
                  quantity
                  weight
                  height
                  length
                  width
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
            }
            nextToken
            startedAt
          }
          event {
            __typename
            items {
              __typename
              id
              type
              time
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              order {
                __typename
                id
                customer
                reference
                comment
                price
                date
                status
                payment
                termOfPayment
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                transport {
                  __typename
                  nextToken
                  startedAt
                }
                event {
                  __typename
                  nextToken
                  startedAt
                }
                truck {
                  __typename
                  id
                  name
                  plate
                  trailerPlate
                  ignitionState
                  deviceID
                  lastSignalTime
                  heading
                  speed
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
            }
            nextToken
            startedAt
          }
          truck {
            __typename
            id
            name
            plate
            trailerPlate
            location {
              __typename
              lat
              lon
            }
            ignitionState
            deviceID
            lastSignalTime
            heading
            speed
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteOrder">>
  >;

  OnCreateEventListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEvent">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateEvent {
        onCreateEvent {
          __typename
          id
          type
          time
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          order {
            __typename
            id
            customer
            reference
            comment
            price
            date
            status
            payment
            termOfPayment
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              items {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
            event {
              __typename
              items {
                __typename
                id
                type
                time
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            truck {
              __typename
              id
              name
              plate
              trailerPlate
              location {
                __typename
                lat
                lon
              }
              ignitionState
              deviceID
              lastSignalTime
              heading
              speed
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEvent">>
  >;

  OnUpdateEventListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEvent">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateEvent {
        onUpdateEvent {
          __typename
          id
          type
          time
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          order {
            __typename
            id
            customer
            reference
            comment
            price
            date
            status
            payment
            termOfPayment
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              items {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
            event {
              __typename
              items {
                __typename
                id
                type
                time
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            truck {
              __typename
              id
              name
              plate
              trailerPlate
              location {
                __typename
                lat
                lon
              }
              ignitionState
              deviceID
              lastSignalTime
              heading
              speed
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEvent">>
  >;

  OnDeleteEventListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEvent">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteEvent {
        onDeleteEvent {
          __typename
          id
          type
          time
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          order {
            __typename
            id
            customer
            reference
            comment
            price
            date
            status
            payment
            termOfPayment
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            transport {
              __typename
              items {
                __typename
                id
                customer
                price
                costofgoods
                distance
                trailer
                attributes
                straps
                eta
                notes
                reference
                travelTime
                services
                adr
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                route {
                  __typename
                  nextToken
                  startedAt
                }
                cargo {
                  __typename
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
            event {
              __typename
              items {
                __typename
                id
                type
                time
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                order {
                  __typename
                  id
                  customer
                  reference
                  comment
                  price
                  date
                  status
                  payment
                  termOfPayment
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
              }
              nextToken
              startedAt
            }
            truck {
              __typename
              id
              name
              plate
              trailerPlate
              location {
                __typename
                lat
                lon
              }
              ignitionState
              deviceID
              lastSignalTime
              heading
              speed
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEvent">>
  >;

  OnCreateTruckListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTruck">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTruck {
        onCreateTruck {
          __typename
          id
          name
          plate
          trailerPlate
          location {
            __typename
            lat
            lon
          }
          ignitionState
          deviceID
          lastSignalTime
          heading
          speed
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTruck">>
  >;

  OnUpdateTruckListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTruck">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTruck {
        onUpdateTruck {
          __typename
          id
          name
          plate
          trailerPlate
          location {
            __typename
            lat
            lon
          }
          ignitionState
          deviceID
          lastSignalTime
          heading
          speed
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTruck">>
  >;

  OnDeleteTruckListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTruck">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTruck {
        onDeleteTruck {
          __typename
          id
          name
          plate
          trailerPlate
          location {
            __typename
            lat
            lon
          }
          ignitionState
          deviceID
          lastSignalTime
          heading
          speed
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTruck">>
  >;
}
