import { Injectable } from '@angular/core';
import { APIService } from '../API.service';  
import * as DBTypes from '../API.service';
//import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { Auth } from 'aws-amplify';
import awsconfig from '../../aws-exports';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor( 
      private _api:APIService,
      ) { 
        
  /*      const client = new AWSAppSyncClient({
          url: awsconfig.aws_appsync_graphqlEndpoint,
          region: awsconfig.aws_appsync_region,
          auth: {
            type: AUTH_TYPE.AWS_IAM,
            credentials: () => Auth.currentCredentials(),
          },
        });
    */    
      }
      
    public createSubscripption()
    {
      
       try
     {
       
      var event:DBTypes.CreateEventInput = {  type: DBTypes.EventType.accident, time: new Date().toISOString()
      
      }
      
      this._api.CreateEvent(event)  
       
       
     } catch (e) {
       //console.log(e);
       }
     
     try{   
        	this._api.OnCreateEventListener.subscribe({
			      	next: async (x: any) => {
			          		//console.log('Next Scubscription', x);
				          	//console.log('Sub Value', x.value.data.onCreateEvent);
					
			//		          this._events.publish({name:'data:CreateCargo',params:x.value.data.onCreateCargo}); 
					          
				      //		this._notif.scheduleNowWithIcon("Booking confirmed", "Please navigate to "  );
					}
				
			})
		} catch (e) {
		  //console.log(e);
		}
      
    }
      
    public createRequest(transport):string
    {
     
      try {
          
          const query:DBTypes.CreateTransportInput = { 
            
            travelTime: transport.travelTime, 
            distance: transport.distance,
            eta: transport.eta,  
            
          };
          
         ////console.log('query', query);
          var transportID:any= this._api.CreateTransport(query);
          
          
        } catch (e) 
        {
         ////console.log('Error in CreateTransport', e);
        }
        
        try {
          
          
          for (var i = 0; i < transport.waypoints.length; i++)
          {
          
            const wpquery:DBTypes.CreateWaypointInput = { 
              waypointTransportId: transportID, 
              sequence: i,
              location: {
                        lat: transport.waypoints[i].lat,
                        lon: transport.waypoints[i].lng,
                        }, 
              address: transport.waypoints[i].address, 
              city : transport.waypoints[i].city,
              country: transport.waypoints[i].country,
              zip: transport.waypoints[i].zip, 
              housenumber: transport.waypoints[i].housenumber, 
              street: transport.waypoints[i].street

            };
            
           ////console.log('query', wpquery);
            this._api.CreateWaypoint(wpquery);
            
          }
          
        } catch (e) 
        {
         ////console.log('Error in CreateWaypoint', e);
        }
        
        return transportID.id;
    }
}
