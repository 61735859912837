  <div [formGroup]="loading">
    <mat-form-field  appearance="outline" class="example-full-width">
            <mat-label>{{label}}</mat-label>
            <mat-icon matPrefix *ngIf="field.touched && field.valid && (!field.errors && field.dirty )"  [style.color]="'#39a42b'">check_circle</mat-icon>
            <mat-icon matSuffix  matTooltip="Bitte geben Sie die Adresse der Ladestelle ein, die mit einem LKW erreichbar ist." [style.color]="'#c4c4c4'">help</mat-icon>
            <input matInput (keydown.enter)="$event.preventDefault()" style="width:500px!important" placeholder="Bitte vollständige Adresse eingeben" [matAutocomplete]="auto" [formControlName]="field.value.name" required>
            <mat-autocomplete panelWidth="600" style="width:600px!important;" #auto="matAutocomplete" (optionSelected)="chooseItem($event.option.value, autocompleteItems, trans[stop])" [displayWith]="displayFn(autocompleteItems)">
                <mat-option *ngFor="let item of autocompleteItems " [value]="item.id">
                <span *ngIf="item.resultType=='houseNumber'">{{item.address.label}}</span>
                <span *ngIf="item.resultType=='street'">{{item.address.label}}</span>
                <span *ngIf="item.resultType=='locality'">{{item.address.postalCode}} - {{item.address.city}} - {{item.address.countryName}}</span> 
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
  </div>