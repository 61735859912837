import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../modules/material/material.module';
import { AuthenticateComponent } from '../components/authenticate/authenticate.component';
import { DatePipe } from '@angular/common';
import { MapService } from '../services/map.service';
//import {/* AmplifyAngularModule, AmplifyIonicModule, */AmplifyService } from 'aws-amplify-angular';
import { AmplifyAuthenticatorModule  } from '@aws-amplify/ui-angular';
import { BrowserModule } from '@angular/platform-browser';
import { HomePage } from './home.page';

import { DmlCommonModule } from '../modules/dml-common/dml-common.module'
import { LoadingPointComponent } from '../components/loading-point/loading-point.component'
import { LoadingDateSelectorComponent } from '../components/loading-date-selector/loading-date-selector.component'

@NgModule({
    imports: [
        BrowserModule,
        DatePipe,
        CommonModule,
        FormsModule,
        IonicModule,
        MaterialModule,
        ReactiveFormsModule,
        DmlCommonModule,
        AmplifyAuthenticatorModule,
        RouterModule.forChild([
            {
                path: '',
                component: HomePage
            }
        ])
    ],
    providers: [
        DatePipe,
        MapService
    ],
    declarations: [HomePage, AuthenticateComponent,LoadingPointComponent,LoadingDateSelectorComponent ],
      exports: [
    LoadingPointComponent,
    LoadingDateSelectorComponent
  ],

})
export class HomePageModule {}
