import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Observable, EMPTY} from 'rxjs';
import { map, startWith, switchMap, filter } from 'rxjs/operators';
import { MapService } from '../../services/map.service'
import { DatePipe } from '@angular/common';

@Component({
  selector: 'dml-loading-date-selector',
  templateUrl: './loading-date-selector.component.html',
  styleUrls: ['./loading-date-selector.component.scss'],
})
export class LoadingDateSelectorComponent implements OnInit {
  
 @Input() trans: any;
 @Input() loading: FormGroup;
 @Input() dateField: string;
 @Input() field: FormControl;
 @Input() stop: number;
 @Input() label: string;
 @Input() min: number;
 @Input() max: number;
 
 @Output() change: EventEmitter<any> = new EventEmitter<any>()
 
 countryHolidays:any = [];
  
  dateClass = (d: Date) => {
    var date = d.getDay();
    
    for ( var i = 0; i < this.countryHolidays.length; i++)
    {
      if( this.countryHolidays[i].date.day == d.getDate() &&
          this.countryHolidays[i].date.month == d.getMonth()+1 &&
          this.countryHolidays[i].date.year == d.getFullYear()) return "calendar-holiday";
    }
    
    if (date == 6) return "calendar-saturday" 
    if (date == 0) return "calendar-sunday"
    else return undefined;
  }
  
    constructor(
      private _map: MapService,
      private _dateAdapter: DatePipe, 
      ) { }

  ngOnInit() {
    
   //console.log('INit', this.loading, this.field, this.trans);
    if (this.loading.get(this.loading.value.name).value.startsWith('here:'))
    {
      
      let t:Date = new Date();
      let r = this._dateAdapter.transform(Date.now(), 'dd-MM-yyyy');
      let u = new Date(t.setMonth(t.getMonth() + 6));   
      let s = this._dateAdapter.transform(u, 'dd-MM-yyyy');
      this._map.getBankHoliday(r.substr(0,10), s.substr(0,10), this._map.getCountryISO2(this.trans.country), this._map.getCountyISO2(this.trans.county)).subscribe
          ( result => { 
              this.countryHolidays = result;
              this.trans.localHolidays = result
         //    //console.log('LocalHolidys', result, this.trans)
          })
    }
    
    this.loading.get(this.dateField).valueChanges.pipe(
     switchMap( x => {
       
    //console.log('Changed date', x)
      if(x)// && typeof x.date != "undefined")
      {
       //console.log("THis trans", x)
        let t:Date = new Date();
       // t.setDate();
       //console.log("t", t)
        let r = this._dateAdapter.transform(t, 'dd-MM-yyyy');
        let u:Date = new Date(t.setMonth(t.getMonth() + 6)); 
       //console.log(u)
        let s = this._dateAdapter.transform(u, 'dd-MM-yyyy').toString();;
        this._map.getBankHoliday(t.toString().substr(0,10), s.substr(0,10), this._map.getCountryISO2(this.trans.country), this._map.getCountyISO2(this.trans.county)).subscribe
            ( result => { 
                this.countryHolidays = result;
                this.trans.localHolidays = result
               //console.log('LocalHolidys', result, this.trans)
            })
                  
      }
      
        return EMPTY
      })
          ) .subscribe()

    }
  
    weekdayFilter = (d: Date): boolean => {
    
    if (d)
    { const day = d.getDay();
    
    for ( var i = 0; i < this.countryHolidays.length; i++)
    {
      if( this.countryHolidays[i].date.day == d.getDate() &&
          this.countryHolidays[i].date.month == d.getMonth()+1 &&
          this.countryHolidays[i].date.year == d.getFullYear()) return false;
    }
    
    // Prevent Saturday and Sunday from being selected.
    return (day !== 0); //&& day !== 6
    }
    else return true
  }
  
  setEventDate(e:any , t:any, f:FormControl)
  {
  //console.log('EE', e, f)
    var value: Date = new Date(e.value);
    //value.setMinutes( value.getMinutes() - value.getTimezoneOffset())
    value.setHours( value.getHours() + t.timeRange[1])
    var c = this._dateAdapter.transform(value.toISOString().substr(0,10), 'yyyy-MM-dd');
    
    t.date = c;
    f.setValue(c);
    f.markAsTouched();
    f.updateValueAndValidity();
    this.change.emit(this.trans);
  }
}
