import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, catchError,shareReplay } from 'rxjs/operators';
import { EMPTY } from 'rxjs'
declare var H: any;


@Injectable({
  providedIn: 'root'
})

export class MapService{
    public query: String;
    private platform: any;
    private routing: any;
    private res: any;

    
    geoAccuracy:number;
    geoAddress: string;
    geocoder:any;
    places:any;
    
    watchLocationUpdates:any; 
    loading:any;
    isWatching:boolean;
    
    
    accel = null
    ori = null
    
    constructor(
        private zone: NgZone,
        private _http: HttpClient,
        ) 
    { 
        
       //////console.log('HereMap Service Constructor');
        
        this.platform = new H.service.Platform({
            apikey: "h3oSo8EI6WGods8OHU6nN98WeXbcJ0M9UE40qCoqIVc"
        });
        
        this.routing = this.platform.getRoutingService();
        this.geocoder = this.platform.getGeocodingService();
        this.places = this.platform.getPlacesService();
    }
    
    public reverseGeocode(query:any):Subject<any>  {
        let values:Subject<any> = new Subject<any>();
        //////console.log('searchtext', query);
        
        var geocodingParams = {
            "prox": query.lat + "," + query.lng + ',100',
            "mode": "retrieveAddresses",
            "maxresults": "1",
            "gen": "9"},
        onResult = function(result:any) {
            values.next(result);
            //////console.log('Inner Reverse:', result);
            },
            onError = function(error:any) {
            //////console.log(error);
            };
           
            //////console.log('Inner Reverse:', geocodingParams);
            
            this.geocoder.reverseGeocode(geocodingParams, onResult, onError); 
            
        return values;
    }
           
    public forwardGeocode(query:any):Subject<any>  {
        let values:Subject<any> = new Subject<any>();
        //////console.log('searchtext', query);
        
    
           var geocodingParams = {
                 searchText: query,
                 countryfocus: 'DEU,EEE',
                 bbox: '73,-35;35,73',
                 "maxresults": "10"
            },
        
            onResult =  function(result:any) {
                    values.next(result);
                    //////console.log('Inner forwardGeocode:', result.Response.View[0]);
                
                },
                onError = function(error:any) {
                //////console.log(error);
                };
               
           // var geocoder = this.platform.getGeocodingService();
            this.geocoder.search(geocodingParams, onResult, onError); 
              
            //////console.log(values);
 
        return values;
    }
    
    public checkMileage(start, dest, truck):Subject<any>
    {
       let values:Subject<any> = new Subject<any>();
      
        var checkdistparams = {};
        if (truck.vehicleLength>0) checkdistparams = {...checkdistparams, ...{"length": truck.vehicleLength/100,}} 
        if (truck.vehicleHeight>0) checkdistparams = {...checkdistparams, ...{"height": truck.vehicleHeight/100,}}
        if (truck.vehicleWidth>0)  checkdistparams = {...checkdistparams, ...{"width": truck.vehicleWidth/100,}}
        if (truck.limitedWeight>0) checkdistparams = {...checkdistparams, ...{"limitedWeight": truck.limitedWeight,}}
        if (truck.trailersCount>0) checkdistparams = {...checkdistparams, ...{"trailerCount": truck.trailersCount,}}
        if (truck.axleCount>0)     checkdistparams = {...checkdistparams, ...{"axleCount": truck.axleCount,}}
        if (truck.weightPerAxle>0) checkdistparams = {...checkdistparams, ...{"weightPerAxle": truck.weightPerAxle,}}
        if (truck.destination.lat != 0) checkdistparams = {...checkdistparams, ...{"waypoint2": "geo!"+truck.destination.lat + "," + truck.destination.lng,}}
        
         var checkdistparams2 = {...checkdistparams, ...{
                                "mode": truck.route + ";truck;traffic:" + truck.trafficUpdates,
                                "waypoint0": "geo!"+start.lat + "," + start.lng,
                                "waypoint1": "geo!"+ dest.lat + "," + dest.lon,
                                "truckType" : "truck",
                                "tunnelCategory": truck.avoidTunnelCategory.toString(),
                                "shippedHazardousGoods": truck.cargoHazardousGoods.toString(),
                                "routeattributes": 'sc',
                                "representation": "overview",
                                "jsonattributes":9,
                            }};
                            
        this.routing.calculateRoute(checkdistparams2, 
                    function(result:any) {
                        //////console.log('Inner checkMileage:', result);
                         let res = {start: start, dest:dest, route: result.response.route}
                         values.next(res);
                         values.complete();
                    },
                    function(error:any) {
                        //////console.log(error);
                        }
            );
            
        return values;
    }
    
    public getIsoline(truck, range):Subject<any>
    {
       let values:Subject<any> = new Subject<any>();
      
       var params = {
            "range": range*60,
            "rangetype": "time",
            "departure": "now",
            "start": truck.currentPosition.lat + "," + truck.currentPosition.lng,
            "truckType" : "truck",
            "tunnelCategory": truck.avoidTunnelCategory.toString(),
            "shippedHazardousGoods": truck.cargoHazardousGoods.toString(),
          }

        if (truck.route != "shortest") params = {...params, ...{"mode": truck.route + ";truck;traffic:" + truck.trafficUpdates,}}
        else {params = {...params, ...{"mode": "balanced;truck;traffic:" + truck.trafficUpdates,}}}
            
        if (truck.vehicleLength>0) params = {...params, ...{"length": truck.vehicleLength/100,}} 
        if (truck.vehicleHeight>0) params = {...params, ...{"height": truck.vehicleHeight/100,}}
        if (truck.vehicleWidth>0)  params = {...params, ...{"width": truck.vehicleWidth/100,}}
        if (truck.limitedWeight>0) params = {...params, ...{"limitedWeight": truck.limitedWeight,}}
        if (truck.trailersCount>0) params = {...params, ...{"trailerCount": truck.trailersCount,}}
        //if (this.myDriver.truck.axleCount>0)     params = {...params, ...{"axleCount": this.myDriver.truck.axleCount,}} //not supported yet in Isoline
        if (truck.weightPerAxle>0) params = {...params, ...{"weightPerAxle": truck.weightPerAxle,}}
     
         //////console.log("Params", params)
        
        this.routing.calculateIsoline(params, data => {
            if(data.response) {
                //////console.log('Inner Isonline', data.response)
                values.next(data)
                values.complete();
            }
            
        }, error => {
            ////console.error(error);
        });

        return values;
    }
    
    public suggest (query:any):Subject<any>  {
        let values:Subject<any> = new Subject<any>();
        //////console.log('searchtext', query);
        
    
      var placesService= this.platform.getPlacesService(),
      parameters = {q: query},

      onResult = function(result:any) {
       values.next(result);
        //////console.log('Inner Function:', result.Response.View[0]);
                
        },
      onError = function(error:any) {
        //////console.log(error);
      };
      placesService.suggest(parameters, onResult, onError);
      
      return values;  
    }
    
 /*
   
  public autosuggest(paras:any):Observable<any>
  {
    var parameters = {...paras,... {headers:{}, apikey: "HY6rcC3aqdZ_swJRCAshe0SFNlQlLgXjSZ415PtZums"}}
    ////console.log(parameters);
    let URL= 'https://places.cit.api.here.com/places/v1/autosuggest?app_id=FmY0jZFqDN62P5di4Zrl&app_code=z_fhC6T8qWrPe4JahQcA-Q& addressFilter=EEE&size=10&result_types=address&in=-35,34,70,73&q='+paras;
    
    return this.http.get(URL) 
  }
*/

    getBankHoliday(start, end, country, region)
    {
        
      const httpOptions = {
      headers: new HttpHeaders({
      //  'Content-Type': 'multipart/form-data', //'application/json',
     //   'Access-Control-Allow-Origin':'*',
     //   'Access-Control-Allow-Headers': '*',// 'Origin, X-Requested-With, Content-Type, Accept'.,
//        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
        
         })
        };
     
    /*    let params= {
            headers:    { 'Access-Control-Allow-Origin': '*' },
                    { 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'},
                    { 'Allow-Control-Allow-Methods': '*'},
                    { 'Content-Type', 'application/json; charset=utf-8'}
        }*/
        
        //let URL= 'https://date.nager.at/api/v2/publicholidays/' + year + '/' + country;
        let URL = 'https://kayaposoft.com/enrico/json/v2.0/?action=getHolidaysForDateRange&fromDate=' +start+ '&toDate=' + end +'&country=' + country+ '&holidayType=public_holiday' + '&region=' + region;// &region=dc
       //console.log(URL)
          return this._http.get(URL, httpOptions).pipe(
                retry(3), 
                catchError(() => 
                    { 
                       //console.log('Error')
                        return EMPTY;
                    }), 
                shareReplay())
    }

    getCountryISO2(countryCode){

        var countryISOMapping = {
          AFG: "AF",
          ALA: "AX",
          ALB: "AL",
          DZA: "DZ",
          ASM: "AS",
          AND: "AD",
          AGO: "AO",
          AIA: "AI",
          ATA: "AQ",
          ATG: "AG",
          ARG: "AR",
          ARM: "AM",
          ABW: "AW",
          AUS: "AU",
          AUT: "AT",
          AZE: "AZ",
          BHS: "BS",
          BHR: "BH",
          BGD: "BD",
          BRB: "BB",
          BLR: "BY",
          BEL: "BE",
          BLZ: "BZ",
          BEN: "BJ",
          BMU: "BM",
          BTN: "BT",
          BOL: "BO",
          BIH: "BA",
          BWA: "BW",
          BVT: "BV",
          BRA: "BR",
          VGB: "VG",
          IOT: "IO",
          BRN: "BN",
          BGR: "BG",
          BFA: "BF",
          BDI: "BI",
          KHM: "KH",
          CMR: "CM",
          CAN: "CA",
          CPV: "CV",
          CYM: "KY",
          CAF: "CF",
          TCD: "TD",
          CHL: "CL",
          CHN: "CN",
          HKG: "HK",
          MAC: "MO",
          CXR: "CX",
          CCK: "CC",
          COL: "CO",
          COM: "KM",
          COG: "CG",
          COD: "CD",
          COK: "CK",
          CRI: "CR",
          CIV: "CI",
          HRV: "HR",
          CUB: "CU",
          CYP: "CY",
          CZE: "CZ",
          DNK: "DK",
          DJI: "DJ",
          DMA: "DM",
          DOM: "DO",
          ECU: "EC",
          EGY: "EG",
          SLV: "SV",
          GNQ: "GQ",
          ERI: "ER",
          EST: "EE",
          ETH: "ET",
          FLK: "FK",
          FRO: "FO",
          FJI: "FJ",
          FIN: "FI",
          FRA: "FR",
          GUF: "GF",
          PYF: "PF",
          ATF: "TF",
          GAB: "GA",
          GMB: "GM",
          GEO: "GE",
          DEU: "DE",
          GHA: "GH",
          GIB: "GI",
          GRC: "GR",
          GRL: "GL",
          GRD: "GD",
          GLP: "GP",
          GUM: "GU",
          GTM: "GT",
          GGY: "GG",
          GIN: "GN",
          GNB: "GW",
          GUY: "GY",
          HTI: "HT",
          HMD: "HM",
          VAT: "VA",
          HND: "HN",
          HUN: "HU",
          ISL: "IS",
          IND: "IN",
          IDN: "ID",
          IRN: "IR",
          IRQ: "IQ",
          IRL: "IE",
          IMN: "IM",
          ISR: "IL",
          ITA: "IT",
          JAM: "JM",
          JPN: "JP",
          JEY: "JE",
          JOR: "JO",
          KAZ: "KZ",
          KEN: "KE",
          KIR: "KI",
          PRK: "KP",
          KOR: "KR",
          KWT: "KW",
          KGZ: "KG",
          LAO: "LA",
          LVA: "LV",
          LBN: "LB",
          LSO: "LS",
          LBR: "LR",
          LBY: "LY",
          LIE: "LI",
          LTU: "LT",
          LUX: "LU",
          MKD: "MK",
          MDG: "MG",
          MWI: "MW",
          MYS: "MY",
          MDV: "MV",
          MLI: "ML",
          MLT: "MT",
          MHL: "MH",
          MTQ: "MQ",
          MRT: "MR",
          MUS: "MU",
          MYT: "YT",
          MEX: "MX",
          FSM: "FM",
          MDA: "MD",
          MCO: "MC",
          MNG: "MN",
          MNE: "ME",
          MSR: "MS",
          MAR: "MA",
          MOZ: "MZ",
          MMR: "MM",
          NAM: "NA",
          NRU: "NR",
          NPL: "NP",
          NLD: "NL",
          ANT: "AN",
          NCL: "NC",
          NZL: "NZ",
          NIC: "NI",
          NER: "NE",
          NGA: "NG",
          NIU: "NU",
          NFK: "NF",
          MNP: "MP",
          NOR: "NO",
          OMN: "OM",
          PAK: "PK",
          PLW: "PW",
          PSE: "PS",
          PAN: "PA",
          PNG: "PG",
          PRY: "PY",
          PER: "PE",
          PHL: "PH",
          PCN: "PN",
          POL: "PL",
          PRT: "PT",
          PRI: "PR",
          QAT: "QA",
          REU: "RE",
          ROU: "RO",
          RUS: "RU",
          RWA: "RW",
          BLM: "BL",
          SHN: "SH",
          KNA: "KN",
          LCA: "LC",
          MAF: "MF",
          SPM: "PM",
          VCT: "VC",
          WSM: "WS",
          SMR: "SM",
          STP: "ST",
          SAU: "SA",
          SEN: "SN",
          SRB: "RS",
          SYC: "SC",
          SLE: "SL",
          SGP: "SG",
          SVK: "SK",
          SVN: "SI",
          SLB: "SB",
          SOM: "SO",
          ZAF: "ZA",
          SGS: "GS",
          SSD: "SS",
          ESP: "ES",
          LKA: "LK",
          SDN: "SD",
          SUR: "SR",
          SJM: "SJ",
          SWZ: "SZ",
          SWE: "SE",
          CHE: "CH",
          SYR: "SY",
          TWN: "TW",
          TJK: "TJ",
          TZA: "TZ",
          THA: "TH",
          TLS: "TL",
          TGO: "TG",
          TKL: "TK",
          TON: "TO",
          TTO: "TT",
          TUN: "TN",
          TUR: "TR",
          TKM: "TM",
          TCA: "TC",
          TUV: "TV",
          UGA: "UG",
          UKR: "UA",
          ARE: "AE",
          GBR: "GB",
          USA: "US",
          UMI: "UM",
          URY: "UY",
          UZB: "UZ",
          VUT: "VU",
          VEN: "VE",
          VNM: "VN",
          VIR: "VI",
          WLF: "WF",
          ESH: "EH",
          YEM: "YE",
          ZMB: "ZM",
          ZWE: "ZW",
          XKX: "XK"
        }
        return countryISOMapping[countryCode]
}

 getCountyISO2(countyName){

        var countyISOMapping = {
          'Nordrhein-Westfalen': "NW",
          'Baden-Württemberg':"bw", 
          'Bayern':"by", 
          'Berlin':"be", 
          'Brandenburg':"bb",
          'Bremen':"hb", 
          'Hamburg':"hh", 
          'Hessen':"he", 
          'Mecklenburg-Vorpommern':"mv", 
          'Niedersachsen':"ni", 
          'Rheinland-Pfalz':"rp",
          'Saarland':"sl", 
          'Sachsen':"sn", 
          'Sachsen-Anhalt':"st", 
          'Schleswig-Holstein':"sh", 
          'Thüringen':"th"
        }
        return countyISOMapping[countyName]
}

    
    async clearWatch() {
        
       
  
   }
}