import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MapService } from './services/map.service';
import { HttpService } from './services/http.service';
import { AuthService} from './services/auth.service'
import { DatabaseService} from './services/database.service'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './modules/material/material.module'
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';

import { CookieService} from 'ngx-cookie-service'
//import {/*AmplifyAngularModule, */ AmplifyService } from 'aws-amplify';

import { Amplify } from 'aws-amplify';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { HomePageModule } from './home/home.module'; 
import { OrderPageModule } from './pages/order/order.module'; 
registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        
        CommonModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        AmplifyAuthenticatorModule,
        HttpClientModule, 
        ReactiveFormsModule,
        MaterialModule,
        HomePageModule,
        OrderPageModule
    ],
    declarations: [AppComponent],
    providers: [
        DatePipe,
        MapService,
        HttpService,
        AuthService,
        DatabaseService,
        CookieService,
//        StatusBar,
//        SplashScreen,
        //AmplifyService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
