import * as DBTypes from '../API.service'; 
import { Transport } from './transport'

export class Cargo {
    id: string
	transport: Transport 
	reference: string
	category: string
	packaging: DBTypes.Packaging
	quantity: number
	weight: number
	height: number
	length: number
	width: number
 
}
