import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl,FormArray } from '@angular/forms';
import { Observable, EMPTY} from 'rxjs';
import { tap, map, startWith, switchMap, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { HttpService } from '../../services/http.service'
import { of } from 'rxjs'; 
@Component({
  selector: 'dml-loading-point',
  templateUrl: './loading-point.component.html',
  styleUrls: ['./loading-point.component.scss'],
})
export class LoadingPointComponent implements OnInit {
 @Input() trans: any;
 @Input() loading:FormArray;
 @Input() field: FormControl;
 @Input() stop: number;
 @Input() label: string;
 
 formContName: string;
 
 @Output() change: EventEmitter<any> = new EventEmitter<any>()
 
 public autocompleteItems: Observable<any>;
 
  constructor(
       private http: HttpService,
       ) { }

  ngOnInit() {
      
    //console.log('Trans', this.trans)
      
     this.field.get(this.field.value.name).valueChanges.pipe(
     map( x => {
           //console.log(x) 
            return x}),
      debounceTime(400),
      distinctUntilChanged(),
      filter(val => val != null),
     // filter(val => val.trim() != ""),
     // filter(val => !val.startsWith("here:")),
      switchMap(search => {
    
            if(typeof search != 'undefined' && search != "") 
            return this.http.getPlace(search).pipe(
                        tap(data => {}))
                        
            else if(search == "") 
                    return EMPTY;

        }))
          .subscribe(val => {
                            
                            if(val.items&&val.items.length >0) {
                                var r = val.items.filter(r =>  r.resultType == "locality" || r.resultType == "street" || r.resultType == "houseNumber" )
                                r = r.filter(d => d.localityType != "district")
                                this.autocompleteItems = r;
                                
                            }
                        })
    }
  
   displayFn(options: any): (id: number) => string | null {
      return (id: number) => { 
        if(typeof options != 'undefined')
        {
        const correspondingOption = Array.isArray(options) ? options.find(option => option.id === id) : null;
        return correspondingOption ? correspondingOption.address.label : '';
        }
    }
  }
  
   chooseItem(item: any, options:any, value:any) { 
    
  //console.log(item, options, value)
   if (typeof options != 'undefined')
    {
      const correspondingOption = options.find(option => option.id == item) ;
      value.lat = correspondingOption.position.lat;
      value.lng = correspondingOption.position.lng;
      value.address = correspondingOption.address.label;
      if (correspondingOption.address.postalCode) value.zip = correspondingOption.address.postalCode
      if (correspondingOption.address.city) value.city = correspondingOption.address.city
      if (correspondingOption.address.state) value.county = correspondingOption.address.state
      if (correspondingOption.address.countryName) value.country = correspondingOption.address.countryCode
      if (correspondingOption.address.street) value.street = correspondingOption.address.street
      if (correspondingOption.address.houseNumber) value.housenumber = correspondingOption.address.houseNumber
    }
    this.change.emit(this.trans);
   }

}
