 <div [formGroup]="loading">
      <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>{{label}}</mat-label>
          <mat-icon matPrefix *ngIf=" !field.invalid && (!field.errors ) && field.touched"  [style.color]="'#39a42b'">check_circle</mat-icon>
           <mat-hint *ngIf="trans.eta!=null" align="end">ETA: {{trans.eta | date:'long':'CET':'de-DE'}}</mat-hint>
          <input matInput (keydown.enter)="$event.preventDefault()" [name]="field" readonly [matDatepickerFilter]="weekdayFilter" [min]="min" [max]="max"  [matDatepicker]="picker" (dateChange)="setEventDate($event, trans, field)" placeholder="Datum" [formControlName]="dateField">
            <mat-datepicker-toggle matSuffix matTooltip="Bitte wählen Sie das Beladedatum aus!" [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker [dateClass]="dateClass" #picker></mat-datepicker>
            
        </mat-form-field>
 </div>