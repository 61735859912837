import { UntypedFormControl, AbstractControl, AsyncValidatorFn,ValidationErrors, FormGroup } from '@angular/forms';
import { Auth } from 'aws-amplify'
import { Observable, of} from 'rxjs';

export class UserValidator {
    
  static userValidator(): AsyncValidatorFn {
  return (control: UntypedFormControl):Promise<ValidationErrors  | null> | Observable<ValidationErrors | null>  => {
      const ret: ValidationErrors = { userExists: true };
    
        if (control.value == null || control.value === '') {
            return of(ret);
        }
      
      let q = new Promise<ValidationErrors>(
        async (resolve, reject)=>  { 
            var name = control.value;
            if (name == '') {
              resolve(null);
            }
            else {

                if (control.value)
                 {     
                      if(await Auth.currentUserInfo()) resolve(null);
                   
                        Auth.signIn({
                        username: control.value,
                        password: 'testettettte',
                            
                          })
                          .then(data => {
                            
                          })
                          .catch(error => {

                            //The user has already registered so go to the SignIn method
                            if(error['code'] === "UserNotFoundException"){ 
                              resolve(null)
                            }
                            else{
                              
                              resolve(ret)
                            }
                          });
                    }
                           
                        
                 }
            })
                        
       return q;
    }
  }
 }
