import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';
import { MaterialModule } from '../../modules/material/material.module'; 

import { OrderPageRoutingModule } from './order-routing.module';
import { DetailsComponent } from './details/details.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AmplifyAuthenticatorModule  } from '@aws-amplify/ui-angular';
import { BrowserModule } from '@angular/platform-browser';

import { DmlCommonModule } from '../../modules/dml-common/dml-common.module'
import { OrderPage } from './order.page';

@NgModule({
    imports: [
        BrowserModule,
        DatePipe,
        CommonModule,
        FormsModule,
        IonicModule,
        MaterialModule,
        ReactiveFormsModule,
        DmlCommonModule,
  //      AmplifyAngularModule,
//        AmplifyIonicModule,
        OrderPageRoutingModule
    ],
    providers: [
  //      AmplifyService
    ],
    declarations: [OrderPage, DetailsComponent]
})
export class OrderPageModule {}
