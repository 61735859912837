<ion-app>
    <ion-nav #content swipeBackEnabled="false"></ion-nav>

    <ion-menu contentId="content1" [swipeGesture]="false" side="start" type="overlay">
      <ion-header no-border>
        <ion-toolbar>
          <ion-title> DML24 </ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-menu-toggle auto-hide="false" *ngFor="let p of authPages">
            <ion-item [routerDirection]="'root'" [routerLink]="[p.url]" (click)="p.messages=0">
              <ion-icon slot="start" [name]="p.icon"></ion-icon>
              <ion-badge slot= "end" color="primary" *ngIf="p.messages>0">{{p.messages}}</ion-badge>
              <ion-label>
                {{p.title}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
    </ion-content>
    </ion-menu>
    <ion-router-outlet id="content1" [swipeGesture]="false" main></ion-router-outlet>
<!--  <ion-router-outlet></ion-router-outlet>-->
</ion-app>
