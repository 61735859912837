import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, ActivatedRoute } from '@angular/router'; 
import { EventService } from './services/event.service'

import { Analytics } from 'aws-amplify';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {
  
  public authState = { sender: "appComponent",
                       signedIn: false,
                       groups: [""]};
                       
  public appPages = [
     {
      title: 'Preis',
      url: '/home',
      icon: 'pricetag-outline',
      groups: [''],
      messages: 0,
    },
    {
      title: 'Buchungen',
      url: '/order',
      icon: 'time-outline',
      groups: ['Admins'],
      messages: 0,
    },
 /*   {
      title: 'Dashboard',
      url: '/dashboard',
      icon: 'analytics',
      groups: ['Admins'],
      messages: 0,
    },*/
  ]; 

  showSplash = true; // <-- show animation
  public signedIn: any;
  public authPages:any[]=[];
  
  constructor(
    private platform: Platform,
  //  private splashScreen: SplashScreen,
  //  private statusBar: StatusBar,
    private _events: EventService,
  //  private _router : Router,
  //  private _activatedRoute: ActivatedRoute,
   
  ) {
       //console.log('Init constructor');
      this._events.currentEvent.subscribe(value =>
          {
           //console.log('Event Value', value);
            if(value.name=='data:AuthState'){
              //get value.name 
              this.authState.signedIn = value.params.signedIn;
              this.authState.groups = value.params.groups;
             //console.log('Signed In', this.authState)
            }
      
      this.authPages = [];
      
         while (this.authPages.length) this.authPages.pop()
      
      if(this.authState.signedIn==true)
      {
        for (var i=0; i<this.appPages.length; i++)
        {
          if (this.appPages[i].groups[0]=="") this.authPages.push(this.appPages[i]);
          if (this.appPages[i].groups.find(val => this.authState.groups.includes(val)))
            this.authPages.push(this.appPages[i]);
        }
      }
      
     //console.log(this.appPages, ' Auth', this.authPages)       
      
    })
    
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      
//     this.statusBar.styleDefault();
//     this.splashScreen.hide();
      
  //   //console.log("router", this._router.url);
 //     this._router.navigateByUrl('home');
//      this._router.navigate(['home'], {});
       
 /*     Analytics.autoTrack('pageView', {
      // REQUIRED, turn on/off the auto tracking
      enable: true,
      // OPTIONAL, the event name, by default is 'pageView'
      eventName: 'pageView',
      // OPTIONAL, the attributes of the event, you can either pass an object or a function 
      // which allows you to define dynamic attributes
      attributes: {
          attr: 'attr'
      },
      // when using function
      // attributes: () => {
      //    const attr = somewhere();
      //    return {
      //        myAttr: attr
      //    }
      // },
      // OPTIONAL, by default is 'multiPageApp'
      // you need to change it to 'SPA' if your app is a single-page app like React
      type: 'multiPageApp',
      // OPTIONAL, the service provider, by default is the Amazon Pinpoint
      provider: 'AWSPinpoint',
      // OPTIONAL, to get the current page url
      getUrl: () => {
          // the default function
          return window.location.origin + window.location.pathname;
    }
});*/
    });
  }


}
  