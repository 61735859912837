/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_mobile_analytics_app_id": "24fd5161431747e48caa5957522dedc8",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "24fd5161431747e48caa5957522dedc8",
            "region": "eu-central-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://ud9ltcv3oa.execute-api.eu-central-1.amazonaws.com/live",
            "region": "eu-central-1"
        },
        {
            "name": "DMLGetQuote",
            "endpoint": "https://tis0r1qb2m.execute-api.eu-central-1.amazonaws.com/live",
            "region": "eu-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://52zmr7gambfdzigwti3dhajzfy.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-central-1:df3dea46-68fe-4d20-ad0f-db7d0701de83",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_tPML5LN6c",
    "aws_user_pools_web_client_id": "68bmc31r0h83d4n1l9hk4gqmbp",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "dml24app-live",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://d1kbebh6e7h04u.cloudfront.net"
};


export default awsmobile;
