import { Injectable } from '@angular/core';
import { API } from 'aws-amplify'
import { Subject,AsyncSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
    
@Injectable({
  providedIn: 'root'
})
export class HttpService {
    amplify: any;

  constructor( 
//      public amplify: AmplifyService,
      public http: HttpClient,
      ) { }
  
  public getPlace(term):Subject<any>
  {
    let values:Subject<any> = new Subject<any>();
    
    const data = {  headers: {},
                    response: true,
                    queryStringParameters: {  // OPTIONAL
                    q : encodeURIComponent(term), 
                  }
    }
                  
    
    API.get('DMLGetQuote', '/suggest', data).then((i) => {
        // ... and to get the list after you save it.
        
        values.next(JSON.parse(i.data.response.body));
    })
    .catch((err) => {
        values.error(err);
    })
   
   return values;   
  }
  
  public getRoute(cargo, transport ):AsyncSubject<any>
  {
    let values:AsyncSubject<any> = new AsyncSubject<any>();
    
  ////console.log('getRoute', cargo, transport);
    
    var data = {  headers: {},
                  response: true,
                  queryStringParameters: {  // OPTIONAL
                   
                    mode: 'fastest;truck;traffic:enabled',
                   // routeattributes: 'sc',
                   // legAttributes: 'li'
                  }
    }
                  
  
    var arrival:Date;
    var departure:Date[]=[];
    var waytimeOpening:Date;
    var waytimeClosing:Date;
    var waytimeUnloadOpening;
    var waytimeUnloadClosing:Date[]=[];
    var i= 0;
    
   //console.log(transport)
    for (i= 0; i<transport.waypoints.loading.length;i++ )
    {
      if (transport.waypoints.loading[i].date != "") {
        departure[i] = new Date(transport.waypoints.loading[i].date)
        departure[i].setHours(departure[i].getHours()+transport.waypoints.loading[i].timeRange[1]);
        
        waytimeOpening = new Date(transport.waypoints.loading[i].date)
        waytimeOpening.setHours(waytimeOpening.getHours() + transport.waypoints.loading[i].timeRange[0]);
   
        waytimeClosing = new Date(transport.waypoints.loading[i].date)
        waytimeClosing.setHours(waytimeClosing.getHours()+transport.waypoints.loading[i].timeRange[1])
    
        if(i==0)  data.queryStringParameters = {...data.queryStringParameters, ...{ ["waypoint"+i.toString()]: transport.waypoints.loading[i].lat +','+ transport.waypoints.loading[i].lng + ';closing:' + waytimeClosing.toISOString().slice(0,19),}}
        else      data.queryStringParameters = {...data.queryStringParameters, ...{ ["waypoint"+i.toString()]: /*'geo!stopOver,7200!'+*/transport.waypoints.loading[i].lat +','+ transport.waypoints.loading[i].lng + ';opening:' + waytimeOpening.toISOString().slice(0,19) +';closing:' + waytimeClosing.toISOString().slice(0,19),}}
    }
    else 
      data.queryStringParameters= {...data.queryStringParameters, ...{["waypoint"+i.toString()]:transport.waypoints.loading[i].lat +','+ transport.waypoints.loading[i].lng} }
    }
    
    for (var j= 0; j<transport.waypoints.unloading.length;j++ )
    {
      if (transport.waypoints.unloading[j].date != "") {
        waytimeUnloadOpening = new Date(transport.waypoints.unloading[j].date)
        waytimeUnloadOpening.setHours(waytimeUnloadOpening.getHours() + transport.waypoints.unloading[j].timeRange[0]);
    
        waytimeUnloadClosing[j] = new Date(transport.waypoints.unloading[j].date)
        waytimeUnloadClosing[j].setHours(waytimeUnloadClosing[j].getHours() + transport.waypoints.unloading[j].timeRange[1])
  
        if(j==0 && transport.waypoints.unloading.length==1) data.queryStringParameters = {...data.queryStringParameters = {...data.queryStringParameters, ...{["waypoint"+(i+j).toString()]: transport.waypoints.unloading[j].lat +','+ transport.waypoints.unloading[j].lng + ';opening:' + waytimeUnloadOpening.toISOString().slice(0,19) +';closing:' + waytimeUnloadClosing[j].toISOString().slice(0,19),}}}   
        else if(j>=0 && j < transport.waypoints.unloading.length-1) data.queryStringParameters = {...data.queryStringParameters = {...data.queryStringParameters, ...{["waypoint"+(i+j).toString()]: /*'geo!stopOver,7200!'+*/transport.waypoints.unloading[j].lat +','+ transport.waypoints.unloading[j].lng + ';opening:' + waytimeUnloadOpening.toISOString().slice(0,19) +';closing:' + waytimeUnloadClosing[j].toISOString().slice(0,19),}}}
        else if(j == transport.waypoints.unloading.length-1) data.queryStringParameters = {...data.queryStringParameters = {...data.queryStringParameters, ...{["waypoint"+(i+j).toString()]: transport.waypoints.unloading[j].lat +','+ transport.waypoints.unloading[j].lng + ';opening:' + waytimeUnloadOpening.toISOString().slice(0,19) +';closing:' + waytimeUnloadClosing[j].toISOString().slice(0,19),}}}
      }
      else 
        data.queryStringParameters= {...data.queryStringParameters, ...{["waypoint"+(i+j).toString()]:transport.waypoints.unloading[j].lat +','+ transport.waypoints.unloading[j].lng}} 
        
      
    }
    
    if(departure[0])
      data.queryStringParameters = {...data.queryStringParameters, ...{ departure: departure[0].toISOString().slice(0,19)}};
    
    if(waytimeUnloadClosing[transport.waypoints.unloading.length-1])
    {
        arrival = waytimeUnloadClosing[transport.waypoints.unloading.length-1];
        arrival.setHours(arrival.getHours()-2);
        data.queryStringParameters = {...data.queryStringParameters, ...{ arrival : arrival.toISOString().slice(0,19),}};
    }
      
    API.get('DMLGetQuote', '/route', data).then((i) => {
        // ... and to get the list after you save it.
        //console.log("GetQuote", i)
        values.next(i);
        values.complete();
            })
    .catch((err) => {
        values.error(err);
    })
   
   return values;   
  }
  
  public getPrice(cargo, transport ):Subject<any>
  {
    let values:Subject<any> = new Subject<any>();
    
   const data = {  headers: {},
                  response: true, 
                  queryStringParameters: {  // OPTIONAL
                //    "waypoint0" : transport.waypoints.loading[0].lat+','+ transport.waypoints.loading[0].lng, 
                //    "waypoint1" : transport.waypoints.unloading[0].lat+','+ transport.waypoints.unloading[0].lng ,
                    mode: 'balanced;truck;traffic:enabled',
                    truckType: 'truck',
                   //transportMode: 'truck',
                   //routingMode: 'fast',
                    routeattributes: 'sc',
                    return: 'summary,',
                    //lang: 'de-DE'
                  //  instructionFormat: 'text',
                    representation: 'overview',
                    jsonattributes: '9',
                  }
    }
    
    var i= 0;
    for (i= 0; i<transport.waypoints.loading.length;i++ )
    {
      data.queryStringParameters= {...data.queryStringParameters, ...{["waypoint"+i.toString()]:transport.waypoints.loading[i].lat +','+ transport.waypoints.loading[i].lng+';500'}  }
    }
    
    for (var j= 0; j<transport.waypoints.unloading.length;j++ )
    {
      data.queryStringParameters= {...data.queryStringParameters, ...{["waypoint"+(i+j).toString()]:transport.waypoints.unloading[j].lat +','+ transport.waypoints.unloading[j].lng+';500'}} 
    }
    
/*                  
    //if (transport.waypoints[2] && transport.waypoints[2].lat!=0) 
    data.queryStringParameters = {...data.queryStringParameters, 
                                    ...wps};
*/
    if (transport.waypoints.loading[0] && transport.waypoints.loading[0].date!=0) 
    {
      let dep = new Date(transport.waypoints.loading[0].date);
      dep.setHours(dep.getHours() + transport.waypoints.loading[0].timeRange[0]);
      
      data.queryStringParameters = {...data.queryStringParameters, 
                                    ...{departure: dep.toISOString().slice(0,19)}};
    }



    //console.log('getPrice', data, transport)
    
    API.get('DMLGetQuote', '/quote', data).then((i) => {
        // ... and to get the list after you save it.
        values.next(i);
        values.complete();
    })
    .catch((err) => {
      //////console.log(`Error getting cusotmer: ${err}`)
    })
   
   return values;   
  }
  
  public placeOrder( cargo, organisation, profile, transport ):Subject<any>
  {
    let values:Subject<any> = new Subject<any>();
    
    const body = {  cargo: cargo, 
                    organisation: organisation,
                    profile: profile,
                    transport: transport
    }
                  
    const data = {  headers: {},
                  response: true,
                  body: body
    }
    
 ////console.log("Data:", data)
    
   API.put('DMLGetQuote', '/order', data).then((i) => {
        // ... and to get the list after you save it.
        values.next(i);
        values.complete();
    })
    .catch((err) => {
      //////console.log(`Error getting cusotmer: ${err}`)
    })
   
   return values;   
  }
  
  public checkCompany(org):Subject<any>
  {
    let values:Subject<any> = new Subject<any>();
     
   var data = {  headers: {},
                  response: true,
                  queryStringParameters: {  // OPTIONAL
                    organisation: org
                    
                  }
    }
                  
    
    API.get('DMLGetQuote', '/customer', data).then((i) => {
        // ... and to get the list after you save it.
        values.next(i);
    })
    .catch((err) => {
      //////console.log(`Error getting cusotmer: ${err}`)
    })
   return values;   
    
  }
  
  public checkCompanyApprox(taxNo, street, name, zip_code, city):Subject<any>
  {
    let values:Subject<any> = new Subject<any>();
     
   var data = {  headers: {},
                  response: true,
                  queryStringParameters: {  // OPTIONAL
                    organisation: taxNo,
                    street: street,
                    name: name,
                    zip_code: zip_code,
                    city: city
                    
                  }
    }
                  
   API.get('DMLGetQuote', '/customer', data).then((i) => {
        // ... and to get the list after you save it.
        values.next(i);
    })
    .catch((err) => {
      //////console.log(`Error getting cusotmer: ${err}`)
    })
   return values;   
    
  
  }
  
   public createRequest(organisation, profile, transport, cargo):Subject<any>
   {
     let values:Subject<any> = new Subject<any>();
      
     const body = { organisation: organisation,
                    profile: profile,
                    transport: transport,
                    cargo: cargo
    }
                  
    const data = {  headers: {},
                  response: true,
                  body: body
    }
    
  ////console.log("CreateData:", data)
    
   API.post('DMLGetQuote', '/items', data).then((i) => {
        // ... and to get the list after you save it.
        values.next(i);
        values.complete();
    })
    .catch((err) => {
      //////console.log(`Error getting cusotmer: ${err}`)
    })
   
   return values;   
    }
    
   public updateRequest(organisation, profile, transport, cargo):Subject<any>
   {
     let values:Subject<any> = new Subject<any>();
      
     const body = { organisation: organisation,
                    profile: profile,
                    transport: transport,
                    cargo: cargo
    }
                  
    const data = {  headers: {},
                  response: true,
                  body: body
    }
    
  ////console.log("UpdateData:", data)
    
   API.put('DMLGetQuote', '/items', data).then((i) => {
        // ... and to get the list after you save it.
        values.next(i);
        values.complete();
    })
    .catch((err) => {
      //////console.log(`Error getting cusotmer: ${err}`)
    })
   
   return values;   
    }
    
   public cancelOrder(order, profile):Subject<any>
   {
     let values:Subject<any> = new Subject<any>();
      
     const body = { order: order,
                    profile: profile
                    
    }
                  
    const data = {  headers: {},
                  response: true,
                  body: body
    }
    
  ////console.log("CreateData:", data)
    
  API.post('DMLGetQuote', '/cancel', data).then((i) => {
        // ... and to get the list after you save it.
        values.next(i);
        values.complete();
    })
    .catch((err) => {
      //////console.log(`Error getting cusotmer: ${err}`)
    })
   
   return values;   
    }
}
