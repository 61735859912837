import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {RoleGuard} from './role.guard'
import { HomePage } from './home/home.page';
//import { DashboardPage } from './pages/cust/dashboard/dashboard.page';
import {OrderPage} from './pages/order/order.page'

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home',            component: HomePage},
  {
    path: 'home/:start :dest',            component: HomePage},
  
  { path: 'order',                      component: OrderPage, canActivate: [RoleGuard], 
    data: { expectedRole: 'Admins'} 
  },
/*  {
    path: 'dashboard',                component: DashboardPage, canActivate: [RoleGuard], 
    data: { expectedRole: 'Admins'} 
  },*/
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
