<ion-header translucent>
  <ion-toolbar>
    <ion-title>
      Bestelldaten
    </ion-title>
    <img src="./assets/icon/logo-final.png" >
     <ion-buttons slot="secondary" >
      <ion-button (click)="close()"> 
        <ion-icon slot="icon-only" name="close-circle-outline" ></ion-icon>
      </ion-button>
  </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
   <ion-toolbar>
  
  </ion-toolbar>
   <div>
     <ion-card>
        <ion-item-divider>
          <label>Allgemein</label>
        </ion-item-divider>
      <ion-grid>
        <ion-row >
          <ion-col>
            <ion-item>
              <ion-label position="stacked">Strecke</ion-label>
              <ion-input readonly>{{transport.distance | number:'1.0-0'}} km</ion-input>
            </ion-item>
          </ion-col>

          <ion-col>
            <div class="pricing-style">
            <ion-item  lines="none">
              <ion-label position="stacked">Online Preis</ion-label>
              <ion-input readonly><ins>{{transport.price/100 | currency:'EUR' }}</ins></ion-input>
            </ion-item>
           
            </div>
          </ion-col>
          </ion-row>
          <ion-row>
            <ion-col *ngFor="let item of transport.waypoints; index as i">
             <ion-item>
                <ion-label position="stacked">Geplante Anfunkt Wegpunkt {{i}}:  </ion-label>
                <ion-input readonly>{{item.eta | date:'short':'CET':'de-DE'}}</ion-input>
            </ion-item>
            </ion-col>
          </ion-row>
      </ion-grid>
     </ion-card>
     <ion-card>

      <ion-grid>
        <ion-item-divider>
          <label>Sendung</label>
        </ion-item-divider>
         <ion-row >
           <ion-col>
    
            <mat-form-field appearance="outline" class="links-full-width">
              <mat-label>Sendungsreferenz</mat-label>
              <input matInput readonly type="text" maxlength="36" placeholder="Your Shipping RefNo."  [(ngModel)]="transport.reference" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            </ion-col>
            <ion-col>
            </ion-col>
         </ion-row>
        <ion-row >
            <div>
             <ion-col *ngFor="let item of transport.waypoints, index as i">
                <ion-item-divider>
                  <label>Abschnitt {{i+1}}</label>
                </ion-item-divider>
                 <mat-form-field appearance="outline" class="links-full-width">
              <mat-label>Name / Kunde:</mat-label>
              <input readonly matInput type="text" maxlength="50" placeholder="Point of Loading"  [(ngModel)]="item.name" [ngModelOptions]="{standalone: true}">
              
            </mat-form-field>
            <ion-item>
              <ion-label position="stacked"> Ort </ion-label>
              <ion-input readonly>{{item.address}}</ion-input>
            </ion-item>
              <ion-item>
              <ion-label position="stacked"> Ladezeit </ion-label>
              <ion-input readonly>Zwischen {{item.openingdate | date:'short':'CET':'de-DE'}}  und {{item.closingdate | date:'short':'CET':'de-DE'}}</ion-input>
            </ion-item>
            <mat-form-field appearance="outline" class="links-full-width">
              <mat-label>Referenz Ladestelle</mat-label>
              
              <input readonly matInput type="text" maxlength="25" placeholder="Your Loading RefNo."  [(ngModel)]="item.reference" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
              <ion-col>
                <mat-form-field appearance="outline" class="links-full-width">
                <mat-label>Ansprechpartner</mat-label>
                
                <input readonly matInput type="text" maxlength="25" placeholder="Contact Person"  [(ngModel)]="item.contact" [ngModelOptions]="{standalone: true}">
              </mat-form-field>  
              </ion-col>
                <ion-col>
                <mat-form-field appearance="outline" class="links-full-width">
                <mat-label>Telefonnummer Ansprechpartner</mat-label>
                
                <input readonly matInput type="tel" maxlength="25" placeholder="Phone numer of contact person"  [(ngModel)]="item.reference" [ngModelOptions]="{standalone: true}">
              </mat-form-field>  
              </ion-col>
            <mat-form-field appearance="outline" class="links-full-width">
              <mat-label>Bemerkung:</mat-label>
              <input readonly matInput type="text" maxlength="50" placeholder="Your  notes"  [(ngModel)]="item.notes" [ngModelOptions]="{standalone: true}">
              
            </mat-form-field>  </ion-col>
            </div>
        
         
        </ion-row>
          </ion-grid>
      </ion-card>
  

  <ion-card>
    <ion-item-group>
    <ion-item-divider>
      <label>Trailer</label>
      <p></p>
    </ion-item-divider>
    
        <ion-item>
          <ion-label>{{transport.trailer}}</ion-label>
        </ion-item>
    
   <ion-item>
          <ion-label>{{transport.attributes}}</ion-label>
    </ion-item>
    
  </ion-item-group>
  
  <ion-item-group>
    <ion-item-divider>
      <label> Cargo</label>
    </ion-item-divider>
  
  <ion-card  *ngFor="let item of cargo; index as i">
  <ion-grid>
      <ion-row>
          <ion-col>
    <ion-item>
      
      <ion-label position="stacked">Verpackungsart</ion-label>
      <ion-input [(ngModel)]="item.packaging" [ngModelOptions]="{standalone: true}" readonly ></ion-input>
    </ion-item>
    
    <ion-item>
      <ion-label position="stacked">Kategorie</ion-label>
       <ion-input  [(ngModel)]="item.category" [ngModelOptions]="{standalone: true}" readonly></ion-input>
    </ion-item>
    </ion-col>
  
  <ion-col>
    <ion-item>
      <ion-label position="stacked">Menge</ion-label>
      <ion-input type="number" min = "0" [(ngModel)]="item.quantity" [ngModelOptions]="{standalone: true}" readonly></ion-input>
    </ion-item>
    
    <ion-item>
      <ion-label position="stacked">Ladenummer (optional)</ion-label>
      <ion-input type="text" [(ngModel)]="item.reference" [ngModelOptions]="{standalone: true}" readonly></ion-input>
    </ion-item>
    </ion-col>
    
    <ion-col>
    <ion-item>
      <ion-label position="stacked">Gewicht (je Stück) in kg</ion-label>
      <ion-input  type="number" min = "0" steps="0.1" [(ngModel)]="item.weight" [ngModelOptions]="{standalone: true}" readonly></ion-input>
    </ion-item>
    </ion-col>
    
    </ion-row>
    
    <ion-item-group>
          <ion-item-divider>
            <label>Maße</label>
          </ion-item-divider>
        <ion-row>
          <ion-col>
        <ion-item>
          <ion-label position="stacked">Höhe (in cm)</ion-label>
          <ion-input type="number" min = "0" max="265" [(ngModel)]="item.height" [ngModelOptions]="{standalone: true}" readonly></ion-input>
        </ion-item>
        </ion-col>
        <ion-col>
        <ion-item>
          <ion-label position="stacked">Länge (in cm)</ion-label>
          <ion-input type="number" min = "0" max = "1360" [(ngModel)]="item.length" [ngModelOptions]="{standalone: true}" readonly></ion-input>
        </ion-item>
        </ion-col>
        <ion-col>
        <ion-item>
          <ion-label position="stacked">Breite (in cm)</ion-label>
          <ion-input type="number" min = "0" max = "245" [(ngModel)]="item.width" [ngModelOptions]="{standalone: true}" readonly></ion-input>
        </ion-item>
        </ion-col>
      </ion-row>
  </ion-item-group>
           
    </ion-grid>
  </ion-card>
  <ion-grid>
       <ion-row>
      
       <ion-col>
    </ion-col>
   
    <ion-col>
    <ion-item>
      <ion-label position="stacked">Gesamtvolumen (cbm)</ion-label>
    <ion-input type="number" max="85" steps="0.1" [(ngModel)]="totalvolume" [ngModelOptions]="{standalone: true}" readonly></ion-input>
    </ion-item>
    
    </ion-col>
          
      <ion-col>
    <ion-item>
      <ion-label position="stacked">Gesamtgewicht (t)</ion-label>
      <ion-input type="number" max="24.5" steps="0.1"  [(ngModel)]="totalweight" [ngModelOptions]="{standalone: true}" readonly></ion-input>
    </ion-item>
    </ion-col>
    </ion-row>
  </ion-grid>
  
 </ion-item-group>
   </ion-card>
     
  
   <ion-card>
    <ion-item-group>
    <ion-item-divider>
      <label>Bemerkung: </label>
      <p></p>
    </ion-item-divider>
    
    <ion-item>
       <ion-textarea type="text" maxlength="255" rows="10"  [(ngModel)]="transport.notes" [ngModelOptions]="{standalone: true}" placeholder="Zusätzliche Information an uns..." readonly></ion-textarea>
    </ion-item>     
    </ion-item-group>
    </ion-card>
    
       </div>
</ion-content>